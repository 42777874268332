<div class="itemlist">
  <div class="col10">
    <div class="subtitle">{{ labelTranslation.date }}</div>
  </div>
  <div class="col50">
    <div class="subtitle">{{ labelTranslation.action }}</div>
  </div>
  <div class="col30">
    <div class="subtitle">{{ labelTranslation.parentCompany }}</div>
  </div>
  <div class="col10">
    <div class="subtitle">{{ labelTranslation.dunsNumber }}</div>
  </div>
</div>
<div>
  <div class="row-item" *ngFor="let item of parentChildHistory">
    <tsm-parent-child-history-item [item]="item">
    </tsm-parent-child-history-item>
  </div>
</div>
