import { formatDate } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from "@angular/core";
import { ReportSearchType } from "src/app/models/Search";
import { FilterType, FilterValue, fromToDateFilter } from "src/app/services/esgReport/esg-report.service";
import { Subject } from 'rxjs';
import { ApplyFilterEvent, RequestStatusEvent } from 'src/app/views/report-preview/report-preview.component';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { SubheaderPropsType } from 'src/app/models/SubheaderProps';


@Component({
    template: ""
})
export class BaseSupplierFilterComponent implements OnInit {
    applyFilterEventEmitter = new EventEmitter<ApplyFilterEvent>(true);
    filterCriteria: ReportSearchType;
    invalidFields = [];
    clearFilter: Subject<boolean> = new Subject();
    invalidValue: Subject<string> = new Subject();
    clearFilterButton: ButtonPropsType;
    applyFilterButton: ButtonPropsType;
    reportProps?: SubheaderPropsType;
    filterCriteriaPayload: ReportSearchType;
    showWaitingMessage = false;
    filterRequestCompleted = false;
    waitingMessageLabel = "";


    constructor() { }

    ngOnInit(): void {
        this.reportProps = {
            isWelcomeMessage: false,
            heading: ""
        }
    }

    scheduleWaitingMessage() {
        this.resetWaitingMessage();
        setTimeout(() => this.showWaitingMessage = true, 5000);
    }

    resetWaitingMessage() {
        this.filterRequestCompleted = false;
        this.showWaitingMessage = false;
    }

    checkEmail(email: FilterType) {
        const reg = /^[\w-.]+@[a-zA-Z_.]+?\.[a-zA-Z]{2,3}$/;
        const result = reg.test(this.filterCriteria[email] as string);
        if (result || this.filterCriteria[email] === '') {
            delete this.invalidFields[email];
        } else {
            this.invalidFields[email] = true;
            this.invalidValue.next(email);
        }
    }

    checkDuns(duns: FilterType) {
        const reg = /^[0-9]{9}$/;
        const result = reg.test(this.filterCriteria[duns] as string);
        if (result || this.filterCriteria[duns] === '') {
            delete this.invalidFields[duns];
        } else {
            this.invalidFields[duns] = true;
            this.invalidValue.next(duns);
        }
    }

    validateDates(dateFilter: fromToDateFilter) {
        delete this.invalidFields[dateFilter.from];
        delete this.invalidFields[dateFilter.to];

        let fromDate = this.filterCriteria[dateFilter.from];
        let toDate = this.filterCriteria[dateFilter.to];

        if (fromDate && !(fromDate instanceof Date)) {
            this.invalidFields[dateFilter.from] = true;
            this.invalidValue.next(`${dateFilter.from}`);
        }

        if (toDate && !(toDate instanceof Date)) {
            this.invalidFields[dateFilter.to] = true;
            this.invalidValue.next(`${dateFilter.to}`);
        }

        if (!fromDate || !toDate) {
            return;
        }

        if (toDate < fromDate) {
            this.invalidFields[dateFilter.from] = true;
            this.invalidFields[dateFilter.to] = true;
            this.invalidValue.next(`${dateFilter.from}#${dateFilter.to}`);
        }
    }

    formatPayloadDate(filter: fromToDateFilter) {
        if (this.filterCriteria[filter.from]) {
            this.filterCriteriaPayload[filter.from] = formatDate(this.filterCriteria[filter.from] as Date | string, 'yyyy-MM-dd', 'en_US');
        }
        if (this.filterCriteria[filter.to]) {
            this.filterCriteriaPayload[filter.to] = formatDate(this.filterCriteria[filter.to] as Date | string, 'yyyy-MM-dd', 'en_US');
        }
    }

    setFilterButtonsDisabledProps(isDisabled: boolean) {
        const enableApplyFiltersButton: ButtonPropsType = {
            ...this.applyFilterButton,
            disabled: false,
            backgroundColor: 'blue',
            isProcessing: false,
            borderColor: 'blue',
        };

        const disableApplyFiltersButton: ButtonPropsType = {
            ...this.applyFilterButton,
            disabled: true,
            backgroundColor: 'mediumGrey',
            isProcessing: true,
            borderColor: 'mediumGrey',
        };

        const enableClearFiltersButton: ButtonPropsType = {
            ...this.clearFilterButton,
            disabled: false,
            backgroundColor: 'lightPurple',
            color: 'purple'
        };

        const disableClearFiltersButton: ButtonPropsType = {
            ...this.clearFilterButton,
            disabled: true,
            backgroundColor: 'mediumGrey',
            color: 'white'
        };

        if (isDisabled) {
            this.applyFilterButton = enableApplyFiltersButton;
            this.clearFilterButton = enableClearFiltersButton;
        }
        else {
            this.applyFilterButton = disableApplyFiltersButton;
            this.clearFilterButton = disableClearFiltersButton;
        }
    }

    filterCriteriaChanged(value: FilterValue, filter: FilterType) {
        this.filterCriteria[filter] = value;
    }

    handleRequestStatusEvent(event: RequestStatusEvent) {
        if (event.status === 'Success' || event.status === 'Error') {
            this.setFilterButtonsDisabledProps(true);
            this.filterRequestCompleted = true;
        }
    }
}