<div class="modal-header">
    <h4 class="modal-title">{{EditorTitle}}</h4>
</div>
<div class="modal-body">
    <div class="body-content">
        <div *ngIf="isPublish" class="modal-body-content">
            <p class="deleteFaq">{{publishTip}}</p>
        </div> 
        <div class="button-content" [ngStyle]="{'display': showSaveBtn ? 'block' : 'none'}">
            <sh-button  ngbAutofocus [props]="cancelProps"></sh-button>
            <sh-button   class="saveButton" ngbAutofocus [props]="deleteProps"></sh-button>
        </div>
        <div class="button-content" [ngStyle]="{'display': !showSaveBtn ? 'block' : 'none'}">
            <sh-button  ngbAutofocus [props]="closeProps"></sh-button>
        </div>
    </div>
</div>