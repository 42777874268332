import { Component } from '@angular/core';
import {
  ButtonPropsType,
  ComponentConfig,
  DynamicContext,
  DynamicFormComponent,
  HttpService,
  SupplierRelationshipService,
  TaxTypeService
} from "@lsl16/sustainability-shared-components";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'tsm-pop-up-consent-renewal',
  templateUrl: './pop-up-consent-renewal.component.html',
  styleUrl: './pop-up-consent-renewal.component.sass'
})
export class PopUpConsentRenewalComponent {
  consentRenewalJson: any;
  buttonProps: ButtonPropsType = {
    label: "Okay",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 96,
    height: 48,
    padding: "16px 10px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.cancel();
    }
  };
  constructor(
    public activeModal: NgbActiveModal,
  ) { }
  async ngOnInit() {
    this.consentRenewalJson = {
      title: "",
      content: "",
      okayLabel: ""
    };
  }
  async cancel(): Promise<any> {
    this.activeModal.close();
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.popUp) {
        this.consentRenewalJson = multilanguageJson.popUp.consentRenewal;
      }
    }
    this.buttonProps.label = this.consentRenewalJson.okayLabel;
  }
}