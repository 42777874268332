<sh-alert *ngIf="showAlert" [props]="alertProps"></sh-alert>
<div class="subheader-container" *ngIf="props">
  <div class="text-group-container">
    <div *ngIf="props.isWelcomeMessage; else headingPrefix" class="heading-prefix" [style.color]="headingPrefixColor">
      {{ welcomeMessage | uppercase }}
    </div>
    <ng-template #headingPrefix>
      <div class="heading-prefix" [style.color]="
          props.headingPrefixColor == 'grey' ? '#6d7173' : '#4e4e87'
        " [ngClass]="{
          'header-search-title': props.headingPrefix == 'Supplier Search'
        }">
        {{ headingPrefixLabel ? headingPrefixLabel : props.headingPrefix }}
      </div>
    </ng-template>
    <div class="heading-container">
      <div class="heading" [ngClass]="{
          'header-search-message':
            props.heading ==
            'Use the search bar below to find existing suppliers in the True Supplier Marketplace system. Alternatively, if the desired supplier is not listed, you can add them via the Add New Supplier button.'
        }">
        {{ getHeadingLabel ? getHeadingLabel : getHeading(props.heading) }}
      </div>
      <div *ngIf="props.tag" class="tag">
        <span>
          {{ props.tag }}
        </span>
      </div>
    </div>
    <div *ngIf="isConsentRenewal === 'true'" class="subheading-consent">
      {{consentRenewalPageHeading}}<div class="country">{{countryServedName}}</div>
    </div>
    <div *ngIf="isConsentRenewal !== 'true'">
      <div *ngIf="props.allQuestionsButtonProps" class="questions-filter-buttons">
        <sh-button [props]="props.allQuestionsButtonProps"></sh-button>
      </div>
      <div *ngIf="props.unansweredButtonProps" class="questions-filter-buttons">
        <sh-button [props]="props.unansweredButtonProps"> </sh-button>
      </div>
      <div *ngIf="props.answeredButtonProps" class="questions-filter-buttons">
        <sh-button [props]="props.answeredButtonProps"> </sh-button>
      </div>
    </div>
  </div>
  <div *ngIf="isConsentRenewal !== 'true'">
    <div *ngIf="props.subheaderButtonProps" class="subheader-button">
      <sh-button [props]="props.subheaderButtonProps"></sh-button>
    </div>
  </div>
  <div *ngIf="isConsentRenewal !== 'true'">
    <div *ngIf="props.manageUsersButtonProps && hasValidRole" class="subheader-manage-users-button" triggers="hover"
      [ngbTooltip]="tipContent" isOpen="True" placement="bottom-left" tooltipClass="manage-user-tooltip-class">
      <sh-button [props]="props.manageUsersButtonProps"></sh-button>
    </div>
  </div>
  <ng-template #tipContent>
    <div class="tip-inner-content">
      <div class="tip-header">{{supplierOptionsCard.Tip}}</div>
      {{ buttonJson.manageUsersTooltip }}
    </div>
  </ng-template>
</div>