<div class="assesment mb-12 shadow rounded-0 position-relative border">
    <div class="row ms-3">
        <div class="col-6">
            <div class="title-container mt-3">
                <div class="title">{{esgData?.sustainabilityAssessment}}</div>
                <div class="sub_title_name">{{esgData?.ownSA}}</div>
            </div>
        </div>
        <div class="col-6">
            <sh-button class="fl_right me-5 mt-3" [props]="manageUsersButton"
                [ngbTooltip]="manageUsersButton?.tooltipText && !isDuplicate ? tipOption : null" placement="bottom-right"
                tooltipClass="manage-user-tooltip-class">
            </sh-button>
            <ng-template #tipOption>
                <div class="tip-inner-content">
                  <div class="tip-header">
                    {{optionsCardData.Tip}}
                  </div>
                  {{ buttonJson.manageUsersTooltip }}
                </div>
              </ng-template>
        </div>
    </div>
    <div class="row mt-3  ms-3 table-header" *ngIf= "exhibitAssessmentList.length != 0">
        <div class="col-countriesServed col-common" >{{subSupplierData.countriesServed}}</div>
        <div class="col-requesteddate col-common" style="white-space: nowrap;">{{esgData.requestDate}}</div>
        <div class="col-status col-common">
            {{esgData.status}}
            <img class="icon-size icon-ml" alt="icon-info" src="assets/icons/info-blue.svg" (click)="showStatusPopup()"/>
        </div>
        <div class="col-actions col-common ml-3">{{optionsCardData.ACTIONS}}</div>
    </div>
    <div class="row ms-3 header-border"></div>
        <div class="table-overflow">
            <div *ngFor="let item of exhibitAssessmentList">
                <div class="row mt-3  ms-3">
                    <div class="col-countriesServed col-common col-font-common">
                        <div class="text-overflow" placement="bottom-left" triggers="hover" [ngbTooltip]="tipServedCountry" tooltipClass="manage-user-tooltip-class" container="body">
                            {{item.servedCountryLabelAfterTranslate.join(', ')}}
                        </div>
                        <ng-template #tipServedCountry>
                            <div class="tip-inner-content">
                                {{item.servedCountryLabelAfterTranslate.join(', ')}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-requesteddate col-common col-font-common">{{item.creationDate | date: 'MM/dd/yyyy'}}</div>
                    <div class="col-status col-common">
                        <div class="width-100">
                            <div class="colstatus-complete colstatus-common" [ngClass]="item.esgStatusLevel>=4?'colstatus-complete':'colstatus-process'">{{item.esgStatusLabelt}}</div>
                            <div class="display-flex">
                                <div class="status-bar-common" [ngClass]="item.esgStatusLevel==4?'status-bar-complete':item.esgStatusLevel>=1?'status-bar-process':'status-bar-empty'" ></div>
                                <div class="status-bar-common" [ngClass]="item.esgStatusLevel==4?'status-bar-complete':item.esgStatusLevel>=2?'status-bar-process':'status-bar-empty'"></div>
                                <div class="status-bar-common" [ngClass]="item.esgStatusLevel==4?'status-bar-complete':item.esgStatusLevel>=3?'status-bar-process':'status-bar-empty'"></div>
                                <div class="status-bar-common" [ngClass]="item.esgStatusLevel>=4?'status-bar-complete':'status-bar-empty'"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-actions col-common col-font-common ml-3">
                        <div class="width-100 display-flex" style="white-space: nowrap;">
                            <a class="a-button" (click)="jumpPage(item)">{{item?.actiont}}</a>
                        </div>
                        <div style="padding-right:50px;margin-left: 1rem;">
                            <a class="a-button" style="white-space: nowrap;padding-right :-5px;" (click)="jumpPage(item,item.actionTwo)">{{item?.actionTwot}}</a>
                        </div>
                    </div>
                    <div class="col-subsidiary col-common ml-1" style="display: flex;" *ngIf="item.isSubsidiary">
                        <div class="width-100">
                            <div class="subsidiary-color subsidiary-common">{{esgData.subsidiary}}</div>
                        </div>
                        <img class="subsidiary-icon" style="margin-left:0.5rem" alt="icon-info" src="assets/icons/info-blue.svg" [ngbTooltip]="tipSubsidiary" tooltipClass="manage-user-tooltip-class" triggers="hover" container="body"/>
                        <ng-template #tipSubsidiary>
                            <div class="tip-inner-content">
                                <div class="tip-header">
                                    {{optionsCardData.subsidiary}}
                                </div>
                                <div>
                                    {{svSupplierProfileData}}
                                </div> 
                                <div class="text mt-3">
                                    <div class="d-flex">
                                        <div>
                                            {{optionsCardData.subsidiary}}
                                        </div>
                                        <div class="text-bold subsidiary-sa-info-value">
                                           {{item.originalTask?.supplier.supplierName}}
                                        </div>
                                    </div>
                              
                                    <div class="d-flex">
                                        <div>
                                          {{optionsCardData.sentTo}}
                                        </div>
                                        <div class="text-bold subsidiary-sa-info-value">
                                            {{item.originalTask?.supplier.supplierAdmin.userName}}
                                        </div>
                                    </div>
                              
                                    <div class="d-flex">
                                        <div>
                                          {{optionsCardData.sentOn}}
                                        </div>
                                        <div class="text-bold subsidiary-sa-info-value">
                                            {{item.originalTask?.creationDate | date: 'MM/dd/yyyy'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="row ms-3 header-border"></div>
            </div>
        </div>
        <div class="row mb-4"></div>
  </div>