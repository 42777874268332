import { Injectable } from '@angular/core';
import { SubheaderPropsType } from '../../../models/SubheaderProps';

@Injectable()
export class SystemAdminBoardSubheader {
  constructor() {}

  /**
   * Description: Provides props for subheader component in user management page
   * Params: none
   * Output: Subheader props object of type SubheaderPropsType
   */
  getProps = (): SubheaderPropsType => {
    return {
      isWelcomeMessage: false,
      headingPrefix: 'system admin',
      headingPrefixColor: 'grey',
      heading: 'Manage Users',
    };
  };
}
