import { ConfirmCancelRelationshipRequestComponent } from './../../components/confirm-cancel-relationship-request/confirm-cancel-relationship-request.component';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmWithdrawRelationshipRequestComponent } from './../../components/confirm-withdraw-relationship-request/confirm-withdraw-relationship-request.component';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonPropsType, ParentChildRelation, SupplierRelationshipService } from "@lsl16/sustainability-shared-components";
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupplierGeneralProfileService } from 'src/app/services/supplier-general-profile.service';

type parentDetailLabels = {
  parent: string;
  parentDuns: string;
  website: string;
  parentStatus: string;
  subsidiaryStatus: string;
  rejectReason: string;
  withdrawRelationshipButtonLabel: string;
  cancelRelationshipButtonLabel: string;
};

@Component({
  selector: 'tsm-supplier-parent-detail',
  templateUrl: './supplier-parent-detail.component.html',
  styleUrls: ['./supplier-parent-detail.component.sass'],
})
export class SupplierParentDetailComponent implements OnInit {
  @Input() supplierTsmId: string;
  @Input() isDuplicate = false;
  public parentChildRelation?: ParentChildRelation;
  public showRevokeRelationshipButton: boolean = false;
  private relationChangedSubscription: Subscription;
  companyWebsiteDisplay: string;
  showWebsiteFlag: boolean = false;
  showParentTitleFlag: boolean = false;
  supplier: any;

  showWebsite(): void {
    if (this.companyWebsiteDisplay !== this.parentData.website) {
      this.showWebsiteFlag = true;
    }
  }

  hideWebsite(): void {
    this.showWebsiteFlag = false;
  }

  showParentTitle(): void{    
    this.showParentTitleFlag = true;
  }

  hideParentTitle(): void{    
    this.showParentTitleFlag = false;
  }

  public parentData = {
    name: '',
    dunsNumber: '',
    website: '',
    parentStatus: '',
    childStatus: '',
    country: '',
    rejectReason: '',
  };

  public labelTranslations: parentDetailLabels = {
    parent: '',
    parentDuns: '',
    website: '',
    parentStatus: '',
    subsidiaryStatus: '',
    rejectReason: '',
    withdrawRelationshipButtonLabel: '',
    cancelRelationshipButtonLabel: '',
  };

  public revokeRelationshipButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 14,
    fontFamily: 'Graphik-Medium',
    padding: '0px 0px',
    disabled: this.isDuplicate,
    margin: '15px 0px 0px 0px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    icon: 'assets/icons/x-grey.svg',
    iconAlt: 'icon-withdraw',
    iconHeight: 14,
    iconWidth: 14,
    onClick: () => {
      if (!this.isDuplicate) {
        this.redirectToRevokeProcess();
      }
    },
  };

  constructor(
    private relationshipService: SupplierRelationshipService,
    private router: Router,
    private authService: AuthService,
    private ngbModalService: NgbModal,
    public supplierGeneralProfileService: SupplierGeneralProfileService
  ) {}

  async ngOnInit() {
    this.subscribeEvent();
    this.supplier = await this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId);
    this.isDuplicate =
    this.supplier?.supplierName.substring(0, 10).toLowerCase() ===
    'duplicate-';
  }

  private subscribeEvent() {
    this.relationChangedSubscription = this.relationshipService
      .getSupplierRelationChangedEvent()
      .subscribe((next) => this.handleSupplierRelationChanged(next));
  }

  async ngOnChanges(): Promise<void> {
    await this.loadParentData();
    await this.handleRevokeRelationshipButtonVisibility();
  }

  private redirectToRevokeProcess(): void {
    const revokeRelationshipModalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      windowClass: 'add-parent-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };

    if (this.parentChildRelation?.status === 'AwaitingParentConfirmation') {
      this.openConfirmWithdrawRelationshipModal(revokeRelationshipModalOptions);
    }
    if (this.parentChildRelation?.status === 'MutuallyConfirmed') {
      this.openConfirmCancelRelationshipModal(revokeRelationshipModalOptions);
    }
  }

  private openConfirmWithdrawRelationshipModal(
    withdrawRelationshipModalOptions: NgbModalOptions
  ): void {
    const modalReference = this.ngbModalService.open(
      ConfirmWithdrawRelationshipRequestComponent,
      withdrawRelationshipModalOptions
    );
    modalReference.componentInstance.parentTsmId =
      this.parentChildRelation.parentTsmId;
    modalReference.componentInstance.subsidiaryTsmId = this.supplierTsmId;
  }

  private openConfirmCancelRelationshipModal(
    cancelRelationshipModalOptions: NgbModalOptions
  ): void {
    const modalReference = this.ngbModalService.open(
      ConfirmCancelRelationshipRequestComponent,
      cancelRelationshipModalOptions
    );
    modalReference.componentInstance.parentTsmId =
      this.parentChildRelation.parentTsmId;
    modalReference.componentInstance.subsidiaryTsmId = this.supplierTsmId;
    modalReference.componentInstance.invokedBy = 'Subsidiary';
  }

  private async handleRevokeRelationshipButtonVisibility(): Promise<void> {
    // only supplier can see the button
    const hasValidRole =
      this.authService.getRole() === 'supplier' &&
      (this.authService.isGeneralSupplier() ||
        this.authService.isSupplierAdmin());
    if (!hasValidRole) {
      this.showRevokeRelationshipButton = false;
      return;
    }

    // button is only awailable when awaiting parent confirmation
    const hasValidParentChildRelation =
      this.parentChildRelation.status === 'AwaitingParentConfirmation' ||
      this.parentChildRelation.status === 'MutuallyConfirmed';
    if (!hasValidParentChildRelation) {
      this.showRevokeRelationshipButton = false;
      return;
    }

    // current user must belong to the supplier that is being displayed
    const parentAccess =
      await this.relationshipService.hasAccessToParentSupplier(
        this.supplierTsmId
      );
    if (!parentAccess.self) {
      this.showRevokeRelationshipButton = false;
      return;
    }
    // Button will be disabled for duplicate supplier profiles
    this.revokeRelationshipButtonProps.disabled = this.isDuplicate;
    
    this.showRevokeRelationshipButton = true;
  }

  private async handleSupplierRelationChanged(supplierTsmId: string) {
    if (supplierTsmId === this.supplierTsmId) {
      await this.loadParentData();
      await this.handleRevokeRelationshipButtonVisibility();
    }
  }

  private async loadParentData(): Promise<void> {
    await this.updateParentRelation();
    await this.updateParentData();
  }

  private async updateParentRelation(): Promise<void> {
    this.parentChildRelation =
      await this.relationshipService.getParentChildRelation(this.supplierTsmId);
  }

  private async updateParentData(): Promise<void> {
    if (!this.parentChildRelation?.parent) {
      this.parentData.dunsNumber = '';
      this.parentData.name = '';
      this.parentData.website = '';
      this.parentData.country = '';
      return;
    }

    this.parentData.dunsNumber = this.parentChildRelation.parent.dunsNumber;
    this.parentData.name = this.parentChildRelation.parent.supplierName;
    this.parentData.website = this.parentChildRelation.parent.companyWebsite;
    this.parentData.country = this.parentChildRelation.parent.countryLocation;
  }

  navigateToParentProfile(): void {
    let redirectUrl;
    if (this.authService.getRole() === 'supplier') {
      redirectUrl = `parent-supplier-profile/${this.parentChildRelation.parentTsmId}`;
    } else {
      redirectUrl = `supplier-profile/${this.parentChildRelation.parentTsmId}`;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([redirectUrl]);
  }

  showLinkText(): boolean {
    return false;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations =
          multilanguageJson.body.main.supplierParentDetail;

        if (this.parentChildRelation?.status === 'AwaitingParentConfirmation') {
          this.revokeRelationshipButtonProps.label =
            this.labelTranslations.withdrawRelationshipButtonLabel;
        } else if (this.parentChildRelation?.status === 'MutuallyConfirmed') {
          this.revokeRelationshipButtonProps.label =
            this.labelTranslations.cancelRelationshipButtonLabel;
        }

        if (this.parentChildRelation) {
          this.parentData.childStatus =
            multilanguageJson.body.main.parentChildRelationLiterals.parentChildStatusLiterals[
              this.parentChildRelation.childStatus
            ];
          this.parentData.parentStatus =
            multilanguageJson.body.main.parentChildRelationLiterals.parentChildStatusLiterals[
              this.parentChildRelation.parentStatus
            ];
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.relationChangedSubscription) {
      this.relationChangedSubscription.unsubscribe();
    }
  }
}
