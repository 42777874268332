import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ButtonPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { Popup } from "../../models/Popup";
import { ModalService } from "src/app/services/modal/modal.service";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { UpdateRejectRequestConfig } from "./UpdateRejectRequestConfig";

@Component({
  selector: "tsm-pop-up-sa-update-reject",
  templateUrl: "./pop-up-sa-update-reject.component.html",
  styleUrls: ["./pop-up-sa-update-reject.component.sass"]
})

export class PopUpSaUpdateRejectComponent implements OnInit {
  public redirectUrl = "/termination-request-board";
  @Input() confirmType: UpdateRejectRequestConfig;
  @Input() opened: boolean = true;
  modalData: Popup;
  rejectParams: any;
  rejectReason: any;
  rejectButton: ButtonPropsType = {
    label: "Reject",
    backgroundColor: "gray",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 102,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 24px",
    hoverBackgroundColor: "gray",
    hoverColor: "white",
    onClick: async () => { }
  };
  cancelButton: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 102,
    height: 48,
    padding: "16px 22px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "purple",
    hoverColor: "white",
    borderColor: "palePurple"
  };

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    public modalService: ModalService,
    public ngbModalService: NgbModal,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.modalData = this.modalService.getSAUpdateRejectData();
    this.cancelButton.onClick = this.activeModal.close;
  }

  async rejectRequest() {
    this.rejectParams = {
      rejectReason: this.rejectReason,
      ...this.confirmType.rejectParams
    };
    const getTerminateUrl = `${environment.tsmBackendServiceURL}/supplierUpdateSA/reject`;
    const response = await this.httpService.PostPromise(getTerminateUrl, this.rejectParams);
    if (response) {
      this.activeModal.close(response);
    }
  }

  onKeyUp() {
    if (this.rejectReason.length === undefined || this.rejectReason.length === 0) {
      this.rejectButton = {
        label: "Reject",
        backgroundColor: "gray",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        width: 102,
        height: 48,
        padding: "16px 24px",
        margin: "0 0 0 24px",
        hoverBackgroundColor: "gray",
        hoverColor: "white",
        onClick: async () => { }
      };
    } else {
      this.rejectButton = {
        label: "Reject",
        backgroundColor: "blue",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        width: 102,
        height: 48,
        padding: "16px 24px",
        margin: "0 0 0 24px",
        hoverBackgroundColor: "darkBlue",
        hoverColor: "white",
        onClick: async () => {
          await this.rejectRequest();
        }
      }
    }
  }
}