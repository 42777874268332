<tsm-subheader [props]="subheaderProps"></tsm-subheader>

<tsm-request-multiple-sa-process-flow></tsm-request-multiple-sa-process-flow>

<div class="tab-container">
  <tsm-tab-bar [tabs]="tabs"></tsm-tab-bar>
</div>

<div *ngFor="let tab of tabs">
  <div *ngIf="tab.active && tab.id === 'Search'">
    <tsm-request-multiple-sa-search></tsm-request-multiple-sa-search>
  </div>
  <div *ngIf="tab.active && tab.id === 'Upload'">
    <tsm-request-multiple-sa-upload></tsm-request-multiple-sa-upload>
  </div>
</div>
