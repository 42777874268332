import { Component } from '@angular/core';
import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsm-company-size-filter',
  templateUrl: './company-size-filter.component.html',
  styleUrls: ['./company-size-filter.component.sass']
})
export class CompanySizeFilterComponent {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;

  values: any[];
  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }
  companySize: any[];

  constructor() { }

  async ngOnInit(): Promise<void> {
    let questionnire = localStorage.getItem('Getting Started');
    let resultQuestionnire = JSON.parse(questionnire);
    const keys = Object.keys(resultQuestionnire['Gen8'].data);
    this.companySize = keys.map(s=> {return {Code:s,Text:s}});
    this.clearFilter.subscribe(() => this.clear());
  }

  dropdownChanged(selectedValues) {
    this.values = selectedValues;
    this.valueChanged.emit(this.values);
  }

  clear() {
    this.values = [];
    this.companySize.forEach(cs => {
      cs.checked = false;
    });
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.SaRetriggeringProcessFlow.companySize;
      }
    }
  }

}
