import { Component, Input, OnInit } from "@angular/core";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { Termination } from "src/app/models/Termination";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpRejectComponent } from "../../pop-up-reject/pop-up-reject.component";
import { PopUpTerminateComponent } from "../../pop-up-terminate/pop-up-terminate.component";
import { DropdownItemPropsType } from "src/app/models/SubheaderMultipleDropdown";
import { RejectRequestConfig } from "../../pop-up-reject/rejectRequest";
import { TerminateRequestConfig } from "../../pop-up-terminate/terminateRequest";

@Component({
  selector: 'tsm-termination-request-log-item',
  templateUrl: './termination-request-log-item.component.html',
  styleUrls: ['./termination-request-log-item.component.sass']
})
export class TerminationRequestLogItemComponent implements OnInit {
  @Input() model: Termination;
  @Input() id: string;
  rejectButton: ButtonPropsType;
  terminateButton: ButtonPropsType;
  countryServedMapping: string;
  countryServed: string;
  countries: DropdownItemPropsType[];
  rejectRequestConfig: RejectRequestConfig = {
    type: "reject-request",
    options: {},
    rejectParams: {},
    size: "lg",
    centered: true,
    windowClass: "modal-reject"
  };
  terminateRequestConfig: TerminateRequestConfig = {
    type: "terminate-request",
    options: {},
    terminationParams: {},
    size: "lg",
    centered: true,
    windowClass: "modal-terminate"
  };
  countryList: any;

  constructor(private ngbModalService: NgbModal) { }

  ngOnInit(): void {
    this.countryList = JSON.parse(localStorage.getItem('countryList'));
    let list = this.model.countryServed.split(',')
    if (list.length < 2) {
      this.model.countryServed = this.countryList.mapping[`${this.model.countryServed}`];
    } else {
      this.model.countryServed = this.resetCountryServed(list);
    }
    if (this.model.statusType === 1) {
      this.rejectButton = {
        label: "Reject",
        color: "purple",
        fontSize: 14,
        backgroundColor: "white",
        borderColor: "palePurple",
        hoverColor: "purple",
        hoverBackgroundColor: "white",
        hoverBorderColor: "palePurple",
        height: 40,
        width: 67,
        padding: "10px",
        iconPosition: "left",
        onClick: () => this.openRejectPopUp()
      };
      this.terminateButton = {
        label: "Withdraw",
        color: "white",
        fontSize: 14,
        backgroundColor: "blue",
        borderColor: "palePurple",
        hoverColor: "white",
        hoverBackgroundColor: "blue",
        hoverBorderColor: "palePurple",
        height: 40,
        width: 92,
        padding: "10px",
        iconPosition: "left",
        onClick: () => this.openTerminatePopUp()
      };
    } else {
      this.rejectButton = {
        label: "Reject",
        color: "white",
        fontSize: 14,
        backgroundColor: "gray",
        borderColor: "gray",
        hoverColor: "white",
        hoverBackgroundColor: "gray",
        hoverBorderColor: "gray",
        height: 40,
        width: 67,
        padding: "10px",
        iconPosition: "left",
        onClick: () => { }
      };
      this.terminateButton = {
        label: "Withdraw",
        color: "white",
        fontSize: 14,
        backgroundColor: "gray",
        borderColor: "gray",
        hoverColor: "white",
        hoverBackgroundColor: "gray",
        hoverBorderColor: "gray",
        height: 40,
        width: 92,
        padding: "10px",
        iconPosition: "left",
        onClick: () => { }
      };
    }
  }

  resetCountryServed(list) {
    let countries = '';
    for (let a = 0; a < list.length; a++) {
      if (countries == '') {
        countries = countries + this.countryList.mapping[list[a]]
      } else {
        countries = countries + ', ' + this.countryList.mapping[list[a]]
      }
    }
    return countries;
  }

  openRejectPopUp() {
    const confirmType = this.rejectRequestConfig;
    const rejectParams = {
      terminationId: this.model.id
    };
    confirmType.rejectParams = rejectParams;
    const modalReference = this.ngbModalService.open(PopUpRejectComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
    let tsmRoot  =  <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden","false");
    }
  }

  openTerminatePopUp(): void {
    const confirmType = this.terminateRequestConfig;
    const terminationParams = {
      terminationId: this.model.id,
      taskId: this.model.taskId
    };
    confirmType.terminationParams = terminationParams;
    const modalReference = this.ngbModalService.open(PopUpTerminateComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
    let tsmRoot  =  <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden","false");
    }
  }
}
