<div class="row mt-24">
  <div class="col-6">
    <ul class="company-general-info">
      <li class="info-item title">
        <span *ngIf="mode === 'subsupplier'; else parentLabel">{{
          modalTranslation.supplierNameLabel
        }}</span>
        <ng-template #parentLabel
          ><span>{{
            modalTranslation.parentCompanyNameLabel
          }}</span></ng-template
        >
      </li>
      <li class="info-item-item">
        <span>{{ confirmSupplier.supplierName }}</span>
      </li>
    </ul>
  </div>
  <div class="col-6">
    <ul class="company-general-info">
      <li class="info-item title">
        <span>{{ modalTranslation.locationLabel }}</span>
      </li>
      <li class="info-item-item">
        <span>{{ confirmSupplier.countryLocation }}</span>
      </li>
    </ul>
  </div>
</div>
<div class="row mt-24">
  <div class="col-6">
    <ul class="company-general-info">
      <li class="info-item title">
        <span>{{ modalTranslation.websiteLabel }}</span>
      </li>
      <li class="info-item-item">
        <span>{{ confirmSupplier.companyWebsite }}</span>
      </li>
    </ul>
  </div>
  <div class="col-6">
    <ul class="company-general-info">
      <li class="info-item title">
        <span>{{ modalTranslation.domainNameLabel }}</span>
      </li>
      <li class="info-item-item">
        <span>{{ confirmSupplier.companyDomainName }}</span>
      </li>
    </ul>
  </div>
</div>
<div class="row mt-24">
  <div class="col-6">
    <ul class="company-general-info">
      <li class="info-item title">
        <span>{{ modalTranslation.dunsNumberLabel }}</span>
      </li>
      <li class="info-item-item">
        <span>{{ confirmSupplier.dunsNumber }}</span>
      </li>
    </ul>
  </div>
</div>
<div class="row mt-24">
  <div class="col-6">
    <ul class="company-general-info">
      <li class="info-item title">
        <span>{{ modalTranslation.pocNameLabel }}</span>
      </li>
      <li class="info-item-item">
        <span>{{ this.getObfuscatedPocName() }}</span>
      </li>
    </ul>
  </div>
</div>
<div class="row mt-24">
  <div class="col-6">
    <ul class="company-general-info">
      <li class="info-item title">
        <span>{{ modalTranslation.pocEmailLabel }}</span>
      </li>
      <li class="info-item-item">
        <span>{{ this.getObfuscatedPocEmail() }}</span>
      </li>
    </ul>
  </div>
</div>
