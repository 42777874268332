import { Injectable, EventEmitter } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";

@Injectable({
    providedIn: "root"
})
export class EventService {
    public eventEmit: any;
    public privacyStatementEmit: any;
    public psCallbackEmit: any;

    constructor(private httpService: HttpService) {
        this.eventEmit = new EventEmitter();
        this.privacyStatementEmit = new EventEmitter();
        this.psCallbackEmit = new EventEmitter();
    }

}
