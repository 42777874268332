import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonPropsType, Popup } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'tsm-pop-up-exit-addsupplier',
  templateUrl: './pop-up-exit-addsupplier.component.html',
  styleUrls: ['./pop-up-exit-addsupplier.component.sass']
})
export class PopUpExitAddsupplierComponent implements OnInit {

  popData: Popup;
  exitButtonProps: ButtonPropsType = {
    label: "Still Exit",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 150,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    borderColor: "black-12",
    onClick: () => {
      this.router.navigateByUrl("/dashboard-search");
      this.activeModal.close();
    }
  };

  backButtonProps: ButtonPropsType = {
    label: "Back To",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 150,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    borderColor: "black-12",
    onClick: async () => {
      this.activeModal.close();
    }
  };

  constructor(private modalService: ModalService, private activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit(): void {
    this.popData = this.modalService.getExitAddSupplierData();
  }

}
