<div class="modal-header">
    <h4 class="modal-title">{{ modalData.title }}</h4>
</div>
<div class="modal-body">
    <div class="modal-body-content" style="min-height: 0!important;">
        <div style="margin-bottom:18.5px;" *ngIf="confirmType.content === 'action'">
            In this step, the country selection is limited to countries which share the same onboarding questionnaire.
            To onboard this supplier to other counties which do not use the same questionnaire or process, you have to
            initiate a separate onboarding request in TSM from the
            <span style="color: blue; cursor: pointer;" (click)="jmppage()">
                Supplier Profile
            </span>
            Onboarding action.
        </div>
        <div style="margin-bottom:18.5px;" *ngIf="confirmType.content === 'supplier'">
            The country selection is limited to countries which share the same onboarding questionnaire. You can send
            one request to multiple countries which share same onboarding questionnaire, or one specific country.
        </div>
        <div *ngFor="let item of actionCountryServed" style="font-size:14px;margin-bottom: 15px;position: relative;">
            <label class="countryname">
                <input name="countryname"
                    style="height:16.7px;width:16.7px;margin-right:11.7px;position: absolute;top: 2px;" type="checkbox"
                    [disabled]="item.disabled" [checked]="item.checked" (click)="check(item)">
                <span style="margin-left: 30px">{{item.countryServedName}}</span>
            </label>
        </div>
    </div>
    <div class="d-flex" style="margin: 30px;">
        <sh-button ngbAutofocus [props]="buttonProps1" style="width: 120px;"> </sh-button>
        <sh-button ngbAutofocus [props]="buttonProps2"> </sh-button>
    </div>
</div>