import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { ButtonPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { manageSARequestApproveModel } from "src/app/models/manageSArequestApprove";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpSaUpdateRejectComponent } from "../../pop-up-sa-update-reject/pop-up-sa-update-reject.component";
import { PopUpSaUpdateApproveComponent } from "../../pop-up-sa-update-approve/pop-up-sa-update-approve.component";
import { popUpManageSARequestMessageComponent } from "../pop-up-manage-SA-request-message/pop-up-manage-SA-request-message.component";
import { DropdownItemPropsType } from "src/app/models/SubheaderMultipleDropdown";
import { UpdateRejectRequestConfig } from "../../pop-up-sa-update-reject/UpdateRejectRequestConfig";
import { UpdateApproveRequestConfig } from "../../pop-up-sa-update-approve/updateApproveRequest";
import { LoginUser } from 'src/app/models/LoginUser';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from "src/environments/environment";
@Component({
  selector: 'tsm-manage-SA-request-approve-log-item',
  templateUrl: './manage-SA-request-approve-log-item.component.html',
  styleUrls: ['./manage-SA-request-approve-log-item.component.sass']
})
export class manageSARequestApproveLogItemComponent implements OnInit {
  @Input() model: manageSARequestApproveModel;
  @Input() id: string;
  @Output() private reQuery = new EventEmitter<any>();
  rejectButton: ButtonPropsType;
  terminateButton: ButtonPropsType;
  countryServedMapping: string;
  countryServed: string;
  countries: DropdownItemPropsType[];
  loggedInUser: LoginUser;
  rejectRequestConfig: UpdateRejectRequestConfig = {
    type: "reject-request",
    options: {},
    rejectParams: {},
    size: "lg",
    centered: true,
    windowClass: "modal-reject"
  };
  terminateRequestConfig: UpdateApproveRequestConfig = {
    type: "terminate-request",
    options: {},
    terminationParams: {},
    size: "lg",
    centered: true,
    windowClass: "modal-terminate"
  };
  countryList: any;
  countryServedData: any = [];
  countryServedResponse: any = [];

  constructor(private ngbModalService: NgbModal, private authService: AuthService, private HttpService: HttpService) { }

  ngOnInit(): void {
    this.countryList = JSON.parse(localStorage.getItem('countryList'));
    let list = this.model.countryServed
    if (!Array.isArray(list)) {
      list = list.split(',')
    }
    if (list.length < 2) {
      this.model.countryServed = this.countryList.mapping[`${this.model.countryServed}`];
    } else {
      this.model.countryServed = this.resetCountryServed(list);
    }
    if (this.model.status === 'Update Requested') {
      this.rejectButton = {
        label: "Reject",
        color: "purple",
        fontSize: 14,
        backgroundColor: "white",
        borderColor: "palePurple",
        hoverColor: "purple",
        hoverBackgroundColor: "white",
        hoverBorderColor: "palePurple",
        height: 40,
        padding: "10px",
        iconPosition: "left",
        onClick: () => this.openRejectPopUp()
      };
      this.terminateButton = {
        label: "Approve",
        color: "white",
        fontSize: 14,
        backgroundColor: "blue",
        borderColor: "palePurple",
        hoverColor: "white",
        hoverBackgroundColor: "blue",
        hoverBorderColor: "palePurple",
        height: 40,
        padding: "10px",
        iconPosition: "left",
        onClick: () => this.openTerminatePopUp()
      };
    } else {
      this.rejectButton = {
        label: "Reject",
        color: "mediumGrey",
        fontSize: 14,
        backgroundColor: "white",
        borderColor: "white",
        hoverColor: "mediumGrey",
        hoverBackgroundColor: "white",
        hoverBorderColor: "white",
        height: 40,
        padding: "10px",
        iconPosition: "left",
        disabled: true,
        clickBorderColor: 'white',
        onClick: () => { }
      };
      this.terminateButton = {
        label: "Approve",
        color: "white",
        fontSize: 14,
        backgroundColor: "gray",
        borderColor: "gray",
        hoverColor: "white",
        hoverBackgroundColor: "gray",
        hoverBorderColor: "gray",
        height: 40,
        disabled: true,
        padding: "10px",
        iconPosition: "left",
        onClick: () => { }
      };
    }
  }

  resetCountryServed(list) {
    let countries = '';
    for (let a = 0; a < list.length; a++) {
      if (countries == '') {
        countries = countries + this.countryList.mapping[list[a]]
      } else {
        countries = countries + ', ' + this.countryList.mapping[list[a]]
      }
    }
    return countries;
  }

  async getCountryServedLatam(){
    const countryServed = this.model.countryServed
    const countryServedDataUrl = `${environment.tsmBackendServiceURL}/countries/activeCountryServed`;
    this.countryServedData = await this.HttpService.GetPromise(countryServedDataUrl);
    this.countryServedResponse = this.countryServedData.filter(i => (i.countryServedName === countryServed)).map(j => j.tsmCode);
    return this.countryServedResponse;
  }


  async openRejectPopUp() {
    const countryServedResponseReject = await this.getCountryServedLatam();
    const confirmType = this.rejectRequestConfig;
    const rejectParams = {
      taskId: this.model.taskId,
      id: this.model.id,
      authosrityUserId: this.authService.getLoginUser().userId,
      supplierName: this.model.supplierName,
      countryServedReject: countryServedResponseReject
    };
    confirmType.rejectParams = rejectParams;
    const modalReference = this.ngbModalService.open(PopUpSaUpdateRejectComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
    modalReference.result.then((result) => {
      this.operationCallBack(result)
    });
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  async openTerminatePopUp(): Promise<void> {
    const countryServedResponseApprove = await this.getCountryServedLatam();
    const confirmType = this.terminateRequestConfig;
    const approveParams = {
      taskId: this.model.taskId,
      id: this.model.id,
      authosrityUserId: this.authService.getLoginUser().userId,
      supplierName: this.model.supplierName,
      countryServedApprove: countryServedResponseApprove
    };
    confirmType.terminationParams = approveParams;
    const modalReference = this.ngbModalService.open(PopUpSaUpdateApproveComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
    modalReference.result.then((result) => {
      this.operationCallBack(result)
    });
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }
  operationCallBack(result) {
    if (result.msg && !result.data && result.msg === 'The status of this request has changed, please refresh the page.') {
      this.ngbModalService.open(popUpManageSARequestMessageComponent, this.terminateRequestConfig).result.then(() => {
        this.reQuery.emit()
      })
    } else {
      this.reQuery.emit()
    }
  }
}
