import { Injectable } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import {
    privacyStatementModalData,
    privacyStatementModalOptions,
    termsOfUseData,
    termsOfUseOptions
} from "./privacyStatementModalData";
import {
    requestorDataOptions
} from "./requestorModalData"
import { ModalType, PrivacyStatementResponseType , TermOfUseResponse } from "./types";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { popData, popNewData } from "src/app/components/pop-up/popData";
import { requestorData } from "src/app/components/pop-up-bv/popData";
import { Profile } from "src/app/components/profile-details/profile";
import {
    profileDetailsData, profileDetailsOptions,
} from "src/app/components/profile-details/profileDetailsData";
import { Popup } from "src/app/models/Popup";
import { B2cService } from "../b2c/b2c.service";
import {
    welcomeContent,
    welcomeModalData,
    welcomeModalOptions
} from "./welcomeModalData";
import { SupplierGeneralProfileService } from "../supplier-general-profile.service";
import { popDeleteUserData, popDeleteUserOptions } from "src/app/components/pop-up-delete-user/popDeleteUserData";
import { popDisabledData } from "src/app/components/pop-up-disabled/popDisabledData";
import { popInactiveData } from "src/app/components/pop-up-inactive/popInactiveData";
import { popSupplierData } from "src/app/components/pop-up-promisionsupplier/popPromissionData";
import { popBuyerData } from "src/app/components/pop-up-promisionbuyer/popPromisionBuyerData";
import { managerequestrData } from "src/app/components/pop-up-bv-managerequest/pop-managerequest";
import { terminationData } from "src/app/components/bv-pop-up-termination/pop-termination";
import { onboardingData } from "src/app/components/bv-pop-up-onboarding/pop-onboarding";
import { countryNodifySendSaData, countryNodifyWithdrawSaData, countryNodifyPCmapping } from "src/app/components/pop-up-countryserved-existing/pop-country-notyfy";
import { successData } from "src/app/components/bv-pop-up-success/pop-success";
import { popExitAddSupplierData, popExitAddSupplierOptions } from "src/app/components/pop-up-exit-addsupplier/popExitAddSupplierData";
import { popTerminateData } from "src/app/components/pop-up-terminate/popTerminateData";
import { popUpManageSARequestMessageData } from "src/app/components/manage-SA-request-approve/pop-up-manage-SA-request-message/popUpManageSARequestMessageData";
import { popRejectData } from "src/app/components/pop-up-reject/popRejectData";
import { popPossibleScenariosData } from "src/app/components/pop-up-possible-scenarios/popPossibleScenariosData";
import { popSAUpateApproveData } from "src/app/components/pop-up-sa-update-approve/popSAUpateApproveData"
import { popUpdateRejectData } from "src/app/components/pop-up-sa-update-reject/popUpdateRejectData";
import { EventService } from "../EventService.service";
import { forkJoin } from "rxjs";
import { consentRenewalModalOptions } from "src/app/components/pop-up-consent-renewal/consentRenewalData";

@Injectable({
    providedIn: "root"
})
export class ModalService {
    dynamoDbPrivacyStatement: PrivacyStatementResponseType = {
        privacyStatementText: "",
        version: ""
    };

  dynamoTermOfUse: TermOfUseResponse = {
    termsOfUserText: "",
    version: "",
    organisationalRole:""
  };
    privacyStatementModalData: ModalType = privacyStatementModalData;
    privacyStatementUrl: string;
    userId: string;

    emailAddress: string;

    buyerEmailAddress: string;
    constructor(
        private httpService: HttpService,
        private authService: AuthService,
        private b2cService: B2cService,
        private supplierGeneralProfileService: SupplierGeneralProfileService,
        private eventService: EventService,
    ) {}

  fetchTermOfUseByRole = async (role:string): Promise<TermOfUseResponse> => {
    if (!this.dynamoTermOfUse.termsOfUserText && this.authService.getToken()) {
     const url =`${environment.tsmBackendServiceURL}/term-use/${role}`;
      const response: TermOfUseResponse = await this.httpService.GetPromise(
        url
      );
      this.dynamoTermOfUse.version = response.version;
      this.dynamoTermOfUse.termsOfUserText =
        response.termsOfUserText;
    }
    return this.dynamoTermOfUse;
  };



    fetchPrivacyStatementText = async (): Promise<PrivacyStatementResponseType> => {
        if (!this.dynamoDbPrivacyStatement.privacyStatementText && this.authService.getToken()) {
            this.getPrivacyStatementText();
        }
        return this.dynamoDbPrivacyStatement;
    };

    getPrivacyStatementText = async (): Promise<void> => {
        this.privacyStatementUrl = `${environment.tsmBackendServiceURL}/privacy-statement`;
            const response: PrivacyStatementResponseType = await this.httpService.GetPromise(
                this.privacyStatementUrl
            );
            this.dynamoDbPrivacyStatement.version = response.version;
            this.dynamoDbPrivacyStatement.privacyStatementText =
                response.privacyStatementText;
    };

    getPrivacyStatementData = async (modalType: string): Promise<ModalType> => {
        const { privacyStatementText } = await this.fetchPrivacyStatementText();
        this.privacyStatementModalData.content = privacyStatementText;
        switch (modalType) {
            case "privacy-statement-relaxed":
                return this.privacyStatementModalData;
            case "privacy-statement-strict":
                return {
                    ...this.privacyStatementModalData,
                    buttonText: "I Accept",
                    headerCloseButton: false
                };
        }
    };

    getTermsOfUseData =async (): Promise<ModalType> => {
        const {termsOfUserText} = await this.fetchTermOfUseByRole(this.authService.getRole());
        return  {...termsOfUseData, content: termsOfUserText}
    };

    getWelcomeData = (): ModalType => {
        if(this.authService.getRole() === "supplier"){
            return {
                ...welcomeModalData,
                content: welcomeContent
            }
        }
    };
    getPrivacyStatementVersion = async (isFetchPSVersion: boolean): Promise<string> => {
        if (!this.dynamoDbPrivacyStatement.version || isFetchPSVersion) {
            await this.getPrivacyStatementText();
        }
        return this.dynamoDbPrivacyStatement.version;
    };

    getPopData(): Popup {
        return popData;
    }

    getNewPopData(): Popup {
        return popNewData;
    }

    getRequestorData(): Popup {
        return requestorData;
    }

    getDeleteUserData(): Popup {
        return popDeleteUserData;
    }

    getDisabledData(): Popup {
        return popDisabledData;
    }

    getInactiveData(): Popup {
        return popInactiveData;
    }

    getsupplierData(): Popup {
        return popSupplierData;
    }

    getpopBuyerData(): Popup {
        return popBuyerData;
    }

    getmanagerequestrData(): Popup {
        return managerequestrData;
    }

    getterminationData(): Popup {
        return terminationData;
    }

    getonboardingData(): Popup {
        return onboardingData;
    }

    getCountryNodifyData(type): Popup {
        switch (type) {
            case 'send-sa':
                return countryNodifySendSaData;
            case 'withdraw-sa':
                return countryNodifyWithdrawSaData;
            case 'pc-mapping':
                return countryNodifyPCmapping;
        }
    }

    getExitAddSupplierData(): Popup {
        return popExitAddSupplierData;
    }

    getTerminateData(): Popup {
        return popTerminateData;
    }
    getmanageSARequestData(): Popup {
        return popUpManageSARequestMessageData;
    }
    getRejectData(): Popup {
        return popRejectData;
    }

    getPossibleScenariosData(): Popup {
        return popPossibleScenariosData;
    }
    
    // successData

    getsuccessData(): Popup {
        return successData;
    }

    getSAUpdateApproveData(): Popup {
        return popSAUpateApproveData;
    }

    getSAUpdateRejectData(): Popup {
        return popUpdateRejectData;
    }
    
    /**
     * Service to get the modal data
     * @param modalType: string - this is the type of model data to request
     * @returns modalData: Modal - the data for that modal
     */
    getData(modalType: string): Profile {
        switch (modalType) {
            case "edit-profile-detail":
                return profileDetailsData;
            case "profile-detail-strict":
                return {
                    ...profileDetailsData,
                    headerCloseButton: false
                };
        }
    }

    /**
     * Service to get the modal options
     * @param modalType: string - this is the type of model data to request
     * @returns modalOptions: NgbModalOptions - the options for that modal
     */
    getOptions(modalType: string): NgbModalOptions {
        switch (modalType) {
            case "privacy-statement-relaxed":
                return privacyStatementModalOptions;
            case "privacy-statement-strict":
                return {
                    ...privacyStatementModalOptions,
                    backdrop: "static",
                    keyboard: false
                };
            case "consent-renewal-data":
                return {
                    ...consentRenewalModalOptions,
                    backdrop: "static",
                    keyboard: false
                };
            case "terms-of-use":
                return termsOfUseOptions;
            case "edit-profile-detail":
                return profileDetailsOptions;
            case "profile-detail-strict":
                return {
                    ...profileDetailsOptions,
                    backdrop: "static",
                    keyboard: false
                };
            case "requestorDataOptions":
                return requestorDataOptions;
            case "welcome":
                return {
                    ...welcomeModalOptions,
                    backdrop: "static",
                    keyboard: false
                };
            case "delete-user":
                return popDeleteUserOptions;
            case "exit-add-supplier":
                return popExitAddSupplierOptions;
        }
    }

    /**
     * Service to get the callback function for the modal
     * @param modalType: string - this is the type of model data to request
     * @returns exitViaButtonCallback: () => void - this is the callback to apply
     * when the user clicks uses the button to close the modal
     */
    getExitViaButtonCallback(modalType: string): any {
        switch (modalType) {
            case "privacy-statement-relaxed":
                return;
            case "privacy-statement-strict":
                return this.privacyStatementStrictCallback;
            case "terms-of-use":
                return;
        }
    }

    /**
     * keep order for modal pop up
     * privacy -> welcome
     */
    async popWelcomeOrNot(): Promise<void>{
          const loginUser = this.authService.getLoginUser();
          if (loginUser && loginUser.defaultProfile === "supplier" && loginUser.tsmId) {
            const b2cUser = this.authService.decodeToken();
            const profileData = await this.supplierGeneralProfileService.getProfileByEmail(b2cUser.email);
            const profileRequests = [];
            for(let key  in  profileData.profileData){
                profileRequests.push(this.supplierGeneralProfileService.getSupplierById(key));
            }
            forkJoin(profileRequests).subscribe((results: any)=>{
                let needPopup = true;
                results.forEach(supplier => {
                    if(supplier && supplier.status !== "Pending"){
                        needPopup = false;
                    }
                });
                if (needPopup) {
                    this.supplierGeneralProfileService.eventEmit.emit("openWelcomeModal");
                } else {
                    this.eventService.psCallbackEmit.emit("popUpEditProfile");
                }
            })
          }
    }

    privacyStatementStrictCallback = async (): Promise<void> => {
        // keep order for modal pop up: privacy -> welcome
        this.popWelcomeOrNot().then(r => console.log("judge whether need to pop up welcome modal"));

        // call api to update the privacy version extension attribute in B2C when accepted.

        this.userId = this.authService.getLoginUser().userId;

        const data = {
            extension_tsm_privacyStatement: localStorage.getItem("needSavePrivacy")
        };

        // No error handling required on FE. This is a fire and forget.
        await this.b2cService.updateUsersPrivacyStatement(this.userId, data);
    }
}
