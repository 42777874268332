import { Component, Input, OnInit } from '@angular/core';

type systemAdminBoardUserLiterals = {
  userName: string;
  email: string;
  role: string;
  emptyMessage: string;
  noResultMessage: string;
  errorMessage: string;
}

@Component({
  selector: 'tsm-system-admin-board-users',
  templateUrl: './system-admin-board-users.component.html',
  styleUrls: ['./system-admin-board-users.component.sass']
})
export class SystemAdminBoardUsersComponent implements OnInit {
  @Input() users: Array<any>
  @Input() currentLoginEmail: string;
  @Input() searchExecuted: boolean = false;
  @Input() noUsersFound: boolean = false;
  @Input() serverError: boolean = false;
  labelTranslation: systemAdminBoardUserLiterals;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        this.labelTranslation = multilanguageJson.body.main.systemAdminBoardUser;
      }
    }
  }
}
