<div class="checkbox-wrap">
  <br />
  <input
    class="checkbox"
    type="checkbox"
    id="includeIndirectCheckbox"
    [(ngModel)]="includeIndirect"
    (change)="inputChanged()"
    (keydown.enter)="toggleCheckbox()"

  />
  <label class="label-margin form-label" for="includeIndirectCheckbox">{{ checkboxLabel }}</label>
  <img
    tabindex="0"
    class="image-margin"
    src="..\..\..\..\assets\icons\icon-help-enabled.svg"
    width="24"
    alt="help icon"
    (mousemove)="showTooltip($event)"
    (mouseleave)="hideTooltip()"
    (focus)="showTooltip($event)"
    (blur)="hideTooltip()"
  />
</div>
