import { Injectable } from '@angular/core';
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthorityService {

  private assessmentStatusUrl = "";


  constructor(private httpService: HttpService) {
    this.assessmentStatusUrl = `${environment.tsmBackendServiceURL}/tasks/status/auth`;
   }



  /**
   *Get latest task record 
   *
   * @param {*} paramData: countryserved,supplierTsmId,authorityTsmId
   * @memberof AuthorityService
   */
  getLatestTaskRecord = async (paramData): Promise<any> => {
    const url = `${this.assessmentStatusUrl}/${paramData.country}/${paramData.supplierTsmId}/${paramData.authorityTsmId}`;
    return await this.httpService.GetPromise(url);
  };

}
