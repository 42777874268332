<div class="item">
  <div class="row align-items-center">
    <div class="col width-10 sub-supplier-name" *ngIf="!displayLink; else subSupplierNameTemplate">
      <div (mouseenter)="showWebsite()"
      (mouseleave)="hideWebsite()">
      {{ item.name.length > 10 ? item.name.slice(0, 10) + '...' : item.name }}  
        </div>
      <div *ngIf="showSupplierFlag" class="tooltip-body">
        {{ item.name }}
      </div>
    </div>
    <ng-template #subSupplierNameTemplate>
      <span class="col width-10">
        <a
          (click)="navigateToSubSupplierProfile()"
          class="sub-supplier-link hover_pointer"
        >
          {{ item.name }}
        </a>
      </span>
    </ng-template>
    <div class="col width-10">
      <div class="collabel sub-supplier-location">
        {{ item.location }}
      </div>
    </div>
    <div class="col width-15">
      <div class="collabel sub-supplier-poc-email">
        {{ item.personOfContact.email }}
      </div>
    </div>
    <div class="col" [ngClass]="isSupplier ? 'fixed-col' : 'width-20'">
      <div class="collabel sub-supplier-countriesServed">
        {{ item.countriesServed }}
      </div>
    </div>
    <div class="col fixed-col sub-supplier-score" *ngIf="!isSupplier">
      {{ score }}
    </div>
    <div class="col fixed-col">
      <tsm-relation-status
        [customStyles]="relationStatusStyles"
      ></tsm-relation-status>
    </div>
    <div class="col cancel-btn">
      <sh-button
        *ngIf="displayButtons"
        [props]="cancelRelationshipButtonProps"
      ></sh-button>
    </div>
  </div>
</div>
