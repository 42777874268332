<div class="item">
  <div class="row hover_pointer align-items-center">
    <div class="col-3">
      <span>
        <a href="#/parent-supplier-profile/{{ model.childTsmId }}" id="supplierName">{{ model.supplierName }}</a>
      </span>
    </div>
    <div class="col-2 ps-2 pe-0">
      <span class="collabel" id="location">{{ model.supplierLocation }}</span>
    </div>
    <div class="col-2 ps-0 pe-0">
      <span class="collabel" id="userName">{{ model.userName }}</span>
    </div>
    <div class="col-2 ps-0 pe-0">
      <span class="email">{{ model.email }}</span>
    </div>
    <div class="col-1">
      <div class="collabel">
        <img *ngIf="model.saparentaccess" src="/assets/icons/tick_purple.svg" height="24px" width="24px" />
      </div>
    </div>
    <div class="col-md-2">
      <sh-button *ngIf="showEditUser()" [props]="editBtnProps"></sh-button>
    </div>
  </div>
</div>