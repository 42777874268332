<div class="modal-header">
    <h4 class="modal-title">{{welcomeJson.title}}</h4>
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-outline-secondary language-button" id="dropdownBasicWelcome" ngbDropdownToggle>{{ "currentLanguage" | translate }}</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasicWelcome">
        <button ngbDropdownItem *ngFor="let item of languageList" (click)="switchLanguage(item.value)" class="language-button">{{item.name}}</button>
      </div>
    </div>
</div>
<div class="modal-body">
    <div class="modal-body-content" [innerHTML]="welcomeJson.content"></div>
    <div class="d-flex justify-content-center">
        <sh-button ngbAutofocus [props]="buttonProps"></sh-button>
    </div>
</div>
