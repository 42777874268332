<div class="modal-header">
  <h4 class="modal-title">
    {{ modalTranslation?.title }}
  </h4>
</div>
<sh-alert *ngIf="isAlertVisible" [props]="verifyAlertProps"></sh-alert>
<div class="modal-body" id="parent-rejectReason">
  <div class="info-container">
    <div class="info-message" id="infoTitle">
      {{ modalTranslation?.infoTitle }}
    </div>
    <div class="info-message" id="infoMessage">
      {{ modalTranslation?.infoMessage }}
    </div>
  </div>
  <div class="modal-body-content"> 
    <textarea      
      class="rejection-text"
      ngbAutofocus
      name="rejectReason"
      (keyup)="onKeyUp()"
      [(ngModel)]="rejectReason"
      rows="5"
      cols="90"
      maxlength="200"  
      aria-labelledby="parent-rejectReason"    
    ></textarea>
  </div>
</div>
<div class="modal-buttons">
  <sh-button id="cancelButton" [props]="cancelButtonProps"></sh-button>
  <sh-button id="rejectButton" [props]="submitRejectButtonProps"></sh-button>
</div>
