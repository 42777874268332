import { EventEmitter, Injectable } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
import { v4 as uuidv4 } from 'uuid';
import {
  SupplierListResponse,
  ParsedSupplierListFile,
  MassInviteEvents,
  MassInviteEventTypes as MIEventTypes,
  ErrorResponse
} from "src/app/models/SupplierListFile";
import { XmlConversionWorkerService } from "../xmlConversionWorker/xml-conversion-worker.service";

export const isSupplierListResponse = (
  obj: SupplierListResponse | ErrorResponse
): obj is SupplierListResponse => {
  return !obj.msg;
};

@Injectable({
  providedIn: "root"
})
export class MassInviteService {
  private baseUrl: string;
  private tsmbuBaseUrl: string;
  public eventEmit: EventEmitter<MassInviteEvents> = new EventEmitter();

  constructor(
    private httpService: HttpService,
    private xmlConversion: XmlConversionWorkerService
  ) {
    this.baseUrl = `${environment.susHubESGServiceURL}/mass-invite`;
    this.tsmbuBaseUrl = `${environment.tsmBackendServiceURL}/supplier-mass-upload/bu`;
    //this.tsmbuBaseUrl = `http://localhost:8083/tsm/backend-service/supplier-mass-upload/bu`;
  }

  public async readSupplierListFile(file: File) {
    return this.xmlConversion.readFileAndConvertXmlToJsonString(file);
  }
  
  public async validateSupplierListFile(filename: string, contents: ParsedSupplierListFile) {
    const url = `${this.baseUrl}/validate/${filename}`;
    const response = await this.httpService.PostPromise<SupplierListResponse | ErrorResponse>(url, contents);
    if (isSupplierListResponse(response)) {
      return this.transformValidationResult(response);
    }
    else {
      return response;
    }
  }

  private transformValidationResult(response: any) {
    // validation endpoint does not return success flag for rows
    response.report?.forEach(element => {
      element.success = element.validations?.length === 0;
    });
    return response;
  }

  public async processSupplierListFile(filename: string, contents: ParsedSupplierListFile) {
    this.eventEmit.emit({ name: MIEventTypes.MIProcess_Start });
    const url = `${this.baseUrl}/process/${filename}`;
    try {
      const response = await this.httpService.PostPromise<SupplierListResponse | ErrorResponse>(url, contents);
      this.eventEmit.emit({ name: MIEventTypes.MIProcess_Done, value: response });
    } catch (error) {
      console.error('Error in processing mass invite request', error);
      this.eventEmit.emit({ name: MIEventTypes.MIProcess_Fail, value: error });
    }
  }

  public async UploadAndValidateBUFile(file: any, initiatesa: string): Promise<any> {
    const buid = uuidv4();
    const url = `${this.tsmbuBaseUrl}/upload/${buid}/${initiatesa}`;
    let formData: FormData = new FormData();
    formData.append("attachment", file);
    try {
      const response: any = await this.httpService.PostPromise(url, formData);
      if(response.text && response.text === "OK")
      {
        return buid;
      } else if(response.text !== "OK" && response.msg) {
        return response;
      }
    } catch (error) {
      console.error('Error in processing the file upload request', error);
    }
    return "0";
  }
  public async getStatus(buid) {
    const url = `${this.tsmbuBaseUrl}/getStatusData?buId=${buid}`;
    return await this.httpService.GetPromise(url);
  }
  public async getValidationLogs(buid , downloadReport): Promise<any> {
    const url = `${this.tsmbuBaseUrl}/getData`;
    const payload = {
      buId: buid,
      downloadReport: downloadReport
    };
    try {
      const response: any = await this.httpService.PostPromise(url, payload);
      return response;
    } catch (error) {
      console.error('Error in getting validation logs', error);
    }
    return;
  }

  public async createBulkTsmProfile(buid) {
    const url = `${this.tsmbuBaseUrl}/create-profile/${buid}`;
    return await this.httpService.GetPromise(url);
  }
}