<div class="form">
    <div class="match-space">
        <div class="title">Duplicate check</div>
        <div class="describe">The supplier you’re about to create might already exist, please check the following list
            for
            duplicates before you continue.</div>
    </div>
    <div class="top-border">
        <div class="row header-border"></div>
    </div>
    <div *ngIf="matchDataList?.length === 15" class="display-suppliers">
        <div class="tip-font"><svg style="margin-right: 10px;" width="20" height="20" viewBox="0 0 20 20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM11 11V5H9V11H11ZM11 15V13H9V15H11ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10Z"
                    fill="#004EA0" />
            </svg>Display up to 15 potential duplicate suppliers</div>
    </div>
    <div class="match-ispace">
        <div class="row mt-3 table-header">
            <div class="col-1 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                triggers="hover" [ngbTooltip]="tip_MATCH" style="flex: 0.5 !important;">MATCH
                <ng-template #tip_MATCH>
                    <div class="tip-inner-content">
                        MATCH
                    </div>
                </ng-template>
            </div>
            <div class="col-1 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                triggers="hover" [ngbTooltip]="tip_COMPANY_NAME">COMPANY NAME
                <ng-template #tip_COMPANY_NAME>
                    <div class="tip-inner-content">
                        COMPANY NAME
                    </div>
                </ng-template>
            </div>
            <div class="col-1 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                triggers="hover" [ngbTooltip]="tip_INTERNATIONAL">
                <div class="match-tab">INTERNATIONAL</div>
                <div class="match-tab">COMPANY NAME</div>
                <ng-template #tip_INTERNATIONAL>
                    <div class="tip-inner-content">
                        INTERNATIONAL COMPANY NAME
                    </div>
                </ng-template>
            </div>
            <div class="col-1 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                triggers="hover" [ngbTooltip]="tip_REGISTERED">
                <div class="match-tab">REGISTERED COMPANY</div>
                <div class="match-tab">LOCATION</div>
                <ng-template #tip_REGISTERED>
                    <div class="tip-inner-content">
                        REGISTERED COMPANY LOCATION
                    </div>
                </ng-template>
            </div>
            <div class="col-1 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                triggers="hover" [ngbTooltip]="tip_TAXID">
                <div class="match-tab">TAX ID</div>
                <div class="match-tab">NUMBER</div>
                <ng-template #tip_TAXID>
                    <div class="tip-inner-content">
                        TAX ID NUMBER
                    </div>
                </ng-template>
            </div>
            <div class="col-1 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                triggers="hover" [ngbTooltip]="tip_NUMBER">D-U-N-S NUMBER
                <ng-template #tip_NUMBER>
                    <div class="tip-inner-content">
                        D-U-N-S NUMBER
                    </div>
                </ng-template>
            </div>
            <div class="col-1 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                triggers="hover" [ngbTooltip]="tip_GLOBAL_NUMBER">
                <div class="match-tab">GLOBAL PARENT</div>
                <div class="match-tab">D-U-N-S NUMBER</div>
                <ng-template #tip_GLOBAL_NUMBER>
                    <div class="tip-inner-content">
                        GLOBAL PARENT D-U-N-S NUMBER
                    </div>
                </ng-template>
            </div>
            <div class="col-1 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                triggers="hover" [ngbTooltip]="tip_WEBSITE">WEBSITE
                <ng-template #tip_WEBSITE>
                    <div class="tip-inner-content">
                        WEBSITE
                    </div>
                </ng-template>
            </div>
            <div class="col-1 match-tab" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                triggers="hover" [ngbTooltip]="tip_STATUS">
                <div class="match-tab">SUPPLIER</div>
                <div class="match-tab">STATUS</div>
                <ng-template #tip_STATUS>
                    <div class="tip-inner-content">
                        SUPPLIER STATUS
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row header-border"></div>
        <div class="content-max">
            <div *ngFor="let item of matchDataList">
                <div class="row mt-3 fs-12">
                    <div class="col-1 match-content" style="flex: 0.5 !important;" placement="bottom-left"
                        tooltipClass="manage-user-tooltip-class" triggers="hover" [ngbTooltip]="tip_i_match">
                        <svg *ngIf="item?.sw_flag" width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM11 11V5H9V11H11ZM11 15V13H9V15H11ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10Z"
                                fill="#B10B02" />
                        </svg>
                        <svg *ngIf="!item?.sw_flag" width="20" height="20" viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM11 11V5H9V11H11ZM11 15V13H9V15H11ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10Z"
                                fill="#936501" />
                        </svg>
                        <ng-template #tip_i_match class="match">
                            <div *ngIf="item?.sw_flag" class="tip-inner-content">
                                <div>Strong match means Company Name,</div>
                                <div>Location company is based or D-U-N-S number or Tax ID number is an exact match.
                                </div>
                            </div>
                            <div *ngIf="!item?.sw_flag" class="tip-inner-content">
                                <div>Weak match means Company Name or International Company Name or Tax ID number is
                                    fuzzy match. </div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-1 match-content" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="item?.suppliername?tip_i_COMPANY_NAME:''">{{item?.suppliername}}
                        <ng-template #tip_i_COMPANY_NAME>
                            <div class="tip-inner-content">
                                {{item?.suppliername}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-1 match-content" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="item?.companyLegalName?tip_i_INTERNATIONAL:''">
                        {{item?.companyLegalName}}
                        <ng-template #tip_i_INTERNATIONAL>
                            <div class="tip-inner-content">
                                {{item?.companyLegalName}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-1 match-content" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="item?.countryLocation?tip_i_REGISTERED:''">
                        {{item?.countryLocation}}
                        <ng-template #tip_i_REGISTERED>
                            <div class="tip-inner-content">
                                {{item?.countryLocation}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-1 match-content" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="item?.tax_registration_number?tip_i_TAXID:''">
                        {{item?.tax_registration_number}}
                        <ng-template #tip_i_TAXID>
                            <div class="tip-inner-content">
                                {{item?.tax_registration_number}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-1 match-content" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="item?.dunsNumber?tip_i_DUNS_NUMBER:''">{{item?.dunsNumber}}
                        <ng-template #tip_i_DUNS_NUMBER>
                            <div class="tip-inner-content">
                                {{item?.dunsNumber}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-1 match-content" placement="bottom-left" tooltipClass="manage-user-tooltip-class"
                        triggers="hover" [ngbTooltip]="item?.globalUltimateParentDUNS?tip_i_GLOBAL:''">
                        {{item?.globalUltimateParentDUNS}}
                        <ng-template #tip_i_GLOBAL>
                            <div class="tip-inner-content">
                                {{item?.globalUltimateParentDUNS}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-1 match-content" style="color: #0071EB;" placement="bottom-left"
                        tooltipClass="manage-user-tooltip-class" triggers="hover"
                        [ngbTooltip]="item?.companyWebsite?.trim()?tip_i_WEBSITE:''">
                        <a class="a-button match-content">{{item?.companyWebsite}}</a>
                        <ng-template #tip_i_WEBSITE>
                            <div class="tip-inner-content">
                                {{item?.companyWebsite}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-1 match-content">{{item?.status}}
                    </div>
                </div>
                <div class="row header-border" style="padding-top: 1rem"></div>
            </div>
        </div>
    </div>
    <div class="match-reason" *ngIf="showReason" >
        <label class="reason">Reason<span class="mandatory">*</span></label>
        <br>
        <input class="reason-input" [(ngModel)]="reason" id="reason" name="reason" maxlength="5000" (input)="blockNonPrintableChar()" placeholder="Please provide the reason for continuing with this supplier" required>
        <p class="error" *ngIf="showError">Please provide a reason</p>
    </div>
    <div class="match-footer">
        <div>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.2679 3C13.0377 1.66667 14.9623 1.66667 15.7321 3L24.3923 18C25.1621 19.3333 24.1999 21 22.6603 21H5.33975C3.80015 21 2.8379 19.3333 3.6077 18L12.2679 3Z"
                    fill="#936501" />
                <path d="M15 18H13V16H15V18Z" fill="white" />
                <path d="M15 15H13V9H15V15Z" fill="white" />
            </svg>
        </div>
        <div class="warning">
            <div>You may create a duplicate supplier.</div>
            <div>Do you still want to continue?</div>
        </div>
        <sh-button [props]="cancelButton" class="cancelButton"></sh-button>
        <sh-button [props]="submitButton"></sh-button>
    </div>
</div>