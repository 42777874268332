<div class="dropdown-select" [ngStyle]="{ 'width': width }" #inputPanel>
    <div class="dropdown-select-tittle" [ngStyle]="{'pointer-events': dropDownViewOnly == true ? 'none' : 'auto'}" (click)="dropdownopen()">
        <input style="display: inline-block;" [ngStyle]="{'height': height, 'font-size': fontSize, 'color': color}" [(ngModel)]="choiceDate" [title] = "choiceDate" class="dropdown-input input-placeholder" [readonly]="readonly"  [placeholder]="placeholder" (ngModelChange)="textChange()">
        <img class="icon-dropdown" src="./assets/icons/chevron-down-dropdown.svg" alt="icon-chevron-down-dropdown"  />
    </div>
    <div *ngIf="dropDownOpen">
        <div class="dropdown-option" *ngIf="!multiple" 
            >
             <div  class="line-drop" *ngFor="let item  of drapDownData">
                <div class="dropdown-option-content" (click)="slectTarget(item)">{{item.Text}}</div>
            </div>
        </div> 

        <div class="dropdown-option" *ngIf="multiple" >
            <div  class="line-drop">
                <div class="dropdown-option-content" style="padding-left:30px;position: relative;" *ngFor="let item  of drapDownData;let i = index;" >
                    <input type="checkbox" [id]="item.Code+'_'+i+id" aria-label="multiple dropdown" [value]="item.Text" class="checkbox-style" (click)="slectTargetMultiple($event,item)" [checked]="item.checked"> {{item.Text}}</div>
            </div>
        </div>
    </div>
</div>