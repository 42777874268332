import { Inject, Injectable, EventEmitter } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class BuyerService {
    public eventEmit: any;
    private taskStatusUrl = "";
    private buyerEmailAddressUrl = "";
    private getUserUrl = "";
    private assessmentStatusUrl = "";
    private nordicsStatusUrl = "";
    private popupStatusUrl = "";

    constructor(private httpService: HttpService) {
        this.eventEmit = new EventEmitter();
        this.taskStatusUrl = `${environment.susHubESGServiceURL}/invite-event/buyerLockStatus`;
        this.buyerEmailAddressUrl = `${environment.susHubESGServiceURL}/invite-event/getBuyerEmailAddress`;
        this.getUserUrl = `${environment.susHubB2CUserMgtServiceURL}/users/`;
        this.assessmentStatusUrl = `${environment.tsmBackendServiceURL}/tasks/status`;
        this.nordicsStatusUrl = `${environment.tsmBackendServiceURL}/nordics/supplier`;
        this.popupStatusUrl = `${environment.tsmBackendServiceURL}/nordics/countryGroupStatus`;
    }
    
    /**
     * Description: get sustainability assessment task info.
     * @Param data: supplierTsmId & buyerTsmId
     */
    getBuyerLockStatus = async (supAndBuyID): Promise<any> => {
        const url = `${this.taskStatusUrl}`;
        const response = await this.httpService.PostPromise(url, supAndBuyID);
        return response;
    };

    /**

   * Description: get buyer email info.
   * @Param data: supplierTsmId & buyerTsmId
   */
  buyerEmailAddress = async (reqBody): Promise<any> => {
    const url = `${this.buyerEmailAddressUrl}`;
    const getUserUrl = `${this.getUserUrl}`;
    try {
      let response;
      response = await this.httpService.PostPromise(url, reqBody);
      if (response.status == 200) {
        const getUserResponse = await this.httpService.GetPromise(
          getUserUrl + response.data.buyerUserTsmId
        );
        return getUserResponse;
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };
  /**
   * Description: get buyer email info No permission.
   * @Param data: supplierTsmId & buyerTsmId
   */
  getBuyerEmailAddress = async (reqBody): Promise<any> => {
    const url = `${this.buyerEmailAddressUrl}`;
    const getUserUrl = `${this.getUserUrl}`;
    try {
      let response;
      response = await this.httpService.PostPromise(url, reqBody);
      if (response.status == 200) {
        if(response.data.buyerUserTsmId === "SYSTEM") {
          return { userName: "SYSTEM" };
        }

        const getUserResponse = await this.httpService.GetPromise(
          getUserUrl + "getUserAddress/" + response.data.buyerUserTsmId
        );
        return getUserResponse;
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  getBuyerEmailAddressByTsmId = async (buyerUserTsmId): Promise<any> => {
    const getUserUrl = `${this.getUserUrl}`;
    try {
      if (!buyerUserTsmId || buyerUserTsmId === "SYSTEM") {
        return { userName: "SYSTEM" };
      }
      const getUserResponse = await this.httpService.GetPromise(
        getUserUrl + "getUserAddress/" + buyerUserTsmId
      );
      return getUserResponse;
    } catch (error) {
      return;
    }
  }

    getAssessmentStatus = async (assessData): Promise<any> => {
        const url = `${this.assessmentStatusUrl}/${assessData.country}/${assessData.supplierTsmId}/${assessData.buyerTsmId}`;
        return await this.httpService.GetPromise(url);
    };

        /**
     * Description: get nordics Country Status.
     * @Param data: supplierTsmId
     */
         getNordicsCountryStatus = async (supIdAndCountry): Promise<any> => {
          const url = `${this.nordicsStatusUrl}/${supIdAndCountry.supplierTsmId}/${supIdAndCountry.countryServed}`;
          const response = await this.httpService.GetPromise(url);
          return response;
      };

              /**
     * Description: get pop up Status.
     * @Param data: supplierTsmId
     */
          getPopUpStatus = async (supIdAndCountry): Promise<any> => {
          const url = `${this.popupStatusUrl}/${supIdAndCountry.supplierTsmId}/${supIdAndCountry.countryServed}`;
          const response = await this.httpService.GetPromise(url);
          return response;
      };
}
