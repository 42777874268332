import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FileUploader } from "file-upload-bfp";
import { MassInviteService } from 'src/app/services/massInvite/mass-invite.service';
import {
  SupplierListFileEvent,
  MassInviteEventTypes as MIEventTypes,
  MassInviteEvents
} from "src/app/models/SupplierListFile";
import { Subject, Subscription, switchMap, timer } from 'rxjs';
import {
  SupplierListResponseReportRow,
} from "src/app/models/SupplierListFile";
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'tsm-create-bulk-profile-upload',
  templateUrl: './create-bulk-profile-upload.component.html',
  styleUrls: ['./create-bulk-profile-upload.component.sass']
})
export class CreateBulkProfileUploadComponent {
  @Output() supplierListFileEvent = new EventEmitter<SupplierListFileEvent>(true);
  fileToUpload: File | null = null;

  // Unused, but necessary for ng2FileDrop
  uploader!: FileUploader;

  /** Is the current file being read, validated or sent to the backend? */
  isUploading = false;
  subscription !: Subscription;

  // Separate variable from fileLevelErrors because we need to still show the generic
  // error message, even if there are no user-showable details
  hasErrors = false;
  fileLevelErrors: string[] = [];

  labels = {
    genericError: "There is an issue with the file you are trying to upload. Please try again.",
    multiFile: "Sorry, you cannot upload more than one file at the same time.",
    onlyXlsx: "Only xlsx files are supported.",
    sizeExceeded: "File size more than 10 MB is not allowed, please check the file size and reupload.",
    uploadProgress: "Upload in-progress....",
    validationProgress: "Validation in-progress...."
  };
  //
  public displayError: boolean = false;
  currentSupplierListFile: any | null = null;
  displayValidationList = false  // to show or hide validation container
  updateSupplierValidationListSubject: Subject<SupplierListResponseReportRow[]> = new Subject<SupplierListResponseReportRow[]>();

  tooltipProps = {
    tooltip: true,
    tooltipMessage: 'Please note the following before uploading a document:<br><ul><li>The file name can only contain letters, numbers, and special characters visible on the keyboard.</li><li>Maximum data rows in the xlsx file: 100</li><li>Accepted file format: xlsx</li><li>If you want to perform Supplier Profile creation and request for Sustainability Assessments, the file must contain at least the columns & fields like ‘Supplier Name’, ‘International Company Name’, ‘Location Company is based’, ‘Website (type N/A if not applicable)’, ‘Company domain name (type N/A if not applicable)’, ‘Country Served (Multiple countries should be seperated by ";")’, ‘Invited Supplier first name’, ‘Invited Supplier last name’, ‘Invited Supplier Email’, ‘Request Purpose’.​</li><li>If you want to perform only Supplier Profile creation, the file must contain at least the columns & fields like ‘Supplier Name’, ‘International Company Name’, ‘Location Company is based’, ‘Website (type N/A if not applicable)’, ‘Company domain name (type N/A if not applicable)’.​</li></ul>'
  };

  translations: any = {
    text: 'Upload a .xlsx file with the list of suppliers.',
    attachDocumentation: 'Drag and drop/Browse for your supplier list here',
    emptyMessage: 'Upload your supplier list to see the details here'
  };
  downloadButton: ButtonPropsType = {
    label: "Download Template",
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    width: 256,
    icon: "assets/icons/file_download-24px.svg",
    iconPosition: "left",
    onClick: () => {
      this.downloadTemplate();
    }
  };
  validateTsmProfile: ButtonPropsType = {
    label: '',
  };
  enableValidateTsmProfile: ButtonPropsType = {
    label: "Validate TSM Profile",
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    onClick: () => {
      this.UploadAndValidateFile();
    }
  };
  disableValidateTsmProfile: ButtonPropsType = {
    ...this.enableValidateTsmProfile,
    backgroundColor: 'lightGrey',
    hoverBackgroundColor: 'gray',
    borderColor: 'mediumGrey',
    isProcessing: false,
    disabled: true
  };
  buid: string;
  public includeSendSA: boolean = false;
  checkboxLabel = "Include Sustainability Assessment Request(optional)";
  initiatesa: string;
  logList: any[];
  isCheckboxDisabled: boolean = false;
  isFileUploadDisabled: boolean = false;
  refreshed = false;
  refresheddate = (new Date()).toLocaleString();
  currentStatusMsg = "";
  showAlert;
  showAlert_fail;
  @Output() dataEvent = new EventEmitter<boolean>();
  initiatesaParam;

  constructor(
    private massInviteService: MassInviteService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  get showEmptyMessage() {
    return !this.displayValidationList
  }
  ngOnInit(): void {
    this.disableValidateTsmProfileButton();
    this.activatedRoute.queryParams.subscribe(params => {
      this.initiatesaParam = params['initSa'];
    });
    if(this.initiatesaParam === 'Yes'){
      this.includeSendSA = true;
      this.isCheckboxDisabled = true;
    }
    this.buid = this.activatedRoute.snapshot.paramMap.get("buid");
    if(this.buid !== '0') {
      const statusResponse:any = this.massInviteService.getStatus(this.buid);
      this.subscription = timer(0, 10000).pipe(
        switchMap(() => this.massInviteService.getStatus(this.buid))
      ).subscribe(async (result:any) => {
        if(result.status !== "INIT" && result.status !== "VALIDATE"){
          this.subscription.unsubscribe();
          this.logList = await this.massInviteService.getValidationLogs(this.buid, false)
          if(this.logList){
            this.displayValidationList = true;
          } else {
            this.displayValidationList = false;
          }
          
        } 
      } );
    }
   
    this.initializeUploader();
    this.massInviteService.eventEmit.subscribe((event) => this.handleMassInviteEvent(event));
  }
  private initializeUploader() {
    this.uploader = new FileUploader({
      allowedMimeType: ['xlsx', 'xls'],
      allowedFileType: ['xlsx', 'xls'],
      autoUpload: false,
    });
  }

  enableValidateTsmProfileButton(){
    this.validateTsmProfile = this.enableValidateTsmProfile;
  }
  disableValidateTsmProfileButton(){
    this.validateTsmProfile = this.disableValidateTsmProfile;
  }
  public async handleFile(files: FileList) {
    this.hasErrors = false;
    this.notifyFileDeleted();
    this.fileToUpload = this.getSingleFile(files);
    if (!this.fileToUpload) {
      this.notifyFileDeleted();
      return;
    }
    this.isUploading = true;
    await this.validateFileTypeAndSize();
    this.isUploading = false;
    this.enableValidateTsmProfileButton();
    this.isCheckboxDisabled = false;
    this.massInviteService.eventEmit.emit({ name: MIEventTypes.NewFile });
  }

  public async UploadAndValidateFile() {
    this.logList = [];
    this.refreshed = true;
    this.isCheckboxDisabled = true;
    this.displayValidationList = true;
    this.currentStatusMsg = this.labels.uploadProgress;
    this.disableValidateTsmProfile.isProcessing = true;
    this.disableValidateTsmProfileButton();
    this.initiatesa = this.includeSendSA ? "Yes" : "No";
    const uploadResponse = await this.massInviteService.UploadAndValidateBUFile(this.fileToUpload, this.initiatesa);
    this.currentStatusMsg = this.labels.validationProgress;
    // to check if no. of records in file is more than 100
    if(!uploadResponse.msg){
      this.buid = uploadResponse;
      this.router.navigate(["../",this.buid], 
      { 
        relativeTo: this.activatedRoute,
        queryParams: {initSa: this.initiatesa},
        queryParamsHandling: 'merge'
      });
      if(this.buid !== "0") {
        this.subscription = timer(0, 10000).pipe(
          switchMap(() => this.massInviteService.getStatus(this.buid))
        ).subscribe(async (result:any) => {
          this.refreshed = true;
          this.displayValidationList = true;
          this.refresheddate = (new Date()).toLocaleString();
          if(result.status !== "INIT" && result.status !== "VALIDATE"){
            this.subscription.unsubscribe();
            this.logList = await this.massInviteService.getValidationLogs(this.buid, false)
            if(this.logList){
              this.displayValidationList = true;
              this.disableValidateTsmProfile.isProcessing = false;
            } else {
              this.displayValidationList = false;
              this.disableValidateTsmProfile.isProcessing = false;
            }
            
          } 
        }
        );
      }
    } else {
      this.disableValidateTsmProfile.isProcessing = false;
      this.hasErrors = true;
      this.fileLevelErrors.push(uploadResponse.msg);
      this.displayValidationList = false;
    }
  }

  public deleteFile() {
    this.fileToUpload = null;
    this.fileLevelErrors = [];
    this.hasErrors = false;
    this.disableValidateTsmProfileButton();
    this.notifyFileDeleted();
  }

  private notifyFileDeleted() {
    this.supplierListFileEvent.emit({
      deleted: true,
      hasErrors: this.hasErrors
    });
  }

  private getSingleFile(files: FileList): File | null {
    this.hasErrors = false;
    this.fileLevelErrors = [];
    if (!files.length) {
      return null;
    }
    if (files.length > 1) {
      this.hasErrors = true;
      this.fileLevelErrors.push(this.labels.multiFile);
      return null;
    }
    return files.item(0);
  }
  private validateFileTypeAndSize() {
    const fileSize = this.fileToUpload.size/1024/1024;
    if (!this.fileToUpload.name.toLowerCase().endsWith(".xlsx")) {
      this.hasErrors = true;
      this.fileLevelErrors.push(this.labels.onlyXlsx);
      if(fileSize > 10){
        this.hasErrors = true;
        this.fileLevelErrors.push(this.labels.sizeExceeded);
      }
      this.notifyFileDeleted();
      return;
    }
  }

  private handleMassInviteEvent(event: MassInviteEvents) {
    if (event.name === MIEventTypes.MIProcess_Start) {
      this.isUploading = true;
    } else if (event.name === MIEventTypes.MIProcess_Done || event.name === MIEventTypes.MIProcess_Fail) {
      this.isUploading = false;
    }
  }

  downloadTemplate(): void {
    let link = document.createElement('a');
    link.download = 'Create Bulk TSM Profiles Template';
    link.href = '/assets/download/Create Bulk TSM Profiles Template.xlsx';
    link.click();
  }
  inputChanged(){
    
  }
  handleAlertDismissEvent(data){
    if(data === 'dismiss'){
      this.router.navigateByUrl("/create-bulk-tsm-profiles/0");
      this.logList = null;
      this.fileToUpload = null;
      this.isFileUploadDisabled = false;
      this.isCheckboxDisabled = false;
      this.includeSendSA = false;
      this.displayValidationList = false;
    }
  }
  disableFileUpload(data){
    if(data){
      this.isFileUploadDisabled = true;
    }
  }

}
