import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tsm-secondary-banner',
  templateUrl: './secondary-banner.component.html',
  styleUrls: ['./secondary-banner.component.sass']
})
export class SecondaryBannerComponent implements OnInit {

  @Input() isPopUp: boolean;
  @Input() redirect: string = '';
  @Output() outerEvent = new EventEmitter<string>();

  labelReturn: string = "Return to Supplier Network";

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  backtoHome() {
    if (this.isPopUp === true) {
      this.outerEvent.emit("add supplier");
    } else if (this.redirect != '') {
      this.router.navigateByUrl(this.redirect);
    } else {
      this.router.navigateByUrl("/dashboard-search");
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));

      if (multiLanguageJson.body != undefined) {
        this.labelReturn = multiLanguageJson.body.main.secondaryBanner.return;
      }
    }
  }
}
