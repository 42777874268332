<div class="item">
  <div class="row align-items-center">
    <div class="col-3">
      <span
        *ngIf="isSubsidiaryUser; else supplierNameTemplate"
        class="supplierName"
      >
        {{ model.supplierName }}
      </span>
      <ng-template #supplierNameTemplate>
        <span>
          <a
            (click)="navigateToSupplierProfile()"
            class="supplierName-link supplierName hover_pointer"
            >{{ model.supplierName }}</a
          >
        </span>
      </ng-template>
    </div>

    <div class="col-3">
      <span class="collabel location">{{ model.countryLocation }}</span>
    </div>

    <div class="col-3">
      <span class="poc pocEmail">{{ model.pocEmail }}</span>
    </div>

    <div class="col-3">
      <sh-button
        *ngIf="showCancelRelationshipButton"
        [props]="cancelRelationshipButtonProps"
      ></sh-button>
    </div>
  </div>
</div>
