import { Component, Input, OnInit, Output, EventEmitter, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { AppComponent } from 'src/app/app.component';

//no translations, internal user view only
const checkboxLabel = "Include Indirect Suppliers";
const checkboxHelptext = "If Include Indirect Suppliers is selected, the search result will contain direct and indirect suppliers.";
@Component({
  selector: 'tsm-include-indirect-checkbox-filter',
  templateUrl: './include-indirect-checkbox-filter.component.html',
  styleUrls: ['./include-indirect-checkbox-filter.component.sass']
})
export class IncludeIndirectCheckboxFilterComponent implements OnInit {
  @Output() includeIndirectChanged = new EventEmitter<boolean | unknown>();
  @Input() clearFilter: Subject<boolean>;

  @Input() tooltip: string = checkboxHelptext;

  public includeIndirect: boolean = false;
  private doHideTooltip: boolean = true;
  checkboxLabel = checkboxLabel;
  checkboxHelptextLabel = checkboxHelptext;

  constructor(private appComponent: AppComponent) { }

  ngOnInit(): void {
    this.clearFilter.subscribe(() => this.clear())
  }

  inputChanged() {
    this.includeIndirectChanged.emit(this.includeIndirect);
  }
  toggleCheckbox() {
    this.includeIndirect = !this.includeIndirect;
    this.inputChanged(); 
  }

  clear() {
    this.includeIndirect = false;
  }

  showTooltip(event: MouseEvent | FocusEvent): void {


    if (event instanceof FocusEvent) {
      this.doHideTooltip = false;
      const focusEvent = event as FocusEvent;
      const target = focusEvent.target as HTMLElement;
      const rect = target.getBoundingClientRect();
      const left = rect.left + window.scrollX;
      const top = rect.top + window.scrollY + target.offsetHeight;
      setTimeout(() => {
        if (!this.doHideTooltip) {
          this.appComponent.showGlobalTooltip(this.checkboxHelptextLabel, "", left, top);
        }
      }, 20);
    }
    else if (event instanceof MouseEvent) {
      const mouseEvent = event as MouseEvent;
      this.doHideTooltip = false;
      setTimeout(() => {
        if (!this.doHideTooltip) {
          this.appComponent.showGlobalTooltip(this.checkboxHelptextLabel, "", mouseEvent.pageX - 200, mouseEvent.pageY + 20);
        }
      }, 20);
    }

  }
  hideTooltip(): void {
    this.doHideTooltip = true;
    setTimeout(() => {
      if (this.doHideTooltip) {
        this.appComponent.hideGlobalTooltip();
      }
    }, 30);
  }
}


