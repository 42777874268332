<div class="modal-header">
  <h4 class="modal-title">{{"supplier.body.main.supplierEsgAssessment.Standard Process" | translate}}</h4>
</div>
<div class="modal-body">
  <div class="modal-body-content">
    <div class="modal-body-item">
      <div class="modal-body-item-img">
        <img src="assets/icons/Action.svg" class="modal-icon" alt="" />
      </div>
      <div class="modal-body-item-after"></div>
    </div>
    <div class="modal-body-item-content">

      <div class="modal-body-item-title">{{"supplier.body.main.supplierEsgAssessment.Awaiting Acceptance" | translate}}
      </div>
      <br>
      <div class="modal-body-item-title">
        {{"supplier.body.main.supplierEsgAssessment.Sustainability Assessment request initiated" | translate}}
      </div>
    </div>
  </div>
  <div class="modal-body-content">
    <div class="modal-body-item">
      <div class="modal-body-item-img">
        <img src="assets/icons/Action.svg" class="modal-icon" alt="" />
      </div>
      <div class="modal-body-item-after"></div>
    </div>
    <div class="modal-body-item-content">

      <div class="modal-body-item-title">{{"supplier.body.main.supplierEsgAssessment.Awaiting Submission" | translate}}
      </div>

      <div class="modal-body-item-title">
        {{"supplier.body.main.supplierEsgAssessment.Supplier accepted invitation, pending submission" | translate}}
      </div>
    </div>
  </div>

  <div class="modal-body-content" *ngIf="headerRole() === 'buyer'">
    <div class="modal-body-item">
      <div class="modal-body-item-img">
        <img src="assets/icons/Action.svg" class="modal-icon" alt="" />
      </div>
      <div class="modal-body-item-after"></div>
    </div>
    <div class="modal-body-item-content">

      <div class="modal-body-item-title">Pending Review</div>
      <div class="modal-body-item-title">
        Sustainability Assessment is under review
      </div>
    </div>
  </div>
  <div class="modal-body-content" *ngIf="headerRole() === 'supplier'">
    <div class="modal-body-item">
      <div class="modal-body-item-img">
        <img src="assets/icons/Action.svg" class="modal-icon" alt="" />
      </div>
      <div class="modal-body-item-after"></div>
    </div>
    <div class="modal-body-item-content">

      <div class="modal-body-item-title">{{"supplier.body.main.supplierEsgAssessment.Submitted" | translate}}</div>
      <div class="modal-body-item-title">
        {{"supplier.body.main.supplierEsgAssessment.Sustainability Assessment is under review" | translate}}
      </div>
    </div>
  </div>
  <div class="modal-body-content">
    <div class="modal-body-item">
      <div class="modal-body-item-img">
        <img src="assets/icons/Onboarded.svg" class="modal-icon" alt="" />
      </div>
      <div class="modal-body-item-after modal-body-item-last"></div>
    </div>
    <div class="modal-body-item-content">

      <div class="modal-body-item-title">{{"supplier.body.main.supplierEsgAssessment.Validation Completed" | translate}}
      </div>
      <div class="modal-body-item-title">
        {{"supplier.body.main.supplierEsgAssessment.Sustainability Assessment has been validated" | translate}}
      </div>
    </div>

  </div>
  <div class="d-flex">
    <sh-button class="button1" ngbAutofocus [props]="cancelProps" style="width: 120px;"></sh-button>
  </div>
</div>