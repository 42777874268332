import { Component, OnInit, Input } from '@angular/core';
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailTemplateService } from '../../../app/services/emailTemplateManager/email-template-manager';

@Component({
  selector: 'pop-up-template-edit',
  templateUrl: './pop-up-template-edit.component.html',
  styleUrls: ['./pop-up-template-edit.component.sass']
})

export class PopUpTemplateEditComponent implements OnInit {
  constructor(
    public eMailTemplateService: EmailTemplateService,
    public activeModal: NgbActiveModal
  ) { }
  @Input() confirmType: any;
  @Input() templateMessage: any;
  EditorSuccess: string = "Edit Template Success"
  EditorTitle: string;
  showSaveBtn: boolean = true;
  tip: string = "*";
  emailTypeLabel: string = "Email Type";
  questionLabel: string = "Subject";
  answerLabel: string = "Template";
  emailTypeInfo: string = ""
  publishTip: string = "";
  subjectInfo: string = "";
  templateInfo: string = "";
  helpAndSupportId: string
  isCreate: boolean = true;
  isEdit: boolean = true;
  isDelete: boolean = true;
  isPublish: boolean = true;
  constants: any = {};

  saveProps: ButtonPropsType = {
    label: "Save Changes",
    color: "white",
    class: "faqGray",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    padding: "14px 10px",
    margin: "0 0 0 2px",
    iconHeight: 14,
    iconWidth: 14,
    iconPosition: "right",
    disabled: true,
    onClick: async () => {
      await this.save();
    }
  };

  closeProps: ButtonPropsType = {
    label: "Close",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: () => {
      this.closeClick();
    }
  };

  templateData: any = {
    subject: "",
    template: ""
  };

  cancelProps: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.cancel();
    }
  };

  async ngOnInit(): Promise<void> {
    await this.config();
    this.accessbility();
  }

  async config() {
    const res = this.confirmType;
    this.EditorTitle = res.title;
    this.emailTypeInfo = res.emailType; //emailtype value
    this.emailTypeLabel = "Email Type"; //emailtype lable
    this.subjectInfo = res.emailSubject; //email subject value
    this.templateInfo = res.emailTemplate; //  email template value
    this.saveProps.disabled = false;
    this.saveProps.class = "faqBlue";
    this.constants = {
      templateSaveSuccess: `Template Saved Successfully!`,
      errormessage: `Something went wrong !`,
      savePublishMsg: `Are you sure to publish the content? The contents will be updated for this ${this.emailTypeInfo} template after publishing.`
    };
    this.publishTip = this.constants.savePublishMsg;
  }

  async textChange() {
    if (this.emailTypeInfo.trim() && this.subjectInfo.trim() && this.templateInfo.trim()) {
      this.saveProps.disabled = false;
      this.saveProps.class = "faqBlue";
    } else {
      this.saveProps.disabled = true;
      this.saveProps.class = "faqGray";
    }
  }

  closeClick(): void {
    this.activeModal.close();
    window.location.reload();
  }

  async save() {

    this.templateData.subject = this.subjectInfo.trim();
    this.templateData.template = this.templateInfo
    let payload = {
      templateData: this.templateData,
      emailTemplateId: this.confirmType.emailTemplateId
    }
    const response = await this.eMailTemplateService.updateData(payload);

    if (response.msg === 'success') {
      this.publishTip = this.constants.templateSaveSuccess;
      this.showSaveBtn = false;
    }
    else {
      this.publishTip = this.constants.errormessage;
      this.showSaveBtn = false;
    }
  }

  async cancel(): Promise<any> {
    this.activeModal.close();
  }

  accessbility() {
    const tsmRoot = document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }
}
