<div *ngIf="opened" class="modal-header">
    <img class="modal-icon" [src]="modalData.iconUrl" alt="icon-modalData" />
    <h4 class="modal-title">{{ modalData.title }}</h4>
</div>
<div *ngIf="opened" class="modal-body">
    <div class="modal-body-content">
        <div class="row">
            <span class="modal-body-content-text-title">{{ modalData.content }}</span>
        </div>
        <div class="row">
            <span class="modal-body-content-text-subtitle">Onboard a new supplier (incl. Sustainability Assessment)</span>
        </div>
        <div class="row">
            <span class="modal-body-content-text">
                This action triggers the Onboarding Form and the Sustainability Assessment.
                <ul style="list-style: auto; padding-inline-start: 19px">
                    <li style="margin-bottom: 10px; margin-top: 10px">Start by <b>seaching</b> for your preferred supplier.</li>
                    <li style="margin-bottom: 10px">If supplier doesn't exist, <b>'Add a new supplier'</b> and follow the steps.</li>
                    <li>Select <b>'Send Request'</b> from the onboarding activity card to invite them to complete both forms.
                        Do not send a separate Sustainability Assessment invite.</li>
                </ul>
            </span>
        </div>
        <div class="row">
            <span class="modal-body-content-text-subtitle" style="height: 40px">Invite an existing or potential supplier to complete the Sustainability Assessment only</span>
        </div>
        <div class="row">
            <span class="modal-body-content-text">
                You want the supplier to complete only the Sustainability Assessment.
                <ul style="list-style: auto; padding-inline-start: 19px; margin-bottom: 10px;">
                    <li style="margin-bottom: 10px; margin-top: 10px;">Because they participate in an ongoing RFP (before onboarding) OR.</li>
                    <li>Because of ongoing due diligence with an existing supplier (after onboarding).</li>
                </ul>
                Start by searching for the supplier. In their profile select <b>'Send Assessment'</b> from the Sustainability Assessment activity card.
            </span>
        </div>
        <div class="row">
            <span class="modal-body-content-text-subtitle">Add a new country served</span>
        </div>
        <div class="row">
            <span class="modal-body-content-text">
                <b>The supplier already exists in TSM</b>, but you would like to add a new location to which the supplier delivers business to.
                <ul style="list-style: auto; padding-inline-start: 19px">
                    <li style="margin-bottom: 10px; margin-top: 10px;"><b>Search</b> for the supplier and identify the right profile card.</li>
                    <li style="margin-bottom: 10px">In the supplier profile select the <b>'Change Location'</b> button.</li>
                    <li>Select <b>'Add New Country Served'</b> button.</li>
                </ul>
            </span>
        </div>
        <div class="row">
            <span class="modal-body-content-text-subtitle">Extend an existing supplier to a new Accenture Company Code</span>
        </div>
        <div class="row">
            <span class="modal-body-content-text" style="height: 120px">
                <b>The supplier is active in TSM and Supplier Hub</b>, but you would like to add a new Accenture Company Code in Supplier Hub.<br><br>
                For this, navigate to Supplier Hub via the <b>'View related supplier profiles'</b> card and add a new company code for existing suppliers active in SAP and TSM that will deliver services to the newly assigned codes.
            </span>
        </div>
        <div class="row">
            <span class="modal-body-content-text-subtitle">Invite my supplier to update their supplier profile</span>
        </div>
        <div class="row">
            <span class="modal-body-content-text">
                If your supplier needs to update details in Supplier Hub please reach out to <a class='email' href='mailto:procurement.support@accenture.com'>procurement.support&#64;accenture.com</a> so they can send the invite to update Supplier Hub data. Migrated suppliers should appear in search results both in TSM and Supplier Hub.
            </span>
        </div>
    </div>
</div>
<div *ngIf="opened" class="modal-header" style="height: 100px;justify-content: flex-end" tabindex="0">
    <div class="d-flex flex-row-reverse">
        <sh-button tabindex="0" (keydown.enter)=" this.closeButton.onClick()" (keydown.tab)=" this.closeButton.onClick()" ngbAutofocus [props]="closeButton"> </sh-button>
    </div>
</div>