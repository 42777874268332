import { Component, Input, OnInit } from '@angular/core';
import { SubsidiarySupplierUser } from 'src/app/models/SubsidiarySupplierUser';

type subsidiarySupplierUserLiterals = {
  company: string;
  location: string;
  userName: string;
  email: string;
  editRights: string;
  emptyMessage: string;
  noResultMessage: string;
  errorMessage: string;
}

@Component({
  selector: 'tsm-subsidiary-supplier-admin-board-users',
  templateUrl: './subsidiary-supplier-admin-board-users.component.html',
  styleUrls: ['./subsidiary-supplier-admin-board-users.component.sass']
})
export class SubsidiarySupplierAdminBoardUsersComponent implements OnInit {
  @Input() searchExecuted: boolean;
  @Input() errorOccurred: boolean;
  @Input() users: Array<SubsidiarySupplierUser>;
  labelTranslation: subsidiarySupplierUserLiterals;
  constructor() { }

  ngOnInit(): void {

  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        this.labelTranslation = multilanguageJson.body.main.subsidiarySupplierUser;
      }
    }
  }
}
