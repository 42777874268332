import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { from } from 'rxjs';
import { BaseSupplierFilterComponent } from 'src/app/components/base-filter/base-supplier-filter.component';
import { SubheaderPropsType } from 'src/app/models/SubheaderProps';
import { RAStatusCode, RAStatusText } from './ra-status.enum';
import { BIBDAStatusCode, BIBDAStatusText } from './bi-bda-status.enum';
import { InfoSecScoreCode, InfoSecScoreText } from './info-sec-score.enum';
import { DataPrivacyScoreCode, DataPrivacyScoreText } from './data-privacy-score.enum';
import { FilterData, FilterType, fromToDateFilter } from './../../services/esgReport/esg-report.service';

@Component({
  selector: 'tsm-ra-report',
  templateUrl: './ra-report.component.html',
  styleUrls: ['./ra-report.component.sass']
})
export class RaReportComponent extends BaseSupplierFilterComponent implements OnInit {
  private filtersToApply: FilterType[] = [
    'supplierName',
    'countryServed',
    'creationDateFrom',
    'creationDateTo',
    'categories',
    'subcategories',
    'raStatus',
    'biBdaStatus',
    'infoSecScore',
    'dataPrivacyScore',
    'includeRaQuesAns',
  ];
  subheaderProps: SubheaderPropsType;
  allCategory: any;
  allSubCategory: any;
  allCountry: any;
  tempCountry = [];
  placeholders = {
    category: "Select category",
    subCategory: "Select subcategory",
    raStatus: "Select RA Status",
    biBdaStatus: "Select BI/BDA Status",
    country: "Select country/ies",
    infoSecScore: "Enter Info sec score",
    dataPrivacyScore: "Enter Data Privacy score"

  }
  tempRAStatus: any = [
    {
      Code: RAStatusCode.pendingReview,
      Text: RAStatusText.pendingReview
    }, {
      Code: RAStatusCode.materialityDetermined,
      Text: RAStatusText.materialityDetermined
    }, {
      Code: RAStatusCode.dueDiligenceCompleted,
      Text: RAStatusText.dueDiligenceCompleted
    }
  ];
  BIBDAStatus: any = [
    {
      Code: BIBDAStatusCode.notApplicable,
      Text: BIBDAStatusText.notApplicable
    }, {
      Code: BIBDAStatusCode.notStartOnGoing,
      Text: BIBDAStatusText.notStartOnGoing
    }, {
      Code: BIBDAStatusCode.completed,
      Text: BIBDAStatusText.completed
    }
  ];
  infoSecScore: any = [
    {
      Code: InfoSecScoreCode.notRequired, 
      Text: InfoSecScoreText.notRequired 
    }, {
      Code: InfoSecScoreCode.aboveNormal,
      Text: InfoSecScoreText.aboveNormal
    }, {
      Code: InfoSecScoreCode.high,
      Text: InfoSecScoreText.high
    }, {
      Code: InfoSecScoreCode.normal,
      Text: InfoSecScoreText.normal
    }
  ];
  dataPrivacyScore: any = [
    {
      Code: DataPrivacyScoreCode.notRequired,
      Text: DataPrivacyScoreText.notRequired
    }, {
      Code: DataPrivacyScoreCode.material,
      Text: DataPrivacyScoreText.material
    }, {
      Code: DataPrivacyScoreCode.notmaterial,
      Text: DataPrivacyScoreText.notmaterial
    }
  ];
  public includeRaQuesAns: boolean = false;
  checkboxLabel = "Include RA questions and answers";
  heading = "Risk Assessment Report";
  private dateTypes: any[] = [
    { from: 'creationDateFrom', to: 'creationDateTo'  },
  ];

  ngOnInit(): void {
    this.allCategory = JSON.parse(localStorage.getItem('categoryList'));
    this.allSubCategory = JSON.parse(localStorage.getItem('subCategoryList'));
    this.allCountry = JSON.parse(localStorage.getItem('countryList')).servedCountryList;
    this.tempCountry = this.getServedCountryList();
    super.ngOnInit();
    this.initFilterCriteria();
    this.initButtons();
    this.subheaderProps = {
      isWelcomeMessage: false,
      heading: ""
    };
  }
  initFilterCriteria() {
    this.filterCriteria = {
      countryServed: null,
      categories: null,
      subcategories: null,
      creationDateFrom: null,
      creationDateTo: null,
      raStatus: null,
      biBdaStatus: null,
      infoSecScore: null,
      dataPrivacyScore: null,
      supplierName: "",
      includeRaQuesAns: false,
    };
    this.filterCriteriaPayload = {
      countryServed: null,
      categories: null,
      subcategories: null,
      creationDateFrom: null,
      creationDateTo: null,
      raStatus: null,
      biBdaStatus: null,
      infoSecScore: null,
      dataPrivacyScore: null,
      supplierName: "",
      includeRaQuesAns: false,
    }
  }

  initButtons() {
    this.clearFilterButton = {
      label: "",
      color: "purple",
      clickBorderColor: "purple",
      backgroundColor: "lightPurple",
      hoverBorderColor: "purple",
      fontSize: 16,
      height: 48,
      width: 180,
      onClick: async () => {
        this.clearFilters();
      }
    };
    this.applyFilterButton = {
      label: "",
      color: "white",
      fontSize: 16,
      backgroundColor: "blue",
      borderColor: "blue",
      height: 48,
      width: 180,
      onClick: async () => {
        this.applyFilters();
      }
    };
  }
  applyFilters() {
    this.invalidFields = [];
    this.filterCriteriaPayload = { ...this.filterCriteria };

    this.dateTypes.forEach((filter) => {
      this.validateDates(filter);
      this.formatPayloadDate(filter);
    });

    if (Object.keys(this.invalidFields).length > 0) {
      return;
    }
    const filterData: FilterData[] = this.filtersToApply.filter((filterType) => this.includeFilterInPayload(filterType))
      .map(filter => ({
        filterName: filter,
        filterValue: this.filterCriteriaPayload[filter]
      }));
    this.setFilterButtonsDisabledProps(false);
    this.applyFilterEventEmitter.emit({ reportType: 'ra_report', filters: filterData });
    this.scheduleWaitingMessage();
  }
  clearFilters() {
    this.initFilterCriteria();
    this.includeRaQuesAns = false;
    this.clearFilter.next(true);
  }
  includeFilterInPayload(raFiltertype: FilterType): boolean {
    // compliant filter value needs to be explicilty included
    if (raFiltertype === "includeRaQuesAns") {
      return true;
    }

    return !!this.filterCriteriaPayload[raFiltertype];
  }

  inputChanged() {
    this.filterCriteriaChanged(this.includeRaQuesAns,'includeRaQuesAns')
  }
  getServedCountryList(): any {
    
    this.allCountry?.forEach(element => {
      const state = element.countryServedEnable;
      if (state === true) {
        const country = {
          Code: element.tsmCode,
          Text: element.countryServedName
        };
        this.tempCountry.push(country);
      }
    });
    return this.sortSelectResource(this.tempCountry);
  }
  sortSelectResource = (resource: any): any => {
    return resource?.sort((obj1: any, obj2: any) => {
      const val1 = obj1.Text;
      const val2 = obj2.Text;
      if (val1 > val2) {
        return 1;
      } else if (val1 < val2) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  ngAfterContentChecked() {
    this.subheaderProps.heading = this.heading;
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.clearFilterButton.label = multilanguageJson.body.main.reportFilters.clearFilter;
        this.applyFilterButton.label = multilanguageJson.body.main.reportFilters.applyFilter;
        this.waitingMessageLabel = multilanguageJson.body.main.reportFilters.waitingMessage;
      }
    }
  }

}