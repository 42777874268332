<div class="relationship-action-container">
  <div class="row m-0">
    <div class="col-7 relationship-action-left-area">
      <div class="row m-0">
        <div class="relationship-action-text-heading">
          <p>
            {{ labelTranslations.actionRequired }}
          </p>
        </div>
      </div>
      <div class="row m-0">
        <div class="relationship-action-text-message">
          <p>
            {{ labelTranslations.message }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-4 relationship-action-right-area">
      <div class="row">
        <div class="relationship-action-disclaimer">
          <div class="row m-0">
            <div class="col-1">
              <img id="disclaimer-img"
                [src]="checkboxImage"
                [(ngModel)]="disclaimerSelected"
                (click)="onDisclaimerClicked()"
                alt="icon-checkbox"
              />
            </div>
            <div class="col">
              <label class="input-label" for="relationship-disclaimer">
                {{ labelTranslations.confirm }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0">
        <div class="relationship-action-actions">
          <sh-button [props]="rejectButtonProps"></sh-button>
          <sh-button class="confirmbutton" [props]="confirmButtonProps"></sh-button>
        </div>
      </div>
    </div>
  </div>
</div>
