import { EventEmitter, Injectable } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { RiskAssessmentModel,createRaModel } from "../../models/riskAssessment" ;
import saveAs from "file-saver";

type EditMode = "create" | "edit" | "view";

@Injectable({
    providedIn: "root"
})
export class RiskassessmentService {
    private baseUrl = "";
    public eventEmit: any;
    public mode:EditMode = "create";
    // To pass the ra metadata from triage popup to ra questionnaire page
    public raMetadata = {};
    public raCode;
    public raModel:RiskAssessmentModel = {};
    public shortDescription: string = ""
    public raQuestion:any
    private raPriorityRoles = {
        scm: 'scm',
        srdp: 'srdp',
        prisk: 'prisk',
        srsa: 'srsa',
        srra: 'srra'
      }
      verifyAlertVisiable: boolean;
      public bibdaEventEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(private httpService: HttpService, private http: HttpClient) {
        this.eventEmit = new EventEmitter();
        this.baseUrl = `${environment.tsmBackendServiceURL}`;
    }

    /**
     * Description: Initilize RiskAssessment Questionnair.
     * @Param data: data from survey
     */
    initilizeRiskAssessmentForm =  (cid:string, scid:string): Promise<any> => {
        const url = `${this.baseUrl}/riskAssessmentData/getRiskAssessmentQuestions?category=${cid}&subcategory=${scid}`;
        const response = this.httpService.GetPromise(url);
        // TO DO: Error handling
        return response;
    };

    /**
     * Description: get Date from Database for risk assessment.
     * @Param data: payload with ra_id and role
     */
    loadSupplierRequestForm = async () => {
        let isRaAdmin = false;
        let ra_admin = localStorage.getItem("RA_flag");
        if (ra_admin == this.raPriorityRoles.scm || ra_admin == this.raPriorityRoles.srdp || ra_admin == this.raPriorityRoles.prisk || ra_admin == this.raPriorityRoles.srsa || ra_admin == this.raPriorityRoles.srra) {
            isRaAdmin = true;
        }
        const url = `${this.baseUrl}/riskAssessmentData/getRiskAssessmentAnswers`;
        const response:any = await this.httpService.PostPromise(url,{
            "ra_id": this.raCode,
            "role": isRaAdmin
        });
        if(response.error) {
            this.verifyAlertVisiable = true
            setTimeout(() => this.verifyAlertVisiable = false, 5000);
            scroll(0,0)
        }else {
            this.verifyAlertVisiable = false  
        }
        this.raQuestion = {data:response.data.questionsList};
        this.raModel.RequestForm.RiskAssessmentInfo = response.data.model;
        this.shortDescription = response.data.shortDesc;
    };

    loadSupplierRequestFormEdit = async () => {
        
        let isRaAdmin = false;
        let ra_admin = localStorage.getItem("RA_flag");
        if (ra_admin == this.raPriorityRoles.scm || ra_admin == this.raPriorityRoles.srdp || ra_admin == this.raPriorityRoles.prisk || ra_admin == this.raPriorityRoles.srsa || ra_admin == this.raPriorityRoles.srra) {
            isRaAdmin = true;
        }
        const url = `${this.baseUrl}/riskAssessmentData/getRiskAssessmentDraft`;
        const response:any = await this.httpService.PostPromise(url,{
            "ra_id": this.raCode,
            "role": isRaAdmin
        });
        this.raQuestion = {data:response.data.questionsList};
        await this.sortSelectResource(this.raQuestion)

        // to convert the date value into datepicker format
        function parseMDY(s) {
            var b = s.split(/\D+/);
            var d = new Date(b[2], b[0]-1, b[1]);
            return d && d.getMonth() == b[0]-1? d : new Date(NaN);
          }
        if(response.data.model.BI_BDA_1bEndDate !== undefined && response.data.model.BI_BDA_1bEndDate !== '') {
            response.data.model.BI_BDA_1bEndDate = parseMDY(response.data.model.BI_BDA_1bEndDate);
        }
       
        if(response.data.model.BI_BDA_1bStartDate !== undefined && response.data.model.BI_BDA_1bStartDate !== '') {
            response.data.model.BI_BDA_1bStartDate = parseMDY(response.data.model.BI_BDA_1bStartDate);
        }
        
        response.data.model.dataPrivacy = response.data.model.dataPrivacy === 'true' ? true: false;
        response.data.model.AI3 = response.data.model.AI3 === 'true' ? true: false;
        this.raModel.RequestForm.RiskAssessmentInfo = response.data.model;
        
        this.shortDescription = response.data.shortDesc;
        console.log("raid", this.raModel.RequestForm.RiskAssessmentInfo);
    };

    // sort the searchable drop down in risk assessment quetionire
    sortSelection = (resource: any): any => {
        return resource?.sort((obj1: any, obj2: any) => {
            var val1 = obj1.text;
                var val2 = obj2.text;
                if (val1 > val2) {
                    return 1;
                } else if (val1 < val2) {
                    return -1;
                } else {
                    return 0;
                }
        });
    }

    // sort the searchable drop down in risk assessment quetionire
    sortSelectResource= (raQuestion: any): any => {
        if(raQuestion.data.length) {
            for (let i = 0; i < raQuestion.data.length; i++) {
                for (let j = 0; j < raQuestion.data[i].fieldset.length; j++) {
                    let question =raQuestion.data[i].fieldset[j];
                    if (
                      question.type === 'searchableDropdown' && question.dataSource.length
                  ) {
                      question.dataSource = this.sortSelection(question.dataSource);
                  }
                }
            }
        }  
    }
    // download RA report
    downloadReportOfRA = async (ra_id): Promise<any> => {
        try {
          const fileName = `${ra_id}.csv`;
          const downloadUrl =`${this.baseUrl}/riskAssessmentData/riskAssessmentExportReport`;
          const file: any = await this.http.post(downloadUrl, {
            ra_id: ra_id
          }, { responseType: "blob" }).toPromise();
  
          if(file == undefined) {
            console.log('Exception in download')
            return;
          }
          // return file
          const blob = new Blob([file], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
          saveAs(blob, fileName);
        } catch (e) {
          //don't do logs in the frontend, but in future this is where we could put some logic to tell the user their download failed via UI
          console.log(e);
        }
      };


    getRiskAssessmentAvailability = async (payload) => { 
        const riskAssessmentDetails = `${this.baseUrl}/riskAssessmentData/getRiskAssessmentAvailability`;
        return await this.httpService.PostPromise(riskAssessmentDetails, payload);
    };

    sendBIBDAScore = async (payload) => { 
        const riskAssessmentDetails = `${this.baseUrl}/riskAssessmentData/submitBibdaScore`;
        return await this.httpService.PostPromise(riskAssessmentDetails, payload);
    };

    updateBibdaCaseId = async (payload) => { 
        const url = `${this.baseUrl}/riskAssessmentData/updateBibdaCaseId`;
        return await this.httpService.PostPromise(url, payload);
    };

}