<tsm-user-profile-container (saveClickedEvent)="saveRole()" [userId]="user.userId" #userProfileContainer>
    <div class="row no-gutters mt-4">
        <div class="col-1 icon-col role-icon-container">
            <img src="assets/icons/icon-services.svg" alt="icon-services" />
        </div>
        <div class="col">
            <div (click)="toggleDropdown()" class="dropdown" #inputPanel>
                <span id="displayRole" class="input-role">{{ user.displayRole }}</span>
                <div class="dropdown-icon">
                    <img src="assets/icons/chevron-down-purpledropdown.svg" alt="icon-chevron-down" />
                </div>
            </div>
            <div *ngIf="open" class="multi-drop-container">
                <div *ngIf="open" class="multi-drop-panel" #dropPpanel>
                    <div class="multi-drop-panel-cont">
                        <div *ngFor="let role of roles" class="individual-option" [value]="role.Code">
                            <label [for]="role.Code" class="rc-container label-drop">
                                <input [id]="role.Code" type="checkbox" [checked]="role.checked"
                                    (change)="checkedChanged($event, role)" style="display: none"
                                    aria-label="input-multiple-selector-checkbox" />
                                <img *ngIf="!role.checked" src="./assets/icons/square.svg" alt="icon-unchecked" />
                                <img *ngIf="role.checked" src="./assets/icons/check-square-fill.svg" alt="icon-checked" />
                                <span>{{ role.Text }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row no-gutters mt-4">
        <div class="col-1 icon-col">
            <img src="assets/icons/icon-bin.svg" alt="delete-user-icon" />
        </div>
        <span id="deleteUser" class="col delete-user user-data"
            *ngIf="loggedInUser.defaultProfile === 'supplier' || loggedInUser.defaultProfile === 'buyer'"
            (click)="delete()">{{deleteUser}}</span>
        <span id="restrictAccess" class="col delete-userA user-data" *ngIf="loggedInUser.defaultProfile === 'authority'"
            (click)="restrict()">{{restrictAccess}}</span>
    </div>
</tsm-user-profile-container>