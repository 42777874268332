import { Component, OnInit } from "@angular/core";
import { GdprLogItem, GdprLogItemView, ButtonPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { GdprService } from "src/app/services/gdpr/gdpr.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { AlertPropsType } from "@lsl16/sustainability-shared-components/lib/models/AlertPropsType";

@Component({
  selector: "tsm-gdpr-dashboard",
  templateUrl: "./gdpr-dashboard.component.html",
  styleUrls: ["./gdpr-dashboard.component.sass"]
})
export class GdprDashboardComponent implements OnInit {
  public users: GdprLogItemView[] = [];
  userName: string;
  searchButton: ButtonPropsType;
  tsmGetSupplier = `${environment.tsmBackendServiceURL}/supplier`;
  successAlertVisible: boolean;
  failureAlertVisible: boolean;
  deletionError: boolean;
  successAlert: AlertPropsType;
  failureAlert: AlertPropsType;

  constructor(private gdprService: GdprService, private httpService: HttpService, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.searchButton = {
      label: "Search",
      color: "white",
      fontSize: 16,
      backgroundColor: "blue",
      borderColor: "blue",
      hoverColor: "white",
      hoverBackgroundColor: "blue",
      hoverBorderColor: "white",
      height: 48,
      width: 150,
      padding: "10px",
      iconPosition: "left",
      onClick: () => this.search()
    };
    // Initialize the Deletion alerts
    this.setSuccessAlertProps();
    this.setFailureAlertProps();

  }

  /**
   * Search on enter key
   * @param event
   */
  searchKeyDown(event) {
    if (event.keyCode == 13) {
      // enter:13
      this.search();
    }
  }

  /**
   * Function to get user details based on parameters in searchbox
   */
  search = async () => {
    // Don't search if the search term is empty
    if (!this.userName) {
      return;
    }

    // Don't search for the same user again
    if (this.users.some((user) => user.userName === this.userName)) {
      return;
    }

    try {
      // Make call to B2C to get users
      const emailPattern = /^([a-zA-Z0-9_\-\.])+\@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,}){1,2})$/;
      const result = emailPattern.test(this.userName);
      if (!result) {
        return;
      }
      const baseUrl = `${environment.tsmBackendServiceURL}/userprofile/getUserprofile/${this.userName}`;
      const userProfile = await this.httpService.GetPromise<any>(baseUrl);
      const userTwo: GdprLogItem = await this.gdprService.getUsers(this.userName);
      const user = Object.assign({}, userProfile);
      user.userName = userTwo.userName;
      user.firstName = userTwo.firstName;
      user.lastName = userTwo.lastName;
      user.userId = userTwo.userId;
      user.supplierName = "";
      const tsmIdArry = [];
      if (user.profileData) {
        for (const key in user.profileData) {
          if (user.supplierName !== "") {
            user.supplierName = user.supplierName + "," + user.profileData[key].profileName;
          } else {
            user.supplierName = user.supplierName + user.profileData[key].profileName;
          }
          tsmIdArry.push(key);
        }
      } else {
        user.supplierName = "User TSM profile deleted by each GDPR request.";
      }

      if (user) {
        const itemRow: GdprLogItemView = {
          userName: user.userName,
          firstName: user.firstName,
          lastName: user.lastName,
          tsmId: user.extension_tsm_tsmId,
          company: user.supplierName,

          onDelete: () => {
            console.log(user);
            if (tsmIdArry.length > 0) {
              this.deleteGDPRData(user.userId, tsmIdArry, user.userName);
            }
          }
        };
        if (tsmIdArry.length <= 0) {
          itemRow.btn = {
            backgroundColor: "gray",
            borderColor: "white",
            hoverColor: "",
            hoverBackgroundColor: "",
            hoverBorderColor: "",
            disabled: true
          };
        }
        this.users = [itemRow];
      }
      setTimeout(() => {
        // word-wrap:break-word
        let elements = <HTMLElement>document.getElementsByClassName("role")[0];
        elements.style.wordBreak = "break-word";
      }, 500);

    } catch (e) {
      //TODO show some UI for errors
    }
  };

  /**
   * call backend apis for GDPR deletion and show confirmation alerts
   * @param tsmID
   */
  deleteGDPRData = async (userId: string, tsmId: any, email: string) => {
    if (tsmId.length > 0) {
      for (let i = 0; i < tsmId.length; i++) {
        const tsmID = tsmId[i];
        const gdprProfileUrl = `${environment.tsmBackendServiceURL}/gdpr/supplierGeneralProfile/${tsmID}`;
        const gdprESGUrl = `${environment.tsmBackendServiceURL}/gdpr/deleteEsgQuestionnairePersonalData/${tsmID}`;
        const gdprUserUrl = `${environment.tsmBackendServiceURL}/b2c/user/${userId}`;
        const TSMProFileIdUrl = `${environment.tsmBackendServiceURL}/userprofile/deleteTSMProFileId`;
        const pocEmialUrl = `${environment.tsmBackendServiceURL}/userprofile/deletePocEmail`;
        try {
          this.deleteTSMProFile(TSMProFileIdUrl, tsmID, email).then(() => {
            return this.deletePersonalData(gdprESGUrl)
          }).catch((res) => {
            if(res ===  "No Data")
            return this.deletePersonalData(gdprESGUrl)
          }).then(() => {
            return this.deletePersonalData(gdprProfileUrl)
          }).catch(() => {
            return this.deletePersonalData(gdprProfileUrl)
          }).then(() => {
            return this.deletePcoEmail(pocEmialUrl, email)
          }).catch(() => {
            return this.deletePcoEmail(pocEmialUrl, email)
          }).then(() => {
            return this.deleteB2CUser(gdprUserUrl)
          }).catch(() => {
            return this.deleteB2CUser(gdprUserUrl)
          });

        } catch (e) {
          this.deletionError = true;
        } finally {
          //show either confirmation or error alert
          this.showDeletionAlert();
          //remove the user from this.user if they have been deleted
          if (!this.deletionError) {
            this.users.splice(0, 1);
          }
        }
      }
    } else {
      this.showDeletionAlert();
      this.users.splice(0, 1);
    }
  };
  /**
   * Deletes personal data for a url, throws an error if a non 200 status code is returned
   * @param url Url to call
   * @returns undefined or throws an error
   */
  private deletePcoEmail = async (url, email) => {
    const response = await this.httpService.PostPromise<any>(url,
      {
        email: email
      }
    );
    console.log(response);
    if (response && response.$metadata.httpStatusCode === 200) {
      return;
    } else {
      throw "";
    }
  };
  /**
   * Deletes personal data for a url, throws an error if a non 200 status code is returned
   * @param url Url to call
   * @returns undefined or throws an error
   */
  private deleteTSMProFile = async (url, tsmID, email) => {
    if (tsmID == null) {
      tsmID = "";
    }
    if (email == null) {
      email = "";
    }
    const response = await this.httpService.PostPromise<any>(url,
      {
        tsmId: tsmID,
        email: email
      }
    );
    if (response && response.$metadata?.httpStatusCode === 200) {
      return;
    } else {
      throw "";
    }
  };
  /**
   * Deletes personal data for a url, throws an error if a non 200 status code is returned
   * @param url Url to call
   * @returns undefined or throws an error
   */
  private deletePersonalData = async (url) => {
    const response = await this.httpService.PostPromise<any>(url, {});
    if (response && response.status === 200) {
      return;
    } else {
      throw "";
    }
  };

  /**
   * Calls the tsm backend to delete a b2c user, checks the status code of the response
   * to see if the user has been deleted or not
   * @param url Url to call
   * @returns resolves void or rejects an error
   */
  private deleteB2CUser = async (url) => {
    const data = {
      extension_tsm_TSM: "{\"1\":[],\"2\":[],\"3\":[]}",
      extension_tsm_SUP: "{\"1\":[],\"2\":[],\"3\":[]}",
      extension_tsm_SPH: "{\"1\":[],\"2\":[],\"3\":[]}",
    };
    const response = await this.httpService.PostPromise<any>(url, data);
    console.log(response);
  };

  /**
   * Sets the success alert properties
   */
  setSuccessAlertProps = (): void => {
    this.successAlert = {
      type: "alert-profileVerifiedSuccess",
      message: "All personal data for selected user was successfully removed from our systems.",
      icon: "assets/icons/check_circle-24px.svg",
      iconFill: "#376803",
      iconClose: "assets/images/icon-cross-blue.svg",
      onDismiss: () => {
        this.successAlertVisible = false;
      }
    };
  };

  /**
   * Sets the failure alert properties
   */
  setFailureAlertProps = (): void => {
    this.failureAlert = {
      type: "alert-profileVerifiedFaild",
      message:
        "There's been an error! We've been unable to complete delete this user's details from our systems.",
      icon: "assets/icons/alert.svg",
      iconFill: "#cc1e32",
      iconClose: "assets/images/icon-cross-blue.svg",
      onDismiss: () => {
        this.failureAlertVisible = false;
      }
    };
  };

  /**
   * Determine if there has been an error and show approriate alert
   */
  showDeletionAlert = (): void => {
    this.deletionError === true ? (this.failureAlertVisible = true) : (this.successAlertVisible = true);
  };
  blockNonPrintableChar() {
    let printablevalue= this.userName;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.userName = printablevalue; 
  }
}
