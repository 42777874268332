import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from '@lsl16/sustainability-shared-components';
import { InviteSupplierEvents } from 'src/app/models/InviteSupplier';
import { environment } from "../../../environments/environment";
import { SupplierInvite } from './../../models/SupplierInvite';

@Injectable({
  providedIn: 'root'
})
export class SupplierInviteService {
  private baseUrl = "";
  public eventEmit: EventEmitter<InviteSupplierEvents> = new EventEmitter<InviteSupplierEvents>();

  constructor(private httpService: HttpService) {
    this.baseUrl = environment.tsmBackendServiceURL;
  }

  async confirmInvitedSupplier(inviteMode: string, params: SupplierInvite): Promise<void> {
    let payload;
    let url;
    if (!params.supplierTsmId || !params.supplierTsmId.trim()) {
      throw new Error("Subsidiary tsm id is null or empty");
    }
    if (inviteMode === "parent") {
      payload = { comment: params.comment }
      url = `${this.baseUrl}/workflow/relationships/approve/${params.supplierTsmId}`;
    } else {
      payload = { workflowId: params.workflowId, comment: params.comment }
      url = `${this.baseUrl}/workflow/relationships/confirmSubSupplierInvite/${params.supplierTsmId}`;
    }
    const response = await this.httpService.PostPromise<any>(url, payload);

    if (response.text != "OK") {
      throw new Error(`Approving the parent supplier failed: ${response}`);
    }
  }

  async rejectInvitedSupplier(inviteMode: string, params: SupplierInvite): Promise<void> {
    let payload;
    let url;
    if (inviteMode === "parent") {
      url = `${this.baseUrl}/workflow/relationships/rejectParentInvite/${params.supplierTsmId}`;
      payload = { rejectReason: params.comment }
    } else {
      url = `${this.baseUrl}/workflow/relationships/rejectSubSupplierInvite/${params.supplierTsmId}`;
      payload = { workflowId: params.workflowId, rejectionReason: params.comment }
    }
    try {
      const response = await this.httpService.PostPromise<any>(url, payload);
      if (response.text === "OK") {
        this.eventEmit.emit("rejectSuccess");
      } else {
        throw new Error(`Failed to reject invited supplier: ${response}`);
      }
    } catch (error) {
      this.eventEmit.emit("error");
    }
  }

}
