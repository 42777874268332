import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { HeaderEvents, HeaderService } from 'src/app/services/header/header-service';
import { Bar } from '../chart-bar/chart-bar.component';

@Component({
    selector: 'score-chart',
    templateUrl: './score-chart.component.svg'
})
export class ScoreChartComponent implements OnInit {
    @Input() scoreLevel: string;

    translations = {
        leader: "",
        strategic: "",
        established: "",
        emerging: "",
        nascent: "",
        leaderTooltip: "",
        strategicTooltip: "",
        establishedTooltip: "",
        emergingTooltip: "",
        nascentTooltip: "",
        your: "",
        score: ""
    };
    public bars: Array<Bar> = [];

    constructor(private headerService: HeaderService) { }

    ngOnInit(): void {
        this.subscribeEvents();
        this.refreshData();
    }

    private loadTranslations() {
        if (localStorage.getItem('multiLanguage')) {
            const multilanguageJson = JSON.parse(localStorage.getItem('multiLanguage'));
            if (multilanguageJson.questionnire) {
                this.translations = multilanguageJson.questionnire.Scoring;
            }
        }
    }

    private calculatePerformanceLevel(scoreLevel: string) {
        if (scoreLevel === "Emerging") { return 1; }
        if (scoreLevel === "Established") { return 2; }
        if (scoreLevel === "Strategic") { return 3; }
        if (scoreLevel === "Leader") { return 4; }
        return 0;
    }

    private createBars(level: number): Array<Bar> {
        const result: Array<Bar> = [];

        result.push(new Bar(this.translations.leader, level === 4, 25, 0, this.translations.leaderTooltip));
        result.push(new Bar(this.translations.strategic, level === 3, 40, 52, this.translations.strategicTooltip));
        result.push(new Bar(this.translations.established, level === 2, 55, 104, this.translations.establishedTooltip));
        result.push(new Bar(this.translations.emerging, level === 1, 70, 156, this.translations.emergingTooltip));
        result.push(new Bar(this.translations.nascent, level === 0, 85, 208, this.translations.nascentTooltip));

        return result;
    }

    private refreshData() {
        this.loadTranslations();
        const level = this.calculatePerformanceLevel(this.scoreLevel);
        this.bars = this.createBars(level);
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes["scoreLevel"]) {
            this.refreshData();
        }
    }

    private subscribeEvents() {
        this.headerService.eventEmit.subscribe((event) => {
            if (event === HeaderEvents.multiLanguageChanged) {
                this.refreshData();
            }
        });
    }
}
