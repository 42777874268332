import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Options } from "../../models/Options";
import { AuthService } from "src/app/services/auth/auth.service";
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { BuyerService } from "src/app/services/buyer/buyer.service";
import { SupplierHubService } from "src/app/services/supplierHub/supplier-hub.service";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { EventService } from 'src/app/services/EventService.service';
import { HttpService, ParentChildRelation, SupplierRelationshipService, AlertPropsType } from "@lsl16/sustainability-shared-components";
import { OnboardingStatusResponseType } from "src/app/services/supplierHub/types";
import { environment } from "../../../environments/environment";
import { isEmpty } from 'lodash';
import { countryServedList } from "../../../utilities/const/country-served";
import { countryList } from "../../../utilities/const/country-list";
import { BvPopUpOnboardingComponent } from "src/app/components/bv-pop-up-onboarding/bv-pop-up-onboarding.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalService } from "src/app/services/modal/modal.service";
import { SupplierService } from "src/app/services/supplier/supplier.service";
import { Subscription } from "rxjs";
import { PopUpCountryServedNotifyComponent } from 'src/app/components/pop-up-country-served-notify/pop-up-country-served-notify.component';
import { CountryServedService } from "src/app/services/countryServedData/country-served.service";
@Component({
    selector: "tsm-supplier-action-selection",
    templateUrl: "./supplier-action-selection.component.html",
    styleUrls: ["./supplier-action-selection.component.sass"]
})
export class SupplierActionSelectionComponent implements OnInit {
    esgAssessmentProps: Options = {
        title: "Sustainability Assessment",
        imageUrl: "assets/images/actions-img.png",
        reason: "",
        isReject: false,
        countryServed: "",
        tsmId: "",
        isTerminateStatus: false,
        supplierName: "",
        taskId: "",
        isLockedStatus: false,
        requestdate: "",
        description: "Understand supplier's sustainability performance.",
        buttonProps: {
            label: "Send Assessment",
            color: "white",
            backgroundColor: "blue",
            borderColor: "blue",
            hoverColor: "white",
            hoverBackgroundColor: "blue",
            hoverBorderColor: "white"
        },
        explain: `Select this, when onboarding is not needed.`,
        onClick: () => {
            this.jumpPage();
        },
        manageUsersButtonProps: {
            color: "purple",
            backgroundColor: "transparent",
            borderColor: "blue",
            label: "Manage Users"
        },
        onManageUsersButtonClick: () => {
            this.redirectToAdminBoard();
        }
    };
    esgAssessment: Options;
    onboarding: Options;
    viewRelatedProfilesProps: Options;
    supplierTsmId: string;
    countryServed: string;
    supplierName: string;
    supplier: GeneralSupplierType;
    selectedSupplierTsmId: string;
    error: boolean;
    countryServedMapping: string;
    showCard: boolean = false;
    onboardingStatusUrl: string;
    userType: any;
    esgStatus: string;
    supplierHubOnboardingStatus: OnboardingStatusResponseType;
    hasConfirmedParent: boolean = false;
    nordicsState: boolean = false;
    addSupplierValues: any;
    routeParamCountry: string;
    routeCountries: string;
    showAlertsuccess: boolean = false;
    successAlert: AlertPropsType;
    parentSupplier: GeneralSupplierType;
    saveSubmission: Subscription;
    private translations = {
        awaitingParentMessage: "",
        mutuallyConfirmParentSAInfo: "",
        awaitingInviteApprovalMessage: ""
    }
    saActionSubscription: Subscription;
    parentNotSa: boolean = true
    popData: any;
    countryNotCreated: any;
    countryCreated:any;
    constructor(
        private router: Router,
        public supplierGeneralProfileService: SupplierGeneralProfileService,
        public eventService: EventService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private supplierHubService: SupplierHubService,
        private httpService: HttpService,
        private relationshipService: SupplierRelationshipService,
        private BuyerService: BuyerService,
        public ngbModalService: NgbModal,
        private ModalService: ModalService,
        private supplierService: SupplierService,
        private countryServedService: CountryServedService
    ) { }

    async ngOnInit(): Promise<void> {
        let routeParam = this.route.snapshot.paramMap.get("id");
        let routeParamCountry = this.route.snapshot.paramMap.get("country");
        let routeParamnordicsCountry = this.route.snapshot.paramMap.get("countryServed");
        this.routeCountries = routeParamCountry;
        this.supplier = await this.supplierGeneralProfileService.getSupplierById(routeParam);
        this.hasConfirmedParent = !isEmpty(this.supplier.parentTsmId) && this.supplier.parentTsmId !== this.supplier.supplierTsmId;
        if (routeParamCountry != '') {
            let countryList = routeParamCountry.split(',');
            this.routeParamCountry = countryList[0];
        };
        if (routeParamnordicsCountry) {
            if (this.hasConfirmedParent === true) {
                let supIdAndCountry = {
                    supplierTsmId: this.supplier.parentTsmId,
                    countryServed: routeParamnordicsCountry
                };
                const respond = await this.BuyerService.getNordicsCountryStatus(supIdAndCountry);
                this.nordicsState = respond.nordics
            } else if (this.hasConfirmedParent === false) {
                let supIdAndCountry = {
                    supplierTsmId: routeParam,
                    countryServed: routeParamnordicsCountry
                };
                const respond = await this.BuyerService.getNordicsCountryStatus(supIdAndCountry);
                this.nordicsState = respond.nordics
            }
        }
            if (this.hasConfirmedParent) {
                let parentListBeforeUpdate = await this.supplierGeneralProfileService.getSupplierById(this.supplier.parentTsmId);
                let parentListAfterUpdate = await this.supplierGeneralProfileService.getSupplierById(this.supplier.parentTsmId);
                this.routeCountries.split(',').forEach(i => {
                    if (!parentListAfterUpdate.countryServed.includes(i)) {
                        parentListAfterUpdate.countryServed.push(i)
                        let childAndParentCountryList = parentListAfterUpdate.countryServed;
                        const updateSupplierValues = {
                            countryServed: childAndParentCountryList,
                            pocEmail: this.authService.getLoginUser().email,
                        };
                        this.supplierGeneralProfileService.updateSupplier(this.supplier.parentTsmId, updateSupplierValues);
                    }
                })
                this.supplierGeneralProfileService.updateCountryExtendDate(this.supplier.parentTsmId,parentListAfterUpdate,parentListBeforeUpdate)
            }
        if(routeParamCountry) {
            let supIdAndCountry = {
                supplierTsmId: routeParam,
                countryServed: routeParamCountry
            };
            const respond = await this.BuyerService.getPopUpStatus(supIdAndCountry);
            this.popData = respond
        }
        if(this.popData.countryNotCreated.length>0) {
            if(this.popData.countryNotCreated.length == 2) {
                this.countryNotCreated = this.popData.countryNotCreated.join(' and ')
            } else if(this.popData.countryNotCreated.length > 2) {
                let arrayA = this.popData.countryNotCreated.pop()
                let arrayB = this.popData.countryNotCreated.join(',') + ' and ' + arrayA
                this.countryNotCreated = arrayB
            } else{
                this.countryNotCreated = this.popData.countryNotCreated.join(',')
            }
        }
        if(this.popData.countryCreated.length>0) {
            if(this.popData.countryCreated.length == 2) {
                this.countryCreated = this.popData.countryCreated.join(' and ')
            }else if(this.popData.countryCreated.length > 2) {
                let arrayA = this.popData.countryCreated.pop()
                let arrayB = this.popData.countryCreated.join(',') + ' and ' + arrayA
                this.countryCreated = arrayB
            }else{
                this.countryCreated = this.popData.countryCreated.join(',')
            }
        }
        this.selectedSupplierTsmId = routeParam;
        this.supplierTsmId = routeParam;
        this.esgAssessmentProps.tsmId = this.supplierTsmId
        await this.initialiseSupplier();
        this.countryServed = this.routeParamCountry
        this.esgAssessmentProps.countryServed = this.routeParamCountry
        this.esgAssessmentProps.supplierName = this.supplier.supplierName
        this.userType = this.authService.getRole();
        this.route.queryParams.subscribe((params) => {
            this.supplierName = params.supplierName;
        });
        this.updatePropsBasedOnConfirmParent();
        await this.refreshData();
        await this.fetchParentData();
        this.esgAssessment = {
            title: "Sustainability Assessment",
            description: "Understand supplier's sustainability performance.",
            imageUrl: "assets/images/actions-img.png",
            buttonProps: {
                label: "Send Assessment",
                color: "white",
                backgroundColor: "blue",
                borderColor: "blue",
                hoverColor: "white",
                hoverBackgroundColor: "blue",
                hoverBorderColor: "white"
            },
            explain: `Select this, when onboarding is not needed.`,
            onClick: () => {
                this.jumpPage();
            }
        };

        await this.checkParent();

        this.onboarding = {
            title: "Onboarding Form",
            description: "Gather key supplier details to begin using their goods and/or services.",
            imageUrl: "assets/images/onboarding.svg",
            buttonProps: {
                label: "Send Request",
                color: "white",
                backgroundColor: "blue",
                borderColor: "blue",
                hoverColor: "white",
                hoverBackgroundColor: "blue",
                hoverBorderColor: "white"
            },
            explain: "The Sustainability Assessment will be automatically sent as part of onboarding.",
            onClick: () => {
                this.popUpforSendOnboardingRequest();
            }
        };

        this.viewRelatedProfilesProps = {
            title: "View Related Supplier Profiles",
            description: "See related supplier profiles and their statuses listed under this company.",
            imageUrl: "assets/images/ipad-card-image.png",
            buttonProps: {
                label: "View Related Profiles",
                color: "blue",
                backgroundColor: "white",
                borderColor: "blue",
                hoverColor: "blue",
                hoverBackgroundColor: "white",
                hoverBorderColor: "blue"
            },
            onClick: () => {
                this.handleSupplierHubRedirect();
            }
        };

        this.saveSubmission = this.eventService.eventEmit.subscribe(e => {
            let emitArray = e.split("#")
            if (emitArray[0] === 'onboardingSendRequest_Action') {
                this.handleSendOnboardingRequest(emitArray[1]);
            }
        })
        this.saActionSubscription = this.eventService.eventEmit.subscribe(e => {
            if (e === 'send-sa') {
                this.redirectSa();
            };
        });
    }

    checkParent = async (): Promise<void> => {
        const hasConfirmedParent = !isEmpty(this.supplier.parentTsmId) && this.supplier.parentTsmId !== this.supplier.supplierTsmId;
        if (hasConfirmedParent) {
            const userInfo = this.authService.getLoginUser();
            let supAndBuyID = {
                supplierTsmId: this.supplier.parentTsmId,
                buyerTsmId: userInfo.tsmId,
                countryServed: this.getOnlyOneSupplierCountryCode(this.countryServed)
            };
            const respond = await this.BuyerService.getBuyerLockStatus(supAndBuyID);
            if (respond && respond.length != 0) {
                if (
                    (respond[0].status === "Pending" && respond[0].type === "StartESGAssessment") ||
                    (respond[0].status === "In Progress" && respond[0].type === "ContinueESGAssessment") ||
                    (respond[0].status === "In Progress" && respond[0].type === "ContinueESGAssessmentForOnboarding") ||
                    (respond[0].status === "Pending" && respond[0].type === "StartESGAssessmentForOnboarding") ||
                    (respond[0].status === "Resent" && respond[0].type === "StartESGAssessment")
                ) {
                    this.esgAssessment.isLockedStatus = true;
                    this.esgAssessment.requestdate = respond[0].displayCreationDate;
                }
            }
        }
    }

    async popUpforSendOnboardingRequest() {
        const getCountriesURL = `${environment.tsmBackendServiceURL}/countries/getOnboardingCountries/${this.supplier.supplierTsmId}/${this.countryServed}`;
        const response: any = await this.httpService.GetPromise(getCountriesURL);
        let countryMu = response
        let countryArr = this.routeCountries.split(",")
        let compare = []
        countryArr.forEach((item) => {
            countryMu.forEach((element) => {
                if (item === element.tsmCode) {
                    compare.push(element)
                }
            })
        });
        let popIf: boolean = false;
        compare.forEach((item) => {
            compare.forEach((element) => {
                if (item.countryGroupOnboard !== element.countryGroupOnboard) {
                    popIf = true
                }
            });
        });
        if (popIf) {
            try {
                const confirmType = {
                    options: {},
                    supplier: this.supplier,
                    centered: true,
                    size: "lg",
                    isAction_selection: true,
                    actionCountryServed: compare,
                    windowClass: "modal-onboardingReq",
                    content:"action",
                };
                const modalReference = this.ngbModalService.open(BvPopUpOnboardingComponent, confirmType);
                modalReference.componentInstance.confirmType = confirmType;
                this.setButtonDisabledProp(false);
            } catch (error) {
                this.setButtonDisabledProp(false);
            }
        } else {
            this.routeCountries = this.routeCountries ? this.routeCountries : this.supplier.countryServed[0];
            this.handleSendOnboardingRequest(this.routeCountries);
        }

    }

    handleSendOnboardingRequest = async (countryServedList: string): Promise<void> => {
        this.setButtonDisabledProp(true);        
        this.routeCountries = countryServedList;
        this.countryServedMapping = this.getSupplierCountryCodeFromTsmCountryCode(this.routeCountries);
        const companyLegalName = this.supplier.companyLegalName;
        const createSupplierRequestData = {
            supplierTsmId: this.supplier.supplierTsmId,
            supplierName: this.supplier.supplierName,
            countryServed: this.countryServedMapping,
            countryLocation: this.supplier.countryLocation,
            companyWebsite: this.supplier.companyWebsite,
            dunsNumber: this.supplier.dunsNumber,
            createdBy: this.authService.getLoginUser().email,
            companyLegalName: companyLegalName ? companyLegalName : ""
        };
        try {
            const redirectUrl = await this.supplierHubService.getOnboardingRequestRedirectUrl(
                createSupplierRequestData
            );
            window.open(redirectUrl, "_blank");

            this.setButtonDisabledProp(false);
        } catch (error) {
            this.setButtonDisabledProp(false);
        }
        await this.fetchOnboardingStatus();
        await this.onboardingButtonStatus();
    };

    setButtonDisabledProp = (disabled: boolean): void => {
        this.onboarding = {
            ...this.onboarding,
            buttonProps: {
                ...this.onboarding.buttonProps,
                disabled: disabled
            }
        };
    };

    fetchOnboardingStatus = async (): Promise<void> => {
        if (this.countryServed === "") {
            return;
        }
        this.countryServedMapping = this.getOnlyOneSupplierCountryCode(this.countryServed);

        const supplierReqData = {
            source: "buyer",
            supplierTsmId: this.supplierTsmId,
            supplierHubMappedCountryServed: this.countryServedMapping
        };
        this.onboardingStatusUrl = `${environment.tsmBackendServiceURL}/supplier-hub/onboarding-status`;
        this.supplierHubOnboardingStatus = await this.httpService.PostPromise(
            this.onboardingStatusUrl,
            supplierReqData
        );
    };

    initialiseSupplier = async (): Promise<void> => {
        if (this.authService.getToken()) {
            let response = await this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId);
            if (!response) {
                this.error = true;
            } else {
                this.supplier = {
                    ...response,
                    companyLogo: "assets/icons/business-24px.svg",
                    countryServedDescription: []
                };
            }
        }
    };

    jumpPage() {
        const confirmType = {
            options: {},
            notifyType: 'send-sa',
            countryNotify: this.countryNotCreated,
            countryCreated: this.countryCreated,
            centered: true,
            size: "lg",
            windowClass: "modal-country-notify",
            status: this.popData.propStatus,
        };

        // pop up country notify
        const modalReference = this.ngbModalService.open(PopUpCountryServedNotifyComponent, confirmType);
        modalReference.componentInstance.confirmType = confirmType;
        let tsmRoot  =  <HTMLElement>document.getElementsByTagName("tsm-root")[0];
        if (tsmRoot) {
            tsmRoot.setAttribute("aria-hidden","false");
        }
    }

    redirectSa () {
        if(this.popData.propStatus != "A") {
            let unExistTaskCountries = this.popData.nonExistentTask.map(item => item.tsmCode).join(',');
            this.router.navigate(["/sustainability-assessment", this.supplierTsmId, unExistTaskCountries], {
                queryParams: {
                    supplierName: this.supplierName
                }
            });
        } else{
            this.router.navigate(['/supplier-profile', this.supplierTsmId], {
                queryParams: { countryServed: this.popData.existenceTask[0].tsmCode },
            });
        }
    }

    handleSupplierHubRedirect = (): void => {
        // key1 is supplierTsmId
        const key1 = this.supplier.supplierTsmId;
        // key2 is countryServed
        // country served must be in country code format, need to transfer to match supplier-hub side
        this.countryServedMapping = this.getOnlyOneSupplierCountryCode(this.countryServed);
        const key2 = this.countryServedMapping;

        const redirectUrl = this.supplierHubService.getProfilesCardPageRedirectUrl(key1, key2);
        window.open(redirectUrl, "_blank");
    };

    onboardingButtonStatus = async (): Promise<void> => {
        this.showCard = this.supplierHubOnboardingStatus?.lengthOnboardingDetails >= 1;
    };

    getSupplierCountryCodeFromTsmCountryCode = (tsmCountryCodeString: string): string => {
        let countryCodeList = tsmCountryCodeString.split(",");
        let tsmCountryCodeList = "";
        for (let i = 0; i < countryCodeList.length; i++) {
            if (i === 0) {
                tsmCountryCodeList = countryList['Data'].transferToSupplierHub[countryCodeList[i]];
            } else {
                tsmCountryCodeList = tsmCountryCodeList + "," + countryList['Data'].transferToSupplierHub[countryCodeList[i]];
            }
        }
        return tsmCountryCodeList;
    };

    getOnlyOneSupplierCountryCode = (tsmCountryCodeString: string): string => {
        let countryCodeList = tsmCountryCodeString.split(",");
        let tsmCountryCodeList = "";
        tsmCountryCodeList = countryCodeList.length > 0 ? countryList['Data'].transferToSupplierHub[countryCodeList[0]] : "";
        return tsmCountryCodeList;
    };

    private getAssessmentStatus() {
        const initStatus = "not yet initiated";
        if (this.routeParamCountry === "") {
            this.esgStatus = initStatus;
            return;
        }
        const tsmId = this.hasConfirmedParent ? this.supplier.parentTsmId : this.supplier.supplierTsmId;
        this.supplierService.getESGStatus(tsmId, this.routeParamCountry).then((data) => {
            this.esgAssessmentProps.taskId = data.taskId
            if (!data || Object.keys(data.assessment).length == 0) {
                data = "not yet initiated";
                this.esgStatus = data;
                // Check that the parent did not send the SA
                this.parentNotSa = false
            } else {
                this.esgStatus = data?.assessment;
            }
        });
    }

    getBuyerEmail = async (countryServed) => {
        const userInfo = this.authService.getLoginUser();
        let reqBody = {
            supplierTsmId: this.hasConfirmedParent ? this.supplier.parentTsmId : this.selectedSupplierTsmId,
            buyerTsmId: userInfo.tsmId,
            countryServed: countryServed
        };

        let response = await this.BuyerService.buyerEmailAddress(reqBody);
        if (response) {
            this.ModalService.emailAddress = response.userName;
        }
    };

    getBuyerEmailAddress = async (countryServed) => {
        this.esgAssessmentProps.isTerminateStatus = false;
        this.esgAssessmentProps.isReject = false;
        this.ModalService.buyerEmailAddress = "";
        const userInfo = this.authService.getLoginUser();
        let reqBody = {
            supplierTsmId: this.hasConfirmedParent ? this.supplier.parentTsmId : this.selectedSupplierTsmId,
            buyerTsmId: userInfo.tsmId,
            countryServed: countryServed
        };

        let response = await this.BuyerService.getBuyerEmailAddress(reqBody);
        if (response) {
            this.ModalService.buyerEmailAddress = response.userName;
        } else {
            this.ModalService.buyerEmailAddress = null;

        }
    };


    assessmentButtonStatus = async () => {
        if (this.countryServed === "") {
            return;
        }
        await this.getBuyerEmailAddress(this.countryServed);
        const userInfo = this.authService.getLoginUser();
        let supAndBuyID = {
            supplierTsmId: this.hasConfirmedParent ? this.supplier.parentTsmId : this.selectedSupplierTsmId,
            buyerTsmId: userInfo.tsmId,
            countryServed: this.countryServed
        };
        let respond = await this.BuyerService.getBuyerLockStatus(supAndBuyID);
        const reasonUrl = `${environment.susHubESGServiceURL}/invite-event/getTerminationByTaskId`

        const data = {
            taskId: this.esgAssessmentProps.taskId
        }
        let res: any = await this.httpService.PostPromise(reasonUrl, data);
        if (res && res.terminationRes1stData && respond && respond.length != 0) {
            if ((respond[0].type === "StartESGAssessment" || respond[0].type === "ContinueESGAssessment" || respond[0].type === "ContinueESGAssessmentForOnboarding" || respond[0].type === "StartESGAssessmentForOnboarding") && (this.esgStatus === "awaiting acceptance" || this.esgStatus === "awaiting submission") && res.terminationRes1stData[0].status === "Rejected") {
                this.esgAssessmentProps.isReject = true;
                this.esgAssessmentProps.reason = res.terminationRes1stData[0].rejectReason;
                this.esgAssessmentProps.isTerminateStatus = true;
            } else {
                this.esgAssessmentProps.isTerminateStatus = false;
            }
        }
        if (res.text && respond && respond.length != 0) {
            if ((respond[0].type === "StartESGAssessment" || respond[0].type === "ContinueESGAssessment" || respond[0].type === "ContinueESGAssessmentForOnboarding" || respond[0].type === "StartESGAssessmentForOnboarding") && (this.esgStatus === "awaiting acceptance" || this.esgStatus === "awaiting submission") && res.text === "No data") {
                this.esgAssessmentProps.isTerminateStatus = true;
            } else {
                this.esgAssessmentProps.isTerminateStatus = false;
            }
        }
        // /parent-child-relation/:childTsmId
        const parentChildUrl = `${environment.tsmBackendServiceURL}/parent-child-relation/${this.supplierTsmId}`
        let respondparent: any = await this.httpService.GetPromise(parentChildUrl);
        if (respondparent.status === "MutuallyConfirmed") {
            this.esgAssessmentProps.isTerminateStatus = false;
            this.esgAssessmentProps.explain = "Please go to parent to request the withdrawal after the relationship is mutually confirmed."
        } else {
            this.esgAssessmentProps.explain = "Select this, when onboarding is not needed."
        }
        if (respond && respond.length != 0) {
            this.esgAssessmentProps.requestdate = respond[0].displayCreationDate;

            if (
                (respond[0].status === "Pending" && respond[0].type === "StartESGAssessment") ||
                (respond[0].status === "In Progress" && respond[0].type === "ContinueESGAssessment") ||
                (respond[0].status === "In Progress" && respond[0].type === "ContinueESGAssessmentForOnboarding") ||
                (respond[0].status === "Pending" && respond[0].type === "StartESGAssessmentForOnboarding") ||
                (respond[0].status === "Resent" && respond[0].type === "StartESGAssessment")
            ) {
                this.esgAssessmentProps.isLockedStatus = true;
            } else {
                this.esgAssessmentProps.isLockedStatus = false;
            }

        } else {
            this.esgAssessmentProps.isLockedStatus = false;
        }
        await this.getBuyerEmail(this.countryServed);
    };

    setSuccessAlertProps = (): void => {
        this.showAlertsuccess = true
        this.refreshData()
        this.successAlert = {
            type: "alert-profileVerifiedSuccess",
            message: "Withdraw Success",
            icon: "assets/images/icon-tick.svg",
            iconFill: "#d5eed9",
            iconClose: "assets/images/icon-cross-green.svg",
            onDismiss: () => {
                this.showAlertsuccess = false;
            }
        };
        window.scrollTo(0, 0);
    };


    async fetchParentData(): Promise<void> {
        if (this.hasConfirmedParent) {
            let response = await this.supplierGeneralProfileService.getSupplierById(this.supplier.parentTsmId);
            this.parentSupplier = response;
        }
    }

    updatePropsBasedOnConfirmParent() {
        if (this.hasConfirmedParent) {
            this.esgAssessmentProps = {
                ...this.esgAssessmentProps,
                description: "",
                supplierTsmId: this.supplierTsmId,
                relationshipInfoText: this.translations.mutuallyConfirmParentSAInfo,
                displayRelationStatus: true,
                hasConfirmedParent: true
            }
        }
    }

    ngAfterContentChecked() {
        if (localStorage.getItem('multiLanguage')) {
            const multilanguageJson = JSON.parse(
                localStorage.getItem('multiLanguage')
            );
            if (multilanguageJson.body) {
                this.translations =
                {
                    ...multilanguageJson.body.main.bvSupplierProfile,
                    mutuallyConfirmParentSAInfo: multilanguageJson.body.main.optionsCard.mutuallyConfirmParentSAInfo
                }
            }
        }
    }

    redirectToAdminBoard = async () => {
        this.router.navigate(['/system-supplier-admin-board'], { queryParams: { supplierTsmId: this.selectedSupplierTsmId } });
    };
    private async refreshData() {
        if (this.nordicsState === true || this.hasConfirmedParent === true) {
            this.getAssessmentStatus();
            setTimeout(async () => {
                await this.assessmentButtonStatus();
            }, 100);
        }
        await this.fetchOnboardingStatus();
        await this.onboardingButtonStatus();
    }
    ngOnDestroy(): void {
        if (!!this.saveSubmission) {
            this.saveSubmission.unsubscribe();
        }
        if (!!this.saActionSubscription) {
            this.saActionSubscription.unsubscribe();
        }
    }
}
