import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { Popup } from "../../models/Popup";
import { ModalService } from "src/app/services/modal/modal.service";
import { AuthService } from "../../services/auth/auth.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tsm-pop-up-promisionsupplier',
  templateUrl: './pop-up-promisionsupplier.component.html',
  styleUrls: ['./pop-up-promisionsupplier.component.sass']
})
export class PopUpPromisionsupplierComponent implements OnInit {

  @Input() opened: boolean = true;
  modalData: Popup;
  buttonProps2: ButtonPropsType = {
    label: "Close",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 115,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "darkBlue",
    hoverColor: "white",
    onClick: () => this.logout()
  };

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: ModalService,
    public ngbModalService: NgbModal,
    private authService: AuthService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    let language = localStorage.getItem('current language')
    this.translate.use(language)
  }
  logout() {
    this.activeModal.close;
    this.authService.logout();
  }

}
