<div class="container container-fluid mb-5">
  <div class="main_bg">
    <div class="search-container mx-5">
      <div
        class="search-textbox d-flex rounded-pill bg-white ps-3 pe-1 py-1 search-error-textbox"
      >
        <input
          type="text"
          id="searchInput"
          class="userInput pb-1 flex-grow-1"
          size="30"
          placeholder="{{ translations.searchByEmail }}"
          [(ngModel)]="searchCriteria.userName"
          (keydown)="searchKeyDown($event)"
          (input)="blockNonPrintableChar()"
          maxlength="5000"
          aria-label="input-search"
          autofocus
          tsmAutofocus
        />
        <div class="sh-btn flex-row-reverse">
          <sh-button [props]="advSearchSearchButton"> </sh-button>
        </div>
      </div>
    </div>

    <div
      *ngIf="searchExecuted && tooManyUsersFound"
      class="mx-5 info-bar-too-many-users"
    >
      <img src="assets/icons/info-light-blue.svg" alt="icon-info" />
      <div style="padding-left: 12px">{{ translations.tooManyUsersInfo }}</div>
    </div>

    <div class="page-content mx-5">
      <tsm-system-admin-board-users
        [users]="users"
        [currentLoginEmail]="currentLoginEmail"
        [searchExecuted]="searchExecuted"
        [noUsersFound]="noUsersFound"
        [serverError]="serverError"
      ></tsm-system-admin-board-users>
    </div>
  </div>
</div>
