export type HandlerMap = Record<string, (ev: MessageEvent<EventRequest>) => any>;

class DeferredPromise<Result = any> {
  public resolve: (any: Result) => void;
  public reject: (err: Error) => void;
  private storedPromise: Promise<Result>;

  constructor() {
    this.storedPromise = new Promise<Result>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public get promise(): Promise<Result> {
    return this.storedPromise;
  }
}

interface EventBase<Payload = unknown> {
  type: string;
  payload?: Payload;
}

// TODO: Replace with GUID
type MessageId = number;
export interface EventRequest<Payload = any> extends EventBase<Payload> {
  id: MessageId;
}

type EventStatus = 'SUCCESS' | 'FAILURE' | 'ERROR';

export interface EventResponse<Payload = any> extends EventBase<Payload> {
  id: MessageId;
  status: EventStatus;
}

interface EventErrorResponse extends EventBase<any> {}

/**
 * Base class for any (web) worker that we want to use in the future.
 * Web workers are used to perform CPU intensive work without blocking the JS event-loop (the UI thread).
 * That means a calculation/code execution can run in the background without disturbing the user expecerience.
 * 
 * This class should only be inherited by actual webworkers!
 * 
 * Incoming messages are mapped by their type and passed to the correct handler (abstract handlerMap) 
 * 
 * Important: This is NOT a worker. We can safely import this file anywhere in the application.
 */
export abstract class WorkerBase {
  private worker: typeof self; // self = window

  protected abstract handlerMap: HandlerMap;

  constructor() {
    this.handleAsync = this.handleAsync.bind(this);
    addEventListener('message', this.handleAsync);

    this.worker = self;
  }

  async handleAsync(event: MessageEvent<EventRequest>) {
    const { data } = event;
    const { type } = data;

    if (!type) {
      this.sendError(event, { error: 'No handler found' });
      return
    }

    const handler = this.handlerMap[type];
    try {
      const result = await handler(event);
      this.sendSuccess(event, result);
    } catch (error) {
      console.error(
        `An error occured while handling message of type ${type}. ${JSON.stringify(
          error
        )}`
      );
      this.sendError(event, error);
    }
  }

  public sendSuccess({ data }: MessageEvent<EventRequest>, result: any) {
    const response: EventResponse = {
      ...data,
      payload: result,
      status: 'SUCCESS',
    };

    this.postMessageWrapper(response);
  }

  // Later on we can use a safeguard to set the status to ERROR
  public sendError({ data }: MessageEvent<EventRequest>, result: any) {
    const response: EventResponse | EventErrorResponse = {
      ...data,
      payload: result,
      status: 'FAILURE',
    };

    this.postMessageWrapper(response);
  }

  private postMessageWrapper(event: EventResponse | EventErrorResponse) {
    this.worker.postMessage(event);
  }
}

/**
 * WorkerWrapper wraps a worker for any UI service.
 * Its goal is to provide a unified interface to communicate with a web worker on an async/await pattern.
 * As long as the message type is known you can send commands to the web-worker (sendAsync) and await it.
 * 
 * Note: It is also possible to retrieve more than one message back, for example to show a progress bar. 
 */
export class WorkerWrapper {
  private runningPromises: Record<MessageId, DeferredPromise> = {};

  private webWorker: Worker;

  constructor(webWorker: Worker) {
    this.webWorker = webWorker;

    this.handleResponse = this.handleResponse.bind(this);
    this.webWorker.onmessage = this.handleResponse;
  }

  private handleResponse({ data }: MessageEvent<EventResponse>) {
    const { id, status, payload } = data;

    const promise = this.runningPromises[id];

    delete this.runningPromises[id];

    if (status === 'SUCCESS') {
      promise.resolve(payload);
    } else {
      promise.reject(payload);
    }
  }

  public sendAsync<Result = any>(message: EventBase): Promise<Result> {
    const deferredPromise = new DeferredPromise<Result>();
    const messageId = this.getMessageId();

    this.runningPromises[messageId] = deferredPromise;

    const eventRequest: EventRequest = {
      ...message,
      id: messageId,
    };

    this.postMessageWrapper(eventRequest);

    return deferredPromise.promise;
  }

  private postMessageWrapper(event: EventRequest | EventErrorResponse) {
    this.webWorker.postMessage(event);
  }

  // TODO: Replace with GUID
  private count: MessageId = 0;
  private getMessageId() {
    return this.count++;
  }
}
