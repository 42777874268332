export const popExitAddSupplierData = {
    title: "Confirm User Creation",
    iconUrl: "assets/icons/icon-conectivity.svg",
    content: `Would you really want to exit the new supplier creation process without any saved?`,
    headerCloseButton: true
};

export const popExitAddSupplierOptions = {
    scrollable: true,
    centered: true,
    size: "lg",
    animation: true,
    windowClass: "modal-container",
    backdropClass: "modal-custom-backdrop"
};