export const Context = {
    ORGANISATION_USERS: "organisation-users"
};

export const Validators = {
    EMAIL_ALREADY_PRESENT: "emailAlreadyPresent",
    IDENTICAL_DUNS_NUMBER: "identicalDunsNumber",
    REQUIRED_TAX_ID:"requiredTaxId",
    REQUIRED_TAX_TYPE:"requiredTaxIdType"
};
