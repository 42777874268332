import { Component } from '@angular/core';
import { read, utils, WorkBook, WorkSheet } from 'xlsx';
import { HelpAndSupportService } from 'src/app/services/helpAndSupport/help-and-support.service';
import { v4 as uuid } from 'uuid';
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';
import { FileUploader } from 'file-upload-bfp';
import {
  AlertPropsType,
  ButtonPropsType,
} from '@lsl16/sustainability-shared-components';
import {
  FAQRecord,
  updateHelpPageContent,
  updateRecords,
} from './faq-help-support-config';

import TaxIdLabels from './faq-help-instructions.json';

@Component({
  selector: 'tsm-help-and-support-dynamic',
  templateUrl: './help-and-support-dynamic.component.html',
  styleUrls: ['./help-and-support-dynamic.component.sass'],
})
export class HelpAndSupportDynamicComponent {
  FAQData: any;
  uploadFaqData: FAQRecord[];
  updateHelpData: updateHelpPageContent[];
  updateRecords: any;
  reportRecords: any;
  fileToUpload: File | null = null;

  // Unused, but necessary for ng2FileDrop
  uploader!: FileUploader;
  contentText;
  showAlert: boolean;
  alertProps: AlertPropsType;
  alertProps_fail: AlertPropsType;
  showAlert_fail = false;

  sharedButtonProps: ButtonPropsType = {
    label: '',
    fontSize: 14,
    height: 40,
    padding: '10px 0px 10px 0px',
    borderColor: 'black-12',
    fontFamily: 'Graphik',
  };
  downloadTemplateAssButton: ButtonPropsType = {
    ...this.sharedButtonProps,
    width: 200,
    color: 'white',
    backgroundColor: 'blue',
    label: 'Download Template',
    onClick: () => {
      this.downloadTemplate();
    },
  };
  extractFAQButton: ButtonPropsType = {
    ...this.sharedButtonProps,
    width: 200,
    color: 'white',
    backgroundColor: 'blue',
    label: 'Extract FAQ & Help',
    onClick: () => {
      this.handleExcelReport();
    },
  };

  FAQSGroupCounts = {
    buyerFaqForEnglish: 0,
    supplierFaqForEnglish: 0,
    suplierFaqForBahasaIndonesia: 0,
    suplierFaqForGerman: 0,
    suplierFaqForSpanish: 0,
    suplierFaqForFrench: 0,
    suplierFaqForItaliano: 0,
    suplierFaqForPortuguese: 0,
    suplierFaqForThai: 0,
    suplierFaqForChinese: 0,
    suplierFaqForJapanese: 0,
    suplierFaqForBrazil: 0,
    suplierFaqForLatamSpanish: 0
  };

  constructor(private helpAndSupportService: HelpAndSupportService) {}

  async ngOnInit() {
    this.contentText = TaxIdLabels.instructions;
    const FAQSGroupCount = (this.updateRecords = await this.handleGetDBData());
    await this.getCountOfFaqsByType(FAQSGroupCount);
    this.initializeUploader();
  }

  async handleGetDBData(): Promise<updateRecords> {
    const updateRecords = await this.helpAndSupportService.getFAQData();
    return updateRecords.data;
  }

  private initializeUploader() {
    this.uploader = new FileUploader({
      allowedMimeType: ['xlsx', 'xls'],
      allowedFileType: ['xlsx', 'xls'],
      autoUpload: false,
    });
  }

  setAlertErrorMessage = (message): void => {
    this.showAlert_fail = true;
    this.alertProps_fail = {
      type: 'alert-requestVerifiedFaild',
      message: message,
      icon: 'assets/images/icon-error.svg',
      iconFill: '#cc1e32',
      iconClose: 'assets/images/icon-cross-blue.svg',
      onDismiss: this.onAlertDismiss_1,
    };
    window.scrollTo(0, 0);
  };

  setAlertSuccessMessage = (message): void => {
    this.showAlert = true;
    this.alertProps = {
      type: 'alert-requestVerifiedSuccess',
      message: message,
      icon: 'assets/images/icon-tick.svg',
      iconFill: '#376803',
      iconClose: 'assets/images/icon-cross-green.svg',
      onDismiss: this.onAlertDismiss,
    };
    window.scrollTo(0, 0);
  };

  onAlertDismiss = (): void => {
    this.showAlert = false;
  };

  onAlertDismiss_1 = (): void => {
    this.showAlert_fail = false;
  };

  async handleExcelReport() {
    this.reportRecords = await this.handleGetDBData();
    const workbook = XLSX.utils.book_new();
    if (
      this.reportRecords?.FAQRecord.length ||
      this.reportRecords?.updateHelpPageContent.length
    ) {
      // Create the FAQ tab
      this.reportRecords.FAQRecord = this.setFaqHeader();
      this.reportRecords.FAQRecord = this.sortSelectResource(
        this.reportRecords.FAQRecord
      );
      const faqSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        this.reportRecords.FAQRecord
      );
      var range = XLSX.utils.decode_range(faqSheet['!ref']);
      var wscolsForFaq = [];
      var wscolsForHelp = [];

      for (var C = range.s.r; C <= range.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
        if (!faqSheet[address]) continue;
        faqSheet[address]['s'] = {
          fill: { fgColor: { rgb: 'FFFF00' } },
          font: { bold: true },
        };
        wscolsForFaq.push({ wch: 15 });
      }
      faqSheet['!cols'] = wscolsForFaq;

      XLSX.utils.book_append_sheet(workbook, faqSheet, 'FAQ');

      this.reportRecords.updateHelpPageContent = this.setHelpContentHeader();

      // Create the Help Page Content tab
      const helpPageContentSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        this.reportRecords.updateHelpPageContent
      );
      // set bgcolor of header
      var range1 = XLSX.utils.decode_range(helpPageContentSheet['!ref']);
      for (var C = range1.s.r; C <= range1.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
        if (!helpPageContentSheet[address]) continue;
        helpPageContentSheet[address]['s'] = {
          fill: { fgColor: { rgb: 'FFFF00' } },
          font: { bold: true },
        };
        wscolsForHelp.push({ wch: 15 });
      }
      helpPageContentSheet['!cols'] = wscolsForHelp;
      XLSX.utils.book_append_sheet(
        workbook,
        helpPageContentSheet,
        'Help Page Content'
      );

      // Generate the Excel file
      const excelBuffer: any = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const excelData: Blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      const excelFile: File = new File(
        [excelData],
        'FAQ&Help Extract Report.xlsx',
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        }
      );

      // Save the file
      saveAs(excelFile);
    } else {
      alert('No data available in DB to generate report');
    }
  }

  setHelpContentHeader(): any {
    return this.reportRecords.updateHelpPageContent.map(
      (row: { [key: string]: string }, index) => {
        return {
          'Element ID': row['elementId'],
          Language: row['language'],
          'Main Header': row['mainHeader'],
          'Main Content': row['mainContent'],
          'FAQ header': row['faqHeader'],
          'Quick Tutorial Header': row['quickTutorialHeader'],
          'Additional Support Header': row['additionalSupportHeader'],
          'Additional Support Content': row['additionalSupportContent'],
          'Quick Link Header': row['quickLinkHeader'],
          'Last modified by': row['lastModifiedBy'],
          'Last modified on': row['lastModifiedOn'],
        };
      }
    );
  }
  setFaqHeader(): any {
    return this.reportRecords.FAQRecord.map(
      (row: { [key: string]: string }, index) => {
        return {
          'FAQ ID': row['faqId'],
          Index: row['index'],
          'Buyer/Supplier FAQ question?': row['buyerSupplierFaqQuestion'],
          Language: row['language'],
          Category: row['category'],
          Question: row['question'],
          Answer: row['answer'],
          'Last modified by': row['lastModifiedBy'],
          'Last modified on': row['lastModifiedOn'],
        };
      }
    );
  }

  handleFileInput(files: FileList): void {
    const file = files.item(0);

    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook: WorkBook = read(data, { type: 'array' });

        // Assuming the first tab is named 'Upload FAQ'
        const uploadFaqSheet: WorkSheet = workbook.Sheets['Update FAQ'];

        // Assuming the second tab is named 'Update FAQ'
        const updateHelpSheet: WorkSheet =
          workbook.Sheets['Update Help Page Content'];
        if (
          this.checkDuplicateInExcelOnly(uploadFaqSheet) ||
          this.checkDuplicateIndex(uploadFaqSheet)
        ) {
          this.setAlertErrorMessage(
            'Duplicate record found in the template, please remove and reupload.'
          );
          return;
        }
        const uploadFaqData: FAQRecord[] =
          this.extractFAQRecords(uploadFaqSheet);
        const updateHelpData: updateHelpPageContent[] =
          this.extractHelpRecords(updateHelpSheet);
        this.uploadFaqData = uploadFaqData;
        this.updateHelpData = updateHelpData;

        // Perform your validation checks
        if (updateHelpData.length === 0 && uploadFaqData.length === 0) {
          this.setAlertErrorMessage('Blank Template. Please fill in details.');
          return;
        }
        this.validateUploadFaq(uploadFaqData);
        this.validateHelpPageContent(updateHelpData);

        if (
          !this.showAlert_fail &&
          (this.uploadFaqData.length || this.updateHelpData.length)
        ) {
          this.bulkUpload();
        }
      };

      fileReader.readAsArrayBuffer(file);
    }
  }

  extractFAQRecords(sheet: WorkSheet): FAQRecord[] {
    const jsonDataFAQ = utils.sheet_to_json(sheet);
    const currTime = new Date().toJSON();
    const username = JSON.parse(localStorage.getItem('userInfo'));

    return jsonDataFAQ.map((row: { [key: string]: string }, index) => {
      let IndexSettup = 0;
      if (isNaN(Number(row['Index'])) || Number(row['Index']) < 0) {
        IndexSettup =
          index +
          this.getCountBylanguage(
            row['Language'],
            row['Buyer/Supplier FAQ question?']
          ) +
          1;
      } else {
        IndexSettup = Number(row['Index']);
      }
      return {
        faqId: uuid(),
        category: row['Category'],
        question: row['Question'],
        answer: row['Answer'],
        language: row['Language'],
        addUpdateDelete: row['Add/Update/Delete FAQ'],
        previousCategory: row['Previous Category'],
        previousQuestion: row['Previous Question'],
        previousAnswer: row['Previous Answer'],
        buyerSupplierFaqQuestion: row['Buyer/Supplier FAQ question?'],
        addUpdateFlag:
          row['Add/Update/Delete FAQ'] === 'Add question' ? true : false,
        lastModifiedBy: username.pocEmail,
        lastModifiedOn: currTime,
        index: IndexSettup,
      };
    });
  }

  extractHelpRecords(sheet: WorkSheet): updateHelpPageContent[] {
    const jsonDataHelp = utils.sheet_to_json(sheet);
    const currTime = new Date().toJSON();
    const username = JSON.parse(localStorage.getItem('userInfo'));
    return jsonDataHelp.map((row: { [key: string]: string }, index) => {
      return {
        elementId: uuid(),
        language: row['Language'],
        mainHeader: row['Main Header'],
        faqHeader: row['FAQ header'],
        mainContent: row['Main Content'],
        quickTutorialHeader: row['Quick Tutorial Header'],
        additionalSupportHeader: row['Additional Support Header'],
        additionalSupportContent: row['Additional Support Content'],
        quickLinkHeader: row['Quick Link Header'],
        lastModifiedBy: username.pocEmail,
        lastModifiedOn: currTime,
      };
    });
  }

  validateUploadFaq(data: FAQRecord[]): void {
    // Validate the uploaded FAQ data based on the conditions mentioned
    for (const record of data) {
      if (
        record.buyerSupplierFaqQuestion === 'Buyer FAQ' &&
        !record.category &&
        record.addUpdateDelete !== 'Delete question'
      ) {
        this.setAlertErrorMessage(
          'Some fields are missing, please update and re-upload.'
        );
        return;
      }
      // Condition 1: Check if the template is blank
      if (
        !record.question &&
        !record.answer &&
        !record.language &&
        !record.addUpdateDelete
      ) {
        this.setAlertErrorMessage(
          'Some fields are missing, please update and re-upload.'
        );
        return;
      }

      // Condition 2: Check if at least one column is updated
      if (
        record.question ||
        record.answer ||
        record.language ||
        record.addUpdateDelete
      ) {
        // Perform the validation checks for the specific conditions

        // Condition 5: Check for required fields if Add/Update/Delete FAQ is selected
        if (
          record.addUpdateDelete === 'Add question' ||
          record.addUpdateDelete === 'Update question'
        ) {
          if (!record.question || !record.answer || !record.language) {
            // Throw an error
            this.setAlertErrorMessage(
              'Some fields are missing, please update and re-upload.'
            );
            return;
          }
          if (
            record.addUpdateDelete === 'Update question' &&
            (!record.previousQuestion ||
              !record.previousAnswer ||
              !record.language)
          ) {
            this.setAlertErrorMessage(
              'Some fields are missing, please update and re-upload.'
            );
            return;
          }
        } else {
          if (
            !record.previousQuestion ||
            !record.previousAnswer ||
            !record.language
          ) {
            this.setAlertErrorMessage(
              'Some fields are missing, please update and re-upload.'
            );
            return;
          }
        }

        // Condition 6: Check if English language is used for Buyer FAQ
        if (
          record.language !== 'English' &&
          record.buyerSupplierFaqQuestion === 'Buyer FAQ'
        ) {
          // Throw an error
          this.setAlertErrorMessage(
            'For Buyer FAQ only english language is supported.'
          );
          return;
        }

        // Condition 8: Check for duplicate questions
        // Perform the necessary check against the database -  call API

        // Condition 9: Check if at least Language value is updated
        if (!record.language) {
          // Throw an error
          this.setAlertErrorMessage(
            'Some fields are missing, please update and re-upload.'
          );
          return;
        }
      }
    }

    this.checkDuplicateFAQ();
  }

  validateHelpPageContent(data: updateHelpPageContent[]): void {
    // Validate the update FAQ data based on the conditions mentioned
    for (const record of data) {
      // Condition 4: Check if at least one column is updated
      if (!record.language) {
        // Throw an error
        this.setAlertErrorMessage(
          'Some fields are missing, please update and re-upload.'
        );
        return;
      }
    }

    this.updatehelpPageContent();
  }

  checkDuplicateInExcelOnly(sheet): boolean {
    const jsonDataFAQ = utils.sheet_to_json(sheet);
    function hasDuplicate(arrayOne) {
      const newArray = arrayOne.filter(
        (thing, index, self) =>
          index ===
          self.findIndex((t) => JSON.stringify(t) === JSON.stringify(thing))
      );
      return newArray.length < arrayOne.length;
    }
    return hasDuplicate(jsonDataFAQ);
  }

  checkDuplicateIndex(sheet): boolean {
    const jsonDataFAQ = utils.sheet_to_json(sheet);
    function hasDuplicate(arrayOne) {
      const newArray = arrayOne.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.Language === thing.Language &&
              t.Index === thing.Index &&
              t['Buyer/Supplier FAQ question?'] ===
                thing['Buyer/Supplier FAQ question?']
          )
      );
      return newArray.length < arrayOne.length;
    }
    return hasDuplicate(jsonDataFAQ);
  }

  checkDublicateInDBRocordsForFAQ(uploadFaqData, dbFAQData): boolean {
    if (
      uploadFaqData &&
      uploadFaqData.buyerSupplierFaqQuestion === 'Buyer FAQ' &&
      uploadFaqData.addUpdateDelete === 'Add question' &&
      uploadFaqData.category === dbFAQData.category &&
      uploadFaqData.question === dbFAQData.question &&
      uploadFaqData.answer === dbFAQData.answer
    ) {
      return true;
    }
    if (
      uploadFaqData &&
      uploadFaqData.buyerSupplierFaqQuestion === 'Supplier FAQ' &&
      uploadFaqData.addUpdateDelete === 'Add question' &&
      uploadFaqData.question === dbFAQData.question &&
      uploadFaqData.answer === dbFAQData.answer
    ) {
      return true;
    }
  }

  checkForUpdateDeleteRecord(uploadFaqData, dbFAQData): boolean {
    if (
      uploadFaqData &&
      uploadFaqData.buyerSupplierFaqQuestion === 'Buyer FAQ' &&
      uploadFaqData.previousCategory === dbFAQData.category &&
      uploadFaqData.previousQuestion === dbFAQData.question &&
      uploadFaqData.previousAnswer === dbFAQData.answer &&
      (uploadFaqData.addUpdateDelete === 'Update question' ||
        uploadFaqData.addUpdateDelete === 'Delete question')
    ) {
      return true;
    }
    if (
      uploadFaqData &&
      uploadFaqData.buyerSupplierFaqQuestion === 'Supplier FAQ' &&
      uploadFaqData.previousQuestion === dbFAQData.question &&
      uploadFaqData.previousAnswer === dbFAQData.answer &&
      (uploadFaqData.addUpdateDelete === 'Update question' ||
        uploadFaqData.addUpdateDelete === 'Delete question')
    ) {
      return true;
    }
  }

  checkDuplicateFAQ(): void {
    if (this.updateRecords && this.updateRecords.FAQRecord.length) {
      for (var i = this.uploadFaqData.length - 1; i >= 0; i--) {
        for (var j = 0; j < this.updateRecords.FAQRecord.length; j++) {
          // check for add records
          if (
            this.checkDublicateInDBRocordsForFAQ(
              this.uploadFaqData[i],
              this.updateRecords.FAQRecord[j]
            )
          ) {
            this.setAlertErrorMessage(
              'Data already exists in database, reupload is not allowed.'
            );
            this.uploadFaqData[i].addUpdateFlag = false;
          }
          if (
            this.checkForUpdateDeleteRecord(
              this.uploadFaqData[i],
              this.updateRecords.FAQRecord[j]
            )
          ) {
            this.uploadFaqData[i].faqId = this.updateRecords.FAQRecord[j].faqId;
            this.uploadFaqData[i].addUpdateFlag = true;
            break;
          }
        }
      }
    }
    this.uploadFaqData = this.uploadFaqData.filter((record) => {
      if (record.addUpdateFlag) {
        delete record.addUpdateFlag;
        return record;
      }
    });
  }

  updatehelpPageContent(): void {
    if (this.updateRecords && this.updateRecords.updateHelpPageContent.length) {
      for (var i = this.updateHelpData.length - 1; i >= 0; i--) {
        for (
          var j = 0;
          j < this.updateRecords.updateHelpPageContent.length;
          j++
        ) {
          if (
            this.updateHelpData[i] &&
            this.updateHelpData[i].language ===
              this.updateRecords.updateHelpPageContent[j].language
          ) {
            this.updateHelpData[i].elementId =
              this.updateRecords.updateHelpPageContent[j].elementId;
          }
        }
      }
    }
  }

  async bulkUpload(): Promise<void> {
    if (this.uploadFaqData.length === 0 && this.updateHelpData.length === 0) {
      this.setAlertErrorMessage('Blank Template. Please fill in details.');
      return;
    }
    const bulkUpload = {
      FAQRecord: this.uploadFaqData,
      updateHelpPageContent: this.updateHelpData,
    };
    const data = await this.helpAndSupportService.addData(bulkUpload);
    if (data.status === 200) {
      this.setAlertSuccessMessage('Template is successfully uploaded.');
      this.updateRecords = await this.handleGetDBData();
    }
  }

  downloadTemplate(): void {
    let link = document.createElement('a');
    link.download = 'Buyer Supplier FAQ Bulk upload Template';
    link.href = '/assets/download/Buyer Supplier FAQ Bulk upload Template.xlsx';
    link.click();
  }

  async getCountOfFaqsByType(FAQSGroupCount): Promise<void> {
    const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
      arr?.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
      }, {} as Record<K, T[]>);

    const results = groupBy(
      FAQSGroupCount.FAQRecord,
      (i) => i['buyerSupplierFaqQuestion']
    );
    const SupplierFaqGroup = groupBy(
      results['Supplier FAQ'],
      (i) => i['language']
    );
    this.FAQSGroupCounts.buyerFaqForEnglish = results['Buyer FAQ']?.length || 0;
    if (SupplierFaqGroup !== undefined) {
      this.FAQSGroupCounts.supplierFaqForEnglish =
        SupplierFaqGroup['English']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForBahasaIndonesia =
        SupplierFaqGroup['Bahasa Indonesia']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForGerman =
        SupplierFaqGroup['German']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForSpanish =
        SupplierFaqGroup['Spainish']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForFrench =
        SupplierFaqGroup['French']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForItaliano =
        SupplierFaqGroup['Italiano']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForPortuguese =
        SupplierFaqGroup['Portuguese']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForThai =
        SupplierFaqGroup['Thai']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForChinese =
        SupplierFaqGroup['Chinese']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForJapanese =
        SupplierFaqGroup['Japanese']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForBrazil =
        SupplierFaqGroup['Brazil']?.length || 0;
      this.FAQSGroupCounts.suplierFaqForLatamSpanish = 
        SupplierFaqGroup['LatamSpanish']?.length || 0;
    }
  }

  getCountBylanguage(language, type): number {
    if (type === 'Buyer FAQ') return this.FAQSGroupCounts.buyerFaqForEnglish;
    switch (language) {
      case 'English':
        return this.FAQSGroupCounts.supplierFaqForEnglish;
      case 'Bahasa Indonesia':
        return this.FAQSGroupCounts.suplierFaqForBahasaIndonesia;
      case 'German':
        return this.FAQSGroupCounts.suplierFaqForGerman;
      case 'Spanish':
        return this.FAQSGroupCounts.suplierFaqForSpanish;
      case 'French':
        return this.FAQSGroupCounts.suplierFaqForFrench;
      case 'Italian':
        return this.FAQSGroupCounts.suplierFaqForItaliano;
      case 'Portuguese':
        return this.FAQSGroupCounts.suplierFaqForPortuguese;
      case 'Thai':
        return this.FAQSGroupCounts.suplierFaqForThai;
      case 'Chinese':
        return this.FAQSGroupCounts.suplierFaqForChinese;
      case 'Japanese':
        return this.FAQSGroupCounts.suplierFaqForJapanese;
      case 'Brazil':
          return this.FAQSGroupCounts.suplierFaqForBrazil;
      case 'LatamSpanish':
          return this.FAQSGroupCounts.suplierFaqForLatamSpanish;
      default:
        return 0;
    }
  }

  sortSelectResource = (resource: any): any => {
    return resource?.sort((a: any, b: any) => {
      if (a.Index === null || a.Index === undefined) return 1;
      if (b.Index === null || b.Index === undefined) return -1;
      if (a.Index < b.Index) return -1;
      if (a.Index > b.Index) return 1;
      return 0;
    });
  };
}
