<div class="modal-header">
  <h4 class="modal-title">
    {{ modalTranslation.title }}
  </h4>
</div>
<sh-alert *ngIf="isAlertVisible" [props]="verifyAlertProps"></sh-alert>
<div class="modal-body">
  <div
    class="information-container confirmparent-info-box"
    *ngIf="isParentFound; else elseBlock"
  >
    <div class="information-title-container">
      <img class="info-title-icon" src="assets/icons/icon-tick.svg" alt="icon-tick" />
      <div class="information-title confirmparent-info-title">
        {{ modalTranslation.confirmParentInfoTitle }}
      </div>
    </div>
    <div class="information-message">
      {{ modalTranslation.confirmParentInfoMessage }}
    </div>
  </div>
  <ng-template #elseBlock>
    <div class="information-container noparent-info-box">
      <div class="information-title-container">
        <img
          class="information-title-icon"
          src="assets/icons/info-light-blue.svg"
          alt="icon-info"
        />
        <div class="information-title">{{ modalTranslation.infoTitle }}</div>
      </div>
      <div class="information-message" id="noParentInfoMsg">{{ modalTranslation.infoMessage }}</div>
    </div>
  </ng-template>

  <!-- Error message -->
    <div class="information-container confirm-noparent-info-box" *ngIf="displayErrorMessage">
      <div class="information-title-container">
        <img class="info-title-icon" src="assets/icons/info-yellow.svg" alt="icon-error" />
        <div class="information-title confirm-noparent-info-title">
          {{ modalTranslation.errorTitle }}
        </div>
      </div>
      <div class="information-message" id="noPocMessage" [innerHTML] = "modalTranslation.errorMessage">
      </div>
    </div>
    <div class="information-container confirm-noparent-info-box" *ngIf="displayErrorSubSupplierMessage" id="errorSubSupplier">
      <div class="information-only-title-container">
        <img class="info-title-icon" src="assets/icons/info-yellow.svg" alt="icon-error" />
        <div class="information-title confirm-noparent-info-title">
          {{ modalTranslation.errorSubSupplierTitle }}
        </div>
      </div>
    </div>
    <div class="information-container confirm-noparent-info-box" *ngIf="displayErrorSuperSupplierMessage" id="errorSuperSupplier">
      <div class="information-only-title-container">
        <img class="info-title-icon" src="assets/icons/info-yellow.svg" alt="icon-error" />
        <div class="information-title confirm-noparent-info-title">
          {{ modalTranslation.errorSuperSupplierTitle }}
        </div>
      </div>
    </div>

  <div class="modal-body-content">
    <div class="radio-button-container" *ngIf="isParentFound; else noParentFound">
      <div>
        <input
          class="radio-input"
          type="radio"
          id="confirmParent"
          value="confirmParent"
          [(ngModel)]="parentSelection"
          (change)="checkEmptyPOCAndDisplayErrorMessage()"
        />
        <label class="input-label" for="confirmParent">{{
          modalTranslation.confirmParentLabel
        }}</label>
      </div>
      <div>
        <input class="radio-input"
         type="radio" 
         id="specifyDifferentParent" 
         value="specifyDifferentParent"
         [(ngModel)]="parentSelection"
        (change)="checkEmptyPOCAndDisplayErrorMessage()" />
        <label class="input-label" for="specifyDifferentParent">{{ 
          modalTranslation.specifyDifferentParentLabel
        }}</label>
      </div>
      <div *ngIf="!confirmType.hideNoParent">
        <input
          class="radio-input"
          type="radio"
          id="noParent"
          value="noParent"
          [(ngModel)]="parentSelection"
          (change)="checkEmptyPOCAndDisplayErrorMessage()"
        />
        <label class="input-label" for="noParent">{{
          modalTranslation.noParentLabel
        }}</label>
      </div>
    </div>

    <ng-template #noParentFound>
    <div class="radio-button-container" *ngIf="!confirmType.hideNoParent">
      <div>
        <input
          class="radio-input"
          type="radio"
          id="specifyParent"
          value="specifyParent"
          (change)="checkEmptyPOCAndDisplayErrorMessage()"
          [(ngModel)]="parentSelection"
        />
        <label class="input-label" for="specifyParent">{{
          modalTranslation.specifyParentLabel
        }}</label>
      </div>
      <div>
        <input
          class="radio-input"
          type="radio"
          id="noParent"
          value="noParent"
          (change)="checkEmptyPOCAndDisplayErrorMessage()"
          [(ngModel)]="parentSelection"
        />
        <label class="input-label" for="noParent">{{
          modalTranslation.noParentLabel
        }}</label>
      </div>     
    </div>
    </ng-template>

    <div class="parent-form" *ngIf="parentSelection !== 'noParent'">
      <!-- specify Parent -->
      <div *ngIf="isSpecifyOrSpecifyDifferentParent()">
        <tsm-specify-parent
          [supplier]="supplier"
          (onParentDunsNumberChanged)="onParentDunsNumberChanged($event)"
          [parentDunsNumber]="parentDunsNumber"
          (onSearchParent)="onSearchParent($event)"
        ></tsm-specify-parent>
        <div *ngIf="noRecordFound">
          <div class="row norecord-found" id="noRecordMessage" >
            {{ modalTranslation.noRecordFound }}          
          </div>
          <div class="row inviteParent">
            <sh-button [props]="inviteNewParentButtonProps"></sh-button>
          </div>
        </div>       
      </div>

    <!-- Confirm parent -->
    <div *ngIf="isConfirmParent()">
      <tsm-confirm-parent
        [confirmSupplier]="confirmSupplier"
      ></tsm-confirm-parent>
    </div>
   </div>
  </div>
  <!-- No parent -->
  <div class="information-container confirm-noparent-info-box" *ngIf="parentSelection === 'noParent'">
    <div class="information-title-container">
      <img class="info-title-icon" src="assets/icons/info-yellow.svg" alt="icon-no-parent" />
      <div class="information-title confirm-noparent-info-title">
        {{ modalTranslation.confirmNoParentInfoTitle }}
      </div>
    </div>
    <div class="information-message" id="noParentSelectionInfoMsg">
      {{ modalTranslation.confirmNoParentInfoMessage }}
    </div>
  </div>
</div>
<div class="modal-buttons">
  <sh-button [props]="cancelButtonProps"></sh-button>
  <sh-button ngbAutofocus [props]="currentConfirmButtonProps"></sh-button>
</div>
