import { Component, OnInit, ViewChild } from '@angular/core';
import {
    ButtonPropsType,
    AssessmentReportItem,
    ComponentConfig,
    DynamicContext,
    DynamicFormComponent
} from "@lsl16/sustainability-shared-components";
import { FilterData, FilterValue, FilterType } from '../../services/esgReport/esg-report.service';
import { MetadataService } from "../../services/metadata/metadata.service";
import { ReportService } from "../../services/authority/report.service";
import { AlertPropsType } from '@lsl16/sustainability-shared-components';
import { SubheaderPropsType } from 'src/app/models/SubheaderProps';
import { Subject } from 'rxjs';
import { ReportSearchType } from 'src/app/models/Search';
import { formatDate } from '@angular/common';

type fromToDateFilter = {
    from: FilterType;
    to: FilterType;
  }

@Component({
    selector: 'tsm-sustainability-process-report',
    templateUrl: './sustainability-process-report.component.html',
    styleUrls: ['./sustainability-process-report.component.sass']
})
export class SustainabilityAssessmentDownloadComponent implements OnInit {

    @ViewChild("form", { static: false }) form: DynamicFormComponent;
    invalidValue: Subject<string> = new Subject();
    downloadFilterButton: ButtonPropsType;
    reportProps: SubheaderPropsType;
    filterCriteria: ReportSearchType;
    filterCriteriaPayload: ReportSearchType;
    successAlert: AlertPropsType;
    failureAlert: AlertPropsType;
    successAlertVisible: boolean;
    failureAlertVisible: boolean;
    time: any;
    invalidFields = [];
    private dateTypes: fromToDateFilter[] = [{ from: 'esgSubmissionDateFrom', to: 'esgSubmissionDateTo' }]
    constructor(private reportService: ReportService) { }

    async ngOnInit() {
        this.reportProps = {
            isWelcomeMessage: false,
            heading: "Process Reports"
        }
        this.setSuccessAlertProps();
        this.setFailureAlertProps();
        this.initButtons();
        this.initFilterCriteria();
    }
    initFilterCriteria() {
        this.filterCriteria = {
            countryServed: null,
            diligenceEndDateFrom: null,
            diligenceEndDateTo: null,
            esgStatus: null,
            esgSubmissionDateFrom: '',
            esgSubmissionDateTo: '',
            internalPoCEmail: "",
            marketUnit: null,
            requestDateFrom: null,
            requestDateTo: null,
            requestPurpose: null,
            requestorEmail: "",
            supplierName: "",
            globalUltimateParentDUNS:""
        };
        this.filterCriteriaPayload = {
          countryServed: null,
          diligenceEndDateFrom: null,
          diligenceEndDateTo: null,
          esgStatus: null,
          esgSubmissionDateFrom: '',
          esgSubmissionDateTo: '',
          internalPoCEmail: "",
          marketUnit: null,
          requestDateFrom: null,
          requestDateTo: null,
          requestPurpose: null,
          requestorEmail: "",
          supplierName: "",
          globalUltimateParentDUNS:""
        }
      }
    
    initButtons() {
        this.downloadFilterButton = {
            label: "Download Reports",
            color: "white",
            fontSize: 16,
            backgroundColor: "blue",
            borderColor: "blue",
            height: 48,
            width: 250,
            icon: "assets/icons/download-white.svg",
            iconPosition: "right",
            onClick: async () => {
                this.handleSearchResults();
            }
        };
    }
    /**
     * Description: Execute searching with all filter conditions.
     */
    async applyFilters() {
        console.log(this.form.values())
    }

    /**
     * Description: Invoked when the child search component returns results.
     * fetches reports review result with search filters based on page number.
     * Also retrieves total number of result pages for pagination bar.
     */
    handleSearchResults = () => {
        this.invalidFields = [];
        this.filterCriteriaPayload = { ...this.filterCriteria };
        this.dateTypes.forEach((filter) => {
            this.validateDates(filter);
            this.formatPayloadDate(filter);
          });
          if (Object.keys(this.invalidFields).length > 0) {
            return
          }
          this.setFilterButtonsDisabledProps(false);
          this.time = setInterval(this.downloadReports, 10000)
    };

    /**
     * Description: Download all preview results with current filter conditions.
     */
     downloadReports = async () => {
      var values: any = {};
      values = {
        dateGroup_From:this.filterCriteriaPayload.esgSubmissionDateFrom == null? '': this.filterCriteriaPayload.esgSubmissionDateFrom,
        dateGroup_To:this.filterCriteriaPayload.esgSubmissionDateTo == null? '': this.filterCriteriaPayload.esgSubmissionDateTo,
        countryServed: "",
        marketUnit: "",
        reportType: "process"
      }
        let uploadResponse = await this.reportService.createReport(values);
        if (uploadResponse.msg === "Success" && uploadResponse.downloadResult.status === "created") {
            this.stopDownload()
            let response = await this.reportService.downloadProcessDumpFile(uploadResponse.downloadResult.s3_url,values.reportType);
            this.successAlertVisible = true
            this.failureAlertVisible = false
            this.setFilterButtonsDisabledProps(true);
        } else if (uploadResponse.msg === "Failed") {
            this.failureAlertVisible = true
            this.successAlertVisible = false
            this.setFilterButtonsDisabledProps(true);
        }
    }
    
    stopDownload = () => {
      if (this.time) {
        clearInterval(this.time);
    }
  }
    filterCriteriaChanged(value: FilterValue, filter: FilterType) {
        this.filterCriteria[filter] = value;
      }

    formatPayloadDate(filter: fromToDateFilter) {
      if (this.filterCriteriaPayload[filter.from]) {
        this.filterCriteriaPayload[filter.from] = formatDate(this.filterCriteriaPayload[filter.from] as Date | string, 'yyyy-MM-dd', 'en_US');
      }
      if (this.filterCriteriaPayload[filter.to]) {
        this.filterCriteriaPayload[filter.to] = formatDate(this.filterCriteriaPayload[filter.to] as Date | string, 'yyyy-MM-dd', 'en_US');
      }
    }

    validateDates(dateFilter: fromToDateFilter) {
        delete this.invalidFields[dateFilter.from];
        delete this.invalidFields[dateFilter.to];
    
        let fromDate = this.filterCriteria[dateFilter.from];
        let toDate = this.filterCriteria[dateFilter.to];
    
        if (fromDate && !(fromDate instanceof Date)) {
          this.invalidFields[dateFilter.from] = true;
          this.invalidValue.next(`${dateFilter.from}`);
        }
    
        if (toDate && !(toDate instanceof Date)) {
          this.invalidFields[dateFilter.to] = true;
          this.invalidValue.next(`${dateFilter.to}`);
        }
    
        if (!fromDate || !toDate) {
          return;
        }
    
        if (toDate < fromDate) {
          this.invalidFields[dateFilter.from] = true;
          this.invalidFields[dateFilter.to] = true;
          this.invalidValue.next(`${dateFilter.from}#${dateFilter.to}`);
        }
      }

      setFilterButtonsDisabledProps(isDisabled: boolean): void {
        const enableApplyFiltersButton: ButtonPropsType = {
            ...this.downloadFilterButton,
            disabled: false,
            backgroundColor: 'blue',
            isProcessing: false,
            borderColor: 'blue'
        };

        const disableApplyFiltersButton: ButtonPropsType = {
            ...this.downloadFilterButton,
            disabled: true,
            backgroundColor: 'mediumGrey',
            isProcessing: true,
            borderColor: 'mediumGrey'
        };

        if (isDisabled) {
          this.downloadFilterButton = enableApplyFiltersButton;
      }
      else {
          this.downloadFilterButton = disableApplyFiltersButton;
      }
      }
            /**
       * Sets the success alert properties
       */
      setSuccessAlertProps = (): void => {
        this.successAlert = {
            type: "alert-downloadSuccess",
            message: "The report has been successfully downloaded.",
            icon: "assets/icons/icon-tick.svg",
            iconFill: "#376803",
            iconClose: "assets/images/icon-cross-green.svg",
            onDismiss: () => {
                this.successAlertVisible = false;
            }
        };
    };

      /**
       * Sets the failure alert properties
       */
      setFailureAlertProps = (): void => {
          this.failureAlert = {
              type: "alert-downloadFaild",
              message:
                  "An error was encountered when downloading the report. Please try again later or contact procurement.support@accenture.com for further assistance.",
              icon: "assets/images/icon-error.svg",
              iconFill: "#cc1e32",
              iconClose: "assets/images/icon-cross-blue.svg",
              onDismiss: () => {
                  this.failureAlertVisible = false;
              }
          };
      };

}
