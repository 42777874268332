import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ButtonPropsType,
  SubSupplier,
  SubSupplierRelationStatus
} from '@lsl16/sustainability-shared-components';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmCancelRelationshipRequestComponent } from 'src/app/components/confirm-cancel-relationship-request/confirm-cancel-relationship-request.component';
import { CustomStyles } from 'src/app/models/Styles';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  HeaderEvents,
  HeaderService
} from 'src/app/services/header/header-service';

type subSupplierLiterals = {
  cancelRelationshipButtonLabel: string;
}
@Component({
  selector: 'tsm-sub-supplier-item',
  templateUrl: './sub-supplier-item.component.html',
  styleUrls: ['./sub-supplier-item.component.sass']
})
export class SubSupplierItemComponent implements OnInit, OnChanges {
  @Input() supplierTsmId: string;
  @Input() item: SubSupplier;
  @Input() displayButtons: boolean = false;
  @Input() isDuplicate: boolean = false;

  labelTranslations: subSupplierLiterals;
  score: string = '';
  relationStatusStyles: CustomStyles;
  displayLink: boolean = false;
  isSupplier: boolean = false;
  supplierNameDisplay : string;
  showSupplierFlag : boolean = false;

  public cancelRelationshipButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 14,
    fontFamily: 'Graphik-Medium',
    padding: '0px 0px',
    disabled: this.isDuplicate,
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    icon: 'assets/icons/x-grey.svg',
    clickIcon: 'assets/icons/x-grey.svg',
    clickedIconHeight: 14,
    clickedIconWidth: 14,
    iconHeight: 14,
    iconWidth: 14,
    onClick: () => {
      this.openConfirmCancelRelationshipModal();
    }
  };

  constructor(
    public authService: AuthService,
    private headerService: HeaderService,
    private ngbModalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isSupplier = this.authService.getRole() === 'supplier';
    this.subscribeEvents();
    this.loadTranslations();
    this.setStyles();
    this.shouldLinkDisplayed();
    this.score = this.getScoreText();
  }

  loadTranslations() {
    if (localStorage.getItem('multiLanguage')) {
      const multiLanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multiLanguageJson) {
        this.labelTranslations = multiLanguageJson.body.main.subSupplier;
        this.labelTranslations = {
          ...this.labelTranslations,
          ...multiLanguageJson.questionnire.reviewSummary.reviewSummaryHeader,
        };
        this.cancelRelationshipButtonProps.label =
          this.labelTranslations.cancelRelationshipButtonLabel;
      }
    }
  }

  showWebsite(): void {
      this.showSupplierFlag = true;
  }

  hideWebsite(): void {
    this.showSupplierFlag = false;
  }

  subscribeEvents() {
    this.headerService.eventEmit.subscribe((event) => {
      if (event === HeaderEvents.multiLanguageChanged) {
        this.loadTranslations();
        this.setStyles();
        this.score = this.getScoreText();
      }
    });
  }

  setStyles() {
    switch (this.item.status) {
      case SubSupplierRelationStatus.connected:
        this.relationStatusStyles = {
          label: this.getStatusText(),
          valueStyle: { 'background-color': '#F2F6E9', color: '#4A7B04' }
        };
        break;

      case SubSupplierRelationStatus.awaitingInviteApproval:
        this.relationStatusStyles = {
          label: this.getStatusText(),
          valueStyle: { 'background-color': '#FBF3E3', color: '#A16100' }
        };
        break;
    }
  }

  getStatusText(): string {
    if (
      this.labelTranslations &&
      this.labelTranslations[this.item.status.toLowerCase()]
    ) {
      return this.labelTranslations[this.item.status.toLowerCase()];
    } else {
      return this.item.status;
    }
  }

  getScoreText(): string {
    if (
      this.labelTranslations &&
      this.item?.score &&
      this.labelTranslations[this.item.score.toLowerCase()]
    ) {
      return this.labelTranslations[this.item.score.toLowerCase()];
    } else {
      return this.item.score;
    }
  }

  private openConfirmCancelRelationshipModal(): void {
    const cancelRelationshipModalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      windowClass: 'add-parent-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };

    const modalReference = this.ngbModalService.open(
      ConfirmCancelRelationshipRequestComponent,
      cancelRelationshipModalOptions
    );
    modalReference.componentInstance.parentTsmId = this.supplierTsmId;
    modalReference.componentInstance.subsidiaryTsmId = this.item.supplierTsmId;
    modalReference.componentInstance.workflowId = this.item.workflowId;
    modalReference.componentInstance.invokedBy = 'Super';

    let confirmModal = <HTMLElement>document.getElementsByTagName("ngb-modal-window")[0];
    if (confirmModal) {
      confirmModal.setAttribute("aria-hidden", "false");
    }
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  private shouldLinkDisplayed(): void {
    this.displayLink = this.item.supplierTsmId != undefined;
  }

  private navigateToSubSupplierProfile(): void {
    let redirectUrl: string;
    if (this.isSupplier) {
      redirectUrl = `sub-supplier-profile/${this.item.supplierTsmId}`;
    }
    else {
      redirectUrl = `supplier-profile/${this.item.supplierTsmId}`;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([redirectUrl]);
  }

  ngOnChanges(){
    this.cancelRelationshipButtonProps.disabled = this.isDuplicate;
  }
}
