import { Injectable } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { Observable, Subject, timer } from "rxjs";
import { ToolNotification } from "src/app/models/ToolNotification";
import { environment } from "../../../environments/environment";
export type NotificationMode = 'unread' | 'archive';
export type NotificiationEventName = "refresh" | "delete" | "closeNotification" | "resetMode" | "redirectToQuestionnaire";
export type NotificiationEventType = {
  name: NotificiationEventName,
  value?: string;
};

@Injectable({
  providedIn: "root"
})
export class NotificationService {

  private notificationEventSource = new Subject<NotificiationEventType>();
  private notificationBaseUrl = "";
  private notifications?: ToolNotification[];

  constructor(private httpService: HttpService) {
    this.notificationBaseUrl = `${environment.tsmBackendServiceURL}/notification`;
  }

  public getRefreshNotificationsEvent(): Observable<NotificiationEventType> {
    return this.notificationEventSource.asObservable();
  }

  public emitNotificationEvent(eventType: NotificiationEventName, eventValue?: string) {
    this.notificationEventSource.next({ name: eventType, value: eventValue });
  }
  /**
   * get notification list
   * @param category notification category
   */
  getNotificationList = async (category): Promise<any> => {
    const url = `${this.notificationBaseUrl}/search?category=${category}`;
    return await this.httpService.GetPromise(url);
  }

  /**
   * fetch notification list
   */
  private async fetchToolNotifications(): Promise<ToolNotification[]> {
    const url = `${this.notificationBaseUrl}/tool`;
    return await this.httpService.GetPromise<ToolNotification[]>(url);
  }

  /**
   * get notification list
   */
  public async getToolNotifications(): Promise<ToolNotification[]> {
    if (!this.notifications) {
      this.notifications = await this.fetchToolNotifications();
      timer(300000, 300000)
        .subscribe(async () => {
          this.notifications = await this.fetchToolNotifications();
          this.notificationEventSource.next({ name: 'refresh' });
        });
    }

    return this.notifications;
  }

  /**
 * get archived notification list
 */
  public async getArchivedToolNotifications(): Promise<ToolNotification[]> {
    const url = `${this.notificationBaseUrl}/tool?type=archived`;
    return await this.httpService.GetPromise(url);
  }

  /**
    * delete notification
    */
  async deleteToolNotification(mode: NotificationMode, notificationId: string) {
    let reqBody = {};
    if (!notificationId || !notificationId.trim()) {
      throw new TypeError('notificationId is empty');
    }
    if (mode === "archive") {
      reqBody = { archiveDelete: true }
    }
    const url = `${this.notificationBaseUrl}/tool/${notificationId}`;
    const response = await this.httpService.PostPromise<any>(url, reqBody);
    if (!response || response.msg !== "success") {
      throw new Error(`delete notification request failed: ${response}`);
    }

    this.removeNotificationFromCache(notificationId);
    this.notificationEventSource.next({ name: 'delete' });
  }

  private removeNotificationFromCache(notificationId: string) {
    if (!this.notifications) {
      return;
    }

    const index = this.notifications.findIndex(n => n.notificationId === notificationId);

    if (index < 0) {
      return;
    }

    this.notifications.splice(index, 1);
  }
}
