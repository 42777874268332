import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsm-request-multiple-sa-process-flow',
  templateUrl: './request-multiple-sa-process-flow.component.html',
  styleUrls: ['./request-multiple-sa-process-flow.component.sass']
})
export class RequestMultipleSaProcessFlowComponent implements OnInit {

  labelTranslations = {
    stepByStep: '',
    guidelines: ''
  }
  steps: { id: string; name: string; }[] = [
    {
      id: 'searchSuppliers',
      name: 'Search Suppliers'
    },
    {
      id: 'downloadSupplierList',
      name: 'Download Supplier List'
    },
    {
      id: 'uploadSupplierList',
      name: 'Upload Supplier List'
    },
    {
      id: 'checkListValidations',
      name: 'Check List Validations'
    },
    {
      id: 'sendAssessments',
      name: 'Send Assessments'
    }
  ]

  constructor() { }

  ngOnInit(): void {}

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.requestMultipleSAProcessFlow;
      }
    }
  }
}
