import { Component, Input, OnInit } from '@angular/core';
import { ButtonPropsType, SupplierRelationshipService } from '@lsl16/sustainability-shared-components';
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { PopUpParentRejectRelationshipComponent } from '../pop-up-parent-reject-relationship/pop-up-parent-reject-relationship.component';
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";

interface parentRejectRelationshipModalConfig {
  type: string,
  options: NgbModalOptions,
  parentTsmId: string,
  subsidiaryTsmId: string,
  subsidiaryCompanyName: string
}

type parentRelationshipActionLabels = {
  actionRequired: string;
  message: string;
  confirm: string;
  confirmButton: string;
  rejectButton: string;
};

@Component({
  selector: 'tsm-parent-relationship-action',
  templateUrl: './parent-relationship-action.component.html',
  styleUrls: ['./parent-relationship-action.component.sass']
})

export class ParentRelationshipActionComponent implements OnInit {
  @Input() supplierTsmId: string;
  @Input() supplierName: string;
  disclaimerSelected: boolean = false;

  parentRejectRelationshipModalConfig: parentRejectRelationshipModalConfig = {
    type: "parent-rejection",
    options: {},
    parentTsmId: '',
    subsidiaryTsmId: '',
    subsidiaryCompanyName: ''
  }

  constructor(private supplierRelationshipService: SupplierRelationshipService,
    public supplierGeneralProfileService: SupplierGeneralProfileService,
    private ngbModalService: NgbModal) { }

  public labelTranslations: parentRelationshipActionLabels = {
    actionRequired: '',
    message: '',
    confirm: '',
    confirmButton: '',
    rejectButton: ''
  };

  checkboxImage: string = './assets/images/enabled.svg';

  confirmButtonProps: ButtonPropsType = {
    label: '',
  };

  enabledConfirmButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 285,
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    icon: "assets/icons/confirm_tick.svg",
    iconAlt: "icon-confirm",
    iconPosition: "left",
    onClick: () => this.confirmRelationship()
  };

  disabledConfirmButtonProps: ButtonPropsType = {
    ...this.enabledConfirmButtonProps,
    backgroundColor: 'lightGrey',
    hoverBackgroundColor: 'gray',
    disabled: true
  };

  rejectButtonProps: ButtonPropsType = {
    label: '',
    borderColor: 'purple',
    backgroundColor: 'transparent',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 160,
    height: 48,
    padding: '16px 24px',
    margin: '0 16px 0 2px',
    hoverBorderColor: 'blue',
    hoverColor: 'purple',
    icon: "assets/icons/CrossBlue.svg",
    iconPosition: "left",
    iconAlt: "icon-reject",
    onClick: () => this.rejectRelationship()
  }

  ngOnInit(): void {
    this.confirmButtonProps = this.disabledConfirmButtonProps;
  }

  onDisclaimerClicked() {
    this.disclaimerSelected = !this.disclaimerSelected;
    this.setConfirmButtonProps();
  }

  setConfirmButtonProps() {
    if (this.disclaimerSelected) {
      this.checkboxImage = './assets/images/selected.svg';
      this.confirmButtonProps = this.enabledConfirmButtonProps;
    }
    else {
      this.checkboxImage = './assets/images/enabled.svg';
      this.confirmButtonProps = this.disabledConfirmButtonProps;
    }
  }

  async confirmRelationship(): Promise<void> {
    try {
      this.confirmButtonProps = this.disabledConfirmButtonProps;
      const parentChildRelationResponse = await this.supplierRelationshipService.getParentChildRelation(this.supplierTsmId);
      let parentBeforeUpdated = await this.supplierGeneralProfileService.getSupplierById(parentChildRelationResponse.parentTsmId);
      await this.supplierRelationshipService.confirmRelationshipByParent(parentChildRelationResponse.parentTsmId, this.supplierTsmId);
      let parentAfterUpdated = await this.supplierGeneralProfileService.getSupplierById(parentChildRelationResponse.parentTsmId);
      await this.supplierGeneralProfileService.updateCountryExtendDate(parentChildRelationResponse.parentTsmId, parentAfterUpdated, parentBeforeUpdated)
    } catch (error) {
      console.error("Failed to confirm the relationship", error);
    }
  }

  async rejectRelationship(): Promise<void> {
    // avoid opening the modal twice at the sametime
    if (this.ngbModalService.hasOpenModals()) {
      return;
    }
    this.parentRejectRelationshipModalConfig.options = {
      scrollable: true,
      size: "lg",
      animation: true,
      windowClass: "modal-container",
      backdropClass: "modal-custom-backdrop"
    };
    this.parentRejectRelationshipModalConfig.subsidiaryTsmId = this.supplierTsmId;
    this.parentRejectRelationshipModalConfig.subsidiaryCompanyName = this.supplierName;
    const modalReference = this.ngbModalService.open(PopUpParentRejectRelationshipComponent, this.parentRejectRelationshipModalConfig.options);
    modalReference.componentInstance.confirmType = this.parentRejectRelationshipModalConfig;
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.parentRelationshipAction;
        this.labelTranslations.message = this.labelTranslations.message.replace('$companyName', this.supplierName);
        this.enabledConfirmButtonProps.label = this.labelTranslations.confirmButton;
        this.disabledConfirmButtonProps.label = this.labelTranslations.confirmButton
        this.rejectButtonProps.label = this.labelTranslations.rejectButton;
      }
    }
  }
}
