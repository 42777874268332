import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AlertPropsType,
  HttpService,
  SAStatus,
  SupplierRelationshipService,
  SupplierRelationUpdateSuccessEventType,
} from '@lsl16/sustainability-shared-components';
import { SupplierGeneralProfileService } from '../../../services/supplier-general-profile.service';
import { BaseSupplierProfileComponent } from '../base-supplier-profile/base-supplier-profile.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SupplierHubService } from 'src/app/services/supplierHub/supplier-hub.service';
import { SupplierService } from 'src/app/services/supplier/supplier.service';
import { environment } from '../../../../environments/environment';
import { ReportService } from 'src/app/services/authority/report.service';
import { isEmpty } from 'lodash';
import {
  HeaderEvents,
  HeaderService,
} from 'src/app/services/header/header-service';

@Component({
  selector: 'tsm-parent-confirm-supplier-profile',
  templateUrl: './parent-confirm-supplier-profile.component.html',
  styleUrls: ['./parent-confirm-supplier-profile.component.sass'],
})
export class ParentConfirmSupplierProfileComponent
  extends BaseSupplierProfileComponent
  implements OnInit {
  @Input() isShowStatus: boolean= true;
  userType: any;
  esgStatus: string;
  baseUrl: string;
  profileData: any;
  back: string = '/';
  parentTsmId: string;
  alertProps: AlertPropsType;
  showAlert: boolean = false;
  relationshipConfirmedOrRejected: boolean;
  confirmParentMode: boolean = false;
  subSupplierMode: boolean = false;
  displayButtons: boolean = false;
  parentSupplier: GeneralSupplierType = undefined;
  supplierRelationshipUpdateEventType: SupplierRelationUpdateSuccessEventType;

  labelTranslation: {
    confirmRelationshipSuccessMessage: string;
    rejectRelationshipSuccessMessage: string;
  };
  showSuppplierEsgStatus: boolean = false;

  constructor(
    public supplierGeneralProfileService: SupplierGeneralProfileService,
    public authService: AuthService,
    public supplierHubService: SupplierHubService,
    public reportService: ReportService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private supplierService: SupplierService,
    private supplierRelationshipService: SupplierRelationshipService,
    private headerService: HeaderService
  ) {
    super(
      supplierGeneralProfileService,
      authService,
      supplierHubService,
      reportService,
      supplierRelationshipService
    );
    this.tabs = [
      {
        id: 'Relationships',
        title: 'Relationships',
        icon: 'assets/images/Group-Active.svg',
        inactiveIcon: 'assets/images/Group-InActive.svg',
        statusIcon: 'assets/icons/information_yellow.svg',
        active: true,
      },
    ];

    if (route.routeConfig.path === 'parental-supplier-response/:id') {
      this.confirmParentMode = true;
    } else if (route.routeConfig.path === 'sub-supplier-profile/:id') {
      this.subSupplierMode = true;
    }
  }

  async ngOnInit(): Promise<void> {
    const routeParam = this.route.snapshot.params.id;
    this.selectedSupplierTsmId = routeParam;
    this.supplierTsmId = routeParam;
    this.loadChildForRelationshipConfirmation = true;
    this.getprofile();
    await this.initialiseSupplier();
    this.countryServed = this.supplier.countryServed[0]
      ? this.supplier.countryServed[0]
      : '';
    this.userType = this.authService.getRole();
    await this.getAssessmentStatus();
    await this.handleActionIconOnLoad();
    await this.fetchParentData();
    if (!this.confirmParentMode) this.collectReviewData();
    await this.updateUiFlags();
    this.subscribeEvent();
    this.calculateShowSupplierEsgStatus();
  }

  private async updateUiFlags() {
    await this.subSupplierUiFlags();
  }

  private async subSupplierUiFlags() {
    const isSupplierAdmin = this.authService.isSupplierAdmin();
    const isGeneralSupplier = this.authService.isGeneralSupplier();
    this.displayButtons =
      this.userType === 'supplier' && (isSupplierAdmin || isGeneralSupplier);
    if (this.displayButtons) {
      const response =
        await this.supplierRelationshipService.hasAccessToParentSupplier(
          this.supplierTsmId
        );
      this.displayButtons = response.self;
    }
  }

  private async handleActionIconOnLoad() {
    const parentChildRelationResponse =
      await this.supplierRelationshipService.getParentChildRelation(
        this.supplierTsmId
      );
    if (
      this.subSupplierMode ||
      this.isSupplierParent ||
      isEmpty(parentChildRelationResponse) ||
      parentChildRelationResponse?.status === 'MutuallyConfirmed' ||
      parentChildRelationResponse?.parentStatus === 'Rejected'
    ) {
      this.tabs[0].statusIcon = '';
    }
  }

  private subscribeEvent() {
    this.supplierRelationshipService
      .getSupplierRelationUpdateSuccessEvent()
      .subscribe((next) => this.handleSupplierRelationUpdateSuccessEvent(next));

    this.headerService.eventEmit.subscribe((event) => {
      if (event === HeaderEvents.multiLanguageChanged) {
        this.refreshAlert();
      }
    });
  }

  async getprofile(): Promise<any> {
    this.baseUrl = `${environment.tsmBackendServiceURL}/userprofile`;
    const b2cUser = this.authService.decodeToken();
    const queryUserProfileUrl = `${this.baseUrl}/getUserprofile/${b2cUser.email}`;
    this.profileData = await this.httpService.GetPromise(queryUserProfileUrl);
  }

  private async getAssessmentStatus() {
    if (this.countryServed === '') {
      this.esgStatus = SAStatus.notYetInitiated;
      return;
    }

    // either use the parent tsm id if it is defiend, or use the supplier id
    const tsmId = this.supplier.parentTsmId
      ? this.supplier.parentTsmId
      : this.supplier.supplierTsmId;
    const esgStatusData = await this.supplierService.getESGStatus(
      tsmId,
      this.countryServed
    );
    if (!esgStatusData || Object.keys(esgStatusData.assessment).length == 0) {
      this.esgStatus = SAStatus.notYetInitiated;
    } else {
      this.esgStatus = esgStatusData?.assessment;
    }
  }

  handleSupplierRelationUpdateSuccessEvent(
    eventType: SupplierRelationUpdateSuccessEventType
  ) {
    this.supplierRelationshipUpdateEventType = eventType;
    if (eventType === 'RelationshipConfirmedByParent') {
      this.tabs[0].statusIcon = '';
      this.relationshipConfirmedOrRejected = true;
      const successMessage =
        this.labelTranslation.confirmRelationshipSuccessMessage;
      this.handleAlert(successMessage);
    } else if (eventType === 'RelationshipRejectedByParent') {
      this.tabs[0].statusIcon = '';
      this.relationshipConfirmedOrRejected = true;
      const successMessage =
        this.labelTranslation.rejectRelationshipSuccessMessage;
      this.handleAlert(successMessage);
    }
  }

  private handleAlert = (message: string) => {
    this.showAlert = true;
    if (this.relationshipConfirmedOrRejected) {
      this.alertProps = {
        type: 'alert-requestVerifiedSuccess',
        message: message,
        icon: 'assets/images/icon-tick.svg',
        iconFill: '#376803',
        iconClose: 'assets/images/icon-cross-green.svg',
        onDismiss: this.onAlertDismiss,
      };
    }
    window.scrollTo(0, 0);
  };

  private onAlertDismiss = (): void => {
    this.showAlert = false;
    this.supplierRelationshipUpdateEventType = null;
  };

  private refreshAlert() {
    this.updateTranslations();
    this.handleSupplierRelationUpdateSuccessEvent(
      this.supplierRelationshipUpdateEventType
    );
  }

  private updateTranslations() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslation = {
          ...multilanguageJson.body.main.parentRejectRelationship,
          ...multilanguageJson.body.main.parentRelationshipAction,
        };
      }
    }
  }

  async getCountryServed(val) {
    if (this.countryServed !== val) {
      this.countryServed = val;
      await this.getAssessmentStatus();
      this.calculateShowSupplierEsgStatus();
    }
  }

  private calculateShowSupplierEsgStatus() {
    this.showSuppplierEsgStatus = this.esgStatus && this.esgStatus !== SAStatus.notYetInitiated && this.esgStatus !== SAStatus.withdrew;
  }

  ngAfterContentChecked() {
    this.updateTranslations();
  }
}
