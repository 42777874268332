import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ButtonPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { Popup } from "../../../models/Popup";
import { ModalService } from "src/app/services/modal/modal.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "tsm-pop-up-manage-SA-request-message",
  templateUrl: "./pop-up-manage-SA-request-message.component.html",
  styleUrls: ["./pop-up-manage-SA-request-message.component.sass"]
})

export class popUpManageSARequestMessageComponent implements OnInit {
  public redirectUrl = "/termination-request-board";
  @Input() opened: boolean = true;
  modalData: Popup;
  terminationParams: any;
  cancelButton: ButtonPropsType = {
    label: "Close",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 102,
    height: 48,
    padding: "16px 22px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "purple",
    hoverColor: "white",
    borderColor: "grey"
  };

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    public modalService: ModalService,
    public ngbModalService: NgbModal,
    public httpService: HttpService
  ) { }
  popupClose = () => {
    this.activeModal.close();
  }
  ngOnInit() {
    this.modalData = this.modalService.getmanageSARequestData();
    this.cancelButton.onClick = this.popupClose;
  }
  
  ngDoCheck() {
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }
}