import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { environment } from "src/environments/environment";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash";
import { EventService } from 'src/app/services/EventService.service';
import { AuthService } from "src/app/services/auth/auth.service";
import { B2cService } from 'src/app/services/b2c/b2c.service';
import { countryList } from 'src/utilities/const/country-list';
import { TranslateService } from '@ngx-translate/core';
import { HeaderEvents, HeaderService } from 'src/app/services/header/header-service';

@Component({
  selector: 'tsm-supplier-onboarding',
  templateUrl: './supplier-onboarding.component.html',
  styleUrls: ['./supplier-onboarding.component.sass']
})
export class SupplierOnboardingComponent implements OnInit {
  @Input() supplier: any;
  @Input() isDuplicate = false;
  supplierTsmId: string;
  onboaredDataList: any = [];
  country: any = [];
  status: any = [];
  sendOnboarding: any;
  terminateStatus: any;
  statusFlag: boolean = true;
  onbordingDataIf: boolean = true;
  dataOnbording: any = [];
  countryServedMapping: string;
  countryServed: string;
  env = environment.env;
  supplierHubUrlEndpoint = environment.supplierHubRedirectBaseSupplierUrl;
  translatedLabels:any=[];
  supplierobj: any
  selectedLanguage:any

  constructor(
    private httpService: HttpService,
    public ngbModalService: NgbModal,
    public eventService: EventService,
    public authService: AuthService,
    private b2cService: B2cService,
    private translate: TranslateService,
    private headerService: HeaderService
  ) { }

  async ngOnInit(): Promise<void> {
    this.sendOnboarding = this.eventService.eventEmit.subscribe(async (value: any) => {
      if (value.indexOf("sendOnboarding") > -1) {
        const supplierTsmIdList = value.split("=");
        const supplierTsmId = supplierTsmIdList[1];
        this.getOnbordingData(supplierTsmId);
      }
    })
  }

  loadTranslations() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        this.translatedLabels = multilanguageJson.body.main.optionsCard;
        if(this.onboaredDataList.length>0){
          this.onboaredDataList.forEach((item) => {
            if(item.action&&item.status){
              item.actiont=this.translatedLabels[item.action];
              item.statust=this.translatedLabels[item.status];
              item.onboardedcountryt = this.CountryServedTranslate(item.onboardedcountry)
            }
          })
        }
      }
    }
  }

  subscribeEvents() {
    this.headerService.eventEmit.subscribe((event) => {
      if (event === HeaderEvents.multiLanguageChanged) {
        this.loadTranslations();
      }
    });
  }


  
  // get onboarding data
  async getOnbordingData(supplierTsmId) {
    const tempServedCountryDataUrl = `${environment.tsmBackendServiceURL}/search/supplier/supplierOnBoardingResult`;
    this.dataOnbording = await this.httpService.PostPromise(tempServedCountryDataUrl, { supplierTsmId: supplierTsmId })
    if (this.dataOnbording.length > 0) {
      this.onbordingDataIf = false
    } else {
      this.onbordingDataIf = true
    }
    this.dataprocessing(this.dataOnbording)
  }

  // data processing
  dataprocessing(dataOnbording) {
    this.onboaredDataList = dataOnbording
    this.onboaredDataList.forEach((item) => {
      if (item.status === "Pending Acceptance") {
        item.level = 1
        item.action = "Start Now"
      }
      if (item.status === "Pending Submission") {
        item.level = 2
        item.action = "View or Edit Details"
      }
      if (item.status === "Pending Review") {
        item.level = 3
        item.action = "View Details"
      }
      if (item.status === "Completed") {
        item.level = 4
        item.action = "View Details"
      }
    })
  }

  ngAfterContentChecked() {
    this.loadTranslations();
    this.subscribeEvents();
  }


  CountryServedTranslate(onboardedcountry: any) {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      let allCountryList = multilanguageJson.allCountry;
      return onboardedcountry.map((CountryServed) => {
        if (!allCountryList.hasOwnProperty(CountryServed)) {
          return CountryServed
        }
        return allCountryList[CountryServed];
      });
    }

  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['supplier'] !== undefined) {
      if (changes['supplier'].currentValue !== undefined) {
        this.supplierTsmId = changes['supplier'].currentValue.supplierTsmId;
        this.getOnbordingData(this.supplierTsmId)
      }
      this.terminateStatus = this.supplier?.status;
      if (this.terminateStatus == "Terminated") {//Terminated
        this.statusFlag = false;
      }
    }
  }

  redirectToOnboarding = async (item) => {
    if(!this.isDuplicate){
      const userInfo = this.authService.getLoginUser();
      const b2cUserInfo = await this.b2cService.getUser(userInfo.userId);
      let email = b2cUserInfo && b2cUserInfo.userName ? b2cUserInfo.userName : "";
      this.countryServedMapping = countryList['Data']?.transferToSupplierHub[`${this.countryServed}`];
      const selectedLanguage = localStorage.getItem('current language');
      const win = window.open("_blank");
      win.location.href =
        this.supplierHubUrlEndpoint +
        "/tsmsearch?key1=" +
        this.supplierTsmId +
        "&key2=" +
        this.countryServedMapping +
        "&email=" +
        email +
        "&supplierCode=" +
        item.suppliercode + `&env=${this.env}` + "&language=" + selectedLanguage +
        "&classification="+this.supplier?.supplierClassification;
    }
  }
}
