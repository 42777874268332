export const consentRenewalModalData = {
    title: "Privacy Statement",
    iconUrl: "assets/icons/info.svg",
    content: "",
    buttonText: "Close",
    headerCloseButton: true
};
export const consentRenewalModalOptions = {
    scrollable: true,
    centered: true,
    size: "lg",
    animation: true,
    windowClass: "modal-container",
    backdropClass: "modal-custom-backdrop"
};