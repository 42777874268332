<div class="item">
    <div class="row hover_pointer align-items-center">
        <div class="col-1" style="max-width: 5%">
            <h6 class="subtitle">{{id | number: '2.0'}}</h6>
        </div>
        <div class="col-2" style="padding-left: 2rem">
            <div class="subtitle ellipsis" [title]="model.supplierName" style="width: 135px">{{model.supplierName}}</div>
        </div>
        <div class="col-1" style="padding-left: 0">
            <h6 class="subtitle ellipsis" [title]="model.countryServed">{{model.countryServed}}</h6>
        </div>
        <div class="col-1" style="padding-left: 0; margin-left: 2rem">
            <div class="subtitle ellipsis" [title]="model.buyerUserEmail">{{model.buyerUserEmail}}</div>
        </div>
        <div class="col-2" style="margin-left: 3.5rem">
            <h6 class="subtitle ellipsis" [title]="model.terminateReason">{{model.terminateReason}}</h6>
        </div>
        <div class="col-1" style="max-width: 10%; padding-left: 0; margin-left: -2rem">
            <div class="subtitle" style="min-width: 140px">{{model.createTime | date:"MMMM dd yyyy"}}</div>
        </div>
        <div class="col-2" style="max-width: 10%; padding-left: 4rem; margin-left: -1.5rem">
            <h6 class="subtitle">{{model.status}}</h6>
        </div>
        <div class="col-1" style="padding-left: 4rem">
            <sh-button [props]="rejectButton"></sh-button>
        </div>
        <div class="col-1" style="margin-left: 2.5rem">
            <sh-button [props]="terminateButton"></sh-button>
        </div>
    </div>
</div>