import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SupplierClassificationService } from '@lsl16/sustainability-shared-components';
import { Subject } from 'rxjs';
import { HeaderEvents, HeaderService } from 'src/app/services/header/header-service';

@Component({
  selector: 'tsm-supplier-category-filter',
  templateUrl: './supplier-category-filter.component.html',
  styleUrls: ['./supplier-category-filter.component.sass']
})
export class SupplierCategoryFilterComponent implements OnInit {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;

  categories: any[] = [];
  values: any[];

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }

  constructor(private headerService: HeaderService,
    private supClassificationService: SupplierClassificationService) { }

  async ngOnInit(): Promise<void> {
    this.subscribeEvents();
    await this.refreshData();
  }

  async refreshData() {
    this.loadTranslations();
    await this.initCategories();
  }

  async initCategories() {
    this.categories = await this.supClassificationService.getCategories();
  }

  dropdownChanged(selectedValues) {
    this.values = selectedValues;
    this.valueChanged.emit(this.values);
  }

  clear() {
    this.values = [];
    this.categories.forEach(c => {
      c.checked = false;
    });
  }

  private loadTranslations() {
    if (localStorage.getItem('multiLanguage')) {
        const multilanguageJson = JSON.parse(localStorage.getItem('multiLanguage'));
        if (multilanguageJson.body) {
            this.labelTranslations = multilanguageJson.body.main.reportFilters.supplierCategory;
        }
    }
  }

  private subscribeEvents() {
    this.clearFilter.subscribe(() => this.clear());

    this.headerService.eventEmit.subscribe((event) => {
        if (event === HeaderEvents.multiLanguageChanged) {
          this.loadTranslations();
        }
    });
  }
}
