<link rel="prefetch" as="image" href="assets/icons/delete.svg" />
<link rel="prefetch" as="image" href="assets/icons/uploading.svg" />
<div *ngIf="fileToUpload" class="selectedFile labelWithIcon">
    <span class="label truncated" [title]="fileToUpload.name">
        {{ fileToUpload.name }}
    </span>
    <img *ngIf="!isUploading" class="icon" src="assets/icons/delete.svg" (click)="deleteFile()" alt="Remove file"/>
    <img *ngIf="isUploading" class="icon" src="assets/icons/processing-symbol.svg" alt="Uploading" role="progressbar" />
</div>
<div class="uploadContainer">
    <label
        class="uploadFile dropTarget"
        [ngClass]="{'error': hasErrors}"
        ng2FileDrop
        [uploader]="uploader"
        (onFileDrop)="handleFile($event)"
    >
        <input #uploadFileInput name="attachment" type="file" (click)="uploadFileInput.value = null" accept=".pdf" (change)="handleFile($event)" />
        <div class="labelWithIcon">
            <img src="assets/icons/attachment.svg" class="icon" alt="icon-attachment" />
            <span class="label">
                Attach your PDF file here
            </span>
        </div>
    </label>
    <div *ngIf="hasErrors" class="file-errors">
        <span class="label">{{ labels.genericError }}</span>
        <ul>
            <li *ngFor="let error of fileLevelErrors" class="label">{{error}}</li>
        </ul>
    </div>
</div>
