import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ButtonPropsType, HttpService, MultipleDropdownComponent } from "@lsl16/sustainability-shared-components";
import { AuthService } from "src/app/services/auth/auth.service";
@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.sass"]
})
export class DashboardComponent implements OnInit {
    @ViewChild("multipleDropDown") multipleDropDown: MultipleDropdownComponent;
    dashboardCards: any[];
    isDonotShowPage:boolean = false;
    constructor(private router: Router,
        public authService: AuthService,
        private httpService: HttpService) { }
    startButton: ButtonPropsType = {
        label: "Start",
        color: "white",
        fontSize: 16,
        backgroundColor: "blue",
        borderColor: "blue",
        hoverColor: "white",
        hoverBackgroundColor: "blue",
        hoverBorderColor: "white",
        height: 48,
        width: 110,
        padding: "10px",
        onClick: () => {
            this.start();
        }
    };

    ngOnInit(): void {}

    async start() {
        await this.authService.JudgeShowWelcomePage(true, false, this.isDonotShowPage);
        this.router.navigateByUrl("/dashboard-search");
    }
}
