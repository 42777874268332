<footer class="footer mt-auto py-3 px-3">
    <div class="container d-flex">
        <div class="me-auto row row-cols-auto ">
            <a tabindex="0"><img class="logo mb-3 me-5 align-self-center" src="assets/images/Acc_Logo_Black_Purple_RGB.svg" alt="icon-accLogo" /></a> 
            <ng-template #tipContent><div class="tip-Content">{{supplierFooter.footerMessage}}</div></ng-template>
            <span tabindex="0" class="text-muted align-self-center footertext copy-right-text" style="color: #53585c;">&copy;{{supplierFooter.footerLeft}}<a tabindex="0" class="footer-higtlight link-focus" (click)="onClick()" [ngbTooltip]="tipContent" tooltipClass="custom-tooltip-class" href="">{{supplierFooter.footerHerf}}</a>. {{supplierFooter.footerRight}}</span>   
        </div>
        <a tabindex="0" (keydown.enter)="openModal('terms-of-use', $event)" (click)="openModal('terms-of-use', $event)" *ngIf="headerRole() === 'supplier'" class="text-muted align-self-center footertext" style="color: #53585c!important;">{{supplierFooter.termsButton}}</a>
        <a tabindex="0" (keydown.enter)="jumpLink()" (click)="jumpLink()" *ngIf="headerRole() === 'buyer' || headerRole() === 'authority'" class="text-muted align-self-center footertext" style="color: #53585c!important;">Do Not Sell or Share My Personal Information</a>
        <a tabindex="0" href="#" role="button" (keydown.enter)="openModalByBuyer('terms-of-use', $event)" (click)="openModalByBuyer('terms-of-use', $event)" *ngIf="headerRole() != 'supplier'" class="ms-5 text-muted align-self-center footertext" style="color: #53585c!important;">Terms of Use</a>
        <a  href="#" tabindex="0" (keydown.enter)="handleEnterKey($event)" (click)="popupPrivacyStatement($event)" *ngIf="headerRole() === 'supplier'" class="ms-5 text-muted align-self-center footertext" style="color: #53585c!important;"
            >{{supplierFooter.privacyButton}}</a>
        <a href="#" tabindex="0" (keydown.enter)="handleEnterKey($event)" (click)="popupPrivacyStatement($event)" *ngIf="headerRole() != 'supplier'" class="ms-5 text-muted align-self-center footertext" style="color: #53585c!important;"
        >Privacy Statement</a>
        <a tabindex="0" href="mailto:procurement.support@accenture.com" class="ms-5 text-muted align-self-center footertext link-focus" style="color: #53585c!important;">{{supplierFooter.contactButton}}</a>
    </div>
</footer>
