export const popDeleteUserData = {
    title: "Confirm user deletion",
    iconUrl: "assets/icons/icon-conectivity.svg",
    content: `Please confirm you would like to delete this user's account. The user will be notified of this role change.`,
    headerCloseButton: true
};

export const popDeleteUserOptions = {
    scrollable: true,
    centered: true,
    size: "lg",
    animation: true,
    windowClass: "modal-container",
    backdropClass: "modal-custom-backdrop"
};