import { Component, Input, OnInit } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { SystemAdminService } from 'src/app/services/systemAdmin/system-admin.service';
import { InternalUser } from 'src/app/models/InternalUser';
import { RoleResolutionService } from 'src/app/services/roleResolution/role-resolution.service';
import { AuthService } from 'src/app/services/auth/auth.service';

type ApiResponse = 'Ok' | 'NoUsers' | 'TooManyUsers' | 'ServerError';

@Component({
  selector: 'tsm-system-admin-board',
  templateUrl: './system-admin-board.component.html',
  styleUrls: ['./system-admin-board.component.sass'],
})
export class SystemAdminBoardComponent implements OnInit {
  @Input() users: InternalUser[];
  @Input() userCount?: number = null;
  searchExecuted: boolean = false;
  noUsersFound: boolean = false;
  tooManyUsersFound: boolean = false;
  serverError: boolean = false;
  maxUsers: number = 50;
  currentLoginEmail: string = '';
  advSearchSearchButton: ButtonPropsType;
  searchCriteria: { userName: string } = {
    userName: '',
  };
  translations = {
    searchBtn: '',
    searchByEmail: '',
    tooManyUsersInfo: '',
  };

  constructor(
    private systemAdminService: SystemAdminService,
    private roleResolutionService: RoleResolutionService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.setButtonProps();
    this.currentLoginEmail = this.authService.getLoginUser().email;
  }

  setButtonProps(): void {
    this.advSearchSearchButton = {
      label: '',
      color: 'white',
      fontSize: 16,
      backgroundColor: 'purple',
      borderColor: 'lightPurple',
      hoverColor: 'purple',
      hoverBackgroundColor: 'lightPurple',
      hoverBorderColor: 'lightPurple',
      height: 40,
      width: 120,
      padding: '10px',
      icon: 'assets/icons/icon-search.svg',
      clickIcon: 'assets/icons/icon-search.svg',
      iconPosition: 'right',
      iconAlt: 'icon-search',
      onClick: () => this.search(),
    };
  }

  async searchKeyDown(event): Promise<void> {
    if (event.keyCode === 13) {
      await this.search();
    }
  }

  updateStateModel(type: ApiResponse) {
    this.serverError = false;
    this.noUsersFound = false;
    this.tooManyUsersFound = false;

    if (type === 'ServerError') {
      this.serverError = true;
    }

    if (type === 'NoUsers') {
      this.noUsersFound = true;
    }

    if (type === 'TooManyUsers') {
      this.tooManyUsersFound = true;
    }
  }

  async search(): Promise<void> {
    const userSearchResponse = await this.systemAdminService.searchInternalUsers(
      this.searchCriteria.userName
    );

    this.searchExecuted = true;

    if (!userSearchResponse || userSearchResponse === 'Not Found') {
      this.users = [];
      this.updateStateModel('ServerError');
    }
    else {
      if (userSearchResponse.metadata.status === 200) {
        const users = userSearchResponse.data.users.map(
          (user: any) => new InternalUser(this.roleResolutionService, user)
        );

        this.users = users;
        this.updateStateModel('Ok');
      }

      if (userSearchResponse.metadata.status === 206) {
        const users = userSearchResponse.data.users.map(
          (user: any) => new InternalUser(this.roleResolutionService, user)
        );

        this.users = users;
        this.updateStateModel('TooManyUsers');
        this.maxUsers = userSearchResponse.metadata.maxUsersReturned;
      }

      if (userSearchResponse.metadata.status === 400) {
        this.users = [];
        this.updateStateModel('NoUsers');
      }

      if (userSearchResponse.metadata.status === 404) {
        this.users = [];
        this.updateStateModel('NoUsers');
      }
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.translations = multilanguageJson.body.main.systemAdminBoardUser;
        this.advSearchSearchButton.label = this.translations.searchBtn;
        this.translations.tooManyUsersInfo =
          this.translations.tooManyUsersInfo.replace(
            '$maxUsers',
            this.maxUsers.toString()
          );
      }
    }
  }
  blockNonPrintableChar() {
    let printablevalue= this.searchCriteria.userName;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.searchCriteria.userName = printablevalue; 
  }
}
