<div class="sh-btn">
  <sh-button *ngIf="displayButtons" [props]="addSupplierButtonProps">
  </sh-button>
</div>

<div class="sub-supplier-container">
  <div
    class="row itemlist"
    [ngClass]="{
      'grid-scroll-right': subSupplieritems && subSupplieritems.length > 10
    }"
  >
    <div class="col width-10">
      <div class="subtitle" id="sub-supplier-name-header">
        {{ labelTranslations?.name }}
      </div>
    </div>
    <div class="col width-10">
      <div class="subtitle" id="sub-supplier-location-header">
        {{ labelTranslations?.location }}
      </div>
    </div>
    <div class="col width-15">
      <div class="subtitle" id="sub-supplier-poc-email-header">
        {{ labelTranslations?.pointOfContact }}
      </div>
    </div>
    <div class="col" [ngClass]="isSupplier ? 'fixed-width-col' : 'width-20'">
      <div class="subtitle" id="sub-supplier-countries-served-header">
        {{ labelTranslations?.countriesServed }}
      </div>
    </div>
    <div class="col fixed-width-col" *ngIf="!isSupplier">
      <div class="subtitle" id="sub-supplier-score-header">
        {{ labelTranslations?.score }}
      </div>
    </div>
    <div class="col fixed-width-col">
      <div class="subtitle" id="sub-supplier-relation-status-header">
        {{ labelTranslations?.relationStatus }}
      </div>
    </div>
    <div class="col">
      <div class="subtitle">&nbsp;</div>
    </div>
  </div>

  <div
    *ngIf="noRecordsFound; else subSupplierTemplate"
    class="centered-content"
  >
    <img
      id="noUserFoundImg"
      class="empty-form-image"
      src="assets/images/EmptyImage_Users.svg"
      style="height: 128px"
      alt="image-empty-records"
    />
    <div style="margin-top: 20px" class="empty-message-text">
      {{ labelTranslations?.emptyMessage }}
    </div>
  </div>
  <ng-template #subSupplierTemplate>
    <div sushubGridScroll [rows]="10">
      <div class="row-item" *ngFor="let subSupplier of subSupplieritems">
        <tsm-sub-supplier-item
          [supplierTsmId]="supplierTsmId"
          [item]="subSupplier"
          [displayButtons]="displayButtons"
          [isDuplicate]="isDuplicate"
        >
        </tsm-sub-supplier-item>
      </div>
    </div>
  </ng-template>
</div>
