import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService, SupplierRelationshipService } from '@lsl16/sustainability-shared-components';
import { LoginUser } from 'src/app/models/LoginUser';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SupplierGeneralProfileService } from 'src/app/services/supplier-general-profile.service';
import { environment } from "../../../environments/environment";
import { SupplierAdminBoardComponent } from '../supplier-admin-board/supplier-admin-board.component';
import { isEmpty } from 'lodash';
import { SubsidiarySupplierAdminBoardComponent } from '../subsidiary-supplier-admin-board/subsidiary-supplier-admin-board.component';
import { EsgQuestionnaireService } from '@lsl16/sushub-frontend';

@Component({
  selector: 'tsm-supplier-admin-board-container',
  templateUrl: './supplier-admin-board-container.component.html',
  styleUrls: ['./supplier-admin-board-container.component.sass']
})

export class SupplierAdminBoardContainerComponent implements OnInit {
  @ViewChild('supplierAdminBoard') supplierAdminBoard: SupplierAdminBoardComponent;
  @ViewChild('subsidiarySupplierAdminBoard') subsidiarySupplierAdminBoard: SubsidiarySupplierAdminBoardComponent;
  loggedInUser: LoginUser;
  private baseUrl?: string;
  profileData: any;
  userprofileData: any;
  eventAdmin: any;
  supplierTsmId: string;
  isSystemSupplierAdmin: boolean;
  supplierNameForSystemSupplierAdmin: string;
  isSubsidiaryAccessingParent = false;
  allTabs = [
    {
      id: "Users",
      title: "Users",
      icon: "assets/images/Group-Active.svg",
      inactiveIcon: "assets/images/Group-InActive.svg",
      statusIcon: '',
      active: true
    },
    {
      id: "SubsidiaryUsers",
      title: "Subsidiary Users",
      icon: "assets/images/Subsidiary-Active.svg",
      inactiveIcon: "assets/images/Subsidiary.svg",
      statusIcon: '',
      active: false
    }
  ];
  tabs = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private httpService: HttpService,
    private supplierGeneralProfileService: SupplierGeneralProfileService,
    private supplierRelationshipService: SupplierRelationshipService,
    private esgQuestionnaireService: EsgQuestionnaireService
  ) { }

  async ngOnInit() {
    this.baseUrl = `${environment.tsmBackendServiceURL}/userprofile`;
    let currentCountryServed = localStorage.getItem('currentCountryServed');
    let saCountryServed = localStorage.getItem('saCountryServed');
    if(saCountryServed) {
      saCountryServed = JSON.parse(saCountryServed);
    } else {
      saCountryServed =""
    }
    this.esgQuestionnaireService.eventEmitToHeaderCS.emit(saCountryServed);
    this.activatedRoute.queryParams.subscribe(params => {
      this.supplierTsmId = params['supplierTsmId'];
    });

    this.isSystemSupplierAdmin = this.authService.isSystemSupplierAdmin();
    this.loggedInUser = this.authService.getLoginUser();
    if (isEmpty(this.supplierTsmId) || this.authService.getRole() === "supplier") {
    this.supplierTsmId = this.loggedInUser.tsmId
    }
    this.isSubsidiaryAccessingParent = await this.checkSubsidiaryAccessingParent(this.loggedInUser.tsmId, this.supplierTsmId);

    if (this.isSystemSupplierAdmin || this.isSubsidiaryAccessingParent) {
      // display only one profile
      const supplier = await this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId);
      this.supplierNameForSystemSupplierAdmin = supplier.supplierName;
    }
    else {
      // load all profiles of current user
      this.getProfile();
    }
    await this.setTabVisibility();
  }

  async reload(event) {
    const isAdmin = JSON.parse(this.profileData.profileData[event.extension_tsm_defaultProfile].extension_tsm_TSM)[2].includes(2)
      && JSON.parse(this.profileData.profileData[event.extension_tsm_defaultProfile].extension_tsm_SPH)[2].includes(2);

    if (!isAdmin && !this.authService.isGeneralSupplier()) {
      if (this.supplierAdminBoard) {
        this.supplierAdminBoard.openDisabledPopUp();
      }
      this.eventAdmin = event.name;
      return;
    }
    const b2cUser = this.authService.decodeToken();
    const queryUserProfileUrl = `${this.baseUrl}/update`;
    await this.httpService.PostPromise(queryUserProfileUrl, event);

    const url = `${this.baseUrl}/getUserprofile/${b2cUser.email}`;
    this.userprofileData = await this.httpService.GetPromise(url);
    const user = this.userprofileData.profileData[this.userprofileData.extension_tsm_defaultProfile];
    const userInfo = {
      tsmId: this.userprofileData.extension_tsm_defaultProfile,
      pocEmail: b2cUser.email,
      extension_tsm_privacyStatement: this.userprofileData.extension_tsm_privacyStatement,
      extension_tsm_defaultRole: this.userprofileData.extension_tsm_defaultRole,
      extension_tsm_systemAdmin: this.userprofileData.extension_tsm_systemAdmin,
      extension_tsm_TSM: user.extension_tsm_TSM,
      extension_tsm_SUP: user.extension_tsm_SUP,
      extension_tsm_SPH: user.extension_tsm_SPH,
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    this.loggedInUser = this.authService.getLoginUser();
    this.supplierTsmId = this.loggedInUser.tsmId;

    await this.setTabVisibility();
    this.updateTabOnProfileSwitch();
  }

  private updateTabOnProfileSwitch() {
    const activeTab = this.tabs.find(x => x.active);
    if (activeTab.id === 'Users') {
      this.supplierAdminBoard.setCreateUserBtnVisibility();
      this.supplierAdminBoard.search();
    } else if (activeTab.id === 'SubsidiaryUsers') {
      this.subsidiarySupplierAdminBoard.reset();
    }
  }

  async checkSubsidiaryAccessingParent(userTsmId: string, supplierTsmId: string): Promise<boolean> {
    const userRole = this.authService.getRole();
    if (userRole !== 'supplier') { return false; }

    if (userTsmId === supplierTsmId) {
      // accessing own board
      return false;
    }

    // consider logged in user as subsidiary
    const pcRelation = await this.supplierRelationshipService.getParentChildRelation(userTsmId);
    if (pcRelation && pcRelation.status === "MutuallyConfirmed" && pcRelation.parentTsmId === supplierTsmId) {
      return true;
    }
    return false;
  }

  async getProfile(): Promise<void> {
    const b2cUser = this.authService.decodeToken();
    const queryUserProfileUrl = `${this.baseUrl}/getUserprofile/${b2cUser.email}`;
    this.profileData = await this.httpService.GetPromise(queryUserProfileUrl);
  }

  async setTabVisibility(): Promise<void> {
    const isParentSubsidiary = await this.isParentHasSubsidiary(this.supplierTsmId);
    if (!isParentSubsidiary || this.isSubsidiaryAccessingParent) {
      this.allTabs.forEach((tab) => {
        tab.active = (tab.id === "Users")
      });
      this.tabs = this.allTabs.filter(x => x.id === "Users");
    }
    else
      this.tabs = this.allTabs;
  }

  async isParentHasSubsidiary(supplierTsmId: string): Promise<boolean> {
    const userRole = this.authService.getRole();
    if (userRole !== 'supplier') { return false; }

    const pcRelation = await this.supplierRelationshipService.getParentChildRelation(supplierTsmId);
    if (!isEmpty(pcRelation) && pcRelation.parentTsmId !== supplierTsmId) {
      return false;
    }
    else {
      try {
        const response =
          await this.supplierRelationshipService.searchSubsidiaryUsers(supplierTsmId);
        if (response && response.data.length > 0) {
          return true;
        }
        return false;
      }
      catch (error) {
        console.error('Subsidiary Users Search Failed!', error);
        return false;
      }
    }
  }
}


