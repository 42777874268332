<div class="modal-header">
  <h4 class="modal-title">Display Columns</h4>
  <h6 (click)="resetColumns()" class="modal-subtitle">Reset to default</h6>
</div>
<div class="modal-body">
  <div class="modal-body-content">
    <ul class="list-group list-group-flush">
      <ng-container *ngFor="let col of allColumns">
        <ng-container *ngIf="!col.isLeftPinnedColumn">
          <li class="list-group-item">
            <input class="form-check-input me-1" [id]="col.name" (click)="toggle(col)" [checked]="isChecked(col)" type="checkbox" value="">
            <label [attr.for]="col.name" class="form-check-label stretched-link modal-body-content-text">
              {{ col.name }}
            </label>
          </li>
        </ng-container>
      </ng-container>
    </ul>
    
  </div>
  <div class="d-flex">
    <sh-button ngbAutofocus [props]="buttonProps1" style="width: 120px;"> </sh-button>
    <sh-button ngbAutofocus [props]="buttonProps2" style="width: 120px;"> </sh-button>
  </div>
</div>