import { Component, OnInit, OnChanges, Input, Output, OnDestroy, AfterContentChecked, EventEmitter, SimpleChanges } from "@angular/core";
import { HttpService, ReportService } from "@lsl16/sustainability-shared-components";
import { ButtonPropsType, SAStatus, getEsgStatusLabel, SupplierRelationshipService } from "@lsl16/sustainability-shared-components";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierService } from "src/app/services/supplier/supplier.service";
import { BuyerService } from "src/app/services/buyer/buyer.service";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BvPopUpTerminationComponent } from "src/app/components/bv-pop-up-termination/bv-pop-up-termination.component";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { EventService } from "src/app/services/EventService.service";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { TaskStatus, TaskType, TerminationStatus } from "src/app/components/bv-esg-assessment/assessment-constant";
import { PopUpSaStatusComponent } from "src/app/components/pop-up-sa-status/pop-up-sa-status.component";
import _ from "lodash";
import { countryList } from "src/utilities/const/country-list";
import { SvEsgJumpParameters } from "src/app/models/SvEsgJumpParameters";

@Component({
  selector: 'tsm-authority-esg-assessment',
  templateUrl: './authority-esg-assessment.component.html',
  styleUrls: ['./authority-esg-assessment.component.sass']
})
export class AuthorityEsgAssessmentComponent implements OnInit, OnDestroy, OnChanges, AfterContentChecked {

  @Input() supplier: any;
  @Input() allMu: any;
  @Input() allCountry: any;
  @Input() isScope3KeySup: boolean = false;
  @Input() isDuplicate = false;
  @Output() private withdrawSuccessAlert = new EventEmitter<any>();
  @Output() refershTerminateCallBack = new EventEmitter();
  statusFlag :boolean = true;

  constructor(
    config: NgbModalConfig,
    private authService: AuthService,
    private supplierService: SupplierService,
    public eventService: EventService,
    public supplierGeneralProfileService: SupplierGeneralProfileService,
    public ngbModalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private supplierRelationshipService: SupplierRelationshipService) {
    config.backdrop = "static";
  }
  esgJumpParameters:SvEsgJumpParameters;

  tipMsgReleationship = "";
  releationshipMsg = "Status reflects the Parent company’s Sustainability Assessment. Applicability of Parent Assessment for this supplier profile and Assessment actions should be considered accordingly.";
  awaitingParentMessage = "For this subsidiary a parent company was identified. You will be notified once the relationship was confirmed or rejected.";
  tipMsgOnboarding = "This sustainability assessment task was automatically initiated by the onboarding event.";
  tipMsgCountry = "Location of goods and services provided";
  
  tempAssessmentStatus: any = [
    {
      Code: SAStatus.awaitingAcceptance,
      Text: "Awaiting acceptance"
    }, {
      Code: SAStatus.awaitingSubmission,
      Text: "Awaiting submission"
    }, {
      Code: SAStatus.pendingReview,
      Text: "Pending review"
    }, {
      Code: SAStatus.updateRequired,
      Text: "Update required"
    }, {
      Code: SAStatus.submittedScored,
      Text: "Validation completed"
    }
  ];
  tempMarketUnit: any = [];
  tempLocation: any = [];
  searchCriteria: any = {
    countryLocation: [],
    marketUnit: [],
    saStatus: []
  };
  oldSearchCriteria: any = _.cloneDeep(this.searchCriteria);

  ProfileDetailStrictData = {
    options: {},
    supplier: {},
    centered: true,
    size: "lg",
    windowClass: "countdown-termination",
    status: {},
  };

  allAssessmentList: any = [];
  exhibitAssessmentList: any = [];
  allCountryServedCodeList: any = [];
  withdrawItem: any;
  withdrawSASub: any;
  withdrawSuccessSub: any;
  initTableSub: any;

  userInfo: any;
  userRole: any;
  isBuyerExpert = false;
  isGeneralSupplier = false;
  isSupplierAdmin = false;
  isSystemSupplierAdmin = false;
  selectedSupplierTsmId: string;
  isChildSupplier = false;
  hasManageUsersValidRole = false;
  isSupplierRequestForm = false;
  isLatamMu: any;
  buttonJson: any;

  pcMapping: any;
  waitingConfirmMapping: boolean = false;

  async ngOnInit() {
    this.withdrawSASub = this.eventService.eventEmit.subscribe(e => {
      if (e === "withdraw-sa") {
        this.withdraw(this.withdrawItem);
      }
    });

    this.initTableSub = this.eventService.eventEmit.subscribe(e => {
      if (e === "initTable-conditionA") {
        this.initialAssessmentList();
        this.filterAssessmentList(this.searchCriteria);
      }
    });
    this.supplier = await this.supplierGeneralProfileService.getSupplierById(this.selectedSupplierTsmId);
  }

  // select changed func
  saveSectionEvent(e, dropdown): void {
    this.searchCriteria[dropdown] = e;
    this.filterAssessmentList(this.searchCriteria);
  }

  // get resource for Select Country/ies
  getServedCountryList(servedData): any {
    const tmpLoc = [];
    servedData?.forEach(element => {
      const state = element.countryServedEnable;
      if (state === true) {
        const country = {
          Code: element.tsmCode,
          Text: element.countryServedName
        };
        tmpLoc.push(country);
      }
    });
    return this.sortSelectResource(tmpLoc);
  }

  sortSelectResource = (resource: any): any => {
    return resource?.sort((obj1: any, obj2: any) => {
      const val1 = obj1.Text;
      const val2 = obj2.Text;
      if (val1 > val2) {
        return 1;
      } else if (val1 < val2) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  private async withdraw(item): Promise<void> {
    const confirmType = this.ProfileDetailStrictData;
    confirmType.supplier = this.supplier;
    confirmType.options = {
      tsmId: this.supplier.supplierTsmId,
      supplierName: this.supplier.supplierName,
      status: this.supplier.status,
      countryServed: item.arrayServedCountryCode,
      taskId: item.taskId
    };
    const modalReference = this.ngbModalService.open(BvPopUpTerminationComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
    const tsmRoot = document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  onReviewAssessmentButtonClick = (item: any) => {
    this.isDuplicate =
    this.supplier?.supplierName.substring(0, 10).toLowerCase() ===
    'duplicate-';
    if (!this.isDuplicate) {
    const route = "/esg-questionnaire/survey-review-summary";
    let queryParams = {};
    if (this.isChildSupplier) {
      queryParams = {
        supplierName: this.supplier.supplierName,
        countryServed: item.countryServed,
        supplierTsmId: this.selectedSupplierTsmId,
        esgStatus: item.esgStatus,
        parentSupplierTsmId: this.supplier.parentTsmId,
        isScope3Supplier: this.isScope3KeySup
      };
    } else {
      queryParams = {
        supplierName: this.supplier.supplierName,
        countryServed: item.countryServed,
        supplierTsmId: this.supplier?.supplierTsmId,
        esgStatus: item.esgStatus,
        isScope3Supplier: this.isScope3KeySup
      };
    }
    this.router.navigate([route, item.esgQuestionnaireId], { queryParams });
    window.scroll(0, 0);
    }
  }

  private initialGlobalVariable(): void {
    this.userInfo = this.authService.getLoginUser();
    this.userRole = this.userInfo.defaultProfile;
    this.isBuyerExpert = this.authService.isBuyerExpert();
    this.isGeneralSupplier = this.authService.isGeneralSupplier();
    this.isSupplierAdmin = this.authService.isSupplierAdmin();
    this.isSystemSupplierAdmin = this.authService.isSystemSupplierAdmin();
    // manage user visibility
    // this.handleManageUsersButtonVisibility();
    const routeParam = this.route.snapshot.paramMap.get("id");
    this.selectedSupplierTsmId = routeParam;
  }

  // initial list data
  private async initialAssessmentList(): Promise<void> {
    this.isChildSupplier = false;
    this.waitingConfirmMapping = false;
    this.supplier = await this.supplierGeneralProfileService.getSupplierById(this.selectedSupplierTsmId);
    this.pcMapping = await this.supplierRelationshipService.getParentChildRelation(this.selectedSupplierTsmId);
    if (this.pcMapping && this.pcMapping.parentTsmId) {
      if (this.pcMapping.status === "MutuallyConfirmed") {
        this.isChildSupplier = true;
        this.tipMsgReleationship = this.releationshipMsg;
      } else if (this.pcMapping.status === "AwaitingParentConfirmation") {
        this.waitingConfirmMapping = true;
        this.tipMsgReleationship = this.awaitingParentMessage;
      }
    }
    const reqDataAssess = {
      supplierTsmId: this.selectedSupplierTsmId,
      buyerTsmId: this.userInfo.tsmId
    };
    const saList = await this.supplierService.getAssessmentList(reqDataAssess);
    const needEsgStatusForWithdraw = [SAStatus.awaitingAcceptance, SAStatus.awaitingSubmission];
    saList.forEach((item: any) => {
      item.arrayServedCountry = item.servedCountryList.map((country: any) => {
        return country.name;
      }).sort();
      item.arrayServedCountryCode = item.servedCountryList.map((country: any) => {
        return country.tsmCode;
      });
      this.allCountryServedCodeList = this.allCountryServedCodeList.concat(item.arrayServedCountryCode);
      const tempStrMarketUnit = item.servedCountryList.map((country: any) => {
        return country.marketUnit;
      }).sort();
      item.arrayMarketUnit = Array.from(new Set(tempStrMarketUnit));
      item.esgStatusLabel = this.upperFirstWord(getEsgStatusLabel(item.esgStatus));
      item.saStatusLevel = this.setSAStatusLevel(item.esgStatus);
      item.showReviewButton = item.esgStatus === SAStatus.submittedScored && this.isBuyerExpert ? true : false;
      // Buyer can not withdraw the SA which is requested by supplier
      this.isSupplierRequestForm = item.requestFrom === "supplier"
      item.showWithdrawButton = needEsgStatusForWithdraw.includes(item.esgStatus) &&
        item.terminationData?.status !== TerminationStatus.WithdrawalRequested &&
        !this.isChildSupplier && !this.isSupplierRequestForm;
      this.isLatamMu = item.arrayMarketUnit == "LATAM";
      item.showRequestorIcon = item.type.includes("ForOnboarding") || this.isLatamMu; 
      item.showRequestUpdateButton = item.esgStatus === SAStatus.submittedScored;
      item.IsReject = item.terminationData?.status === TerminationStatus.Rejected;
    });
    this.allAssessmentList = saList;
    this.exhibitAssessmentList = this.allAssessmentList.sort((a, b) => a.creationDate.localeCompare(b.creationDate));
  }

  private setSAStatusLevel(status: any): any {
    switch (status) {
      case SAStatus.awaitingAcceptance:
        return 1;
      case SAStatus.awaitingSubmission:
        return 2;
      case SAStatus.pendingReview:
      case SAStatus.updateRequired:
        return 3;
      case SAStatus.submittedScored:
        return 4;
      default:
        return 0;
    }
  }

  // show data according to selected box
  private filterAssessmentList(searchCriteria: any): void {
    let tempLoc = this.allCountry;
    this.exhibitAssessmentList = this.allAssessmentList;
    if (searchCriteria.marketUnit.length > 0) {
      const searchMarketUnit = searchCriteria.marketUnit.map((ent: any) => {
        return ent.Code;
      });
      this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
        const intersection = item.arrayMarketUnit.filter(unit => new Set(searchMarketUnit).has(unit));
        if (intersection.length > 0) {
          return true;
        }
        return false;
      });
      tempLoc = this.allCountry.filter((item) => {
        return searchMarketUnit.includes(item.marketUnit);
      });
    }
    if (!_.isEqual(searchCriteria.marketUnit, this.oldSearchCriteria.marketUnit)) {
      searchCriteria.countryLocation = [];
      this.tempLocation = this.getServedCountryList(tempLoc);
    }
    if (searchCriteria.countryLocation.length > 0) {
      const searchCountryLocation = searchCriteria.countryLocation.map((ent: any) => {
        return ent.Code;
      });
      this.exhibitAssessmentList = this.allAssessmentList.filter((item) => {
        const intersection = item.arrayServedCountryCode.filter(country => new Set(searchCountryLocation).has(country));
        if (intersection.length > 0) {
          return true;
        }
        return false;
      });
    }
    if (searchCriteria.saStatus.length > 0) {
      const searchEsgStatus = searchCriteria.saStatus.map((ent: any) => {
        return ent.Code;
      });
      this.exhibitAssessmentList = this.exhibitAssessmentList.filter((item) => {
        if (searchEsgStatus.includes(item.esgStatus)) {
          return true;
        }
        return false;
      });
    }
    this.oldSearchCriteria = _.cloneDeep(searchCriteria);
  }

  showStatusPopup(): void {
    const modalReference = this.ngbModalService.open(PopUpSaStatusComponent);
  }

  upperFirstWord(strWord: string): string {
    return strWord[0]?.toUpperCase() + strWord?.substring(1);
  }

  ngAfterContentChecked(): void {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multiLanguageJson.body !== undefined) {
        this.buttonJson = multiLanguageJson.body.sharedComponent.buttonLable;
      }
    }
  }

  ngOnDestroy(): void {
    const subList = [this.withdrawSASub, this.withdrawSuccessSub, this.initTableSub];
    subList.forEach((item) => {
      if (!!item) {
        item.unsubscribe();
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.allMu !== undefined) {
      if (changes.allMu.currentValue !== undefined) {
        this.tempMarketUnit = this.sortSelectResource(changes.allMu.currentValue);
      }
    }
    if (changes.allCountry !== undefined) {
      if (changes.allCountry.currentValue !== undefined) {
        const dataCountry = changes.allCountry.currentValue;
        this.tempLocation = this.getServedCountryList(dataCountry);
      }
    }
    if (changes.supplier !== undefined) {
      if(this.supplier && this.supplier.status == "Terminated"){//Terminated
        this.statusFlag = false;
        }
      if (changes.supplier.currentValue !== undefined) {
        this.initialGlobalVariable();
        await this.initialAssessmentList();       
      }
    }
  }

}
