import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { PowerBiAuthService } from './app/services/auth/power-bi-auth.service';
import { environment } from './environments/environment';
import '@angular/compiler';

let powerbiloginResult = PowerBiAuthService.handleMaslResponse();


switch(powerbiloginResult) {
  case "Failed": {
    console.log("Failed to handle powerbi authentication. redirecting to main page");
    window.location.replace(window.origin);
  }
  break;
  case "Success": {
    console.log("successfully handled powerbi authentication");
  }
  break;
  case "NoHandling":{
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

    if (['prod', 'stage', 'perf'].includes(environment.env)) {
      disableConsole();
    }

    break;
  }
}

// Override the console method to an empty function 
function disableConsole() {
  Object.keys(window.console).forEach(v => {
    window.console[v] = function () { };
  });
}
