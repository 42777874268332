<div *ngIf="opened" class="modal-header">
    <img class="modal-icon" [src]="modalData.iconUrl" alt="icon-modalData" />
    <h4 class="modal-title">{{ modalData.title }}</h4>
</div>
<div *ngIf="opened" class="modal-body">
    <div class="modal-body-content">
        <img class="modal-icon" [src]="emailIconUrl" alt="icon-email" />
        <span  style="margin-left: 10px;" class="modal-body-content-text">{{emailAddress}}</span>
    </div>
    <div class="d-flex flex-row-reverse">
        <sh-button ngbAutofocus [props]="buttonProps2"> </sh-button>
    </div>
</div>
