/**
 * Description: Takes a number x and forms an array of numbers with length x. Values within the returned array start from 1 (index + 1)
 * @Params value: number
 * @Output array of numbers
 */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "array"
})
export class ArrayPipe implements PipeTransform {
    transform(value): any {
        const result = [...Array(value)].map((entry, index) => index + 1);
        return result;
    }
}
