import { Component, OnInit } from '@angular/core';
import { 
  ButtonPropsType} from "@lsl16/sustainability-shared-components";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../services/auth/auth.service";

@Component({
  selector: 'tsm-pop-up-sa-status',
  templateUrl: './pop-up-sa-status.component.html',
  styleUrls: ['./pop-up-sa-status.component.sass']
})
export class PopUpSaStatusComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
  ) { }
 
  cancelProps: ButtonPropsType = {
    label: 'Back',
    backgroundColor: 'white',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    borderColor: 'purple',
    onClick: async () => {
        await this.cancel();
    }
  }

  ngOnInit(): void {
    const tsmRoot  =  document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  async cancel(): Promise<any> {
    this.activeModal.close();
  }

  headerRole(): string {
    return this.authService.getRole();
}

}
