import { Component, Input, OnInit } from '@angular/core';
import { BaseSupplierFilterComponent } from 'src/app/components/base-filter/base-supplier-filter.component';
import { FilterData, FilterType, fromToDateFilter, ReportType } from 'src/app/services/esgReport/esg-report.service';

@Component({
  selector: 'tsm-sa-retriggering-search',
  templateUrl: './sa-retriggering-search.component.html',
  styleUrls: ['./sa-retriggering-search.component.sass']
})
export class SaRetriggeringSearchComponent extends BaseSupplierFilterComponent implements OnInit {
  
  private filtersToApply: FilterType[] = ['marketUnit', 'countryServed', 'companyCategory', 'companySize','companySubCategory','supplierScore','esgStatus', 'supplierCategory', 'saRequestDate'];
  private dateTypes: fromToDateFilter[] = [{ from: 'esgSubmissionDateFrom', to: 'esgSubmissionDateTo' },
  { from: 'requestDateFrom', to: 'requestDateTo' }]
  reportType: ReportType = "supplier_records";
  saRetriggerFilterScenario = ''

  constructor() {
    super()
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initButtons();
    this.initFilterCriteria();
  }

  initFilterCriteria() {
    this.filterCriteria = {
      marketUnit: null,
      countryServed: null,
      companyCategory: null,
      companySize: null,
      esgStatus: ['Submitted and Scored for RFPs'],
      companySubCategory: null,
      supplierScore: null,
      supplierCategory: null,
      saRequestDate: null
    };
    this.filterCriteriaPayload = {
      marketUnit: null,
      countryServed: null,
      companyCategory: null,
      esgStatus: ['Submitted and Scored for RFPs'],
      companySize: null,
      companySubCategory: null,
      supplierScore: null,
      supplierCategory: null,
      saRequestDate: null
    }
  }

  initButtons() {
    this.clearFilterButton = {
      label: "",
      color: "purple",
      clickBorderColor: "purple",
      backgroundColor: "lightPurple",
      hoverBorderColor: "purple",
      fontSize: 16,
      height: 48,
      width: 180,
      onClick: async () => {
        this.clearFilters();
      }
    };
    this.applyFilterButton = {
      label: "",
      color: "white",
      fontSize: 16,
      backgroundColor: "blue",
      borderColor: "blue",
      height: 48,
      width: 180,
      onClick: async () => {
        this.applyFilters();
      }
    };
  }

  applyFilters() {
    this.invalidFields = [];
    this.filterCriteriaPayload = { ...this.filterCriteria };
    this.dateTypes.forEach((filter) => {
      this.validateDates(filter);
      this.formatPayloadDate(filter);
    });

    if (Object.keys(this.invalidFields).length > 0) {
      return
    }
    const filterData: FilterData[] = this.filtersToApply.filter(filter => this.filterCriteriaPayload[filter])
      .map(filter => ({
        filterName: filter,
        filterValue: this.filterCriteriaPayload[filter]
      }));
    this.setFilterButtonsDisabledProps(false);
    this.applyFilterEventEmitter.emit({ reportType: this.reportType, filters: filterData });
    this.scheduleWaitingMessage();
  }

  clearFilters() {
    this.initFilterCriteria();
    this.clearFilter.next(true);
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {        
        this.applyFilterButton.label = multilanguageJson.body.main.SaRetriggerFilters.applyFilter;
        this.clearFilterButton.label = multilanguageJson.body.main.SaRetriggerFilters.clearFilter;
        this.waitingMessageLabel = multilanguageJson.body.main.SaRetriggerFilters.waitingMessage;
        this.saRetriggerFilterScenario = multilanguageJson.body.main.SaRetriggerFilters.saRetriggerFilterScenario;
      }
    }
  }

}
