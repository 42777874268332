import { Component, OnInit, ViewChild } from '@angular/core';
import {
    ButtonPropsType,
    AssessmentReportItem,
    ComponentConfig,
    DynamicContext,
    DynamicFormComponent
} from "@lsl16/sustainability-shared-components";
import { MetadataService } from "../../services/metadata/metadata.service";
import { ReportService } from "../../services/authority/report.service";
import { AlertPropsType } from '@lsl16/sustainability-shared-components';
import { SubheaderPropsType } from 'src/app/models/SubheaderProps';

@Component({
    selector: 'tsm-sustainability-assessment-report',
    templateUrl: './sustainability-assessment-report.component.html',
    styleUrls: ['./sustainability-assessment-report.component.sass']
})
export class SustainabilityAssessmentReportComponent implements OnInit {

    @ViewChild("form", { static: false }) form: DynamicFormComponent;
    configs: Array<ComponentConfig> = [];
    context: DynamicContext;
    pageNumber: number = 1;
    totalNumberOfPages: number = 0;
    emptyResult: boolean;
    reportResult: AssessmentReportItem[];
    currentPageReport: AssessmentReportItem[];
    pageSize: number = 30;
    initFlag: boolean = false;
    clearFilterButton: ButtonPropsType;
    applyFilterButton: ButtonPropsType;
    downloadButtonDefaultProps: ButtonPropsType = {
        label: "Download Reports",
        color: "white",
        backgroundColor: "greyDark",
        borderColor: "greyDark",
        hoverColor: "white",
        hoverBackgroundColor: "greyDark",
        hoverBorderColor: "greyDark",
        fontSize: 16,
        height: 48,
        width: 250,
        disabled: true,
        icon: "assets/icons/download-white.svg",
        iconPosition: "right",
        onClick: async () => {
            this.clickDownload();
        }
    };
    downloadButton: ButtonPropsType;
    model: any;
    successAlert: AlertPropsType;
    failureAlert: AlertPropsType;
    successAlertVisible: boolean;
    failureAlertVisible: boolean;
    reportProps: SubheaderPropsType;
    time: any;

    constructor(private metadataService: MetadataService, private reportService: ReportService) { }

    async ngOnInit() {
        this.reportProps = {
            isWelcomeMessage: false,
            heading: "Sustainability Assessment Reports"
        }
        //Initialize the Deletion alerts
        this.setSuccessAlertProps();
        this.setFailureAlertProps();
        this.context = new DynamicContext();
        this.configs = await this.metadataService.gettingDataDumpFilterConfig();
        this.initButtons();
        this.toggleDisabled(false)
    }

    initButtons() {
        this.clearFilterButton = {
            label: "Clear Filters",
            color: "blue",
            clickBorderColor: "blue",
            backgroundColor: "white",
            borderColor: "blue",
            hoverColor: "blue",
            hoverBackgroundColor: "white",
            hoverBorderColor: "blue",
            fontSize: 16,
            height: 48,
            width: 180,
            onClick: async () => {
                this.clearFilters();
            }
        };
        this.applyFilterButton = {
            label: "Apply Filters",
            color: "white",
            fontSize: 16,
            backgroundColor: "blue",
            borderColor: "blue",
            height: 48,
            width: 180,
            onClick: async () => {
                this.applyFilters();
            }
        };
        this.downloadButton = this.downloadButtonDefaultProps;
    }

    /**
     * Description: Clear the values of all filter conditions.
     */
    clearFilters() {
        // clear error-message
        this.clearErrMsg();
        // clear form fileds
        this.clearFields();
        // set input enabled
        this.toggleDisabled(false);
        // clear preview result,reset flag to initial status
        this.resetToInitialStatus();
    }

    /**
     * Descriptioin: reset the flags to initial status
    */
    resetToInitialStatus() {
        this.initFlag = false;
        this.emptyResult = true;
        this.setDownloadButtonDisabledProp(true);
    }
    /**
     * Description: Execute searching with all filter conditions.
     */
    async applyFilters() {
        console.log(this.form.values())
        // set input disabled
        this.toggleDisabled(true);
        this.setFilterButtonsDisabledProps(false);
        const reportSearchResults = await this.reportService.getFilterSAReportData(this.form.values())
        const ifEmpty = typeof reportSearchResults !== "object" || reportSearchResults.type !== "searchSuccess" || reportSearchResults.reportResult.length === 0;
        this.toggleEmptyResult(ifEmpty);
        this.initFlag = true;
        if (typeof reportSearchResults === "object") {
            if (reportSearchResults.type === "emptySearch") {
                this.reportResult = [];
                this.totalNumberOfPages = 0;
                this.resetPageNumber();
            } else if (reportSearchResults.type === "searchSuccess") {
                this.reportResult = reportSearchResults.reportResult;
                // data paging display
                this.currentPageReport = this.getCurPageData(this.pageNumber)
                this.totalNumberOfPages = reportSearchResults.pages;
            } else if (reportSearchResults.type === "searchFailed") {
                this.reportResult = [];
                this.totalNumberOfPages = 0;
                this.resetPageNumber();
            }
        }
        this.setFilterButtonsDisabledProps(true);
    }

    clickDownload = () => {
        this.downloadButton.isProcessing = true
        this.time = setInterval(this.downloadReports, 10000)
    }

    stopDownload = () => {
        if (this.time) {
            clearInterval(this.time);
        }
    }

    /**
     * Description: Download all preview results with current filter conditions.
     */
    downloadReports = async () => {
        const values = this.form.values();
        let uploadResponse = await this.reportService.createReport(values);
        if (uploadResponse.msg === "Success" && uploadResponse.downloadResult.status === "created") {
            this.stopDownload()
            let response = await this.reportService.downloadDataDumpFile(uploadResponse.downloadResult.s3_url);
            this.downloadButton.isProcessing = false
            this.successAlertVisible = true
            this.failureAlertVisible = false
        } else if (uploadResponse.msg === "Failed") {
            this.downloadButton.isProcessing = false
            this.failureAlertVisible = true
            this.successAlertVisible = false
        }
    }

    /**
     * Description: Invoked when the child search component returns results.
     * fetches reports review result with search filters based on page number.
     * Also retrieves total number of result pages for pagination bar.
     */
    handleSearchResults = (reportSearchResults): void => {
    };

    /**
     * Description: Invoked when the child pagination bar component emits a page change event.
     * sets local pageNumber variable to page number emitted
     * @Params currentPage
     */
    handleCurrentPage = (currentPage: number): void => {
        this.pageNumber = currentPage;
        this.currentPageReport = this.getCurPageData(currentPage);
    };

    /**
     * Description: resets page number to 1. Invoked each time the user triggers a new search.
     */
    getCurPageData = (currentPage: number) => {
        return this.reportResult.slice((currentPage - 1) * this.pageSize, currentPage * this.pageSize);
    };

    /**
     * Description: resets page number to 1. Invoked each time the user triggers a new search.
     */
    resetPageNumber = (): void => {
        this.pageNumber = 1;
    };

    /**
     * Description: updates the errorstate of the functiion to true when called
     */
    toggleEmptyResult(ifEmpty) {
        this.emptyResult = ifEmpty;
        this.setDownloadButtonDisabledProp(ifEmpty);
    }

    /**
     * Description: Set download button disabled when there's no record found.
     */
    setDownloadButtonDisabledProp(disabled: boolean): void {
        if (disabled) {
            this.downloadButton = this.downloadButtonDefaultProps;
        } else {
            this.downloadButton = {
                ...this.downloadButton,
                disabled: false,
                backgroundColor: "blue",
                borderColor: "blue",
                hoverColor: "white",
                hoverBackgroundColor: "blue",
                hoverBorderColor: "blue"
            };
        }
    };

    //disables buttons on isDisabled = false, enables buttons on isDisabled = true
    setFilterButtonsDisabledProps(isDisabled: boolean): void {
        const enableApplyFiltersButton: ButtonPropsType = {
            ...this.applyFilterButton,
            disabled: false,
            backgroundColor: 'blue',
            isProcessing: false,
            borderColor: 'blue'
        };

        const disableApplyFiltersButton: ButtonPropsType = {
            ...this.applyFilterButton,
            disabled: true,
            backgroundColor: 'mediumGrey',
            isProcessing: true,
            borderColor: 'mediumGrey'
        };

        const enableClearFiltersButton: ButtonPropsType = {
            ...this.clearFilterButton,
            disabled: false,
            backgroundColor: 'white',
            color: 'blue',
            borderColor: 'blue',
        };

        const disableClearFiltersButton: ButtonPropsType = {
            ...this.clearFilterButton,
            disabled: true,
            backgroundColor: 'mediumGrey',
            color: 'white',
            borderColor: 'mediumGrey'
        };

        if (isDisabled) {
            this.applyFilterButton = enableApplyFiltersButton;
            this.clearFilterButton = enableClearFiltersButton;
        }
        else {
            this.applyFilterButton = disableApplyFiltersButton;
            this.clearFilterButton = disableClearFiltersButton;
        }
    }

    /**
     * Description: clear form fileds when user click 'clear filter' button
     */
    clearFields() {
        let formControlsAry = this.form.formGroup.controls;
        Object.keys(formControlsAry).forEach(element => {
            formControlsAry[element].patchValue('');
        });
    }

    /**
     * Description: clear error message when user click 'clear filter' button
     */
    clearErrMsg() {
        if (document.getElementById('date-group-eroMsg')) {
            document.getElementById('date-group-eroMsg').classList.add('display-none');
        }
    }

    /**
     * Description: set formgroup elements disable or enable
     * param: setDisabled - disables elements if true, re-enables if false
     */
    toggleDisabled(setDisabled: boolean) {
        const elements = document.getElementsByName('sa-report');
        Object.keys(elements).forEach(index => {
            if (setDisabled) {
                elements[index].classList.add('set-disabled');
            } else {
                elements[index].classList.remove('set-disabled');
            }
        });
        this.setDownloadButtonDisabledProp(!setDisabled);
    }


    /**
     * Sets the success alert properties
     */
    setSuccessAlertProps = (): void => {
        this.successAlert = {
            type: "alert-downloadSuccess",
            message: "The report has been successfully downloaded.",
            icon: "assets/icons/icon-tick.svg",
            iconFill: "#376803",
            iconClose: "assets/images/icon-cross-green.svg",
            onDismiss: () => {
                this.successAlertVisible = false;
            }
        };
    };

    /**
     * Sets the failure alert properties
     */
    setFailureAlertProps = (): void => {
        this.failureAlert = {
            type: "alert-downloadFaild",
            message:
                "An error was encountered when downloading the report. Please try again later or contact procurement.support@accenture.com for further assistance.",
            icon: "assets/images/icon-error.svg",
            iconFill: "#cc1e32",
            iconClose: "assets/images/icon-cross-blue.svg",
            onDismiss: () => {
                this.failureAlertVisible = false;
            }
        };
    };
}
