import { cloneDeep } from "@lsl16/sustainability-shared-components";
export default class VMController<T> {
  original: T;
  originalStr: string;
  currentStr: string;

  private isSave = false;

  saved() {
    this.isSave = true;
  }

  // this is used to create a new object, not use the old reference
  setOriginal(data: T) {
    this.originalStr = JSON.stringify(data);
    this.original = cloneDeep(data);
  }

  setCurrent(data: T) {
    this.currentStr = JSON.stringify(data);
  }

  // check whether data is changed
  public isChange() {
    if (this.originalStr === this.currentStr) {
      return false;
    } else {
      return true;
    }
  }

  isDirty() {
    if (this.isSave) {
      return false;
    }

    if (!this.isChange()) {
      return false;
    }

    return true;
  }
}
