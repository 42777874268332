import { Component, OnInit, Input } from '@angular/core';
import {
  ButtonPropsType,
  HttpService
} from "@lsl16/sustainability-shared-components"
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";

@Component({
  selector: 'tsm-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.sass']
})
export class ConfirmationBoxComponent implements OnInit {
  @Input() confirmType;
  constructor(private httpService: HttpService, public activeModal: NgbActiveModal, public supplierGeneralProfileService: SupplierGeneralProfileService) { }
  lable: string;
  loading:boolean =false;
  cancel: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    width: 112,
    borderRadius: 25,
    borderColor: "purple",
    margin: "0 15px 0 0",
    onClick: async () => {
      this.activeModal.close()
    }
  }
  confirm: ButtonPropsType = {
    label: "Confirm",
    padding: "11px",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: "purple",
    margin: "0 15px 0 0",
    onClick: async () => {
      this.submited()
    }
  }
  ngOnInit(): void {
    if (this.confirmType.title === "Publish") {
      this.lable = "The document will be visible to end users after publishing, are you sure to proceed?"
      
    }
    if (this.confirmType.title === "delete") {
      this.lable = "The document will not be visible to end user after deleting, are you sure to proceed?"
    }
    if (this.confirmType.title === "Published") {
      this.lable = "There is already a published Authority user guide. If you want to publish a new document, please delete the existing one."
    }
  }
  async submited() {
    this.loading = true
    if (this.confirmType.title === "Publish") {
      if(this.confirmType.type === "add"){
        const baseUrl = `${environment.tsmBackendServiceURL}/document/upload`
        await this.httpService.PostPromise(baseUrl,this.confirmType.formData)
      }else if(this.confirmType.type === "edit"){
        const baseUrl = `${environment.tsmBackendServiceURL}/document/update`
        await this.httpService.PostPromise(baseUrl, this.confirmType.formData)
      }else{
        const baseUrl = `${environment.tsmBackendServiceURL}/document/publish`
        await this.httpService.PostPromise(baseUrl,
          {
            documentId: this.confirmType.formDate.documentId,
            status: 2
          })
      }
      window.location.reload()
    }
    if (this.confirmType.title === "delete") {
      const baseUrl = `${environment.tsmBackendServiceURL}/document/del`
      await this.httpService.PostPromise(baseUrl, {
        documentId: this.confirmType.formDate.documentId,
      })
    }
    this.activeModal.close()
    this.supplierGeneralProfileService.eventEmit.emit("renovateDate");
    this.loading = false
    if (this.confirmType.title === "delete") {
      window.location.reload()
    }
  }
}
