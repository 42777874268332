<div class="pop-up-merge" *ngIf="!showSuccessPopup && !showErrorPopup">
    <div class="row">
      <p>Are you sure you want to merge {{ secondaryProfileName }}({{ secondaryProfileId }}) into {{ primaryProfileName }}({{ primaryProfileId }}) profile?</p>
    </div>
    <div class="row">
      <div class="d-flex justify-content-center mt-4">
        <div class="button-wrapper">
          <sh-button [props]="yesButton" (click)="yesButton.onClick()"></sh-button>
          <sh-button [props]="noButton" (click)="noButton.onClick()"></sh-button>
        </div>
      </div>
    </div>
  </div>
   
  <div class="success-popup" *ngIf="showSuccessPopup">
    <div class="row">
      <p class="success-title text-center h5" style="font-weight: bold; font-size: 24px;">Successful</p>
      <p>The secondary profile is merged into Primary profile successfully.</p>
    </div>
    <div class="row">
      <div class="d-flex justify-content-center mt-4">
        <sh-button [props]="closeSuccessButton" (click)="closeSuccessButton.onClick()"></sh-button>
      </div>
    </div>
  </div>
  <div class="error-popup" *ngIf="showErrorPopup">
    <div class="row">
      <p class="error-title text-center h5" style="font-weight: bold; font-size: 24px;">Error</p>
      <p>Failed to merge the profiles. Please try again later.</p>
    </div>
    <div class="row">
      <div class="d-flex justify-content-center mt-4">
        <sh-button [props]="closeErrorButton" (click)="closeErrorButton.onClick()"></sh-button>
      </div>
    </div>
  </div>