
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { 
  ButtonPropsType,
  ComponentConfig,
  DynamicContext,
  DynamicFormComponent,
  SupplierRelationshipService } from "@lsl16/sustainability-shared-components"
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { PopUpCountryservedExistingComponent } from '../pop-up-countryserved-existing/pop-up-countryserved-existing.component';
import { Subscription } from "rxjs";
import { EventService } from 'src/app/services/EventService.service';
import { BuyerService } from "src/app/services/buyer/buyer.service";
import { countryList } from 'src/utilities/const/country-list';
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { ProfileConfig } from '../profile-details/profile';
import { MessagePopUpComponent } from '../message-pop-up/message-pop-up.component';

type messagePopUpModalLiterals = {
  title: string;
  msg: string;
  closeBtn: string;
}
@Component({
  selector: 'tsm-pop-up-select-country-served',
  templateUrl: './pop-up-select-country-served.component.html',
  styleUrls: ['./pop-up-select-country-served.component.sass']
})
export class PopUpSelectCountryServedComponent implements OnInit {
  isSelected: string;
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    public ngbModalService: NgbModal,
    public eventService: EventService,
    private BuyerService: BuyerService,
    private supplierGeneralProfileService: SupplierGeneralProfileService,
    private authService: AuthService
  ) { }

  @Input() confirmType;
  @Input() config: ComponentConfig
  supplierTsmId: any;
  parentTsmId: any;
  supplier: any;
  configs: Array<ComponentConfig> = [];
  model: any = {};
  context: DynamicContext;
  values: any = {};
  saActionSubscription: Subscription;
  updateCountryServed = [];
  existingCountry: string[];
  countryCapList: any;
  csList: any;
  hasParent: boolean = false;
  parentSupplierTsmId: string;
  pcMapping: any;
  selectForParent: string = 'Current supplier profile has a Parent-Child relationship mapped. Please note, the respective Parent company is responsible for submitting the responses to the assessment request for this child company.';
  @ViewChild("form", { static: false }) form: DynamicFormComponent;
  showComponent = false;
  latamSelected = false;
  selectedMU : string ="";
  isBuyerExpert : boolean;
  messagePopUpModalTranslation: messagePopUpModalLiterals;
  closeLatamPopUp: boolean;
  
  callingComponent:String="tsm-pop-up-select-country-served"
  messagePopUpModalConfig: ProfileConfig = {
    type: "message-pop-up",
    options: {},
    supplier: {}
  };

  latamContinueProps: ButtonPropsType = {
    label: "Continue",
    backgroundColor: "grey",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    padding: "14px 10px",
    margin: "0 0 0 2px",
    icon: "",
    iconHeight: 14,
    iconWidth: 14,
    iconPosition: "right",
    onClick: ()=> { }
  };

  continueProps: ButtonPropsType = {
    label: "Continue",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    padding: "14px 10px",
    margin: "0 0 0 2px",
    icon: "",
    iconHeight: 14,
    iconWidth: 14,
    iconPosition: "right",
    onClick: async () => {
        await this.submitForm();
    }
  };

  cancelProps: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "darkBlue",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.cancel();
    }
  };

  closeBtnEvent(close:boolean){
    if(close){
      this.cancel();
    }
  }

  openLATAMPopUp(value: string) {
    this.isSelected = value;
    if (!this.isBuyerExpert && this.isSelected == "LATAM") {
      this.getModalTranslation();
      this.messagePopUpModalTranslation.title = "Sustainability Assessment Restriction"
      this.messagePopUpModalTranslation.msg = `
  Are you trying to raise SA for a LATAM supplier? <br>
  If yes, we currently cannot proceed with the request. <br>
  Please contact <a href="mailto:VendorMasterSS@accenture.com">'VendorMasterSS@accenture.com'</a> for further information.
`;


      this.messagePopUpModalTranslation.closeBtn = "LATAM";
      this.messagePopUpModalConfig.supplier.modalTranslation = this.messagePopUpModalTranslation;
      this.messagePopUpModalConfig.options = {
        scrollable: true,
        animation: true,
        backdrop: 'static',
        windowClass: 'message-pop-up-modal-window',
        backdropClass: 'modal-custom-backdrop',
      };
      const modalReference = this.ngbModalService.open(MessagePopUpComponent, this.messagePopUpModalConfig.options);
      modalReference.componentInstance.confirmType = this.messagePopUpModalConfig;
    }
  }


  async ngOnInit() {
    this.supplierTsmId = this.confirmType.supplierTsmId;
    this.parentTsmId = this.confirmType.parentTsmId;
    this.csList = this.confirmType.csList;
    this.hasParent = this.confirmType.hasParent;
    this.context = new DynamicContext();
    this.configs = JSON.parse(localStorage.getItem("countryServedConfigs"));
    this.context.set("existingCountry", this.csList);
    this.countryCapList = this.getCountryCapList(this.csList || []);
    this.setCountryCapListIntoConfigs(this.configs, this.countryCapList);
    this.showComponent = true;
    this.accessbility();
    this.isBuyerExpert = this.authService.isBuyerExpert();
    this.closeLatamPopUp = true;
  }

  accessbility() {
    const tsmRoot = document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  getCountryCapList(list) {
    let names = [];
    for (let a = 0; a < list.length; a++) {
      names.push(countryList.Data.mapping[list[a]]);
    }
    return names;
  }

  setCountryCapListIntoConfigs(configs: any, countryCapList: any) {
    configs[1].fieldset[0]['countryCapList'] = countryCapList;
  }

  resetCountryServed(countries) {
    let oriValue = this.values.countryServed;
    if (countries.length != 0) {
      let result = [];
      for (let a = 0; a < countries.length; a++) {
          result.push(countryList.Data.valueToKey_nameToTsmCode[countries[a]]);
      }
      if (result.sort().toString() != oriValue.sort().toString()) {
        for (let a = 0; a < oriValue.length; a++) {
          if (result.indexOf(oriValue[a]) == -1) {
            this.updateCountryServed.push(oriValue[a])
          }
        }
      }
      return result;
    } else {
      this.updateCountryServed = oriValue;
      return oriValue;
    }
  }

  async submitForm(): Promise<any> {
    if (!this.hasParent) {
      this.pcMapping = this.confirmType.parentSupplierInfo;
      if (this.pcMapping && this.pcMapping.parentTsmId && this.pcMapping.status === "MutuallyConfirmed") {
        this.hasParent = true;
        this.parentTsmId = this.pcMapping.parentTsmId;
      }
    }
    this.continueProps.isProcessing = true;
    if (this.form.validate()) {
      this.values = this.form.values();
    }
    let supIdAndCountry = {
      supplierTsmId: (this.hasParent ? this.parentTsmId : this.supplierTsmId),
      countryServed: this.values.countryServed.join(',')
    };
    let allPromises = [
      // this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId),
      this.BuyerService.getPopUpStatus(supIdAndCountry)
    ];
    if (this.hasParent) {
      allPromises.push(this.supplierGeneralProfileService.getSupplierById(this.parentTsmId)); 
    }
    const promisesResult = await Promise.all(allPromises);
    let latest_Supplier = this.confirmType.supplierInfo; // get latest supplier info for parent role or normal supplier
    const respond = promisesResult[0] ;
    let latest_Parent = {};
    if (this.hasParent) {
      latest_Parent =promisesResult[1]; // get latest parent info for child
    }
    this.values.countryServed = this.resetCountryServed(respond.countryNotCreated); // refresh countryServed : has been created
    let confirmType = {
        options: {},
        notifyType: (this.hasParent ? 'pc-mapping' : 'send-sa'), // decide popup type
        centered: true,
        size: "lg",
        windowClass: "modal-country-notify",
        countryNotCreated: respond.countryNotCreated, // has not been created
        countryNeedCreated: this.values.countryServed, // = countryNotCreated
        countryCreated: respond.countryCreated, // has been created
        propStatus: respond.propStatus,
        supplierTsmId: latest_Supplier.supplierTsmId,
        supplierName: latest_Supplier.supplierName,
        saCountryServed: (latest_Supplier.saCountryServed ? latest_Supplier.saCountryServed : []),
        supplierCountryServed: latest_Supplier.countryServed,
        updateCountryServed: this.updateCountryServed, // directly update into profile
        parentTsmId: (this.hasParent ? latest_Parent['supplierTsmId'] : ''),
        parentCountryServed: (this.hasParent ? latest_Parent['countryServed'] : []),
        parentSaCountryServed: (this.hasParent ? latest_Parent['saCountryServed'] : [])
    };
    this.activeModal.close();
    if (respond.propStatus != 'C') { // jump A/B popup before to SA page
      const modalReference = this.ngbModalService.open(PopUpCountryservedExistingComponent, confirmType);
      modalReference.componentInstance.confirmType = confirmType;
      this.accessbility()
    } else { // directly jump to SA-new page
      this.router.navigate(["/sustainability-assessment-new", latest_Supplier.supplierTsmId, this.values.countryServed.join(',')], { queryParams: {
        supplierTsmId: latest_Supplier.supplierTsmId,
        countryServed: this.values.countryServed,
        supplierName: latest_Supplier.supplierName
      },
      state:{
        supplierInfo: latest_Supplier,
        supplierParentInfo: latest_Parent
      }});
    }
    
  }
  async cancel(): Promise<any> {
    this.activeModal.close();
  }

  getModalTranslation() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.messagePopUpModalTranslation = multilanguageJson.body.main.messagePopUpModal;
      }
    }
  }

}
