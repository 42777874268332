import { EventEmitter, Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class HeaderService {
    public eventEmit: any;

    constructor() {
        this.eventEmit = new EventEmitter();
    }
}

export enum HeaderEvents {
    multiLanguageChanged = "multiLanguageChanged"
}