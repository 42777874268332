import { Component, Input, OnInit } from '@angular/core';
import {
  ButtonPropsType,
  SearchCriteriaType,
  SupplierRelationshipService,
} from '@lsl16/sustainability-shared-components';
import { SubsidiarySupplierUser } from 'src/app/models/SubsidiarySupplierUser';

@Component({
  selector: 'tsm-subsidiary-supplier-admin-board',
  templateUrl: './subsidiary-supplier-admin-board.component.html',
  styleUrls: ['./subsidiary-supplier-admin-board.component.sass'],
})
export class SubsidiarySupplierAdminBoardComponent implements OnInit {
  @Input() supplierTsmId: string;
  searchButtonProps: ButtonPropsType;
  public users: Array<SubsidiarySupplierUser> = [];
  maxUsers: number = 50;
  searchExecuted: boolean = false;
  limitExceeded: boolean = false;
  errorOccurred: boolean = false;
  searchCriteria: SearchCriteriaType = {
    companyName: '',
    location: '',
  };
  translations = {
    searchBtn: '',
    searchByCompany: '',
    searchByLocation: '',
    tooManyUsersInfo: '',
  };

  constructor(
    private supplierRelationshipService: SupplierRelationshipService
  ) { }

  ngOnInit(): void {
    this.setButtonProps();
  }

  setButtonProps(): void {
    this.searchButtonProps = {
      label: '',
      color: 'white',
      fontSize: 16,
      backgroundColor: 'purple',
      borderColor: 'lightPurple',
      hoverColor: 'white',
      hoverBackgroundColor: 'darkPurple',
      hoverBorderColor: 'darkPurple',
      height: 48,
      width: 120,
      padding: '10px',
      icon: 'assets/icons/icon-search.svg',
      clickIcon: 'assets/icons/icon-search.svg',
      iconPosition: 'right',
      iconAlt: 'icon-search',
      onClick: () => this.searchSubsidiaryUsers(),
    };
  }

  async searchSubsidiaryUsers(): Promise<void> {
    try {
      this.errorOccurred = false;
      const response =
        await this.supplierRelationshipService.searchSubsidiaryUsers(
          this.supplierTsmId,
          this.searchCriteria
        );
      this.users = response.data;
      this.limitExceeded = response.limitExceeded;
      this.searchExecuted = true;
    } catch (error) {
      this.errorOccurred = true;
      console.error('Subsidiary Users Search Failed!', error);
    }
  }

  async searchKeyDown(event): Promise<void> {
    if (event.keyCode === 13) {
      await this.searchSubsidiaryUsers();
    }
  }

  public reset(): void {
    this.errorOccurred = false;
    this.searchExecuted = false;
    this.limitExceeded = false;
    this.users = [];
    this.searchCriteria = {
      companyName: '',
      location: '',
    };
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.translations = multilanguageJson.body.main.subsidiarySupplierUser;
        this.searchButtonProps.label = this.translations.searchBtn;
        this.translations.tooManyUsersInfo =
          this.translations.tooManyUsersInfo.replace(
            '$maxUsers',
            this.maxUsers.toString()
          );
      }
    }
  }
  blockNonPrintableCharComp() {
    let printablevalue= this.searchCriteria.companyName;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.searchCriteria.companyName = printablevalue; 
  }
  blockNonPrintableCharLoc(){
    let printablevalue= this.searchCriteria.location;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.searchCriteria.location = printablevalue; 
  }
}
