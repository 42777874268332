<div class="modal-header">
  <h4 id="title" class="modal-title">
    {{ translations.title }}
  </h4>
</div>
<div class="modal-body">
  <div id="message" class="modal-body-content">
      {{translations.message}}
  </div>
</div>
<div class="modal-buttons">
  <sh-button id="cancelButton" [props]="cancelButtonProps"></sh-button>
  <sh-button id="confirmButton" ngbAutofocus [props]="confirmButtonProps"></sh-button>
</div>
