<ngb-pagination
    [collectionSize]="pages * pageSize"
    [(page)]="currentPage"
    [maxSize]="8"
    [pageSize]="pageSize"
    [rotate]="true"
    [ellipses]="false"
    [boundaryLinks]="true"
    (pageChange)="onPageChange($event)"
></ngb-pagination>
