import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportService } from '@lsl16/sustainability-shared-components';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsm-ra-report-filter',
  templateUrl: './ra-report-filter.component.html',
  styleUrl: './ra-report-filter.component.sass'
  
})
export class RaReportFilterComponent implements OnInit {

  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
  @Input() dropDownData: any;
  @Input() placeholder: any;

  values: any[];
  marketUnit: any[];

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }

  constructor(private reportService: ReportService) { }

  async ngOnInit(): Promise<void> {
    this.marketUnit = await this.reportService.getAllMarketUnit();
    this.clearFilter.subscribe(() => this.clear());
  }

  dropdownChanged(selectedValues) {
    this.values = selectedValues;
    this.valueChanged.emit(this.values);
  }

  clear() {
    this.values = [];
    this.dropDownData.forEach(item => item.checked = false);
    this.saveFilters();
  }
  saveFilters() {
    const selectedValues = this.dropDownData.filter(item => item.checked).map(item => item.Code);
    console.log("selected values are-",selectedValues);
    this.valueChanged.emit(selectedValues);
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.marketUnit;
      }
    }
  }

}
