import { InviteType } from './../invite-new-supplier/invite-new-supplier.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty } from "lodash";
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AlertPropsType,
  ButtonPropsType,
  ComponentConfig,
  DynamicContext,
  DynamicFormComponent,
  Localization,
  SubSupplierRelationshipService,
  SupplierRelationshipService
} from '@lsl16/sustainability-shared-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { SupplierGeneralProfileService } from 'src/app/services/supplier-general-profile.service';
import { InviteNewSupplierComponent } from '../invite-new-supplier/invite-new-supplier.component';

type addSubSupplierModalLiterals = {
  title: string;
  confirmAndSaveBtn: string;
  cancelBtn: string;
  warnings: {
    noPoc: string;
    alreadyLinked: string;
    linkedAsParent: string;
    linkedAsSubsidiary: string;
  }
  noRecordFound: string;
  inviteNewSubSupplierBtn: string;
}
@Component({
  selector: 'tsm-add-sub-supplier',
  templateUrl: './add-sub-supplier.component.html',
  styleUrls: ['./add-sub-supplier.component.sass']
})
export class AddSubSupplierComponent implements OnInit {
  @ViewChild("form", { static: false }) form: DynamicFormComponent;
  public supplierTsmId: string;
  requestingSupplier?: GeneralSupplierType;
  confirmSupplier?: GeneralSupplierType;
  subsidiaryDunsNumber?: string;
  isAlertVisible: boolean = false;
  modalTranslation: addSubSupplierModalLiterals;

  configs: Array<ComponentConfig> = [];
  context: DynamicContext = new DynamicContext();
  warningMessage?: string = null;
  noRecordFound: boolean = false;

  confirmAndSaveButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 225,
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    onClick: () => {
      this.assignSubSupplier();
    }
  };

  public cancelButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 115,
    height: 48,
    padding: '16px 20px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    onClick: () => {
      this.activeModal.close();
    },
  };

  verifyAlertProps: AlertPropsType = {
    type: "alert-profileVerifiedFaild",
    message: "errorAlertText",
    icon: "assets/images/icon-error.svg",
    iconFill: "#ffe8e6",
    iconClose: "assets/images/icon-cross-blue.svg",
    onDismiss: () => {
      this.isAlertVisible = false;
    }
  };

  disabledConfirmSubsidiaryButtonProps: ButtonPropsType = {
    ...this.confirmAndSaveButtonProps,
    backgroundColor: "gray",
    color: "white",
    disabled: true
  };

  inviteNewSupplierButtonProps: ButtonPropsType =
    {
      label: "Invite New Supplier",
      backgroundColor: 'blue',
      color: 'white',
      fontSize: 16,
      fontFamily: 'Graphik-Medium',
      width: 295,
      height: 48,
      padding: '16px 24px',
      margin: '0px',
      hoverBackgroundColor: 'darkBlue',
      hoverColor: 'white',
      onClick: async () => {
        await this.openInviteSubSupplierModal();
      }
    }

  currentConfirmButtonProps: ButtonPropsType = this.disabledConfirmSubsidiaryButtonProps;

  constructor(
    private activeModal: NgbActiveModal,
    private metadataService: MetadataService,
    private subSupplierRelationshipService: SubSupplierRelationshipService,
    private supplierRelationshipService: SupplierRelationshipService,
    private supplierGeneralProfileService: SupplierGeneralProfileService,
    private ngbModalService: NgbModal,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.disableConfirmButton();
    this.requestingSupplier = await this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId);
    this.configs = await this.metadataService.gettingAddSubSupplierConfig();
    this.initMultiLanguage();
  }

  async onSearchParent(value: GeneralSupplierType) {
    this.warningMessage = null;
    if (value === null) {
      this.noRecordFound = true;
      this.confirmSupplier = null;
      return;
    }

    this.noRecordFound = false;

    // indirect flag is not set for the value parameter
    // fetch and set the flag explicitly
    const supplierWithIndirect = await this.supplierGeneralProfileService.getSupplierById(value.supplierTsmId);
    value.indirect = supplierWithIndirect.indirect;

    this.insertSupplierIds(this.supplierTsmId);

    await this.validateSubSupplier(this.requestingSupplier, value);

    this.confirmSupplier = value;
    this.subsidiaryDunsNumber = value.dunsNumber;
  }

  onSubsidiaryDunsNumberChanged(value: string) {
    this.subsidiaryDunsNumber = value;
    this.noRecordFound = false;
  }

  private insertSupplierIds(parentSupplierId: string) {
    this.context.set("supplierTsmId", parentSupplierId);
  }

  private async openInviteSubSupplierModal(): Promise<void> {
    const options: NgbModalOptions = {
      scrollable: true,
      animation: true,
      centered: true,
      windowClass: 'add-parent-modal-window',
      backdropClass: 'modal-custom-backdrop'
    };
    const configs = await this.metadataService.gettingInviteNewSupplierConfig();
    const modalReference = this.ngbModalService.open(InviteNewSupplierComponent, options);
    modalReference.componentInstance.configs = configs;
    modalReference.componentInstance.inviteType = InviteType.SubSupplier;
    modalReference.componentInstance.newSupplierDunsNumber = this.subsidiaryDunsNumber;
    modalReference.componentInstance.invitingSupplierTsmId = this.supplierTsmId;
    let confirmModal = <HTMLElement>document.getElementsByTagName("ngb-modal-window")[0];
    if (confirmModal) {
      confirmModal.setAttribute("aria-hidden", "false");
    }
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  async assignSubSupplier() {
    if (this.form.validate()) {
      this.disableConfirmButton();
      const formValues = this.form.values();
      if (typeof formValues.countryServed === 'string') {
        //make the countryServed array of strings
        formValues.countryServed = formValues.countryServed.split();
      }
      const response = await this.subSupplierRelationshipService.assignSubSupplier(
        this.supplierTsmId,
        this.confirmSupplier.supplierTsmId,
        formValues.countryServed
      );

      if (response) {
        this.activeModal.close();
      } else {
        this.isAlertVisible = true;
        this.currentConfirmButtonProps = this.confirmAndSaveButtonProps;
      }
    }
  }

  private setButtonLabels(): void {
    this.cancelButtonProps.label = this.modalTranslation.cancelBtn;
    this.currentConfirmButtonProps.label = this.modalTranslation.confirmAndSaveBtn;
    this.confirmAndSaveButtonProps.label = this.modalTranslation.confirmAndSaveBtn;
    this.disabledConfirmSubsidiaryButtonProps.label = this.modalTranslation.confirmAndSaveBtn;
    this.inviteNewSupplierButtonProps.label = this.modalTranslation.inviteNewSubSupplierBtn;
  }

  initMultiLanguage() {
    const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));

    this.modalTranslation = multiLanguageJson?.body.main.addSubSupplierModal;
    Localization.localizeControls(this.configs, this.modalTranslation);
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.modalTranslation = multilanguageJson.body.main.addSubSupplierModal;
        this.setButtonLabels();
      }
    }
  }

  private async validateSubSupplier(currentSupplier: GeneralSupplierType, subSupplier: GeneralSupplierType) {
    if (!this.hasValidPoc(subSupplier)) {
      this.disableConfirmButton();
      this.warningMessage = this.modalTranslation.warnings.noPoc;
      return;
    }

    // found supplier is linked as child to current supplier
    const subSupplierLinkedAsChild = await this.isInProcessOfParentChildLinking(currentSupplier.supplierTsmId, subSupplier.supplierTsmId);
    if (subSupplierLinkedAsChild) {
      this.disableConfirmButton();
      this.warningMessage = this.modalTranslation.warnings.linkedAsSubsidiary;
      return;
    }

    // found supplier is linked as parent to current supplier
    const subSupplierLinkedAsParent = await this.isInProcessOfParentChildLinking(subSupplier.supplierTsmId, currentSupplier.supplierTsmId);
    if (subSupplierLinkedAsParent) {
      this.disableConfirmButton();
      this.warningMessage = this.modalTranslation.warnings.linkedAsParent;
      return;
    }

    // found supplier is already linked as a subsupplier
    const subSupplierAlreadyLinked = await this.isInProcessOfSubSupplierLinking(currentSupplier.supplierTsmId, subSupplier.supplierTsmId);
    if (subSupplierAlreadyLinked) {
      this.disableConfirmButton();
      this.warningMessage = this.modalTranslation.warnings.alreadyLinked;
      return;
    }

    this.currentConfirmButtonProps = this.confirmAndSaveButtonProps;
  }

  private hasValidPoc(supplier: GeneralSupplierType): boolean {
    return supplier.pocEmail && supplier.pocEmail.trim() !== "";
  }

  private disableConfirmButton() {
    this.currentConfirmButtonProps = this.disabledConfirmSubsidiaryButtonProps;
  }

  private async isInProcessOfParentChildLinking(parentTsmId: string, subsidiaryTsmId: string): Promise<boolean> {
    const relation = await this.supplierRelationshipService.getParentChildRelation(subsidiaryTsmId);
    if (!relation || isEmpty(relation)) {
      return false;
    }

    return relation
      && relation.parentTsmId === parentTsmId
      && relation.parentTsmId !== relation.childTsmId
      && (relation.status === "AwaitingParentConfirmation" || relation.status === "MutuallyConfirmed")
  }

  private async isInProcessOfSubSupplierLinking(supplierTsmId: string, subSupplierTsmId: string): Promise<boolean> {
    return await this.subSupplierRelationshipService.isSupplierLinkedWithSubSupplier(supplierTsmId, subSupplierTsmId);
  }
}
