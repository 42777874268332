<div class="dropdown-select" #inputPanel style="width: 400px;">
    <div style="color:blueviolet;font-weight:bold">{{supplierSelectDropDownBox.label}}</div>
    <div style="display: inline-block;" [title]="defaultProfile" class="dropdown-input">{{defaultProfile}}</div>
    <div>
        <div class="dropdown-option" *ngIf="dropDownOpen&&gradeOptions.length > 0" (mouseleave)="mouseleave()">
            <div *ngFor="let item  of gradeOptions" class="line-drop">
                <div class="dropdown-option-content" [title]="item" (click)="setmodel(item)">{{item}}</div>
            </div>
        </div>
        <div class="dropdown-option" style="height:45px" *ngIf="dropDownOpen&&gradeOptions.length === 0"
            (mouseleave)="mouseleave()">
            <div class="dropdown-option-content" style="text-align: center;color: gray;">
                {{supplierSelectDropDownBox.noData}}
            </div>
        </div>
    </div>
</div>