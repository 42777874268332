import { Component, Input, SimpleChange } from '@angular/core';
import { AppComponent } from 'src/app/app.component';

enum LabelType {
  countryServed,
  validationResults,
};
type RowDataLabels = {
  supplierTsmId: string;
  supplierName: string;
  countryServed: string;
  fullCountryServed?: string;
  rowNumber: string;
  validationResults: string;
  fullValidationResults?: string;
  successMessage: string;
};

@Component({
  selector: 'tsm-bulk-profiles-validation-item',
  templateUrl: './bulk-profiles-validation-item.component.html',
  styleUrls: ['./bulk-profiles-validation-item.component.sass']
})
export class BulkProfilesValidationItemComponent {
  @Input() rowDatas: any[];
  fullValidationResultsArr =[];
  rownoArr =[];
  isValidationResultsTruncated: boolean = false;
  isCountryServedTruncated: boolean = false;
  rowHasError:boolean = true;
  columns: any = [
    { prop: 'Supplier Name', name: 'Supplier Name', width: 100 },
    { prop: 'Country', name: 'Country', width: 55 },
    { prop: 'Tax ID Type', name: 'Tax ID Type', width: 75 },
    { prop: 'Tax ID', name: 'Tax ID', width: 40 },
    { prop: 'Row No', name: 'Row No', width: 40 },
    { prop: 'Validation Results', name: 'Validation Results', width: 265 },
  ];
  
  private doHideTooltip: boolean = true;
  maxLabelLengthValidationResults: number = 80;
  labels: RowDataLabels = {
    supplierTsmId: '',
    supplierName: '',
    countryServed: '',
    rowNumber: '',
    validationResults: '',
    successMessage: '',
  };
  helpLabels = {
    supplierTsmId: "SUPPLIER TSM ID",
    supplierName: "SUPPLIER NAME",
    country: "COUNTRY",
    rowNumber: "ROW NUMBER",
    validationResults: "VALIDATION RESULTS",
    rowNumberHelptext: "The ROW NUMBER corresponds to the row number in the uploaded xlsx file that contains the supplier/country combination in question."
  };
  validationLabels = {
    validationSuccess: "Validated Successfully"
  }

  constructor(
    private appComponent: AppComponent,
  ) { }

  ngOnInit(): void {
    if(this.rowDatas){
      this.createValidationResultTooltipText();    
    }
  }

  public get labelType(): typeof LabelType {
    return LabelType;
  }

  showTooltip(evt: MouseEvent): void {
    this.doHideTooltip = false;
    setTimeout(() => {
      if (!this.doHideTooltip) {
        this.appComponent.showGlobalTooltip(this.helpLabels.rowNumberHelptext, "", evt.pageX - 200, evt.pageY + 20);
      }
    }, 20);
  }

  hideTooltip(): void {
    this.doHideTooltip = true;
    setTimeout(() => {
      if (this.doHideTooltip) {
        this.appComponent.hideGlobalTooltip();
      }
    }, 30);
  }

  showTooltip_2(evt: MouseEvent, labelType: LabelType, rowNo:any): void {
    //prevents the tooltip from showing on non truncated labels
    if (
      (labelType === LabelType.countryServed &&
        !this.isCountryServedTruncated) ||
      (labelType === LabelType.validationResults &&
        !this.isValidationResultsTruncated)
    ) {
      return;
    }
    if(this.fullValidationResultsArr){
      this.doHideTooltip = false;
      setTimeout(() => {
        if (!this.doHideTooltip) {
          let fullLabeldisplay;
  
          if(this.fullValidationResultsArr[rowNo].length > this.maxLabelLengthValidationResults){
            fullLabeldisplay = this.fullValidationResultsArr[rowNo];
            this.appComponent.showGlobalTooltipOnTop(
              fullLabeldisplay,
              '',
              evt.pageX,
              evt.pageY
            );
          }
        }
      }, 20);
    } else {
      return;
    }
    
  }

  hideTooltip_2(): void {
    this.doHideTooltip = true;
    setTimeout(() => {
      if (this.doHideTooltip) {
        this.appComponent.hideGlobalTooltip();
      }
    }, 30);
  }

  private createValidationResultTooltipText() {
    this.rowDatas.forEach(element => {
      this.fullValidationResultsArr.push(element["Validation Results"]);
      if(element["Validation Results"] && element["Validation Results"].length > this.maxLabelLengthValidationResults){
        // this.rownoArr.push(element["Row No"]);
        element["Validation Results"] = `${element["Validation Results"]
        .substring(0, this.maxLabelLengthValidationResults)
        .trim()}...`;
        this.isValidationResultsTruncated = true;
      }
    });
  }
  ngOnChanges(changes: SimpleChange) {
    if(this.rowDatas){
      this.rowDatas.sort((a,b) => a["Row No"] - b["Row No"]);
      this.fullValidationResultsArr= [];
      this.createValidationResultTooltipText();    
    }
  }
}
