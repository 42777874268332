import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FileUploader } from "file-upload-bfp";
import { isSupplierListResponse, MassInviteService } from 'src/app/services/massInvite/mass-invite.service';
import {
  SupplierListFileEvent,
  ParsedSupplierListFile,
  MassInviteEventTypes as MIEventTypes,
  MassInviteEvents
} from "src/app/models/SupplierListFile";
@Component({
  selector: 'tsm-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.sass']
})
export class UploadFileComponent implements OnInit {
  @Input() attachDocumentation: string;
  @Input() confirmType: any;
  @Output() supplierListFileEvent = new EventEmitter;

  fileToUpload: any = {};

  // Unused, but necessary for ng2FileDrop
  uploader!: FileUploader;
  disabled: number = 0;

  /** Is the current file being read, validated or sent to the backend? */
  isUploading = false;
  filedata: any = {}
  // Separate variable from fileLevelErrors because we need to still show the generic
  // error message, even if there are no user-showable details
  hasErrors = false;
  fileLevelErrors: string[] = [];
  labels = {
    genericError: "There is an issue with the file you are trying to upload. Please try again.",
    multiFile: "Sorry, you cannot upload more than one file at the same time.",
    onlyXml: "Only PDF files are supported."
  };

  constructor(
    private massInviteService: MassInviteService
  ) { }

  ngOnInit(): void {
    if (this.confirmType.title === "edit") {
      this.fileToUpload.name = this.confirmType.formDate.fileName
    } else {
      this.fileToUpload = null
    }
    this.initializeUploader();
    this.massInviteService.eventEmit.subscribe((event) => this.handleMassInviteEvent(event));
    this.supplierListFileEvent.emit({
      deleted: true,
      hasErrors: this.hasErrors,
      ifupload: true
    });
  }

  private initializeUploader() {
    this.uploader = new FileUploader({
      allowedMimeType: [
        "pdf",
        ""
      ],
      allowedFileType: [
        "pdf"
      ],
      autoUpload: false
    });
  }

  public async handleFile(event) {
    this.hasErrors = false;
    this.fileToUpload = this.getSingleFile(event.target.files);
    this.isUploading = true;
    await this.readAndValidateFile();
    this.isUploading = false;
    if (event.target.files) {
      console.log(event.target.files)
      let file: FileList = event.target.files;
      let ifupload;
      if (this.disabled === 0) {
        ifupload = true
      } else if (this.disabled > 0 && !this.hasErrors && file) {
        ifupload = true
      } else {
        ifupload = false
      }
      this.disabled = this.disabled + 1
      this.supplierListFileEvent.emit({
        deleted: true,
        hasErrors: this.hasErrors,
        file: file,
        ifupload: ifupload
      });

    };
  }
  public deleteFile() {
    this.disabled = this.disabled + 1
    this.fileToUpload = null;
    this.fileLevelErrors = [];
    this.hasErrors = false;
    this.notifyFileDeleted();
  }

  private notifyFileDeleted() {
    this.supplierListFileEvent.emit({
      deleted: true,
      hasErrors: this.hasErrors,
      formData: {},
    });
  }

  private getSingleFile(files: FileList): File | null {
    this.hasErrors = false;
    this.fileLevelErrors = [];
    if (!files.length) {
      return null;
    }
    if (files.length > 1) {
      this.hasErrors = true;
      this.fileLevelErrors.push(this.labels.multiFile);
      return null;
    }
    return files.item(0);
  }

  private async readAndValidateFile() {
    let fileContents: ParsedSupplierListFile;
    // Even though the input[file] has accept=".xml", to add a file extension
    // restriction it only applies to the input (not the droptarget). Even then,
    // accept=.xml is optional and can be removed by the user.
    //
    // While the file extension is also validated by the backend, we need to
    // validate it here to prevent the XML->JSON conversion from failing due to
    // trying to read a PNG, for example (which would only show an unhelpful
    // generic error message)
    // 
    if (!this.fileToUpload.name.toLowerCase().endsWith(".pdf")) {
      this.hasErrors = true;
      this.fileLevelErrors.push(this.labels.onlyXml);
      this.notifyFileDeleted();
      return;
    }
  }

  private handleMassInviteEvent(event: MassInviteEvents) {
    if (event.name === MIEventTypes.MIProcess_Start) {
      this.isUploading = true;
    } else if (event.name === MIEventTypes.MIProcess_Done || event.name === MIEventTypes.MIProcess_Fail) {
      this.isUploading = false;
    }
  }
}
