import { Component, OnInit, ViewChild } from "@angular/core";
import {
    ButtonPropsType,
    DynamicFormComponent,
    ComponentConfig,
    DynamicContext,
    TaxTypeService
} from "@lsl16/sustainability-shared-components";
import { Router } from "@angular/router";
import { SupplierService } from "../../services/supplier/supplier.service";
import { MetadataService } from "../../services/metadata/metadata.service";
import { AuthService } from 'src/app/services/auth/auth.service';
import { Observable, Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopUpExitAddsupplierComponent } from "src/app/components/pop-up-exit-addsupplier/pop-up-exit-addsupplier.component";
import { ModalConfig } from "src/app/services/modal/types";
import { ModalService } from "src/app/services/modal/modal.service";
import { countryList } from "../../../utilities/const/country-list";
import { isEmpty } from 'lodash';
import { Validators } from "src/app/questionnaire/constants";

@Component({
    selector: "tsm-add-supplier",
    templateUrl: "./add-supplier.component.html",
    styleUrls: ["./add-supplier.component.sass"]
})
export class AddSupplierComponent implements OnInit {
    configs: Array<ComponentConfig> = [];
    model: any;
    isPopUp: boolean;
    context: DynamicContext;
    @ViewChild("form", { static: false }) form: DynamicFormComponent;
    saveSubmission: Subscription;
    saveError: boolean;

    nextButton: ButtonPropsType;
    props: ButtonPropsType;
    exitAddSupplierData: ModalConfig = {
        type: 'exit-add-supplier',
        options: {},
    };
    isOpen: boolean;
    supplierCheckFlag: boolean = false;
    formValues: any
    currentCountryServed: string;
    countryServedString: string;
    matchDataList = []
    alltaxType: any;
    taxItems: any;
    taxData: any;


    callingComponent: String = "tsm-add-supplier";
    countrySubscription: Subscription;
    selectedCountry: string;
    selected: Observable<string>;
    taxTypes: Array<ComponentConfig> = [];
    metaData: ComponentConfig[];
    initialConfigs: Array<ComponentConfig> = [];

    constructor(
        private router: Router,
        private metadataService: MetadataService,
        private supplierService: SupplierService,
        private ngbModalService: NgbModal,
        private modalService: ModalService,
        private taxTypeService: TaxTypeService,
        private authService: AuthService
    ) { }


    async ngOnInit(): Promise<void> {
        window.scrollTo(0, 0);
        this.context = new DynamicContext();
        this.configs = await this.metadataService.gettingAddSupplierConfig();
        this.initialConfigs = [...this.configs];
        this.metaData = this.configs;
        this.initButtons();
        this.isPopUp = true;
        this.selected = this.taxTypeService.selectedCountry$;
        this.exitAddSupplierData.options = this.modalService.getOptions(this.exitAddSupplierData.type);
        this.countrySubscription = this.taxTypeService.selectedCountry$.subscribe(async (country) => {
            if (!country) {
                return;
            }
            this.resetOnClear();
            this.selectedCountry = country;
            this.alltaxType = await this.taxTypeService.getCountryTaskType(country);
            this.resetFields();
            let taxTypeStartIndex = this.configs.findIndex(x => x.fieldset[0].isDynamicTaxField);
            if (taxTypeStartIndex > -1) {
                this.configs.splice(taxTypeStartIndex, this.taxTypes.length)
                this.taxTypes = [];
            }
            this.taxTypes = [];
            const localTaxfeilds = [];

            for(let i =0; i< this.alltaxType.taxData.length; i++) {
                if(this.alltaxType.taxData[i].isDropdown === true && this.alltaxType.taxData[i].isHide === false){
                    this.configs.forEach((config) => {
                        if(config.fieldset[0].name === "brnTaxIdType" || config.fieldset[0].name === "brnTaxId") {
                           config.fieldset[0].hide = false ;
                       }
                    })
                    break;
                }
            }

            this.alltaxType.taxData.forEach((tax) => {
                if(tax.isDropdown === true && tax.isHide=== false){
                    localTaxfeilds.push({Code: tax.taxType , Text: tax.taxType});
                }
                else if(tax.isDropdown !== true && tax.isHide=== false){
                const cleanedTaxType = tax.taxType.replace(/[^\w ]/gi, '').replace(/\s/g, '');
                let taxfield = {
                    type: 'layout',
                    fieldset: [
                        {
                            "index": "0",
                            "questionSetId": "bcf38bd8-4198-48fa-a6fd-37b837e193af",
                            "section": "gettingAddSupplierConfig",
                            "questionOrder": 6,
                            "width": "100%",
                            "required": false, // Making fields are not mandatory for new buyer supplier creation
                            "templateOptions": {
                                "tooltip": false,
                                "canFilter": false,
                                "label": tax.taxType,
                                "placeholder": "Insert " + tax.taxType
                            },
                            "validateOn": "change",
                            "name": cleanedTaxType,
                            "type": "input",
                            "isDynamicTaxField": true
                        }
                    ],
                    templateOptions: {}
                }
                this.configs.push(taxfield);
                this.taxTypes.push(taxfield);
            }
            });
            this.configs.forEach((config) => {
                if (config.fieldset[0].name === "brnTaxIdType") {
                    config.fieldset[0].data = localTaxfeilds;
                    // Prepopulate if there is only one dropdown value
                    if (localTaxfeilds.length === 1) {
                        this.form.formGroup.controls['brnTaxIdType'].setValue(localTaxfeilds[0].Code);
                    }
                }
            });
            this.configs = [...this.configs];
            this.configs.sort((a, b) => {
                const orderA = a.fieldset[0].questionOrder || 0;
                const orderB = b.fieldset[0].questionOrder || 0;
                return orderA - orderB;
            });
        })
    }
    ngOnDestroy(): void {
        this.taxTypeService.updateSelectedCountry("");
    }
    resetOnClear() {
        const a = document.getElementById("countryLocation")
        a.oninput = (e: any) => {
          if (!e.currentTarget.value) {
            this.configs = [...this.initialConfigs];
          }
          this.configs.forEach((config) => {
            if(config.fieldset[0].name === "brnTaxIdType" || config.fieldset[0].name === "brnTaxId") {
               config.fieldset[0].hide = true
           }
        })
        }
        if(this.form.formGroup.controls.brnTaxId){
        this.form.formGroup.controls['brnTaxIdType'].setValue('')
        this.form.formGroup.controls['brnTaxId'].setValue('')}
    }
     resetFields() {
        this.taxTypes.forEach(taxType => {
            let fieldname = taxType.fieldset[0].name;
            this.form.formGroup.removeControl(fieldname);
        });
    }

    initButtons(): void {
        this.nextButton = {
            label: "Continue",
            color: "white",
            fontSize: 16,
            backgroundColor: "blue",
            borderColor: "blue",
            height: 48,
            width: 204,
            padding: "10px",
            onClick: async () => {
                await this.save();
            }
        };
    }

    async save(): Promise<any> {
        let domainValue = this.form.values().companyDomainName.trim();
        let webSiteValue = this.form.values().companyWebsite.trim();
        let supplierNameValue = this.form.values().supplierName.trim();
        const dunsNumber = this.form.values().dunsNumber;
        // const brnTaxIdType = this.form.values().brnTaxIdType;
        const globalUltimateParentDUNS = this.form.values().globalUltimateParentDUNS;
        if (this.taxTypes.length > 0) {
            this.taxTypes.forEach(taxType => {
                let fieldname = taxType.fieldset[0].name;
                let taxTypeValue = this.form.values()[fieldname].trim();
                this.form.formGroup.controls[fieldname].patchValue(taxTypeValue);
            });
        }
        this.form.formGroup.controls.companyDomainName.patchValue(domainValue);
        this.form.formGroup.controls.companyWebsite.patchValue(webSiteValue);
        this.form.formGroup.controls.supplierName.patchValue(supplierNameValue);
        let companyLegalNameValue = this.form.values().companyLegalName.trim();
        this.form.formGroup.controls.companyLegalName.patchValue(companyLegalNameValue);
        if (!isEmpty(dunsNumber) && globalUltimateParentDUNS === dunsNumber) {
            this.form.formGroup.controls.globalUltimateParentDUNS.setErrors({ [Validators.IDENTICAL_DUNS_NUMBER]: true });
            this.form.formGroup.controls.globalUltimateParentDUNS.markAsDirty();
        }
        if (this.form.validate()) {
            this.nextButton.isProcessing = true;
            const values = this.form.values();
            const formValues = { ...values };
            this.taxTypes.forEach(taxType => {
                const fieldName = taxType.fieldset[0].name;
                const fieldValue = values[fieldName]?.trim();
                if (!fieldValue) {
                    delete formValues[fieldName];
                }
            });
            if(this.form.values()["brnTaxId"]){
                const brntype = this.form.values()["brnTaxIdType"].replace(/[^\w ]/gi, '').replace(/\s/g, '');
                const brnid = this.form.values()["brnTaxId"].trim();
                formValues[brntype] = brnid;
            }
            this.formValues = formValues;
            this.verify(this.formValues);
        }
    }

    verify(values) {
       
        values.taxData = {};
        this.taxTypes.forEach((taxType) => {
            let fieldname = taxType.fieldset[0].name;
            let taxValue = this.form.values()[fieldname].trim();
            if (taxValue !== '') {
                values.taxData[fieldname] = taxValue;
            }
        });

        this.supplierService.verify(values).then((res) => {
            const tsmidMap: { [tsmid: string]: string[] } = {};
            res.forEach(obj => {
                if (tsmidMap[obj.tsmid]) {
                    tsmidMap[obj.tsmid].push(obj.tax_registration_number);
                } else {
                    tsmidMap[obj.tsmid] = [obj.tax_registration_number];
                }
            });
            const modifiedRes = res.filter(obj => {
                if (tsmidMap[obj.tsmid] && tsmidMap[obj.tsmid].length > 1) {
                    obj.tax_registration_number = tsmidMap[obj.tsmid].filter(Boolean).join(',');
                    delete tsmidMap[obj.tsmid];
                    return true;
                }
                if (res && tsmidMap[obj.tsmid] && tsmidMap[obj.tsmid].length === 1) {
                    return true;
                }
                delete tsmidMap[obj.tsmid];                
                return false;
            });

            if (modifiedRes && modifiedRes?.length > 0) {
                this.nextButton.isProcessing = false;
                this.matchDataList = modifiedRes;
                this.supplierCheckFlag = true;
            } else {
                this.supplierService.save(values).then((response) => {
                    if (response && response.supplierTsmId) {
                        this.goNext(response.supplierTsmId, values);
                    } else {
                        // handle exception
                        this.nextButton.isProcessing = false;
                    }
                }).catch((error) => {
                    // handle exception
                    this.nextButton.isProcessing = false;
                    console.log(error);
                });
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    /**
     * go to add geography served page
     */
    goNext = (supplierTsmId, values): void => {
        /**
         * set params for create task
         * type of currentCountryServedNext should be string
         */
        this.router.navigate(["supplier-profile", supplierTsmId], {
            queryParams: {
                supplierName: values.supplierName
            }
        });
    }

    openPopUp() {
        const values = this.form?.values();
        for (let key in values) {
            if (values[key]) {
                this.isOpen = true;
                break;
            } else {
                this.isOpen = false;
            }
        }
        if (this.isOpen === true) {
            const confirmType = this.exitAddSupplierData;
            const modalReference = this.ngbModalService.open(
                PopUpExitAddsupplierComponent,
                confirmType.options,
            );
            // passing the modal type to the modal
            modalReference.componentInstance.confirmType = confirmType;
            let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
            if (tsmRoot) {
                tsmRoot.setAttribute("aria-hidden", "false");
            }
        } else {
            this.router.navigateByUrl("/dashboard-search");
        }
    }
}