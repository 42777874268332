<div class="modal-header">
    <img class="modal-icon" [src]="popData.iconUrl" alt="icon-popData" />
    <h4 class="modal-title">{{ popData.title }}</h4>
</div>
<div class="modal-body">
    <div class="modal-body-content" [innerHTML]="popData.content"></div>
    <div>
    <sh-button ngbAutofocus [props]="exitButtonProps"> </sh-button>
    <sh-button ngbAutofocus [props]="backButtonProps" style="float: right;"> </sh-button>
    </div>
</div>