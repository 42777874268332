export type Platform = "tsm" | "sph" | "sup";
export type Role = "buyer" | "supplier" | "authority";

export class LoginUser {
    public email?: string;
    public userId?: string;
    public userName?: string;
    public firstName?: string;
    public lastName?: string;
    public tsmId?: string;
    public TSM?: any;
    public SUP?: any;
    public SPH?: any;
    public domainHint?: any;
    public accessContorl?: any;
    public privacyStatement?: string;
    public defaultProfile?: Role;
    public systemAdmin?: boolean;
    public picture?: string;
    public name?: string;
    public id?: string;
    public expires?: number;
}
