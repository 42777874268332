<div class="modal-header">
    <h4 class="modal-title">
      {{ title }}
    </h4>
  </div>
  <div class="modal-body">
    <div class="modal-body-content">
      <form class="detailform">
        <qnr-dynamic-form
          [context]="context"
          [configs]="configs"
          [model]="model"
          [callingComponent]="callingComponent"
          (closeBtnEvent)="closeBtnEvent($event)"
          #form
        ></qnr-dynamic-form>
        <div class="inline-block">
          <img
            class="aboutico"
            src="assets/icons/icon-infomation.svg"
            alt="icon-information"
          />
          <div class="aboutto">{{ modalTranslation.infoMessage }}</div>
        </div>
      </form>
    </div>
  </div>
  
  <div class="modal-buttons">
    <div class="modal-inner-buttons">
      <sh-button [props]="cancelButtonProps"></sh-button>
      <sh-button [props]="backButtonProps"></sh-button>
    </div>
    <sh-button ngbAutofocus [props]="confirmAndSaveButtonProps"></sh-button>
  </div>
  