<div>
    <div style="margin-bottom: 12px">
        <span class="text">Sent on</span><span class="text-bold" style="margin-left: 6px">{{sentDate}}</span>
    </div>
    <div>
        <p class="status_requestor" (click)="openRequestor($event)">View requestor</p>
    </div>
</div>

<tsm-pop-up-bv hidden=true #tsmBuyerPopUp></tsm-pop-up-bv>
