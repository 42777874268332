import { SupplierRelationshipService } from '@lsl16/sustainability-shared-components';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterContentChecked, ViewChild } from '@angular/core';
import { UserProfileContainerComponent } from 'src/app/components/user-profile-container/user-profile-container.component';
import { AdminBoardService } from 'src/app/services/adminboard/admin-board.service';

type UserData = {
  userId: string,
  email: string,
  saEditRights: boolean,  
}

@Component({
  selector: 'tsm-user-profile-edit-sa-access',
  templateUrl: './user-profile-edit-sa-access.component.html',
  styleUrls: ['./user-profile-edit-sa-access.component.sass']
})
export class UserProfileEditSaAccessComponent implements OnInit, AfterContentChecked {
  public backUrl = "supplier-admin-board";
  public user: UserData = {
    userId: '',
    email: '',
    saEditRights: false
  };

  public translations = {
    checkboxLabel: '',
  }

  @ViewChild(UserProfileContainerComponent)
  private userProfileContainer: UserProfileContainerComponent;
  private subsidiaryTsmId: string;

  constructor(
    private route: ActivatedRoute,
    private relationshipService: SupplierRelationshipService,
    private adminBoardService: AdminBoardService,
  ) { }

  public ngAfterContentChecked(): void {
    if (localStorage.getItem("multiLanguage")) {
      const multiLanguageJson =  JSON.parse(localStorage.getItem("multiLanguage"));

      if (multiLanguageJson.body) {
        const userProfileTranslations = multiLanguageJson.body.sharedComponent.userProfile;
        this.translations.checkboxLabel = userProfileTranslations.editSaRightsLabel;
      }
    }
  }

  public async ngOnInit(): Promise<void> {
    this.user.userId = this.route.snapshot.paramMap.get('userId');
    this.subsidiaryTsmId = this.route.snapshot.paramMap.get('subsidiaryId');

    const user = await this.adminBoardService.getAdminBoardLogItem(this.user.userId);
    const saEditRights = await this.relationshipService.subsidiaryUserCanAccessParentSA(this.subsidiaryTsmId, user.userName);

    this.user.email = user.userName;
    this.user.saEditRights = saEditRights;
  }

  public onCheckboxValueChanged(): void {
    this.userProfileContainer.onDataChanged(true);
  }

  public async onSaveButtonClicked(): Promise<void> {
    try {
      const currentUser = this.userProfileContainer.currentUser;
      const username = `${currentUser.firstName} ${currentUser.lastName}`;

      await this.relationshipService.updateSubsidiarySaAccessRights(this.subsidiaryTsmId, currentUser.email, this.user.saEditRights, username);

      this.userProfileContainer.onSaveSuccess();
    } catch (error) {
      console.log("Failed to update the SA access for the subsidiary", error);
    }
  }

}
