<div *ngIf="showEmptyMessage; else validationList" class="form empty-message">
  <img id="searchImage" src="assets/images/EmptyImage_Users.svg" alt="emptySearch" class="image" />
  <span *ngIf="!showErrorMesage" class="highlight-text">
    {{ translatedLabels }}
  </span>
  <span *ngIf="showErrorMesage" class="highlight-text-error">
    {{ errorMessage }}
  </span>
</div>
<ng-template #validationList>
  <div class="form ">
    <div class="download-button-container">
      <div class="d-flex " style="margin-top:30px;justify-content: space-between;">
        <div class="page-size-selection">
          <label for="pageSize">Page Size:</label>
          <select class="page-selection" id="pageSize" (change)="onPageSizeChange($event.target.value)">
            <option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</option>
          </select>
        </div>
        <sh-button ngbAutofocus [props]="deSelectAll"> </sh-button>
        <sh-button ngbAutofocus [props]="selectAll"> </sh-button>
        <sh-button ngbAutofocus [props]="reTriggerSABtn">
        </sh-button>
      </div>
    </div>
    <div *ngIf="rows.length" class="grid-table-container shadow border mt-3 p-3 bg-white mx-3">
      <ngx-datatable class="bootstrap"
        [rows]="rows | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: rows && rows.length }"
        [columns]="columns" [columnMode]="ColumnMode.force" [footerHeight]="100" [headerHeight]="50" rowHeight="auto">
        <ngx-datatable-column *ngFor="let col of columns; let columnIndex = index" [prop]="col.prop"
          [width]="col.width">
          <ng-template ngx-datatable-header-template let-colIndex="colIndex">
            <ng-contianer>
              <div class="column-title" [ngStyle]="col.name === 'SELECTED' ? {'text-align': 'center'}:''">{{ col.name }}
              </div>
            </ng-contianer>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div class="cell-data">
              <span *ngIf="col.prop === 'supplierName'">
                <a tabindex="0" class="btn btn-link p-0 text-start supplier-name"
                  (click)="openSupplier(row.supplierTsmId)"
                  (keydown.enter)="openSupplier(row.supplierTsmId)">{{value}}</a>
              </span>

              <span
                *ngIf="value !== 'action1' && value !== 'action2' && value !== 'action3' && col.prop !== 'supplierName'">{{
                value }}</span>
              <ng-contianer *ngIf="value === 'action1'">
                <div class="dropdown-wrapper">
                  <tsm-single-drop-select 
                    [placeholder]="'Select One'" [drapDownData]="requestPurpose"  [disable]="true"
                    [choiceDate]="requestPurposeData">
                  </tsm-single-drop-select>
                </div>

              </ng-contianer>
              <ng-contianer *ngIf="value === 'action2'">
                <div class="dropdown-wrapper">
                  <tsm-single-drop-select [placeholder]="'Select One'"
                    (SingleDateSend)="onDropdownSelected(row, $event)" [drapDownData]="updateReasonData"
                    [choiceDate]="updateReasonSelected">
                  </tsm-single-drop-select>
                </div>

              </ng-contianer>
              <ng-contianer *ngIf="value === 'action3'">
                <div class="checkbox-wrap">
                  <br />
                  <input class="checkbox" type="checkbox" id="checkforAll-{{row.taskId}}"
                    [(ngModel)]="checkBoxState[row.taskId]" (change)="onCheckboxChange(row, $event)" />
                  <label class="label-margin form-label" for="checkforAll-{{row.taskId}}"></label>

                </div>

              </ng-contianer>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template>

            <div class="d-flex justify-content-center">
              <pagination-controls [directionLinks]="true" previousLabel="" nextLabel=""
                (pageChange)="onPageChange($event)">
              </pagination-controls>
            </div>

          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
  
  <div class="loadEffect" style="position: fixed;left: 44%;top:30%;z-index: 1000;" *ngIf="loading">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</ng-template>