<div
  class="card-container row row-cols-lg-3 row-cols-md-2 row-cols-sm-1 mx-5 px-2"
>
  <div *ngFor="let supplier of suppliers" class="px-2 py-3">
    <tsm-card
      [supplier]="supplier"
      [enableIndirectTag]="enableIndirectTag"
    ></tsm-card>
  </div>
</div>
