import { Injectable } from "@angular/core";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { QuestionFilters } from "src/app/models/Filters";
import { SubheaderPropsType } from "../../../models/SubheaderProps";
import { EsgQuestionnaireService, ConfirmSupplierManageUsersComponent } from "@lsl16/sushub-frontend";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProfileConfig } from "../../profile-details/profile";
import { DropdownItemPropsType, SubheaderMultipleDropdownPropsType } from "src/app/models/SubheaderMultipleDropdown";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SupplierService } from "src/app/services/supplier/supplier.service";
import { countryList } from 'src/utilities/const/country-list';
import _ from "lodash";

@Injectable()
export class EsgQuestionnaireSubheader {
  countries: DropdownItemPropsType[];
  defaultCountryServed: string;
  initStatus: string = "not yet initiated";
  countryServed: string[];
  supplierTsmId: string;
  selectedCountries: string[];
  prevDefaultCountryServed: string;
  prevSupplierTsmId: string;

  constructor(
    private supplierEsgQuestionnaireService: EsgQuestionnaireService,
    private ngbModalService: NgbModal,
    private supplierProfileService: SupplierGeneralProfileService,
    private route: ActivatedRoute,
    private supplierService: SupplierService,
    private authService: AuthService,
  ) {
  }

  supplierManageUsersModalData: ProfileConfig = {
    type: "supplier-manage-users",
    options: {},
    supplier: {},
  };

  sharedButtonProps: ButtonPropsType = {
    label: '',
    fontSize: 16,
    height: 45,
    padding: '10px',
  };

  esgSaveCloseButton: ButtonPropsType = {
    ...this.sharedButtonProps,
    label: "Save & Close",
    color: "white",
    backgroundColor: "blue",
    borderColor: "blue",
    hoverColor: "white",
    hoverBackgroundColor: "darkBlue",
    hoverBorderColor: "darkBlue",
    icon: "assets/icons/chevron-down-purple.svg",
    clickIcon: "assets/icons/chevron-down-purple.svg",
    iconPosition: "left",
    iconAlt: "icon-chevron-down",
    onClick: () => this.saveAndClose()
  }

  esgAllQuestionseButton: ButtonPropsType = {
    ...this.sharedButtonProps,
    label: 'All Questions',
    color: 'blue',
    backgroundColor: 'white',
    borderColor: 'blue',
    class: 'active',
    onClick: () => this.filterQuestions(QuestionFilters.All),
  };

  esgUnansweredButton: ButtonPropsType = {
    ...this.sharedButtonProps,
    label: 'Unanswered',
    color: 'greyDark',
    backgroundColor: 'white',
    borderColor: 'greyDark',
    class: 'inactive',
    onClick: () => this.filterQuestions(QuestionFilters.UnAnswered),
  };

  esgAnsweredButton: ButtonPropsType = {
    ...this.sharedButtonProps,
    label: 'Answered',
    color: 'greyDark',
    backgroundColor: 'white',
    borderColor: 'greyDark',
    class: 'inactive',
    onClick: () => this.filterQuestions(QuestionFilters.Answered),
  };

  manageUsersButton: ButtonPropsType = {
    ...this.sharedButtonProps,
    label: 'Manage Users',
    disabled: false,
    color: "purple",
    backgroundColor: "lightPurple",
    borderColor: "lightPurple",
    hoverColor: "purple",
    hoverBackgroundColor: "lightPurple",
    hoverBorderColor: "lightPurple",
    tooltipText: "manageUsersTooltip",
    onClick: () => this.openConfirmPopupRedirectToAdminBoard(),
  };

  countryDropdown: SubheaderMultipleDropdownPropsType =
    {
      checkedChanged: (event, kv: any) => this.onCountrySelected(event, kv)
    };

  /**
   * Description: Provides props for subheader component in supplier landing page
   * Params: none
   * Output: Subheader props object of type SubheaderPropsType
   */
  getProps = (): SubheaderPropsType => {
    this.updateManageUsersTooltip();
    this.resetFilter();
    return {
      isWelcomeMessage: false,
      headingPrefixColor: "purple",
      heading: "Sustainability Assessment",
      subheaderButtonProps: this.esgSaveCloseButton,
      manageUsersButtonProps: this.manageUsersButton,
      allQuestionsButtonProps: this.esgAllQuestionseButton,
      unansweredButtonProps: this.esgUnansweredButton,
      answeredButtonProps: this.esgAnsweredButton,
    };
  };

  private updateManageUsersTooltip() {
    const isOnlyGeneralSupplier = this.authService.isGeneralSupplier() && !this.authService.isSupplierAdmin();
    if (isOnlyGeneralSupplier) this.manageUsersButton.tooltipText = "generalSupplierManageUsersTooltip";
  }

  saveAndClose() {
    this.supplierEsgQuestionnaireService.eventEmit.emit("saveAndClose");
  }

  /**
  * Description: open ConfirmPopup to redirect to the admin board
  * Params: none
  */
  openConfirmPopupRedirectToAdminBoard() {
    const confirmType = this.supplierManageUsersModalData;
    const modalReference = this.ngbModalService.open(
      ConfirmSupplierManageUsersComponent,
      confirmType.options
    );
    modalReference.componentInstance.confirmType = confirmType;
  }

  filterQuestions(selectedFilter) {
    this.updateStyles(selectedFilter);
    this.supplierEsgQuestionnaireService.appliedFilter.questions = selectedFilter;
    this.supplierEsgQuestionnaireService.eventEmit.emit('filter');
  }

  updateStyles(selectedFilter: any) {
    this.esgAllQuestionseButton.class = 'inactive';
    this.esgUnansweredButton.class = 'inactive';
    this.esgAnsweredButton.class = 'inactive';

    if (selectedFilter === QuestionFilters.All) {
      this.esgAllQuestionseButton.class = 'active';
    } else if (selectedFilter === QuestionFilters.UnAnswered) {
      this.esgUnansweredButton.class = 'active';
    } else if (selectedFilter === QuestionFilters.Answered) {
      this.esgAnsweredButton.class = 'active';
    }
  }

  initialiseSupplier = async (): Promise<void> => {
    const supplier = await this.supplierProfileService.getSupplierById(this.supplierTsmId);
    this.countryServed = supplier.countryServed;
  }

  loadCountries = async (): Promise<void> => {
    if (_.isEmpty(this.countries)) {
      await this.initialiseSupplier();
      this.countries = [];
      if (this.countryServed) {
        let result = this.countryServed.reduce((promise, country) => {
          return promise.then(async last => {
            const esgStatus = await this.getAssessmentStatus(this.supplierTsmId, country);
            const isValidEsgStatus = esgStatus != this.initStatus;
            const countryExists = this.findIfExists(country);
            if (isValidEsgStatus && countryExists < 0) {
              this.countries.push({ 'Code': country, 'Text': countryList['Data'].mapping[country], 'Status': esgStatus });
            }
          });
        }, Promise.resolve());
        result.then(e => {
          const globalExists = this.findIfExists("global");
          if (globalExists < 0) {
            let sortedCountries = _.orderBy(this.countries, ['Text'], ['asc']);
            this.countries = [{ Code: "global", Text: "Global", Status: "awaiting submission" }, ...sortedCountries]
          }
          this.countryDropdown.itemsToDisplay = this.countries;
          this.prepareFilterValues();
        });
      }
    }
    else if (this.defaultCountryServed != this.prevDefaultCountryServed) {
      this.prepareFilterValues();
    }
  }

  findIfExists(searchAttribute) {
    return _.findIndex(this.countries, (e) => {
      return e['Code'] === searchAttribute;
    });
  }

  prepareFilterValues() {
    if (!_.isEmpty(this.countries)) {
      this.selectedCountries = ['global', this.defaultCountryServed];
      this.countryDropdown.selectedKeys = this.selectedCountries;
      this.prevDefaultCountryServed = this.defaultCountryServed;
      this.prevSupplierTsmId = this.supplierTsmId;
      this.supplierEsgQuestionnaireService.appliedFilter.countries = this.selectedCountries;
      this.supplierEsgQuestionnaireService.appliedFilter.allCountries = this.countries;
    }
  }

  onCountrySelected(event, kv: any): void {
    this.countryDropdown.selectedKeys = event;
    this.selectedCountries = event;
    this.supplierEsgQuestionnaireService.appliedFilter.countries = this.selectedCountries;
    this.supplierEsgQuestionnaireService.eventEmit.emit('filter');
  }

  setDefaultCountryServed(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.defaultCountryServed = params["countryServed"];
      this.supplierTsmId = params["supplierTsmId"];
      this.resetCountryFilter();
    });
  }

  getAssessmentStatus = async (supplierTsmId: string, countryCode: string): Promise<string> => {
    if (countryCode === "") {
      return this.initStatus;
    }
    const esgstatus = await this.supplierService.getESGStatus(supplierTsmId, countryCode);
    if (!esgstatus || Object.keys(esgstatus.assessment).length == 0) {
      return this.initStatus;
    } else {
      return esgstatus?.assessment;
    }
  }

  resetCountryFilter() {
    if (this.defaultCountryServed != this.prevDefaultCountryServed || this.supplierTsmId != this.prevSupplierTsmId) {
      this.countryDropdown.selectedKeys = null;
      this.selectedCountries = [];
      this.countries = [];
    }
  }

  resetFilter() {
    if (_.isEmpty(this.supplierEsgQuestionnaireService.appliedFilter)) {
      this.updateStyles(QuestionFilters.All);
    }
  }

}
