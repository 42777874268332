<div class="row itemlist">
  <div class="col-3" style="padding-left: 2rem">
    <h6 class="subtitle">{{ labelTranslation.userName }}</h6>
  </div>
  <div class="col-4">
    <div class="subtitle">{{ labelTranslation.email }}</div>
  </div>
  <div class="col-3">
    <h6 class="subtitle" style="padding-left: 6px">
      {{ labelTranslation.role }}
    </h6>
  </div>
  <div class="col-2"></div>
</div>

<div *ngIf="!searchExecuted" class="centered-content">
  <img
    class="empty-form-image"
    src="assets/images/EmptyImage_Users.svg"
    style="height: 128px"
    alt="image-empty-users"
  />
  <div style="margin-top: 20px" class="highlight-text">
    {{ labelTranslation.emptyMessage }}
  </div>
</div>

<div *ngIf="searchExecuted && noUsersFound" class="centered-content">
  <img
    class="empty-form-image"
    src="assets/images/EmptyImage_Search.svg"
    style="height: 128px"
    alt="image-empty-search"
  />
  <div style="margin-top: 20px" class="highlight-text">
    {{ labelTranslation.noResultMessage }}
  </div>
</div>

<div *ngIf="searchExecuted && serverError" class="centered-content">
  <img
    class="empty-form-image"
    src="assets/images/EmptyImage_Users.svg"
    style="height: 128px"
    alt="image-empty-users"
  />
  <div style="margin-top: 20px" class="highlight-text">
    {{ labelTranslation.errorMessage }}
  </div>
</div>

<div
  *ngFor="let user of users"
  style="border-bottom: solid 1px rgba(78, 78, 135, 0.2)"
>
  <tsm-system-admin-board-user-item
    [model]="user"
    [currentLoginEmail]="currentLoginEmail"
  ></tsm-system-admin-board-user-item>
</div>
