import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsm-internal-poc-email-filter',
  templateUrl: './internal-poc-email-filter.component.html',
  styleUrls: ['./internal-poc-email-filter.component.sass']
})
export class InternalPocEmailFilterComponent implements OnInit {
  @Output() valueChanged = new EventEmitter();
  @Input() clearFilter: Subject<boolean>;
  @Input() invalidValue: Subject<string>;

  internalPocEmail = '';
  invalid = false;

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: '',
    invalidMsg: ''
  }

  constructor() { }

  ngOnInit(): void {
    this.clearFilter.subscribe(() => this.clear());
    this.invalidValue.subscribe((event) => this.handleInvalid(event));
  }
  inputChanged() {
    this.invalid = false;
    this.valueChanged.emit(this.internalPocEmail);
    let printablevalue= this.internalPocEmail;
    printablevalue=printablevalue.replace(/[\x00-\x1F]+/g, ' ');
    this.internalPocEmail = printablevalue; 
  }
  clear() {
    this.invalid = false;
    this.internalPocEmail = '';
  }
  handleInvalid(event) {
    if (event === 'internalPoCEmail')
      this.invalid = true;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.internalPocEmail;
      }
    }
  }
}
