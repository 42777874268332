import { RoleResolutionService, UserRoleDependencies } from 'src/app/services/roleResolution/role-resolution.service';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { InternalUser } from 'src/app/models/InternalUser';
import { environment } from "src/environments/environment";
import { HttpService } from "@lsl16/sustainability-shared-components";
import _ from "lodash";

@Component({
  selector: 'tsm-system-admin-user-profile-detail',
  templateUrl: './system-admin-user-profile-detail.component.html',
  styleUrls: ['./system-admin-user-profile-detail.component.sass']
})

export class SystemAdminUserProfileDetailComponent implements OnInit {
  props: ButtonPropsType;
  @Input() user: InternalUser;
  @Input() roles: any[];
  @Output() outerEvent = new EventEmitter<string>();
  open = false;
  displayRoles: string;
  systemAdmin: string;
  roleDependencies: UserRoleDependencies;
  @ViewChild('dropPanel') dropPanel: ElementRef;

  constructor(roleResolutionService: RoleResolutionService ,private httpService: HttpService,) { 
    this.systemAdmin = roleResolutionService.getProfileTypeMap()[4];
    this.roleDependencies = roleResolutionService.getRoleDependencies();
  }

  ngOnInit(): void {
    this.setCheckedRoles();
    this.props = this.getButtonProps();
  }

  getButtonProps(): ButtonPropsType {
    return {
      label: "Save Changes",
      color: "purple",
      fontSize: 16,
      fontFamily: "Graphik-Medium",
      backgroundColor: "paleGrey",
      borderColor: "paleGrey",
      hoverColor: "purple",
      hoverBackgroundColor: "state-button-contained-secondary-b-h",
      hoverBorderColor: "state-button-contained-secondary-b-h",
      height: 48,
      width: 170,
      padding: "16px 8px",
      iconPosition: "left",
      disabled: false,
      onClick: () => this.saveRole()
    };
  }

  setCheckedRoles() {
    const checkedRoles = this.user.roles;
    this.roles.map(role => {
      role.permissions.map(permission => {
        if ((role.profileName === this.systemAdmin && this.user.isSystemAdmin) ||
          checkedRoles[role.profileId]?.includes(parseInt(permission.roleId))) {
          permission.checked = true;
        }
        else {
          permission.checked = false;
        }
      })
    })
    this.setDisplayRoles();
  }

  async setDisplayRoles() {
    let selectedPermissions = [];
    _.flatMap(this.roles, "permissions").map(role => {
      if (role.checked) {
        selectedPermissions.push(role["roleName"])
      }
    })
    if(localStorage.getItem('MyAccessToggle')==="true"){
      this.roles.forEach((item)=>{
        item.permissions.forEach((i)=>{
          if(i.roleDescription === "Delegated Representative"
          ||i.roleDescription === "Sustainability Expert"
          ||i.roleDescription === "System Supplier Admin"
          ||i.roleDescription === "TSM Supplier Admin"
          ||i.roleDescription === "Buyer Expert"){
            i.disabled = true
          }else{
            i.disabled = false
          }
        })
      })
    }
    this.displayRoles = selectedPermissions.join(", ");
  }

  checkedChanged(profileId: string, permission: any) {
    const role = this.roles.find((role) => role.profileId === profileId);
    const target = role.permissions.find((per) => per.roleId === permission.roleId);
    target.checked = !target.checked;
    if (role.profileName === this.systemAdmin) {
      this.user.isSystemAdmin = target.checked;
    }
    else {
      const topDown = _.find(this.roleDependencies.topDown, { profileId: Number.parseInt(profileId), roleId: target.roleId })
      const bottomUp = _.find(this.roleDependencies.bottomUp, { profileId: Number.parseInt(profileId), roleId: target.roleId })
     
      let selectedRoles = [];
      role.permissions.map(role => {
        if (role.checked) {
          selectedRoles.push(parseInt(role["roleId"]))
        }
      })
      this.user.roles[profileId] = selectedRoles.sort();
    }
    this.setDisplayRoles();
  }

  /**
    * Saves the user's role
   */
  saveRole(): void {
    if (!this.user.roles) {
      return;
    }
    const outEventData = {
      opt: "updateUserRole",
      roles: this.user.roles,
      displayRoles: this.displayRoles
    };
    this.outerEvent.emit(JSON.stringify(outEventData));
  }

  /**
   * Toggles the dropdown box
   * @returns: void
   */
  toggleDropdown(): void {
    this.open = !this.open;
  }

  @HostListener('document:click', ['$event'])
  documentClick(evt) {
    if (this.dropPanel.nativeElement.contains(evt.target)) {
      return;
    }
    if (this.open) {
      this.open = false;
    }
  }
}

