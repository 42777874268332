export enum RAStatusCode {
    pendingReview = "Pending Review",
    materialityDetermined = "Materiality Determined",
    dueDiligenceCompleted = "Due Diligence Completed"
}

export enum RAStatusText{
    pendingReview = "Pending Review",
    materialityDetermined = "Materiality Determined",
    dueDiligenceCompleted = "Due Diligence Completed"
}
