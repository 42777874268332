<div
  class="notification-container"
  [ngClass]="getContainerStyling()"
>
  <div *ngIf="mode === 'unread'">
    <div class="row row-cols-auto header-panel me-0 ms-0">
      <div class="width88">
        <div class="header">{{ translations.headerTitle }}</div>
      </div>
      <div class="float-end close-panel cursor-pointer " tabindex="0" (keydown.enter)="closeNotificationPanel()">
        <img
          class="float-end close-notification"
          (click)="closeNotificationPanel()"
          src="assets/icons/close.svg"
          alt="close Notification"
        />
      </div>
    </div>
    <div class="notification-list-panel">
      <div
        *ngIf="notifications && notifications.length === 0"
        class="empty-notification-panel"
      >
        {{ translations.emptyNotificationMsg }}
      </div>
      <div class="scroll">
        <tsm-notification-list
          [notifications]="notifications"
        ></tsm-notification-list>
      </div>
    </div>
    <div class="row navigate-link" *ngIf="archivedNotificationsCount > 0">
      <div tabindex="0" (keydown.enter)="goToArchive()" (click)="goToArchive()" class="view-archive cursor-pointer ">{{ translations.archiveLink }}</div>
      <img
        class="info-icon cursor-pointer"
        placement="bottom"
        tooltipClass="view-archive-info manage-user-tooltip-class"
        triggers="click:blur"
        src="assets/icons/info-blue.svg"
        alt="icon-info"
        [ngbTooltip]="archiveHint"
      />
      <ng-template #archiveHint>
        <div class="tip-inner-content">
          {{ translations.archivedMovedHint }}
        </div>
      </ng-template>
    </div>
  </div>

  <div *ngIf="mode === 'archive' ">
    <div class="row row-cols-auto header-panel me-0 ms-0">
      <div class="width88">
        <div class="header">
          <div (click)="back()" class="back-to-notification cursor-pointer">
            <div class="archive-header">
              <img
                class="back-icon"
                src="assets/icons/back-arrow.svg"
                alt="icon-back"
              />
              {{ translations.headerTitleArchived }}
            </div>
          </div>
          <img
            class="info-icon cursor-pointer "
            placement="bottom"
            tooltipClass="archive-info manage-user-tooltip-class"
            triggers="click:blur"
            src="assets/icons/info-blue.svg"
            alt="icon-info"
            [ngbTooltip]="archiveHint"
          />
          <ng-template #archiveHint>
            <div class="tip-inner-content">
              {{ translations.archivedDeletedHint }}
            </div>
          </ng-template>
        </div>
      </div>
      <div class="float-end close-panel cursor-pointer">
        <img
          class="float-end close-notification"
          (click)="closeNotificationPanel()"
          src="assets/icons/close.svg"
          alt="close Notification"
        />
      </div>
    </div>
    <div class="notification-list-panel">
      <div
      *ngIf="archivedNotificationsCount === 0"
      class="empty-notification-panel"
      >
        {{ translations.emptyArchiveMsg }}
      </div>      
    </div>    
    <div class="notification-list-panel archived-notifications" >
      <div class="scroll">
        <tsm-notification-list
          [notifications]="archivedNotifications"
          [mode]="mode"
        ></tsm-notification-list>
      </div>
    </div>
  </div>
</div>
