import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { BuyerService } from 'src/app/services/buyer/buyer.service';
import { RiskAssessmentInfo } from '../../models/riskAssessment';
import VMController from '../../models/vm';
import { ButtonPropsType,DynamicFormRaComponent, CommonService,cloneDeep,trim, ComponentConfig,DynamicRaContext, DictionaryRaService, ButtonCommands, DialogService, DialogType, HttpService } from '@lsl16/sustainability-shared-components';
import { environment } from 'src/environments/environment';
import { RiskassessmentService } from 'src/app/services/riskassessment/riskassessment.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PopUpBibdaScoreComponent } from '../pop-up-bibda-score/pop-up-bibda-score.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'tsm-risk-assessment-questionnaire',
  templateUrl: './risk-assessment-questionnaire.component.html',
  styleUrls: ['./risk-assessment-questionnaire.component.sass']
})
export class RiskAssessmentQuestionnaireComponent {

  showAlertPanelO = false;
  showAlertPanel1 = false;
  isDraft = true;
  env = environment.env;
  activeDes = "";
  getConfigsInfo: ComponentConfig[] = [];
  raFormMapping = [];
  context: DynamicRaContext;
  metaDataValues = {};
  shortDesc = "";
  questionsLoaded = false;
  mode = "";
  @ViewChild("dynamicForm") dynamicForm: DynamicFormRaComponent;
  get riskAssessmentInfo(): RiskAssessmentInfo {
    return this.raService.raModel.RequestForm.RiskAssessmentInfo;
  }
  
  get supplierCode(): string {
    return this.raService.raCode;
  }

  onboardInfo: any;

  vm: VMController<RiskAssessmentInfo> = new VMController();
  isResubmitDisabled = true;
  isInformationSecurityShow = false;
  cid = "";
  scid = "";
  ic = "";

  sharedButtonProps: ButtonPropsType = {
    label: "",
    fontSize: 16,
    height: 48,
    padding: "10px",
    margin: "0 0 10px 0"
  };
  saveDraftRaProps: ButtonPropsType;
  submitRaProps: ButtonPropsType;
  reviewbibda: ButtonPropsType;
  raId = 0;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private dialogService: DialogService,
    private raService: RiskassessmentService,
    private route: ActivatedRoute,
    private HttpService: HttpService,
    private authService: AuthService,
    public ngbModalService: NgbModal,
    config: NgbModalConfig,
  ) {
    config.backdrop = "static";
  }

  CancelRaProps: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.cancel();
    }
  };

  async ngOnInit() {
    window.scrollTo(0, 0);
    this.buttonType();
    this.cid = this.route.snapshot.params.cid;
    this.scid = this.route.snapshot.params.scid;
    this.ic = this.route.snapshot.params.ic;
    this.raId = Number(this.route.snapshot.params.code);
    this.metaDataValues = this.raService.raMetadata;
    // workflow load current login user's tasks
    
    this.context = new DynamicRaContext();
    this.context.mode = this.raService.mode;
    this.context.set(
      "parentModel",
      cloneDeep(this.raService.raModel)
    );
    this.context.set('RelationshipIDExists', false)

    // adding this timeout to make the bi-bda popup appear
    await new Promise(f => setTimeout(f, 1000));

    if(this.raService.mode ==="view" || this.raService.mode ==="edit"){
      this.shortDesc = this.raService.shortDescription
    }
    this.raFormMapping = this.raService.raQuestion.data;
    if (this.raFormMapping) {
      this.questionsLoaded = true
    } else {
      this.questionsLoaded = false
    }
    for(let i = 0; i<this.raFormMapping.length; i++){
      if(this.raFormMapping[i].hide && this.raFormMapping[i].id!= "InformationSecurity"){
        for(let j = 0; j<this.raFormMapping[i].fieldset.length; j++){
          this.raFormMapping[i].fieldset[j].hide = true;
        }
      }
      if (this.raFormMapping[i].id == 'InformationSecurity') {
        let hideInfoSec = this.raFormMapping[i].hide;
        if ((this.scid == '5002' || this.scid == '5021') && this.ic == 'Yes') {
          hideInfoSec = true;
        } else if (
          (this.scid == '5002' || this.scid == '5021') &&
          this.ic == 'No'
        ) {
          hideInfoSec = false;
        }
        this.isInformationSecurityShow = !hideInfoSec;
        this.raFormMapping[i].hide = hideInfoSec;
        if (hideInfoSec) {
          for (let j = 0; j < this.raFormMapping[i].fieldset.length; j++) {
            this.raFormMapping[i].fieldset[j].hide = true;
          }
        }

        // add dynamic value in valodators
        for (let j = 0; j < this.raFormMapping[i].fieldset.length; j++) {
          if (
            this.raFormMapping[i].fieldset[j].name ===
            'InformationSecurity_1Yes'
          ) {
            this.raFormMapping[i].fieldset[j].templateOptions.maxLength = 10;
            this.raFormMapping[i].fieldset[j].errorMessage = {RelationshipIDExists : "Record does not exist"}
            this.raFormMapping[i].fieldset[j].validateOn = 'change';
            this.raFormMapping[i].fieldset[j].validators = [
              {
                name: 'RelationshipIDExists',
              },
            ];
          }
        }
      }
    }
    this.getConfigsInfo = this.raFormMapping;
    this.dynamicForm.subscribeToModelChange("BI_BDA_1", async (value) => {
      if (value === "11" || value === "12") {
        await this.dialogService.dialog(
          `Business Sponsor must complete the BI questionaire within the BI Portal. <a href="https://ethicscompliancehub-bip.accenture.com/" target="_blank" style="color: #006caf; font-weight: 600; text-decoration: underline">Click</a> 
          for access to the BI Portal and for more information on the BI vetting process`,
          ButtonCommands.Close,
          DialogType.warning,
          "Please Note"
        );
      }
    });
    this.dynamicForm.subscribeToModelChange(
      "InformationSecurity_1f",
      async (value) => {
        if (value) {
          let tmpMessage;
          if (value === "11") {
            tmpMessage = `By selecting ‘Yes’ to this question you are indicating that as a requirement of the services,
                      data can and will be moved between physical locations which could include geographical boundaries.
                      This requirement is independent of additional controls that may exist to prevent the unauthorized movement of data outside of Accenture or Client applications,
                      environments or infrastructure.`;
          } else if (value === "10") {
            tmpMessage = `By selecting ‘No’ to this question you are indicating the supplier is working within an Accenture
                      or client environment where the scope of services does not require data movement and controls ARE
                      implemented to prevent unauthorized data movement outside of the applications, environment or infrastructure.`;
          }
          await this.dialogService.dialog(
            tmpMessage,
            ButtonCommands.Close,
            DialogType.warning,
            "Please Note"
          );
        }
      }
    );
    this.dynamicForm.subscribeToModelChange("AI1", async (value) => {
      if (value === "11") {
        await this.dialogService.dialog(
          `Please be aware that you may be contacted to complete extra AI questionnaires, and suppliers will have extra due diligence to complete to ensure we comply with regulation`,
          ButtonCommands.Close,
          DialogType.warning,
          "Please Note"
        );
      }
    });

    this.commonService.ExitEvent.subscribe((e) => {
      this.vm.setOriginal(this.dynamicForm.originalValues());
      this.vm.setCurrent(this.dynamicForm.values());
      if (this.vm.isDirty()) {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });
  }

  //To check the logged in user has BI role
  getBIRole = () => {
    if (this.authService.isBI()) {
      return true
    }
    return false
  }

  //To make the BI/BDA review button enable only for applicable RA's
  getBiBdaStatus = () => {
    let bibdaReviewStatus;
    this.route.queryParams.subscribe((params: Params) => {
      bibdaReviewStatus = params["TBR"];
    });
    return bibdaReviewStatus == "1" ? true : false;
  }

  buttonType(){
    this.saveDraftRaProps = {
      ...this.sharedButtonProps,
      width: 230,
      color: "white",
      backgroundColor: "blue",
      borderColor: "blue",
      label: "Save as draft",
      onClick: () => {
        this.isDraft = true;
        this.questionsLoaded = false
        this.getRiskAssessmentPayload(this.dynamicForm);
      },
    };
    this.submitRaProps = {
      ...this.sharedButtonProps,
      width: 230,
      color: "white",
      backgroundColor: "blue",
      borderColor: "blue",
      label: "Submit",
      onClick: () => {
        this.isDraft = false;
        this.getRiskAssessmentPayload(this.dynamicForm);
      },
    };
    this.reviewbibda = {
      ...this.sharedButtonProps,
      width: 230,
      color: "white",
      backgroundColor: "blue",
      borderColor: "blue",
      label: "Review BI/BDA",
      onClick: () => {
        this.evaluateBibda()
      },
      };
    }

  get ShowResubmitButton() {
    return false;
  }


  navigate(item) {
    const element = document.getElementById(item);
    window.scroll(0, element.parentElement.offsetTop);
  }

  validate() {
    return this.dynamicForm.valide();
  }
  
  async getRiskAssessmentPayload(form) {
    if(this.isDraft == false){
      let InformationSecurity_1Yes = form.formGroup.value.InformationSecurity_1Yes
      let InformationSecurity_1 = form.formGroup.value.InformationSecurity_1

      if(InformationSecurity_1 === '11' && InformationSecurity_1Yes !== '') {
        InformationSecurity_1Yes = InformationSecurity_1Yes.toUpperCase()
        await this.checkRiskAssessment(InformationSecurity_1Yes).then((res: any) => {
          if (res.data === undefined) {
            let currentError = { 'RelationshipIDExists': true }
            this.dynamicForm.formGroup.controls['InformationSecurity_1Yes'].setErrors(currentError);
          }
        })
      }

    
      this.context.changeSubmitState();
      this.dynamicForm.removeFormTouch();
    }

   
    
    if(this.isDraft == false && !this.validate() ){
      return;
    }
    const riskAssessmentPayload = []
    let selectedValues = form.formGroup.value;
    const dataPrivacyForm = await this.getDataPrivacyDetails(selectedValues);
    const biBdaForm = await this.getBiBdaDetails(selectedValues);
    const infoSecForm = await this.getInfoSecDetails(selectedValues);
    const brForm = await this.getBusinessResilienceDetails(selectedValues);
    const bcForm = await this.getBusinessCapability(selectedValues);
    const asForm = await this.getAssetSafety(selectedValues);
    const aiForm = await this.getArtificialIntelligence(selectedValues);
    const metatdataInfo = await this.getMetadataInfo();
    riskAssessmentPayload.push(dataPrivacyForm, biBdaForm, infoSecForm, brForm, bcForm, asForm, aiForm);
    const finalPayload = {
      "ra_id": this.raId,
      "metatdataInfo": metatdataInfo,
      "riskAssessmentAnswers": riskAssessmentPayload
    }
    this.submitRiskAssessment(finalPayload);
  }

  async submitRiskAssessment(payload): Promise<void> {
    const triggerRA = `${environment.tsmBackendServiceURL}/riskAssessmentData/riskAssessmentSubmit`;
    let RA = await this.HttpService.PostPromise(triggerRA,payload).then((res: any) => {
      if (res.msg == "Success") {
        this.router.navigate([`supplier-profile/${this.metaDataValues["tsmId"]}`]);
      }
    });
    
  }

  public async checkRiskAssessment(relationshipNumber: string): Promise<any> {
    const url = `${environment.tsmBackendServiceURL}/riskAssessmentData/validateRelationshipId?relationshipnumber=${relationshipNumber}`;   
    return await this.HttpService.GetPromise(url);
  }

  getMetadataInfo() {
    var metatdataInfo = {
      isdraft: this.isDraft,
      assessment_status: this.isDraft ? 'In Progress' : 'Submitted',
      short_desc: this.shortDesc,
    };
    if (this.raId === 0) {
      metatdataInfo['category_code'] = this.metaDataValues['categoryCode'];
      metatdataInfo['sub_category_code'] =
        this.metaDataValues['subCategoryCode'];
      metatdataInfo['tsm_id'] = this.metaDataValues['tsmId'];
      metatdataInfo['requestor_name'] = this.metaDataValues['requestorName'];
      metatdataInfo['market_unit'] = this.metaDataValues['marketUnit'];
      metatdataInfo['country_served'] = this.metaDataValues['countryServed'];
      metatdataInfo['category'] = this.metaDataValues['category'];
      metatdataInfo['sub_catergory'] = this.metaDataValues['subCategory'];
      metatdataInfo['supplier_manager'] = this.metaDataValues['supplierPoc'];
      metatdataInfo['supplier_name'] = this.metaDataValues['supplierName'];
      metatdataInfo['is_independent_contractor'] = this.metaDataValues['independentContractor'];
      metatdataInfo['supplier_contact_first_name'] = this.metaDataValues['supplierContactFirstName'];
      metatdataInfo['supplier_contact_last_name'] = this.metaDataValues['supplierContactLastName'];
    }
    return metatdataInfo;
  }

  getDataPrivacyDetails(value) {
    let dataPrivacy = {};
    this.raFormMapping.forEach((section) => {
      if(section.id == 'Data_Privacy') {
        section.fieldset.forEach((question) => {
          dataPrivacy[question.name] = value[question.name];
        })
      }
    })
    const dp = {
      "dataPrivacy" : dataPrivacy
    }
    return dp;
  }

  getBiBdaDetails(value) {
    let BI_BDA = {};
    this.raFormMapping.forEach((section) => {
      if(section.id == 'BI_BDA') {
        section.fieldset.forEach((question) => {
          if (question.name === "BI_BDA_1bEndDate" || question.name === "BI_BDA_1bStartDate") {
            value[question.name] = value[question.name] === '' ? '' : new Date(value[question.name]).toLocaleDateString("en-US");
          }
          BI_BDA[question.name] = value[question.name];
        })
      }
    })
    const biBda = {
      "BI_BDA" : BI_BDA
    }
    return biBda;
  }

  getInfoSecDetails(value) {
    let InformationSecurity = {};
    this.raFormMapping.forEach((section) => {
      if(section.id == 'InformationSecurity') {
        section.fieldset.forEach((question) => {
          if(question.name === 'InformationSecurity_1Yes') {
            value[question.name] = value[question.name].toUpperCase();
          }
          InformationSecurity[question.name] = value[question.name];
        })
      }
    })
    const infoSec = {
      "InformationSecurity" : InformationSecurity
    }
    return infoSec;
  }

  getBusinessResilienceDetails (value) {
    let BusinessResilience = {};
    this.raFormMapping.forEach((section) => {
      if(section.id == 'BusinessResilience') {
        section.fieldset.forEach((question) => {
          BusinessResilience[question.name] = value[question.name];
        })
      }
    })
    const br = {
      "BusinessResilience": BusinessResilience
    }
    return br;
  }

  getBusinessCapability (value) {
    let BusinessCapability = {};
    this.raFormMapping.forEach((section) => {
      if(section.id == 'BusinessCapability') {
        section.fieldset.forEach((question) => {
          BusinessCapability[question.name] = value[question.name];
        })
      }
    })
    const bc = {
      "BusinessCapability": BusinessCapability
    }
    return bc;
  }

  getAssetSafety (value) {
    let AssetSafety = {};
    this.raFormMapping.forEach((section) => {
      if(section.id == 'AssetSafety') {
        section.fieldset.forEach((question) => {
          if(question.name === "AS1"){
            if(Array.isArray(value[question.name])){
              value[question.name] = value[question.name].join();
            }
          }
          AssetSafety[question.name] = value[question.name];
        })
      }
    })
    const as = {
      "AssetSafety": AssetSafety
    }
    return as;
  }

  getArtificialIntelligence(value) {
    let ArtificialIntelligence = {};
    this.raFormMapping.forEach((section) => {
      if(section.id == 'ArtificialIntelligence') {
        section.fieldset.forEach((question) => {
          ArtificialIntelligence[question.name] = value[question.name];
        })
      }
    })
    const ai = {
      "ArtificialIntelligence" : ArtificialIntelligence
    }
    return ai;
  }

  // update supplier mapping info
  private async updateSupplierMapping() {
    this.raService.raModel.RequestForm.RiskAssessmentInfo = trim(
      this.dynamicForm.values()
    );
  }
  // resubmit request form
  async resubmitForm(): Promise<boolean> {
    return true;
  }

  async resubmit() {
    this.resubmitForm().then((data: boolean) => {
      if (data) {
        this.vm.saved();
        this.dialogService
          .dialog(
            `Your change request has been successfully submitted.`,
            ButtonCommands.Ok,
            DialogType.success,
            "Please Note"
          )
          .then((result: boolean) => {
            this.router.navigate([`buyer`]);
          });
      }
    });
  }

  async cancel(): Promise<any> {
    this.router.navigate([`supplier-profile/${this.metaDataValues["tsmId"]}`]);
  }

  evaluateBibda = async () => {
    const confirmType = {
      options: {},
      centered: true,
      size: "lg",
      windowClass: "modal-country-notify",
      bibdaCaseId: this.riskAssessmentInfo.BI_BDA_1a,
      raId:this.raId,
      raFormMapping: this.raFormMapping,
      riskAssessmentInfo:this.riskAssessmentInfo
    };
    const modalReference = this.ngbModalService.open(PopUpBibdaScoreComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
  }

}
