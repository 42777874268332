<div class="main-container">
  <div *ngIf="previewState!=='RecordsFound';else recordPreview" class="search-message-container">
      <img id="searchImage" *ngIf="previewState == 'initial' || previewState == 'NoRecordsFound'"
          src="assets/images/EmptyImage_Users.svg" alt="emptyUsers" class="image" />
      <img id="timeoutImage" *ngIf="previewState == 'TimeoutError'"
          src="assets/images/EmptyImage_Too-many-records.svg" alt="search error" class="image" />

      <div id="initialMessage" *ngIf="previewState == 'initial'" class="highlight-text">
          {{translatedLabels.noSearchMessage}}
      </div>
      <div id="emptySearchMessage" *ngIf="previewState == 'NoRecordsFound'" class="highlight-text">
          {{translatedLabels.emptySearchMessage}}</div>
      <div id="timeoutMessage" *ngIf="previewState == 'TimeoutError'" class="highlight-text-error">
          {{translatedLabels.timeoutErrorMessage}}</div>
  </div>

  <ng-template #recordPreview>
      <div class="download-button-container">  
        <div class="row align-self-center">
            <div *ngIf="showTimeoutError" class="align-self-center error-container">
                <img src="assets/icons/Critical.svg" id="errorIcon" class="error-icon">
                <div id="errorMessage" class="error-message">{{timeoutErrorLabel}}</div>
            </div>          
            <sh-button [props]="downloadButton"></sh-button>
        </div>          
      </div>
      <div *ngIf="reportPreview?.answerdata.length > 100" class="info-message-container">
          <img src="assets/icons/icon-info-blue.svg" alt="infoIcon" />
          <div>{{countInfoLabel}}</div>
      </div>
      <div class="grid-table-container shadow border mt-3 p-3 bg-white mx-3">
        <ngx-datatable class="bootstrap" [reorderable]="true" [rows]="reportPreview.answerdata" [headerHeight]="50" [scrollbarH]="true" [footerHeight]="footerHeight" [rowHeight]="52" [columnMode]="'force'" [limit]="100" >
          <ngx-datatable-column *ngFor="let col of columns; let columnIndex = index" [prop]="col.prop" [width]="col.width">
            <ng-template ngx-datatable-header-template let-colIndex="colIndex">
              <ng-contianer (mousemove)="showTooltip($event, columnIndex)" (mouseleave)="hideTooltip()">
                <div class="column-title">{{ col.name | uppercase }}</div>
              </ng-contianer>
            </ng-template>
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
              <div class="cell-data">
                <span >{{ value }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>


  </ng-template>
</div>