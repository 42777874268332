export type ToolNotification = {
    notificationId: string;
    actionLink: ActionLink[];
    creationDate: string;
    type: string;
    context?: { [key: number]: string }
}

export type ActionLink = {
    type: ButtonType,
    action: string,
    title: string,
    buttonStyle?: ButtonStyle
}

export enum ButtonType {
    button = "button",
    link = "link"
}

export enum ButtonStyle {
    primary = "primary",
    secondary = "secondary"
} 
