<sh-alert *ngIf="showAlertSuccess" [props]="successAlert"></sh-alert>
<sh-alert *ngIf="showAlertFail" [props]="failAlert"></sh-alert>
<div class="body_container py-3 px-5">
    <div class="article-container my-5 py-5 ps-5 pe-5">
        <div class="article-title">
            <div>
                <img src="assets/images/icon-fa-qpurple.png" alt="pic" />
                <span>Help & Support</span>
            </div>
            <div>
                <sh-button *ngIf="isAuthority" [props]="publishButton"></sh-button>
            </div>
        </div>
        <div class="article-content">
            <div class="content-left">
                <div class="article-description article-text" >
                    The below section is devoted to Frequently Asked Questions (FAQs). Here you can find answers to questions relating to the Accenture True Supplier Marketplace (TSM). For questions relating to Supplier Hub and the onboarding process, please refer to<a class="supportHelp" target="_blank" href="https://supplierhub-buyer.accenture.com/#/buyer/helpPage"> Supplier Hub Help & Support.</a>
                </div>
                <div class="addbutton">
                    <sh-button *ngIf="isAuthority" ngbAutofocus [props]="addButton"></sh-button>
                </div>
                <div class="article-question">
                    <ul *ngIf="buyerhelp?.length > 0" style="word-wrap: break-word;word-break: normal;">
                        <li *ngFor="let cat of  faquniqueCat">
                            <div>
                                <div class="article-sub-title" >{{cat}}</div>
                                <div  *ngFor="let que of  buyerhelp;let i = index" style="margin-bottom: 1rem;">
                                
                                    <div *ngIf="que.category == cat" class="article-list" style="display: flex;">
                                        <span *ngIf="isAuthority" class="icon" (click)="operationFAQ('Delete FAQ',que)">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" fill="#4E4E87"/></svg>
                                        </span>
                                        <span *ngIf="isAuthority" class="icon" (click)="operationFAQ('Edit FAQ',que)">
                                             <img src="assets/icons/icon-edit.svg" alt="icon">
                                        </span>
                                       <span style="display:flex" class="article-dropdown" (click)="handleClick(que.faqId)">
                                            <span style="margin-top: 10px;flex:none" class="right-arrow" [ngClass]="{ 'bottom-arrow': current === que.faqId }"></span>
                                            <span class="article-question-text" >{{que.question}}</span>
                                        </span>
                                    </div>
                                    <div  *ngIf="current === que.faqId && que.category == cat">
                                        <div class="article-text"
                                            >
                                            <p class="none-liststyle"  [ngClass]="{ 'paddingLeft':!isAuthority }"
                                            [innerHTML]="que.answer | html" ></p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div  class="content-right">
                <tsm-help-and-support-download [role]="'buyer'"></tsm-help-and-support-download>
                <div class="article-sub-title">Quick Links</div>
                <div  class="sub-link">
                    <a  class="link" target="_blank" href="https://ts.accenture.com/sites/Requisition%20to%20Pay%20Tier%203%20Support/ProcurementPlus">Procurement Plus - CR/SR Portal</a>
                </div>
                <br/>
                <div  class="sub-link">
                    <a  class="link" target="_blank" href="https://procurementplushelp.accenture.com/">Procurement Plus Bot (Accenture.com)</a>
                </div>
                <br/>
                <div  class="sub-link">
                    <a  class="link" target="_blank" href="https://ts.accenture.com/sites/SupplierInnovationPlatform/Lists/Sustainability%20Hub%20Requirements%20Workstack/AllItems.aspx?isAscending=false&FilterFields1=Priority&FilterValues1=High%3b%23%3b%23Low%3b%23Medium&FilterTypes1=Choice&sortField=Date&groupBy=RequestorName&viewid=c9bc583a%2D60bf%2D4dc8%2D994b%2Dce20c0918dd2">Submit Feedback</a>
                </div>
                <br/>
                <div class="sub-link" >
                    <a class="link" target="_blank" href="https://supplierhub-buyer.accenture.com/#/buyer/helpPage"> Supplier Hub Help & Support.</a>
                </div>
            </div>
        </div>
    </div>