import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ButtonPropsType} from "@lsl16/sustainability-shared-components";
import {Popup} from "../../models/Popup";
import {ModalService} from "src/app/services/modal/modal.service";

@Component({
  selector: "tsm-pop-up-bv",
  templateUrl: "./pop-up-bv.component.html",
  styleUrls: ["./pop-up-bv.component.sass"]
})

// @dynamic
export class PopUpBVComponent implements OnInit {
  @Input() opened: boolean = true;
  @Input() options;
  public static emailAddress: string = "";

  modalData: Popup;
  emailIconUrl: string = "assets/images/icon-email.png";
  timeFlag: number = 0;
  defaultRequestorText = "This sustainability assessment task was automatically initiated by the onboarding event."

  buttonProps2: ButtonPropsType = {
    label: "Close",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 115,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "darkBlue",
    hoverColor: "white"
  };

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: ModalService,
    public ngbModalService: NgbModal
  ) {
  }

  open() {
    const service = this.ngbModalService;
    if (!this.ngbModalService.hasOpenModals()) {
      const modalReference = this.ngbModalService.open(PopUpBVComponent, {centered: true, size: "lg",});
    }
  }

  ngOnInit() {
    this.modalData = this.modalService.getRequestorData();
    this.buttonProps2.onClick = this.activeModal.close;
  }

  get emailAddress() {
    return this.modalService.buyerEmailAddress === null ? this.defaultRequestorText : this.modalService.buyerEmailAddress;
  }
}
