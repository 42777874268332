import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { Popup } from "../../models/Popup";
import { ModalService } from "src/app/services/modal/modal.service";

@Component({
  selector: "tsm-pop-up-disabled",
  templateUrl: "./pop-up-disabled.component.html",
  styleUrls: ["./pop-up-disabled.component.sass"]
})

export class PopUpDisabledComponent implements OnInit {
  @Input() opened: boolean = true;
  modalData: Popup;
  buttonProps2: ButtonPropsType = {
    label: "Close",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 115,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "darkBlue",
    hoverColor: "white"
  };

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: ModalService,
    public ngbModalService: NgbModal
  ) { }

  ngOnInit() {
    this.modalData = this.modalService.getDisabledData();
    this.buttonProps2.onClick = this.activeModal.close;
  }
}