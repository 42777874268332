import { Component, Input, OnInit } from '@angular/core';
import { ButtonPropsType, SubSupplier, SubSupplierRelationshipService } from '@lsl16/sustainability-shared-components';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddParentConfig } from 'src/app/components/profile-details/profile';
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { SupplierRelationshipService } from '@lsl16/sustainability-shared-components';
import { isEmpty } from "lodash";
import { AlertPropsType } from '@lsl16/sustainability-shared-components';
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { InviteNewSupplierComponent, InviteType } from '../invite-new-supplier/invite-new-supplier.component';
import { SubSupplierRelationStatus } from '../../../app/models/SubSupplier';
import { MetadataService } from "../../services/metadata/metadata.service";

type supplierParentSelection = "noParent" | "confirmParent" | "specifyParent" | "specifyDifferentParent";
const CONFIRM_PARENT_SELECTION = "confirmParent";

type addParentModalLiterals = {
  title: string;
  infoTitle: string;
  infoMessage: string;
  infoMessageWithoutNoParent: string;
  noParentLabel: string;
  confirmAndSaveBtn: string;
  cancelBtn: string;
  noParentSaveSuccessMessage: string;
  confirmParentInfoTitle: string;
  confirmParentInfoMessage: string;
  confirmParentInfoMessageWithoutNoPartent: string;
  confirmParentLabel: string;
  confirmNoParentInfoTitle: string,
  confirmNoParentInfoMessage: string,
  errorAlertText: string,
  errorMessage: string,
  errorTitle: string,
  errorSubSupplierTitle: string,
  specifyParentLabel: string,
  noRecordFound: string,
  inviteNewParentBtn: string,
  errorSuperSupplierTitle: string
}
@Component({
  selector: 'tsm-confirm-supplier-parent-creation',
  templateUrl: './confirm-supplier-parent-creation.component.html',
  styleUrls: ['./confirm-supplier-parent-creation.component.sass'],
})
export class ConfirmSupplierParentCreationComponent implements OnInit {
  @Input() confirmType: AddParentConfig;
  supplier: GeneralSupplierType;
  public parentSelection: supplierParentSelection = 'specifyParent';
  public modalTranslation: addParentModalLiterals;
  public currentConfirmButtonProps: ButtonPropsType;
  isParentFound: boolean = false;
  confirmSupplier?: GeneralSupplierType;
  isAlertVisible: boolean = false;
  displayErrorMessage: boolean = false;
  selectedSupplier?: GeneralSupplierType;
  suggestedSupplier?: GeneralSupplierType;
  parentDunsNumber: string;
  noRecordFound: boolean = false;
  confirmedOrSpecifyParentList: Array<supplierParentSelection> = ['specifyParent', "confirmParent", "specifyDifferentParent"];
  displayErrorSubSupplierMessage: boolean = false;
  subSuppliers: SubSupplier[] = [];
  displayErrorSuperSupplierMessage: boolean = false;
  private document = document;

  confirmAndSaveButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 225,
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
  };

  public cancelButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'white',
    borderColor: 'lightBlue',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    width: 115,
    height: 48,
    padding: '16px 20px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'white',
    hoverBorderColor: 'lightBlue',
    hoverColor: 'purple',
    onClick: () => {
      this.activeModal.close();
    },
  };

  confirmNoParentButtonProps: ButtonPropsType = {
    ...this.confirmAndSaveButtonProps,
    onClick: async () => {
      await this.confirmAndSaveNoParentClick();
    },
  };

  confirmParentButtonProps: ButtonPropsType = {
    ...this.confirmAndSaveButtonProps,
    onClick: async () => {
      this.confirmAndSaveConfirmParentClick();
    }
  };

  disabledConfirmParentButtonProps: ButtonPropsType = {
    ...this.confirmAndSaveButtonProps,
    backgroundColor: "gray",
    color: "white",
    disabled: true
  };

  verifyAlertProps: AlertPropsType = {
    type: "alert-profileVerifiedFaild",
    message: "errorAlertText",
    icon: "assets/images/icon-error.svg",
    iconFill: "#ffe8e6",
    iconClose: "assets/images/icon-cross-blue.svg",
    onDismiss: () => {
      this.isAlertVisible = false;
    }
  };

  inviteNewParentButtonProps: ButtonPropsType =
    {
      label: "Invite new parent company",
      backgroundColor: 'blue',
      color: 'white',
      fontSize: 16,
      fontFamily: 'Graphik-Medium',
      width: 295,
      height: 48,
      padding: '16px 24px',
      margin: '0px',
      hoverBackgroundColor: 'darkBlue',
      hoverColor: 'white',
      onClick: async () => {
        await this.openInviteNewParentModal();
      }
    }

  constructor(
    private activeModal: NgbActiveModal,
    private supplierRelationshipService: SupplierRelationshipService,
    private ngbModalService: NgbModal,
    private subSupplierRelationshipService: SubSupplierRelationshipService,
    private metadataService: MetadataService,
  ) {
    this.currentConfirmButtonProps = this.disabledConfirmParentButtonProps;
  }

  async ngOnInit(): Promise<void> {
    this.supplier = this.confirmType.supplier;
    if (
      this.confirmType?.confirmSupplier &&
      !isEmpty(this.confirmType.confirmSupplier)
    ) {
      this.parentSelection = CONFIRM_PARENT_SELECTION;
      this.suggestedSupplier = this.confirmType.confirmSupplier;
      this.confirmSupplier = this.confirmType.confirmSupplier;
      this.isParentFound = true;
      this.currentConfirmButtonProps = this.confirmParentButtonProps;
    }
    await this.fetchSubSuppliers();
    this.checkEmptyPOCAndDisplayErrorMessage();
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.modalTranslation =
          multilanguageJson.body.main.addSupplierParentModal;
        this.setButtonLabels();
        this.setAlertMessage();
        this.setInfoMessages();
      }
    }
  }

  private checkEmptyPOCAndDisplayErrorMessage(): void {
    if (this.parentSelection === CONFIRM_PARENT_SELECTION) {
      this.confirmSupplier = this.suggestedSupplier;
      this.updateConfirmButtonPropsAndMessage();
    }
    else if (this.isSpecifyOrSpecifyDifferentParent()) {
      // specify Parent or specify Different Parent
      this.confirmSupplier = this.selectedSupplier;
      this.updateConfirmButtonPropsAndMessage();
    }
    else {
      // no parent
      this.displayErrorMessage = false;
      this.displayErrorSubSupplierMessage = false;
      this.displayErrorSuperSupplierMessage = false;
      this.currentConfirmButtonProps = this.confirmNoParentButtonProps;
    }
  }

  private setButtonLabels(): void {
    this.cancelButtonProps.label = this.modalTranslation.cancelBtn;
    this.confirmNoParentButtonProps.label =
      this.modalTranslation.confirmAndSaveBtn;
    this.confirmParentButtonProps.label =
      this.modalTranslation.confirmAndSaveBtn;
    this.disabledConfirmParentButtonProps.label =
      this.modalTranslation.confirmAndSaveBtn;
    this.inviteNewParentButtonProps.label = this.modalTranslation.inviteNewParentBtn;
  }

  private setAlertMessage(): void {
    this.verifyAlertProps.message = this.modalTranslation.errorAlertText;
  }

  private setInfoMessages(): void {
    if (this.confirmType.hideNoParent) {
      this.modalTranslation.infoMessage = this.modalTranslation.infoMessageWithoutNoParent;
      this.modalTranslation.confirmParentInfoMessage = this.modalTranslation.confirmParentInfoMessageWithoutNoPartent;
    }
  }

  async confirmAndSaveNoParentClick() {
    try {
      await this.supplierRelationshipService.confirmNoParent(
        this.supplier.supplierTsmId,
        this.confirmSupplier?.supplierTsmId
      );
      this.activeModal.close();
      if (isEmpty(this.confirmType.url)) {
        this.confirmType.redirectToEsgAssessment();
      } else {
        this.document.location.href = this.confirmType.url;
      }
    } catch {
      console.log("Failed to confirm no parent");
    }
  }

  async confirmAndSaveConfirmParentClick() {
    try {
      if (this.parentSelection === 'specifyDifferentParent') {
        await this.supplierRelationshipService.confirmParent(this.supplier.supplierTsmId, this.confirmType.taskId, this.confirmSupplier.supplierTsmId, this.suggestedSupplier.supplierTsmId);
      }
      else {
        await this.supplierRelationshipService.confirmParent(this.supplier.supplierTsmId, this.confirmType.taskId, this.confirmSupplier.supplierTsmId);
      }
      this.activeModal.close();
    } catch {
      this.isAlertVisible = true;
    }
  }

  onSearchParent(value: GeneralSupplierType) {
    this.noRecordFound = false;
    if (value === null) {
      this.noRecordFound = true;
    }
    this.selectedSupplier = value;
    this.confirmSupplier = value;
    this.checkEmptyPOCAndDisplayErrorMessage();
  }

  isParentFoundAndNoPoc(): boolean {
    return this.isConfirmParent() && !this.confirmSupplier?.pocEmail || this.confirmSupplier?.pocEmail.trim() === "";
  }

  isConfirmParent(): boolean {
    return this.confirmedOrSpecifyParentList.indexOf(this.parentSelection) !== -1 && !isEmpty(this.confirmSupplier);
  }

  isSpecifyOrSpecifyDifferentParent(): boolean {
    return this.parentSelection === 'specifyParent' || this.parentSelection === 'specifyDifferentParent';
  }

  onParentDunsNumberChanged(value: string) {
    this.parentDunsNumber = value;
    this.noRecordFound = false;
  }

  async updateConfirmButtonPropsAndMessage() {
    if (isEmpty(this.confirmSupplier)) {
      //no selected parent
      this.displayErrorMessage = false;
      this.displayErrorSubSupplierMessage = false;
      this.displayErrorSuperSupplierMessage = false;
      this.currentConfirmButtonProps = this.disabledConfirmParentButtonProps;
    }
    else if (this.isParentFoundAndSubSupplier()) {
      // parent found but is Sub Supplier > disable Confirm & Save button
      this.displayErrorMessage = false;
      this.displayErrorSubSupplierMessage = true;
      this.displayErrorSuperSupplierMessage = false;
      this.currentConfirmButtonProps = this.disabledConfirmParentButtonProps;
    }
    else if (this.isParentFoundAndNoPoc()) {
      // parent found but no PoC > disable Confirm & Save button
      this.displayErrorMessage = true;
      this.displayErrorSubSupplierMessage = false;
      this.displayErrorSuperSupplierMessage = false;
      this.currentConfirmButtonProps = this.disabledConfirmParentButtonProps;
    }
    else if (await this.isParentFoundAndSuperSupplier()) {
      this.displayErrorMessage = false;
      this.displayErrorSubSupplierMessage = false;
      this.displayErrorSuperSupplierMessage = true;
      this.currentConfirmButtonProps = this.disabledConfirmParentButtonProps;
    }
    else {
      // parent found and also has PoC       
      this.displayErrorMessage = false;
      this.displayErrorSubSupplierMessage = false;
      this.displayErrorSuperSupplierMessage = false;
      this.currentConfirmButtonProps = this.confirmParentButtonProps
    }
  }

  private async openInviteNewParentModal(): Promise<void> {
    const options: NgbModalOptions = {
      scrollable: true,
      animation: true,
      centered: true,
      windowClass: 'add-parent-modal-window',
      backdropClass: 'modal-custom-backdrop'
    };
    const configs = await this.metadataService.gettingInviteNewSupplierConfig();
    const modalReference = this.ngbModalService.open(InviteNewSupplierComponent, options);
    modalReference.componentInstance.configs = configs;
    modalReference.componentInstance.inviteType = InviteType.Parent;
    modalReference.componentInstance.newSupplierDunsNumber = this.parentDunsNumber;
    modalReference.componentInstance.invitingSupplierTsmId = this.supplier.supplierTsmId;
    modalReference.componentInstance.proposedSupplier = this.suggestedSupplier?.supplierTsmId;
    let confirmModal = <HTMLElement>document.getElementsByTagName("ngb-modal-window")[0];
    if (confirmModal) {
      confirmModal.setAttribute("aria-hidden", "false");
    }
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  private async fetchSubSuppliers(): Promise<void> {
    try {
      this.subSuppliers = await this.subSupplierRelationshipService.getAwaitingAndInvitedSubSuppliers(
        this.supplier.supplierTsmId,
      ) || [];
    } catch (error) {
      console.error(`Failed to get sub suppliers for supplier ${this.supplier.supplierTsmId}.`, error);
    }
  }

  private isParentFoundAndSubSupplier(): boolean {
    return !!this.subSuppliers.find(s => s.supplierTsmId === this.confirmSupplier.supplierTsmId);
  }

  private async isParentFoundAndSuperSupplier(): Promise<boolean> {
    try {
      let parentSubSupplierRecord = await this.subSupplierRelationshipService.getSupplierRelationRecord(
        this.confirmSupplier.supplierTsmId, this.supplier.supplierTsmId
      );
      return parentSubSupplierRecord?.status === SubSupplierRelationStatus.connected;

    } catch (error) {
      console.error(`Failed to get sub suppliers for supplier ${this.supplier.supplierTsmId}.`, error);
    }
  }
}
