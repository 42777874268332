import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ButtonPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { Popup } from "../../models/Popup";
import { ModalService } from "src/app/services/modal/modal.service";
import { PossibleScenariosConfig } from "./possibleScenarios";

@Component({
  selector: "tsm-pop-up-possible-scenarios",
  templateUrl: "./pop-up-possible-scenarios.component.html",
  styleUrls: ["./pop-up-possible-scenarios.component.sass"]
})

export class PopUpPossibleScenariosComponent implements OnInit {
  public redirectUrl = "/termination-request-board";
  @Input() confirmType: PossibleScenariosConfig;
  @Input() opened: boolean = true;
  modalData: Popup;
  closeButton: ButtonPropsType = {
    label: "Close",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 121,
    height: 48,
    padding: "16px 22px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "purple",
    hoverColor: "white",
    borderColor: "grey"
  };

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: ModalService,
    public ngbModalService: NgbModal,
    public httpService: HttpService
  ) { }

  ngOnInit() {
    this.modalData = this.modalService.getPossibleScenariosData();
    this.closeButton.onClick = this.activeModal.close;
  }
}