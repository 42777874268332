<div class="card container-fluid user-profile">
  <div class="row row-cols-auto no-gutters">
    <div class="col-1" style="margin-top: 4vh">
      <img class="user-image" src="assets/images/image-empty-state-grey.jpg" alt="icon-empty-state" />
    </div>

    <div class="col-11 ps-5 user-details">
      <div class="row no-gutters mt-4">
        <div class="col-1 icon-col">
          <img src="assets/icons/icon-profile.svg" alt="username-icon" />
        </div>
        <span id="userName" class="col user-data">
          {{ user.displayName }}
        </span>
      </div>
      <div class="row no-gutters mt-4">
        <div class="col-1 icon-col">
          <img src="assets/images/icon-email.png" style="width: 21px" alt="email-icon" />
        </div>
        <span id="userEmail" class="col user-data">
          {{ user.email }}
        </span>
      </div>

      <div class="row no-gutters mt-4">
        <div class="col-1 icon-col role-icon-container">
          <img src="assets/icons/icon-services.svg" alt="icon-services" />
        </div>
        <div class="col" #dropPanel>
          <div (click)="toggleDropdown()" class="dropdown" [ngClass]="open ? 'active' : ''" #inputPanel>
            <input aria-label="multiple dropdown" type="text" [value]="displayRoles" class="multi-drop-input"
              disabled />
            <div class="dropdown-icon">
              <img src="assets/icons/chevron-down-purpledropdown.svg" alt="icon-chevron-down" />
            </div>
          </div>
          <div *ngIf="open" class="multi-drop-container">
            <div *ngIf="open" class="multi-drop-panel">
              <div class="multi-drop-panel-cont">
                <div *ngFor="let role of roles">
                  <label class="groupheader">{{ role.profileName }}</label>
                  <div *ngFor="let permission of role?.permissions" class="individual-option">
                    <label class="rc-container label-drop">
                      <input class="checkboxInput" type="checkbox" [checked]="permission.checked"
                        (change)="checkedChanged(role.profileId, permission)"
                        aria-label="input-multiple-selector-checkbox" [disabled]="permission.disabled" />
                      <span [ngClass]="{grayColor:permission.disabled}">{{ permission.roleName }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="save save-button">
      <sh-button [props]="props"></sh-button>
    </div>
  </div>
</div>