import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { SystemAdminService } from "src/app/services/systemAdmin/system-admin.service";
 
@Component({
  selector: "tsm-pop-up-merge-profile",
  templateUrl: "./pop-up-merge-profile.component.html",
  styleUrls: ["./pop-up-merge-profile.component.sass"]
})
export class PopUpMergeProfileComponent implements OnInit {
  @Input() secondaryProfileId: string;
  @Input() primaryProfileId: string;
  @Input() secondaryProfileName: string;
  @Input() primaryProfileName: string;
 
  showSuccessPopup = false;
  showErrorPopup = false;
 
  yesButton: ButtonPropsType = {
    label: "Yes",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 102,
    height: 48,
    padding: "16px 24px",
    margin: "0 0 0 24px",
    hoverBackgroundColor: "darkBlue",
    hoverColor: "white",
    onClick: async () => {
      this.mergeProfiles();
    }
  };
 
  noButton: ButtonPropsType = {
    label: "No",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 102,
    height: 48,
    padding: "16px 22px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "purple",
    hoverColor: "white",
    borderColor: "grey",
    onClick: () => {
      //this.router.navigate(['/path']);
      this.activeModal.close();
    }
  };
 
  closeSuccessButton: ButtonPropsType = {
    label: "Close",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 102,
    height: 48,
    padding: "16px 22px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "purple",
    hoverColor: "white",
    borderColor: "grey",
    onClick: () => {
      this.showSuccessPopup = false;
      this.activeModal.close('resetFields');
    }
  };

  closeErrorButton: ButtonPropsType = {
    label: "Close",
    backgroundColor: "white",
    color: "red",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    width: 102,
    height: 48,
    padding: "16px 22px",
    margin: "0 0 0 2px",
    hoverBackgroundColor: "red",
    hoverColor: "white",
    borderColor: "grey",
    onClick: () => {
      this.showErrorPopup = false;
      this.activeModal.close('resetFields'); // Pass a specific result
    }
  };
 
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private systemAdminService: SystemAdminService
  ) {}
 
  ngOnInit(): void {}
 
  async mergeProfiles() {
    const response = await this.systemAdminService.mergeProfiles(this.primaryProfileId, this.secondaryProfileId);
    //this.activeModal.close(true);
    console.log("response is",response);
    const responseBody = response['body'];
    if (responseBody && responseBody.status === 200) {
      console.log("Merge successful");
      this.showSuccessPopup = true;
    } else {
      console.log("Error:", responseBody.msg);
      this.showErrorPopup = true;
    }
  }
}