<tsm-select-drop-down-box-admin
  [profileData]="profileData"
  [supplierNameForSystemSupplierAdmin]="supplierNameForSystemSupplierAdmin"
  [eventAdmin]="eventAdmin"
  (reaload)="reload($event)"
  [isSubsidiaryAccessingParent]="isSubsidiaryAccessingParent"
></tsm-select-drop-down-box-admin>
<div class="tab-container">
  <tsm-tab-bar [tabs]="tabs"></tsm-tab-bar>
</div>
<div *ngFor="let tab of tabs">
  <div *ngIf="tab.active && tab.id === 'Users'">
    <tsm-supplier-admin-board
      [supplierTsmId]="supplierTsmId"
      [isSubsidiaryAccessingParent]="isSubsidiaryAccessingParent"
      #supplierAdminBoard
    ></tsm-supplier-admin-board>
  </div>
  <div *ngIf="tab.active && tab.id === 'SubsidiaryUsers'">
    <tsm-subsidiary-supplier-admin-board
      [supplierTsmId]="supplierTsmId"
      #subsidiarySupplierAdminBoard
    ></tsm-subsidiary-supplier-admin-board>
  </div>
</div>
