import { Component, OnInit ,Input,SimpleChanges,ViewChild,ElementRef,HostListener,Output,EventEmitter } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'tsm-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.sass']
})
export class SelectInputComponent implements OnInit {
  @Input() width:string;
  @Input() id:string;
  @Input() height: string;
  @Input() fontSize: string;
  @Input() color: string;
  @Input() refresh:boolean = false;
  @Input() drapDownData: any;
  @Input() readonly:boolean = true;
  @Input() placeholder: string  = '';
  @Input() choiceDate:string;
  @Input() dropDownViewOnly: boolean;
  dropDownOpen: boolean = false;
  dataArry:any = [];
  name:any = [];
  @Input() multiple:boolean;
  @Output() SingleDateSend = new EventEmitter();
  @Output() multipleDateSend = new EventEmitter();
  @Output() textDateSend = new EventEmitter();
  @ViewChild("inputPanel") inputPanel: ElementRef;
  constructor(public ngbModalService: NgbModal ) { }

  ngOnInit(): void {
   
  }

  // selset event and send SingleDate
  slectTarget(item){
    if(item.Text !== this.choiceDate){
      if(item.Text === "Select One"){
        this.choiceDate = ""
      }else{
        this.choiceDate = item.Text
      }
      this.SingleDateSend.emit(item)
      this.dropDownOpen = false
    }
  }
  // selset event and send multipleDate
  slectTargetMultiple($event,i){
    i.checked = $event.target.checked
    if($event.target.checked){
      this.dataArry.push(i)
      this.name.push(i.Text)
    }else{
      this.dataArry.forEach((item,index)=>{
        if(item.Text === i.Text){
          this.dataArry.splice(index,1)
          this.name.splice(index,1)
        }
      })
    }
    this.choiceDate = this.name.toString()
    this.multipleDateSend.emit(this.dataArry)
  }
  // dropdown open
  async dropdownopen() {
    this.dropDownOpen = !this.dropDownOpen
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['drapDownData'] !== undefined) {
      if(changes['drapDownData'].currentValue !== undefined){
        this.drapDownData = changes['drapDownData'].currentValue;
        if(this.refresh){
          this.dataArry = []
          this.name = []
          this.choiceDate = ""
        }
      }
    }
  }
  // close dropDown list
  @HostListener('document:click', ['$event'])
  documentClick(event) {
    if (this.inputPanel &&this.inputPanel.nativeElement.contains(event.target)) {
        return
    }
    this.dropDownOpen = false;
  }
  @HostListener('document:mouseup', ['$event'])
  mouseup(event) {
    if (this.inputPanel &&this.inputPanel.nativeElement.contains(event.target)) {
        return
    }
    this.dropDownOpen = false;
  }
  
  textChange(){
    this.textDateSend.emit(this.choiceDate);
  }
}
