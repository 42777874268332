import { Component, Input, OnInit, ViewChild  } from "@angular/core";
import { ModalService } from "src/app/services/modal/modal.service";
import { Options } from "../../models/Options";

@Component({
    selector: "tsm-options-card-status",
    templateUrl: "./options-card-status.component.html",
    styleUrls: ["./options-card-status.component.sass"]
})
export class OptionsCardStatusComponent implements OnInit {
    iconUrl: string = "assets/images/action-status-icon.png";
    openRequestorModal: boolean;
    @Input() sentDate: string;
    @Input() options: Options;
    @ViewChild('tsmBuyerPopUp',{static:true}) tsmBuyerPopUp;

    constructor(public ModalService: ModalService) {
    }

    openRequestor(e): void {
        this.tsmBuyerPopUp.open();
    }

    ngOnInit(): void {
        
    }
}
