export const profileDetailsData = {
    title: "Please verify your profile details",
    buttonText: "Save",
    headerCloseButton: true
};

export const profileDetailsOptions = {
    scrollable: true,
    centered: true,
    size: "lg",
    animation: true,
    windowClass: "modal-container",
    backdropClass: "modal-custom-backdrop"
};

