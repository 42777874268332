<div *ngIf="login; else loadEffect" (click)="hideGlobalTooltip()">
  <router-outlet></router-outlet>
  <tsm-pop-up hidden=false></tsm-pop-up>
  <app-dialog header="Confirmation" width="425" icon="fa fa-question-circle" #cd></app-dialog>
  <div class="tooltip globalTooltip" id="globalTooltip">
    <div class="tooltipHeeader"></div>
    <div class="tooltipContent"></div>
  </div>
</div>
<ng-template #loadEffect>
  <div style="min-height: 100px"></div>
  <div class="sk-chase">
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
  </div>
</ng-template>
