<tsm-secondary-banner></tsm-secondary-banner>
<sh-alert *ngIf="showAlert" [props]="alertProps"></sh-alert>
<div class="supplier-information-box-container mt-5">
  <sh-supplier-information-box *ngIf="supplier"
    #supplierInformationBox
    id="supplier-information-box"
    [supplier]="supplier"
    [userType]="userType"
    [parentSupplier]="parentSupplier"
    [isDuplicate]="isDuplicate"
    editBtnAuthId="editSupplierProfile=tsm"
    changeLocAuthId="changeLocation=tsm"
    enableIndirectTag="true"
    (outerEvent)="openProfilePopup('edit-profile-detail')"
    (terminateEvent)="openTerminatePopup($event)"
  ></sh-supplier-information-box>
</div>
<div class="mx-5 my-5 pt-2">
  <tsm-tab-bar [tabs]="tabs"></tsm-tab-bar>
  <div *ngFor="let tab of tabs">
    <div *ngIf="tab.id === 'Actions' && tab.active">
      <div class="actions-tittle">
        What would you like to do with this supplier?
      </div>
      <tsm-bv-risk-assessment [supplier]="supplier" [allMu]="allMuCustom" [allCountry]="allCountry"
      [allCategory]="allCategory" [allSubCategory]="allSubCategory"
        (refershTerminateCallBack)="refershTerminate($event)" *ngIf="supplierLoaded" [isDuplicate]="isDuplicate"></tsm-bv-risk-assessment>
      <tsm-bv-new-onboarding
        [supplier]="supplier"
        [allMu]="allMuCustom"
        [allCountry]="allCountry"
        [isDuplicate]="isDuplicate"
        (refershTerminateCallBack)="refershTerminate($event)"
      ></tsm-bv-new-onboarding>
      <tsm-bv-esg-assessment
        [supplier]="supplier"
        [allMu]="allMu"
        [allCountry]="allCountry"
        [isScope3KeySup]="isScope3KeySup"
        [isDuplicate]="isDuplicate"
        (withdrawSuccessAlert)="setAlertProps($event)"
        (refershTerminateCallBack)="refershTerminate($event)"
        style="width: 100%"
      ></tsm-bv-esg-assessment>
    </div>
    <div *ngIf="tab.id === 'Relationships' && tab.active">
      <tsm-buyer-relationship
        [supplierTsmId]="supplierTsmId"
        [isSupplierParent]="isSupplierParent"
        [isIndirect]="supplier.indirect"
        [isDuplicate]="isDuplicate"
      ></tsm-buyer-relationship>
    </div>
    <div *ngIf="tab.id === 'Scoring' && tab.active && isReviewDataAvailabe">
      <tsm-scoring [reviewData]="reviewData"></tsm-scoring>
    </div>
    <!-- suppliers tab -->
    <div *ngIf="tab.id === 'Suppliers' && tab.active">
      <tsm-sub-suppliers [supplierTsmId]="supplierTsmId" [isDuplicate]="isDuplicate"></tsm-sub-suppliers>
      <tsm-historical-sub-suppliers
        [supplierTsmId]="supplierTsmId"
      ></tsm-historical-sub-suppliers>
    </div>

    <div *ngIf="tab.id === 'ActivityLog' && tab.active">
      <tsm-activity-log [supplierTsmId]="supplierTsmId"></tsm-activity-log>
    </div>
    <div class="loadEffect" style="position: fixed;left: 50%;top:30%;z-index: 1000;" *ngIf="!(supplierLoaded)">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
  </div>
  </div>
</div>
