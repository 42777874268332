import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "tsm-pagination-bar",
    templateUrl: "./pagination-bar.component.html"
})
export class PaginationBarComponent implements OnInit {
    @Input() pages: number;
    @Input() currentPage: number = 1;
    pageSize: number = 30;

    @Output() currentPageChange: EventEmitter<number> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    /**
     * Description: invoked when user clicks on page buttons on pagination bar.
     * changes #currentPage number and emits the change for consumption by parent dashboard component
     * @Params page
     */
    onPageChange = (page) => {
        this.currentPage = page;
        this.currentPageChange.emit(page);
    };
}
