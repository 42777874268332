import { Component, Input, OnInit } from '@angular/core';
import { Popup } from "../../models/Popup";
import { ModalService } from "src/app/services/modal/modal.service";
import { ButtonPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { SupplierGeneralProfileService } from 'src/app/services/supplier-general-profile.service';
import { EventService } from 'src/app/services/EventService.service';
import { countryList } from "../../../utilities/const/country-list";
import { Router } from "@angular/router";
@Component({
  selector: 'tsm-bv-pop-up-onboarding',
  templateUrl: './bv-pop-up-onboarding.component.html',
  styleUrls: ['./bv-pop-up-onboarding.component.sass']
})
export class BvPopUpOnboardingComponent implements OnInit {
  @Input() confirmType;
  countries: string;
  countryTsmCodeList: string;
  modalData: Popup;
  countrylist: any;
  actionCountryServed: any;
  contrast: boolean = false;
  buttonProps1: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderColor: "purple",
  };
  buttonProps2: ButtonPropsType = {
    label: "Confirm",
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    padding: '16px 24px',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    onClick: () => {
      this.submit()
    }
  };
  constructor(public modalService: ModalService,
    public activeModal: NgbActiveModal,
    private httpService: HttpService,
    public eventService: EventService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.actionCountryServed = this.confirmType.actionCountryServed
    this.actionCountryServed.forEach((item) => {
      item.disabled = false
      item.checked = false
    })
    this.setcheck()
    this.modalData = this.modalService.getonboardingData();
    this.buttonProps1.onClick = this.activeModal.close;
  }
  setcheck() {
    this.actionCountryServed.forEach((item) => {
      if (this.confirmType.countryServed) {
        if (this.confirmType.countryServed === item.tsmCode) {
          this.check(item)
        }
      } else {
        this.buttonProps2 = {
          label: "Confirm",
          backgroundColor: 'gray',
          color: 'white',
          fontSize: 16,
          fontFamily: 'Graphik-Medium',
          height: 48,
          padding: '16px 24px',
          margin: '0 0 0 2px',
          hoverBackgroundColor: 'darkBlue',
          hoverColor: 'white',
          disabled: true,
          onClick: () => {
            this.submit()
          }
        };
      }
    })
  }
  check(item) {
    item.checked = !item.checked
    this.contrast = false
    this.actionCountryServed.forEach((element) => {
      if (item.countryGroupOnboard !== element.countryGroupOnboard && item.checked) {
        element.disabled = true
      }
      if (element.checked) {
        this.contrast = true
      }
    })
    if (!this.contrast) {
      this.actionCountryServed.forEach((element) => {
        element.disabled = false
      })
      this.buttonProps2 = {
        label: "Confirm",
        backgroundColor: 'gray',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Graphik-Medium',
        height: 48,
        padding: '16px 24px',
        margin: '0 0 0 2px',
        hoverBackgroundColor: 'darkBlue',
        hoverColor: 'white',
        disabled: true,
        onClick: () => {
          this.submit()
        }
      };
    } else {
      this.buttonProps2 = {
        label: "Confirm",
        backgroundColor: 'blue',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Graphik-Medium',
        height: 48,
        padding: '16px 24px',
        margin: '0 0 0 2px',
        hoverBackgroundColor: 'darkBlue',
        hoverColor: 'white',
        disabled: false,
        onClick: () => {
          this.submit()
        }
      };
    }
    let arr = []
    this.actionCountryServed.forEach((element) => {
      if (element.checked) {
        arr.push(element.tsmCode)
      }
    })
    this.countryTsmCodeList = arr.join(",")
  }
  jmppage() {
    let supplierTsmId = this.confirmType.supplier.supplierTsmId
    let selectedCountry = this.confirmType.actionCountryServed[0].tsmCode
    let url = "/#/supplier-profile/" + supplierTsmId + "?countryServed=" + selectedCountry
    window.open(url, "_blank")
  }
  async submit() {
    this.buttonProps1.onClick()
    if (this.confirmType.isAction_selection) {
      this.eventService.eventEmit.emit(`onboardingSendRequest_Action#${this.countryTsmCodeList}`);
    } else {
      this.eventService.eventEmit.emit(`onboardingSendRequest#${this.countryTsmCodeList}`);
    }
  }

}
