import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsm-sa-retriggering-process-flow',
  templateUrl: './sa-retriggering-process-flow.component.html',
  styleUrls: ['./sa-retriggering-process-flow.component.sass']
})
export class SaRetriggeringProcessFlowComponent implements OnInit {

  labelTranslations = {
    stepByStep: '',
    guidelines: ''
  }
  steps: { id: string; name: string; }[] = [
    {
      id: 'searchSuppliersForRetrigger',
      name: 'Search Suppliers'
    },
    {
      id: 'searchSupplierListForRetrigger',
      name: 'Select list of Suppliers from search grid'
    },
    {
      id: 'retriggerSAForSupplierList',
      name: 'Retrigger Sustainability Assessment for the selected Suppliers'
    }
  ]

  constructor() { }

  ngOnInit(): void {}

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.SaRetriggeringProcessFlow;
      }
    }
  }

}
