<div class="notification-item">
    <div class="row me-0 ms-0">
        <div class="detail notificationMessage">
            <div [innerHTML]="translations.message" #messageDiv></div>
        </div>
        <div>
            <img class="delete-notification float-end cursor-pointer" [src]="mode!=='archive'?'assets/icons/archive.svg':'assets/icons/delete-icon.svg'" [alt]="mode!=='archive'?'Archive Notification':'Remove Notification'" (click)="deleteNotification()"/>
        </div>
    </div>
    <div class="row me-0 ms-0">
        <div class="notification-date">
            {{ notification.creationDate | date: "d MMM, y"}} at {{ notification.creationDate | date: "h:mm a"}}
        </div>
    </div>
    <div class="row mt-8">
        <div class="col me-0 ms-0">
            <div *ngFor="let action of notification.actionLink">
                <div *ngIf="action.type==='button'" class="btnClass">
                    <sh-button [props]="getButtonProps(action)"></sh-button>
                </div>
            </div>
        </div>
    </div>
</div>