import { Component, OnInit ,Input,SimpleChanges,ViewChild,ElementRef,HostListener,Output,EventEmitter } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'tsm-single-drop-select',
  templateUrl: './single-drop-select.component.html',
  styleUrls: ['./single-drop-select.component.sass']
})
export class SingleDropSelectComponent implements OnInit {

  @Input() width:string;
  @Input() disable:boolean = false;
  @Input() height: string;
  @Input() background: string;
  @Input() fontSize: string;
  @Input() color: string;
  @Input() refresh:boolean = false;
  @Input() drapDownData: any;
  @Input() readonly:boolean = true;
  @Input() placeholder: string  = '';
  @Input() choiceDate:string;
  @Input() dropDownViewOnly: boolean;
  dropDownOpen: boolean = false;
  dataArry:any = [];
  name:any = [];
  @Output() SingleDateSend = new EventEmitter();
  @Output() textDateSend = new EventEmitter();
  @ViewChild("inputPanel") inputPanel: ElementRef;
  alldata:any;
  constructor(public ngbModalService: NgbModal ) { }

  ngOnInit(): void {
   this.alldata = this.drapDownData
   if(this.disable){
    this.dropDownOpen = false
   }
  }

  // selset event and send SingleDate
  slectTarget(item){
      if(item.Text === "Select One"){
        this.choiceDate = ""
      }else{
        this.choiceDate = item.Text
      }
      this.SingleDateSend.emit(item)
      this.dropDownOpen = false
  }
  
  // dropdown open
  async dropdownopen() {
    if(!this.disable){
      this.dropDownOpen = !this.dropDownOpen
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['drapDownData'] !== undefined) {
      if(changes['drapDownData'].currentValue !== undefined){
        this.drapDownData = changes['drapDownData'].currentValue;
        if(this.refresh){
          this.dataArry = []
          this.name = []
          this.choiceDate = ""
        }
      }
    }
  }
  // close dropDown list
  @HostListener('document:click', ['$event'])
  documentClick(event) {
    if (this.inputPanel &&this.inputPanel.nativeElement.contains(event.target)) {
        return
    }
    this.dropDownOpen = false;
  }
  @HostListener('document:mouseup', ['$event'])
  mouseup(event) {
    if (this.inputPanel &&this.inputPanel.nativeElement.contains(event.target)) {
        return
    }
    this.dropDownOpen = false;
  }
  
  textChange(){
    this.dropDownOpen = true
    this.drapDownData = []
    this.alldata.forEach((item)=>{
      if(item.Text.indexOf(this.choiceDate) > -1){
        this.drapDownData.push(item)
      }
    })
  }

}
