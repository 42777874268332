import { Component, Input, OnInit } from '@angular/core';
import { each } from 'lodash';
import { SAStatus, SAStatusCheck } from './sa-status-constant';

@Component({
  selector: 'tsm-sa-retrigger-status-preview',
  templateUrl: './sa-retrigger-status-preview.component.html',
  styleUrls: ['./sa-retrigger-status-preview.component.sass']
})
export class SaRetriggerStatusPreviewComponent implements OnInit {
  @Input() statusRecord: any [];
  firstStatusRecord;
  
  columns;
  constructor() { }

  async ngOnInit(): Promise<void> {
    const [firstStatusRecord] = this.statusRecord;
    
    this.firstStatusRecord = firstStatusRecord;
    this.columns = [
      { prop: 'supplierName', name: 'SUPPLIER NAME', isLeftPinnedColumn: true, width: 190 },
      { prop: 'supplierTsmId', name: 'TSM ID', width: 300},
      { prop: 'countryServed', name: 'SA COUNTRY', width: 150 },
      { prop: 'reTriggeredstatus', name: 'RETRIGGER STATUS', width: 180, colType: "status"},
      { prop: 'error', name: 'FAILED REASON', width: 200 },
    ];
    await this.formatColumns()
  }

  private setSAStatusLevel(status: any): any {
    switch (status) {
      case SAStatusCheck.New:
        return SAStatus.New;
      case SAStatusCheck.InProgress:
        return SAStatus.InProgress;
      case SAStatusCheck.Passed:
        return SAStatus.Passed;
      case SAStatusCheck.Failed:
        return SAStatus.Failed;
      case SAStatusCheck.Success:
        return SAStatus.Success;
      default:
        return status;
    }
  }

  async formatColumns(): Promise<any> {
    each(this.columns, (col, i) => {
      if (col.colType === 'status') {
        this.statusRecord.forEach((row) => {
          if (row['reTriggeredstatus']) {
            row['reTriggeredstatus'] = this.setSAStatusLevel(row['reTriggeredstatus']);
          }
        });
      }
    });
  }

}



