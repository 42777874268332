<ul class="nav filter-group">
    <li class="nav-item nav-link filter" *ngFor="let tab of tabs; let key = index"
        [ngClass]="{ 'active-filter': tab.active }" (click)="onClick(tab)">
    <div class="item-container">
        <span *ngIf="tab.active; else inactivetab">
           <img *ngIf="tab.icon" [src]="tab.icon" height="20px" width="20px" style="margin-right: 12px" alt="icon-tab" />
        </span>
        <ng-template #inactivetab>
           <img *ngIf="tab.inactiveIcon" [src]="tab.inactiveIcon" height="20px" width="20px" style="margin-right: 12px" alt="icon-tab" /> 
        </ng-template>
        <span class="tab-title">{{ tabJson[key] }}</span>
        <div *ngIf="tab.contentCount" class="contentCount">{{ tab.contentCount }}</div>
        <img *ngIf="tab.statusIcon" [src]="tab.statusIcon" height="20px" width="20px" style="margin-left: 8px" alt="icon-tab" />
    </div>
        <div [ngClass]="{ 'active-filter-border': tab.active }"></div>
    </li>
</ul>