import { Injectable } from "@angular/core";
import { SearchReturnType } from "../../models/Search";
import { ActivityLogItem } from "@lsl16/sustainability-shared-components";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
@Injectable({
    providedIn: "root"
})
export class SupplierInformationService {
    constructor(private httpService: HttpService) {}

    async getProfileData(supplierID): Promise<any> {
        const serviceUrl = environment.tsmBackendServiceURL;
        const url = `${serviceUrl}/supplier/${supplierID}`;

        return await this.httpService.GetPromise(url);
    }

    async search(criteria: any): Promise<Array<SearchReturnType>> {
        const serviceUrl = environment.tsmBackendServiceURL;

        const url = `${serviceUrl}/search/supplier${criteria}`;
        return this.httpService.GetPromise(url);
    }

    async getActivityLog(): Promise<Array<ActivityLogItem>> {
        return [
            {
                createdDate: "17 NOV 2020",
                createdTime: "02:30:30",
                createdBy: "Li Chong",
                description: "Created Generic Information",
                activityId: "abcabcabcabcabcabcabcabcabcabcabcabc",
                initiative: "RequestForm",
                answers: {
                    country: "No",
                    supplierName: "Yes"
                }
            },
            {
                createdDate: "01 NOV 2020",
                createdTime: "03:33:33",
                createdBy: "Allen",
                description: "Created Declaration Information",
                activityId: "xaxaxaxaxaxaxaxaxaxax",
                initiative: "RequestForm",
                answers: {
                    country: "Yes"
                }
            }
        ];
    }

    async getUserRole(criteria: any): Promise<any> {
        const roleList = [
            {
                eid: "cuiying.cao",
                role: "System Admin"
            },
            {
                eid: "chong.b.li",
                role: "System Admin"
            },
            {
                eid: "jiaxin.li",
                role: "SI&S representative"
            }
        ];
        const result: any = {};
        roleList.forEach((item) => {
            if (item.eid === criteria.eid) {
                result.role = item.role;
            }
        });
        return result;
    }
}
