<div class="help-and-support-download">
    <div class="article-sub-title article-sub-title-pdt2rem">

        <!-------START SUPPLIER BLOCK-------->

        <div *ngIf="role === 'supplier';  else buyerDownloads">
            {{"supplier.helpSupport.staticText.supplierGuideDownloadsTitle" | translate}}
        </div>

        <ng-template #buyerDownloads>
            {{"Buyer Guide Downloads"}}
        </ng-template>

    </div>
    <div [ngClass]="supplierGuideDownloadPDF?.length > 5 ? 'downloads' : ''" tabindex="0">
        <div class="sub-link" *ngFor="let item of supplierGuideDownloadPDF">
            <a class="link" [innerHTML]="item.displayText" (click)="onClick(item)"></a>
        </div>
    </div>
    <div class="article-sub-title article-sub-title-pdt3rem">

        <!-------START BUYER BLOCK-------->

        <div *ngIf="role === 'supplier'; else buyerFAQsDownload">
            {{"supplier.helpSupport.staticText.faqsDownloads" | translate}}
        </div>
        <ng-template #buyerFAQsDownload>
            {{"FAQs Downloads"}}
        </ng-template>
    </div>
    <div [ngClass]="faqsDownloads?.length > 5 ? 'downloads' : ''">
        <div class="sub-link" *ngFor="let item of faqsDownloads">
            <a class="link" [innerHTML]="item.displayText" (click)="onClick(item)"></a>
        </div>
    </div>
</div>