<div class="form me-5 mb-5 ms-5 p-5">

    <div class="display">
        <div class="col width-22 mt-4">
            <sh-button [props]="downloadButton"></sh-button>
        </div>
       <div class="col width-10">
            <img
            id="nextArrow"
            src="assets/icons/chevron-compact-right.svg"
            alt="nextArrow"
            class="image"
            />
       </div>
        <div class="col width-30">
            <span [ngClass]="{'error': displayError}">
                {{ translations.text }}
            </span>
            <sh-question-explanations [props]="tooltipProps"></sh-question-explanations>
            <div>
                <link rel="prefetch" as="image" href="assets/icons/delete.svg" />
                <link rel="prefetch" as="image" href="assets/icons/uploading.svg" />
                <div *ngIf="fileToUpload" class="selectedFile labelWithIcon">
                    <span class="label truncated" [title]="fileToUpload.name">
                        {{ fileToUpload.name }}
                    </span>
                    <img *ngIf="!isUploading" class="icon" src="assets/icons/delete.svg" (click)="deleteFile()" alt="Remove file"/>
                    <img *ngIf="isUploading" class="icon" src="assets/icons/processing-symbol.svg" alt="Uploading" role="progressbar" />
                </div>
                <div class="uploadContainer">
                    <label
                        class="uploadFile dropTarget"
                        [ngClass]="{'error': hasErrors}"
                        ng2FileDrop
                        [uploader]="uploader"
                        (onFileDrop)="handleFile($event)"
                    >
                        <input #uploadFileInput type="file" (click)="uploadFileInput.value = null" accept=".xlsx, .xls" (change)="handleFile($event.target.files)" [disabled]="isFileUploadDisabled"/>
                        <div class="labelWithIcon">
                            <img src="assets/icons/attachment.svg" class="icon" alt="icon-attachment" />
                            <span class="label">
                                {{ translations.attachDocumentation }}
                            </span>
                        </div>
                    </label>
                    <div *ngIf="hasErrors" class="file-errors">
                        <span class="label">{{ labels.genericError }}</span>
                        <ul>
                            <li *ngFor="let error of fileLevelErrors" class="label">{{error}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col width-10">
            <img
            id="nextArrow2"
            src="assets/icons/chevron-compact-right.svg"
            alt="nextArrow"
            class="image"
            />
        </div>
        <div class="col width-30 mt-3">
            <sh-button [props]="validateTsmProfile"></sh-button>
            <div class="checkbox-wrap">
                <br />
                <input
                  class="checkbox"
                  type="checkbox"
                  id="sendSustainabilityAssess"
                  [(ngModel)]="includeSendSA"
                  (change)="inputChanged()"
                  [disabled]="isCheckboxDisabled"
                />
                <label class="label-margin form-label" for="sendSustainabilityAssess">{{ checkboxLabel }}</label>
            </div>
        </div>

    </div>
  </div>
  
<div *ngIf="showEmptyMessage; else validationList" class="form me-5 mb-5 ms-5 p-5 empty-message">
    <img id="searchImage" src="assets/images/EmptyImage_Search.svg" alt="emptySearch" class="image" />
    <span class="highlight-text">
        {{ translations.emptyMessage }}
    </span>
</div>
<ng-template #validationList >
    <div class="form me-5 mb-5 ms-5">
        <tsm-bulk-profiles-validation-list [initiatesa]="initiatesa" [logList]="logList" [buid]="buid"
         [refresheddate]="refresheddate" [refreshed]="refreshed" [currentStatusMsg]="currentStatusMsg"
         (dismissEvent)="handleAlertDismissEvent($event)" (dataEvent)="disableFileUpload($event)">
        </tsm-bulk-profiles-validation-list>
    </div>
</ng-template>
