<div class="item">
  <div class="row align-items-center">
    <div
      class="col width-10"
      *ngIf="!displayLink; else subSupplierNameTemplate"
    >
      {{ item.name }}
    </div>
    <ng-template #subSupplierNameTemplate>
      <span class="col width-10">
        <a
          (click)="navigateToSubSupplierProfile()"
          class="sub-supplier-link hover_pointer"
        >
          {{ item.name }}
        </a>
      </span>
    </ng-template>
    <div class="col width-10">
      <div class="collabel">
        {{ item.location }}
      </div>
    </div>
    <div class="col width-15">
      <div class="collabel">
        {{ item.personOfContact.email }}
      </div>
    </div>
    <div class="col width-10">
      <div class="collabel">
        {{ item.countriesServed }}
      </div>
    </div>
    <div class="col fixed-col">
      <tsm-relation-status
        [customStyles]="relationStatusStyles"
      ></tsm-relation-status>
    </div>
    <div
      #rejectReasonContainer
      class="col width-25 line-clamp"
      [ngbTooltip]="tipContent"
      #tooltipBody="ngbTooltip"
      tooltipShouldOpen="true"
      placement="bottom-left top-left"
      [disableTooltip]="!isTextClamped"
      container="body"
      tooltipClass="tooltip-sub-suppliers"
    >
      {{ item.rejectionReason }}
    </div>
    <ng-template #tipContent>
      <div class="tip-Content" [innerHTML]="tooltipMessage"></div>
    </ng-template>
  </div>
</div>
