import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AlertPropsType,
  ButtonPropsType,
  ComponentConfig,
  DynamicContext,
  DynamicFormComponent
} from "@lsl16/sustainability-shared-components"
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MetadataService } from "../../services/metadata/metadata.service";
import { RiskassessmentService } from 'src/app/services/riskassessment/riskassessment.service';

@Component({
  selector: 'tsm-pop-up-bibda-score',
  templateUrl: './pop-up-bibda-score.component.html',
  styleUrls: ['./pop-up-bibda-score.component.sass'],
})
export class PopUpBibdaScoreComponent implements OnInit, AfterViewInit {
  @Input() confirmType;
  supplierTsmId: any;
  loading: boolean;
  constructor(
    public activeModal: NgbActiveModal,
    private metadataService: MetadataService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private riskAssessmentService: RiskassessmentService,
    private router: Router
  ) {}

  @ViewChild('form', { static: false }) form: DynamicFormComponent;
  showComponent = false;
  callingComponent: String = 'tsm-pop-up-bibda-score';
  configs: Array<ComponentConfig> = [];
  bibdaScoringConfig: Array<ComponentConfig> = [];
  context: DynamicContext;

  async ngOnInit(): Promise<void> {
    this.context = new DynamicContext();
    //To set the BIBDA score popup form in to local storage
    if (localStorage.getItem('bibdaScoringConfig') === null) {
      this.bibdaScoringConfig = await this.metadataService.bibdaScoringConfig();
      localStorage.setItem(
        'bibdaScoringConfig',
        JSON.stringify(this.bibdaScoringConfig)
      );
    }
    this.configs = JSON.parse(localStorage.getItem('bibdaScoringConfig'));
    this.showComponent = true;
  }

  async ngAfterViewInit(): Promise<void> {
    await new Promise((f) => setTimeout(f, 1000));
    this.form.formGroup.controls.bibdaCaseId.patchValue(
      this.confirmType.bibdaCaseId
    );
    this.cdr.detectChanges();
    this.route.queryParams.subscribe((params: Params) => {
      this.supplierTsmId = params['supplierTsmId'];
    });
  }

  cancelProps: ButtonPropsType = {
    label: 'Cancel',
    backgroundColor: 'white',
    color: 'purple',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: '14px 5px',
    margin: '0 0 0 2px',
    onClick: async () => {
      await this.cancel();
    },
  };

  continueProps: ButtonPropsType = {
    label: 'Submit',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    height: 48,
    borderRadius: 25,
    padding: '14px 10px',
    margin: '0 0 0 2px',
    iconHeight: 14,
    iconWidth: 14,
    iconPosition: 'right',
    onClick: async () => {
      await this.sendBIBDAScore();
    },
  };

  async cancel(): Promise<any> {
    this.activeModal.close();
  }

  async sendBIBDAScore() {
    if (this.form.validate()) {
      this.loading = true;
      const tempValues = this.form.values();
      if (
        this.confirmType.bibdaCaseId.toLowerCase() !==
        tempValues.bibdaCaseId.toLowerCase()
      ) {
        // update bibda case id
        const reposnseBIBDA = await this.updateBIBDACaseId(
          tempValues.bibdaCaseId
        );
      }
      let userId = JSON.parse(localStorage.getItem("userInfo")).pocEmail;
      const payload = {
        ra_id: this.confirmType.raId,
        bibda_score: tempValues.bibdaReviewScore,
        comment: tempValues.bibdaComments,
        BIApproverId:userId
      };
      const riskAssessmentData = await this.riskAssessmentService
        .sendBIBDAScore(payload)
        .then((res: any) => {
          this.activeModal.close();
          this.loading = false;
          if (res.msg == 'Success') {
            this.riskAssessmentService.bibdaEventEmit.emit(true);
            this.router.navigate([`supplier-profile/${this.supplierTsmId}`]);
            window.scrollTo(0, 0);
          }
        });
    }
  }
  async updateBIBDACaseId(bibdaCaseId) {
    const payload = {
      ra_id: this.confirmType.raId,
      bibdaCaseId: bibdaCaseId,
    };
    return await this.riskAssessmentService.updateBibdaCaseId(payload);
  }
}
