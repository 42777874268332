import { Component, OnInit } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";
import { Termination } from "src/app/models/Termination";

@Component({
    selector: 'tsm-termination-request-board',
    templateUrl: './termination-request-board.component.html',
    styleUrls: ['./termination-request-board.component.sass']
})
export class TerminationRequestBoardComponent implements OnInit {
    public termination: Termination[];
    pageNumber: number = 1;
    totalNumberOfPages: number = 0;
    noDataFound: boolean = false;
    pageParams: any = {
        page: 1,
        pageSize: 10
    };

    constructor(private httpService: HttpService) { }

    async ngOnInit(): Promise<void> {
        await this.showTable();
    }

    async showTable() {
        if (this.pageNumber) {
            this.pageParams.page = this.pageNumber
        }
        const getRecordsUrl = `${environment.tsmBackendServiceURL}/terminate/getRecords?page=${this.pageParams.page}&pageSize=${this.pageParams.pageSize}`;
        const response: any = await this.httpService.GetPromise(getRecordsUrl);
        const { numberOfPages, data } = response;
        if (data.length === 0) {
            this.noDataFound = true;
        } else {
            this.termination = data;
            this.totalNumberOfPages = numberOfPages;
        }
    }

    handleCurrentPage = (currentPage: number): void => {
        this.pageNumber = currentPage;
        window.scrollTo(0, 270);
        this.showTable();
    };
}
