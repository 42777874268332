<div class="container-fluid mb-5 dashBoard-search">
  <div class="main_bg">
    <sh-alert *ngIf="shouldShowSuccessTerminateAlert" style="width: 100%" [props]="successTerminateAlertProps"></sh-alert>
    <div
      class="container container-fluid first-container d-flex"
      style="background-color: white"
    >
      <tsm-search
        [(page)]="pageNumber"
        [(lastQuery)]="lastQuery"
        [categoryFilters]="categoryFilters"
        (hide)="hide()"
        (show)="show()"
        (addNewSupplierEmitter)="handleAddSupplier()"
        (supplierSearchResultsEventEmitter)="handleSearchResults($event)"
        (handleEmptyResult)="handleEmptyResult()"
        (restPage)="resetPageNumber()"
        (changeCurrentPage)="changeCurrentPage($event)"
      ></tsm-search>
    </div>
    <div *ngIf="hiden">
      <tsm-supplier-card-list
        id="supplier-card-list"
        *ngIf="!emptyResult; else noResults"
        [suppliers]="suppliers"
        enableIndirectTag="true"
      ></tsm-supplier-card-list>
      <ng-template #noResults class="ms-5 pb-3 no-results">
        <p
          class="nofound-message"
          style="font-size: 20px; font-weight: bold; line-height: 80px"
        >
          Sorry, we did not find any results matching the search criteria
        </p>

        <p
          class="nofound-message"
          style="font-weight: 500; font-family: Graphik; font-size: 20px"
        >
          Search tips: <br />· Make sure all words are spelled correctly <br />
          - Approximate results won't be shown <br />- Try the advanced search
          <br />- Try different keywords <br />- Try fewer words
        </p>
        <p
          class="nofound-message"
          style="font-weight: 500; font-family: Graphik; font-size: 20px"
        >
          If there is still no record found, please add a new supplier
        </p>
      </ng-template>
    </div>
  </div>
  <tsm-pagination-bar
    *ngIf="totalNumberOfPages !== 0 && hiden"
    class="tsm-pagination-bar"
    [pages]="totalNumberOfPages"
    (currentPageChange)="handleCurrentPage($event)"
    [currentPage]="pageNumber"
  ></tsm-pagination-bar>
  <tsm-add-new-supplier
    *ngIf="shown"
    (addNewSupplierEmitter)="handleAddSupplier()"
  ></tsm-add-new-supplier>
</div>
