import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { MetadataService } from "src/app/services/metadata/metadata.service";
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import {
    ButtonPropsType,
    ComponentConfig,
    DynamicContext,
    DynamicFormComponent,
    HttpService,
    SupplierRelationshipService,
    TaxTypeService
} from "@lsl16/sustainability-shared-components";
import { ModalService } from "src/app/services/modal/modal.service";
import { Profile, ProfileConfig } from "./profile";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { AlertPropsType } from "@lsl16/sustainability-shared-components/lib/models/AlertPropsType";
import { AuthService } from "../../services/auth/auth.service";
import { TranslateService } from '@ngx-translate/core';
import { Role } from "src/app/models/LoginUser";
import { Validators } from "src/app/questionnaire/constants";
import { isEmpty } from 'lodash';
import { EventService } from "src/app/services/EventService.service";
import { environment } from "src/environments/environment";
import { FormControl } from "@angular/forms";
@Component({
    selector: "tsm-profile-details",
    templateUrl: "./profile-details.component.html",
    styleUrls: ["./profile-details.component.sass"]
})
export class ProfileDetailsComponent implements OnInit {
    @Input() confirmType: ProfileConfig;
    supplier: GeneralSupplierType;
    profileData: Profile;
    configs: Array<ComponentConfig> = [];
    model: any;
    context: DynamicContext;
    error: boolean;
    verifyAlertVisiable: boolean;
    VerifyAlertProps: AlertPropsType;
    alltaxType: any;
    selectedCountry: string;
    taxTypes: Array<ComponentConfig> = [];
    @ViewChild("form", { static: false }) form: DynamicFormComponent;
    buttonProps: ButtonPropsType = {
        label: "Save",
        backgroundColor: "blue",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        width: 96,
        height: 48,
        padding: "16px 10px",
        margin: "0 0 0 2px",
        onClick: async () => {
            await this.save();
        }
    };
    cancelProps: ButtonPropsType = {
        label: "Cancel",
        backgroundColor: "white",
        color: "darkBlue",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        height: 48,
        borderRadius: 25,
        borderColor: 'gray',
        padding: "14px 5px",
        margin: "0 0 0 2px",
        onClick: async () => {
          await this.cancel();
        }
      };
    profileDetailsJson: any;
    taxIdFlag: boolean = false;
    initJson: any;
    title: string;
    languageButton: string;
    editButton: boolean;
    isVerify: boolean;
    currentRole: Role;
    countyLocationFlag: boolean = true;
    languageText: string = '';
    languageList = [];
    showText: boolean = false;
    languageTextList = [];
    languageButtonList = [];
    dataOnbording: any = [];
    taxobj: any;
    getAlltaxObj: any;
    taxTypesobj: any;
    showTaxFields: Boolean = false;

    constructor(
        private authService: AuthService,
        private modalService: ModalService,
        private metadataService: MetadataService,
        public activeModal: NgbActiveModal,
        private supplierGeneralProfileService: SupplierGeneralProfileService,
        private translateService: TranslateService,
        private httpService: HttpService,
        private supplierRelationshipService: SupplierRelationshipService,
        private eventService: EventService,
        private taxTypeService: TaxTypeService,
        private cdr: ChangeDetectorRef
    ) { }

    async ngOnInit() {
        this.profileDetailsJson = {
            profileTitle: ''
        }
        this.currentRole = this.authService.getRole();
        this.editButton = false;
        this.isVerify = false;
        this.languageButton = '';
        this.confirmType.supplier['supplierClassificationNew'] = this.confirmType.supplier.supplierClassification;
        this.supplier = this.confirmType.supplier;
        const taxDataUrl = `${environment.tsmBackendServiceURL}/tax/getAllTaxMappingData?tsmid=${this.supplier.supplierTsmId}`;
        this.taxobj = await this.httpService.GetPromise(taxDataUrl);
        const getAlltax = `${environment.tsmBackendServiceURL}/tax/getAllCountryTaxData`
        this.getAlltaxObj = await this.httpService.GetPromise(getAlltax);
        await this.getOnbordingData(this.supplier.supplierTsmId);
        this.profileData = this.modalService.getData(this.confirmType.type);
        this.buttonProps.label = this.profileData.buttonText;
        if (this.confirmType.supplier.classification) {
            this.confirmType.supplier.supplierClassification = this.confirmType.supplier.classification;
            delete this.confirmType.supplier.classification;
        }
        this.model = this.confirmType.supplier;
        this.context = new DynamicContext();
        const initialCountry = this.model.countryLocation;
        this.configs = await this.metadataService.gettingProfileDetailsConfig();
        this.handleSupplierClassificationConfig();
        
        let role = this.authService.getRole();
        if (role !== "supplier" && this.configs[8].fieldset[0].dataSource.name === 'allCommunicationsLanguage') {
            this.configs.pop();
        }
        if ((role !== "supplier" || this.authService.isSustainabilityExpert() || this.authService.isSystemSupplierAdmin()) && this.configs[1].fieldset[0].dataSource.name === "supplierClassificationNew" && this.supplier?.supplierClassification) {
            this.configs[1].fieldset[0].disabled = true;  
            this.model = { ...this.model, supplierClassificationNew: this.supplier?.supplierClassification };
        }
        if (role === "supplier" || this.supplier.status === 'Pending') {
            let countyLocationConfig = this.configs.find(x => x.fieldset[0].name === "countryLocation");
            if (countyLocationConfig) {
                countyLocationConfig.fieldset[0].disabled = false;
                this.countyLocationFlag = false;
            }
        }
        this.showText = true;
        this.form.executeAfterformGroupInit(async () => {
            // disable global parent duns field if parent-child relationship status exists & enable if status is 'NoParent'
            if (role !== 'authority') {
                await this.disableAndEnableParentDunsControl();
            }
        });
        if (initialCountry) {
            this.handleCountrySelection(initialCountry);
        }
        this.taxTypeService.selectedCountry$.subscribe(async (country) => {
            this.handleCountrySelection(country);
        });
        this.initLanguage();
        this.initMultiLanguage();
        this.setAlertProps();
        let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
        if (tsmRoot) {
            tsmRoot.setAttribute("aria-hidden", "false");
        }
    }
 
    handleCountrySelection = async (country: any) => {
        if (!country) {
            return;
        }
        this.taxTypesobj = this.getAlltaxObj?.taxData.map((tax) => {
            return tax.taxType.replace(/[^\w ]/gi, '').replace(/\s/g, '');
        });
        this.model = Object.keys(this.model)
          .filter((objKey) => !this.taxTypesobj.includes(objKey))
          .reduce((newObj, key) => {
            newObj[key] = this.model[key];
            return newObj;
          }, {});
        this.model.countryLocation = country;
        this.taxobj.forEach((tax) => {
            const cleanedTaxType = tax.tax_type.replace(/[^\w ]/gi, '').replace(/\s/g, '');
            if (tax.country === country) {
               if (!this.supplier.hasOwnProperty(cleanedTaxType)) {
                    this.supplier[cleanedTaxType] = tax.tax_registration_number;
                }
                if (!this.model.hasOwnProperty(cleanedTaxType)) {
                    this.model[cleanedTaxType] = tax.tax_registration_number;
                }
            } else {
                this.model[cleanedTaxType] = '';
            }
            this.model = {...this.model};
        });
        this.selectedCountry = country;
        this.alltaxType = await this.taxTypeService.getCountryTaskType(country);
        this.resetOnClear();
        let taxTypeStartIndex = this.configs.findIndex(x => x.fieldset[0].isDynamicTaxField);
        if (taxTypeStartIndex > -1) {
            this.taxTypes.forEach((ele)=>{
                this.form.formGroup.removeControl(ele.fieldset[0].name)
            })
            this.configs.splice(taxTypeStartIndex, this.taxTypes.length)
            this.taxTypes = [];
        }

        const localTaxfeilds = [];  
        for(let i =0; i< this.alltaxType.taxData.length; i++) {
            if(this.alltaxType.taxData[i].isDropdown === true && this.alltaxType.taxData[i].isHide === false){
                this.configs.forEach((config) => {
                    if(config.fieldset[0].name === "brnTaxIdType" || config.fieldset[0].name === "brnTaxId") {
                        config.fieldset[0].hide = false ;
                        this.showTaxFields = true;
                    }
                })
                break;
            }
        }

        this.alltaxType.taxData.forEach((tax) => {
            if(tax.isDropdown === true  && tax.isHide===false){
                localTaxfeilds.push({Code: tax.taxType , Text: tax.taxType})
            }
            else if(tax.isDropdown !== true  && tax.isHide!==true){
            const cleanedTaxType = tax.taxType.replace( /[^\w ]/gi,'').replace( /\s/g,'');
            let taxfield = {
                type: 'layout',
                fieldset: [
                    {
                        "index": "0",
                        "questionSetId": "de4aec6f-f810-4f18-aaf3-df07072bd94f",
                        "section": "gettingProfileDetailsConfig",
                        "questionOrder": 8,
                        "width": "100%",
                        "required": tax.isRequired,
                        "templateOptions": {
                            "tooltip": false,
                            "canFilter": false,
                            "label": tax.taxType,
                            "placeholder": "Insert " + tax.taxType
                        },
                        "validateOn": "change",
                        "name": cleanedTaxType,
                        "type": "input",
                        "hide": false,
                        "isDynamicTaxField": true
                    }
                ],
                templateOptions: {}
            }
            this.configs.push(taxfield);
            this.taxTypes.push(taxfield);
        }
        });
        this.configs.forEach((config) => {
            if(config.fieldset[0].name === "brnTaxIdType") {
                 config.fieldset[0].data = localTaxfeilds;
            }
        })
        this.configs = [...this.configs]
        this.configs.sort((a, b) => {
            const orderA = a.fieldset[0].questionOrder || 0;
            const orderB = b.fieldset[0].questionOrder || 0;
            return orderA - orderB;
        });
        if (this.form.formGroup) {
            this.form.formGroup.addControl('countryLocation', new FormControl(country));
            this.cdr.detectChanges();      
        }
        this.updateCountryLocation(country);
    }
    updateCountryLocation(updatedCountry: string) {
        const countryLocationControl = this.form.formGroup.get('countryLocation');
        if (countryLocationControl) {
            countryLocationControl.setValue(updatedCountry);
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        this.taxTypeService.updateSelectedCountry("");
    }

    resetOnClear() {
        const a = document.getElementById("countryLocation");
        a.oninput = (e: any) => {
            if (!e.currentTarget.value) {
                this.taxTypes.forEach(taxType => {
                    taxType.fieldset[0].hide = true;
                });
            }
            this.configs.forEach((config) => {
                if(config.fieldset[0].name === "brnTaxIdType" || config.fieldset[0].name === "brnTaxId") {
                   config.fieldset[0].hide = true
               }
            })
            this.showTaxFields = false;
        };
        this.model['brnTaxIdType']='';
        this.model['brnTaxId']='';
    }
    async cancel(): Promise<any> {
        this.activeModal.close();
      }
    private handleSupplierClassificationConfig() {
        const isSustainabilityExpert = this.authService.isSustainabilityExpert();
        const isSystemSupplierAdmin = this.authService.isSystemSupplierAdmin();

        //remove the supplier classification dropdown
        if (!isSustainabilityExpert && !isSystemSupplierAdmin) {
            this.configs = this.configs.filter(x => x.fieldset[0].name !== "supplierClassification");
        }

        //all fields except Classification needs to be non-editable for sustainability expert
        if (isSustainabilityExpert && !isSystemSupplierAdmin) {
            this.configs.forEach((config) => {
                if (config.fieldset[0].name !== "supplierClassification") {
                    config.fieldset[0].disabled = true;
                }
            });
        }
    }

    async getOnbordingData(supplierTsmId) {
        const tempServedCountryDataUrl = `${environment.tsmBackendServiceURL}/search/supplier/supplierOnBoardingResult`;
        this.dataOnbording = await this.httpService.PostPromise(tempServedCountryDataUrl, { supplierTsmId: supplierTsmId })
    }


    initLanguage() {
        const language = localStorage.getItem("current language");
        // current CountryServed language
        let currentCSLang = localStorage.getItem('currentCountryServed');

        if (null != language && this.currentRole === 'supplier') {
            this.translateService.use(language);
            if (this.supplier?.saCountryServed) {
                this.setLanguageListByCountryServed(this.supplier?.saCountryServed);
            } else {
                this.setLanguageByCountryServed(currentCSLang);
            }
        } else {
            this.setLanguageByCountryServed("");
        }
        let title;
        let publicMassage;
        this.translateService.get('supplier.questionnire.title').subscribe({
            next: (res: any) => {
                title = res
                localStorage.setItem(
                    "title", JSON.stringify(res)
                )
            }
        });
        this.translateService.get('supplier.questionnire.publicMassage').subscribe({
            next: (res: any) => {
                publicMassage = res
                localStorage.setItem(
                    "publicMassage", JSON.stringify(res)
                )
            }
        });
        this.translateService.get('supplier.questionnire.Getting Started').subscribe({
            next: (res: string) => {
                localStorage.setItem(
                    "Getting Started", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translateService.get('supplier.questionnire.Environment').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Environment", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translateService.get('supplier.questionnire.Human Rights & Social Impact').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Human Rights & Social Impact", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translateService.get('supplier.questionnire.Supplier Inclusion & Diversity').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Supplier Inclusion & Diversity", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translateService.get('supplier.questionnire.Ethics & Sustainability Management').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Ethics & Sustainability Management", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
    }

    setLanguageButton(language) {
        switch (language) {
            case 'english':
                this.languageButton = 'English'
                break;
            case 'spainish':
                this.languageButton = 'Español'
                break;
            case 'portuguese':
                this.languageButton = 'Português'
                break;
            case 'french':
                this.languageButton = 'Français'
                break;
            case 'italiano':
                this.languageButton = 'Italiano'
                break;
            case 'chinese':
                this.languageButton = '中文'
                break;
            case 'german':
                this.languageButton = 'Deutsch'
                break;
            case 'japanese':
                this.languageButton = '日本語'
                break;
            case 'latam':
                this.languageButtonList.push('Português (Brasil)')
                this.languageButtonList.push('LATAM Español')

            default:
                break;
        }
    }

    setLanguageByCountryServed(currentCSLang) {
        let currentLanguage = localStorage.getItem("current language");
        switch (currentCSLang) {
            case 'ES':
            case 'AD':
                this.languageText = 'spainish';
                break;
            case 'PT':
                this.languageText = 'portuguese';
                break;
            case 'FR':
            case 'BE':
            case 'LU':
            case 'MA':
            case 'VN':
                this.languageText = 'french';
                break;
            case 'IT':
                this.languageText = 'italiano';
                break;
            case 'HK':
            case 'TW':
            case 'CN':
                this.languageText = 'chinese';
                break;
            case 'CH':
            case 'AT':
            case 'DE':
            case 'RS':
                this.languageText = 'german';
                break;
            case 'JP':
                this.languageText = 'japanese';
                break;
            case 'AR':
            case 'EC':
            case 'CR':
            case 'PE':
            case 'CO':
            case 'MX':
            case 'CL':
            case 'BR':
                this.languageTextList = ['brazil', 'latamspanish'];
                this.languageText = 'latam'
                break;
            default:
                this.languageText = '';
                break;
        }
        if (currentCSLang == '' && currentLanguage != this.languageText) {
            this.switchLanguage('english')
        }
        this.setLanguageButton(this.languageText);
    }

    // initMultiLanguge --- start
    initMultiLanguage() {
        const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
        this.initJson = JSON.parse(JSON.stringify(multiLanguageJson.body.main.profileDetails));
        for (let index = 1; index < this.configs.length; index++) {
            switch (index) {
                case 2:
                    this.initMessage(index, 'label', 'placeholder');
                    this.initErrorMessage(index, 'required');
                    break;
                case 3:
                    this.initMessage(index, 'label', 'placeholder', 'tooltipMessage');
                    this.initErrorMessage(index, 'required', 'companyNameCheck');
                    break;
                case 4:
                    this.initMessage(index, 'label');
                    this.initErrorMessage(index, 'required');
                    break;
                case 5:
                    this.initMessage(index, 'label', 'placeholder');
                    this.initErrorMessage(index, 'required', 'maxlength')
                    break;
                case 6:
                    this.initMessage(index, 'label', 'placeholder', 'tooltipMessage');
                    this.initErrorMessage(index, 'nineDigitsNumber', 'maxlength');
                case 7:
                    this.initMessage(index, 'label', 'placeholder', 'tooltipMessage');
                    this.initErrorMessage(index, 'nineDigitsNumber', 'maxlength', Validators.IDENTICAL_DUNS_NUMBER);
                    break;
                case 8:
                    this.initMessage(index, 'label', 'placeholder', 'selectDefault');
                    this.initErrorMessage(index, 'required');
                    break;
                default:
                    break;
            }
        }
    }
    initErrorMessage(index: any, data: any, data2?: any, data3?: any) {
        this.configs[index].fieldset[0]['errorMessage'][data] = this.initJson[this.configs[index].fieldset[0].name].errorMessage[data];
        if (!!data2) {
            this.configs[index].fieldset[0]['errorMessage'][data2] = this.initJson[this.configs[index].fieldset[0].name].errorMessage[data2];
        }
        if (!!data3) {
            this.configs[index].fieldset[0]['errorMessage'][data3] = this.initJson[this.configs[index].fieldset[0].name].errorMessage[data3];
        }
    }
    initMessage(index: any, data: any, data2?: any, data3?: any) {
        this.configs[index].fieldset[0].templateOptions[data] = this.initJson[this.configs[index].fieldset[0].name][data];
        if (!!data2) {
            this.configs[index].fieldset[0].templateOptions[data2] = this.initJson[this.configs[index].fieldset[0].name][data2];
        }
        if (!!data3) {
            this.configs[index].fieldset[0].templateOptions[data3] = this.initJson[this.configs[index].fieldset[0].name][data3];
        }
    }
    // initMultiLanguge --- end
    async save(): Promise<any> {
        let values: any = {};
        let nameValue = this.form.values().supplierName.trim();
        let webSiteValue = this.form.values().companyWebsite.trim();
        let supplierNameValue = this.form.values().supplierName.trim();
        let classification
        if(Array.isArray(this.form.values().supplierClassification) && this.form.values().supplierClassification !== this.form.values().supplierClassificationNew){
            classification = this.form.values().supplierClassification;
        }
        const dunsNumber = this.form.values().dunsNumber;
        const globalUltimateParentDUNS = this.form.values().globalUltimateParentDUNS;

        if (this.taxTypes.length > 0) {
            this.taxTypes.forEach(taxType => {
                let fieldname = taxType.fieldset[0].name;
                let taxTypeValue = this.form.values()[fieldname].trim();
                this.form.formGroup.controls[fieldname].patchValue(taxTypeValue);
            });
        }
        let companyLegalName = this.form.values().companyLegalName.trim();
        this.form.formGroup.controls.companyLegalName.patchValue(companyLegalName);
        this.form.formGroup.controls.supplierName.patchValue(nameValue);
        this.form.formGroup.controls.companyWebsite.patchValue(webSiteValue);
        this.form.formGroup.controls.supplierName.patchValue(supplierNameValue);
        if(this.showTaxFields){
            const brnTaxIdType = this.form.values().brnTaxIdType;
            const brnTaxId = this.form.values().brnTaxId;
            if (isEmpty(brnTaxIdType)) {
                this.form.formGroup.controls.brnTaxIdType.setErrors({ [Validators.REQUIRED_TAX_TYPE]: true });
                this.form.formGroup.controls.globalUltimateParentDUNS.markAsDirty();
            }
            if (isEmpty(brnTaxId)) {
                this.form.formGroup.controls.brnTaxId.setErrors({ [Validators.REQUIRED_TAX_ID]: true });
                this.form.formGroup.controls.globalUltimateParentDUNS.markAsDirty();
            }
        }
        if (!isEmpty(dunsNumber) && globalUltimateParentDUNS === dunsNumber) {
            this.form.formGroup.controls.globalUltimateParentDUNS.setErrors({ [Validators.IDENTICAL_DUNS_NUMBER]: true });
            this.form.formGroup.controls.globalUltimateParentDUNS.markAsDirty();
        }
        else if (this.form.validate()) {
            //sustainability expert can only change supplier classification
            if (!this.authService.isSustainabilityExpert()) {
                values = this.buildValuesToSave();
            }

            //rename classification attribute
            if (classification || classification === '') {
                values['classification'] = classification || [];
                delete values['supplierClassification'];
            }

            //add operationlog flag
            values['addLogFlag'] = true;
            values['isVerify'] = this.isVerify;
            if(this.authService.isSystemSupplierAdmin()){
                values['countryLocation'] = this.confirmType.supplier.countryLocation;
            }
            if(this.form.values()["brnTaxId"]){
                const brntype = this.form.values()["brnTaxIdType"].replace(/[^\w ]/gi, '').replace(/\s/g, '');
                const brnid = this.form.values()["brnTaxId"].trim();
                values[brntype] = brnid;
            }
            this.supplierGeneralProfileService
                .updateSupplier(this.supplier.supplierTsmId, values)
                .then((response) => {
                    if (response && response.$metadata && response.$metadata.httpStatusCode && response.$metadata.httpStatusCode === 200) {
                        this.verifyAlertVisiable = false;
                        if (localStorage.getItem('multiLanguageButtonShow')) {
                            localStorage.removeItem('multiLanguageButtonShow');
                        }
                        this.supplierGeneralProfileService.eventEmit.emit("verifySuccess#" + this.confirmType.supplier.currentcountryServed);
                        this.authService.refreshPocProfileInfoInLocalStorage(this.supplier.supplierTsmId);

                        const storageCountryLocation = localStorage.getItem("currentCountryLocation");
                        if (!!storageCountryLocation && storageCountryLocation !== values.countryLocation && storageCountryLocation.concat(values.countryLocation).includes("China/Mainland")) {
                            this.eventService.privacyStatementEmit.emit({ country: values.countryLocation, isFetchPSVersion: true });
                            localStorage.setItem("currentCountryLocation", values.countryLocation);
                        }
                        this.supplierRelationshipService.editProfileEventEmit.next(this.form.values());
                        this.activeModal.close();
                    } else {
                        this.verifyAlertVisiable = true;
                    }

                })
                .catch((response) => {
                    this.error = true;
                    //show failed alert
                    this.verifyAlertVisiable = true;
                });
        }
    }
    setAlertProps = (): void => {
        this.VerifyAlertProps = {
            type: "alert-profileVerifiedFaild",
            message: "There is a technical issue, please try again or contact us.",
            icon: "assets/images/icon-error.svg",
            iconFill: "#ffe8e6",
            iconClose: "assets/images/icon-cross-blue.svg",
            onDismiss: () => {
                this.verifyAlertVisiable = false;
            }
        };
    };

    private buildValuesToSave() {
        console.log("current role", this.currentRole);
        const values = this.form.values();
        if (this.countyLocationFlag) {
            delete values.countryLocation;
        }
        if (!(Array.isArray(values.companyLogo) && values.companyLogo.length)) {
            delete values.companyLogo;
        }
        if (this.currentRole === 'supplier') {
            values.status = "Active";
        }
        values.joinDate = this.supplier.joinDate || new Date().toJSON();
        values.lastUpdatedDate = new Date().toJSON();
        values.pocEmail = this.authService.getLoginUser().email;
        return values;
    }

    setEditButton() {
        if (this.currentRole === 'supplier') {
            if (localStorage.getItem("multiLanguageButtonShow") && localStorage.getItem("multiLanguageButtonShow") == 'true') {
                this.editButton = true;
                this.isVerify = true;
            }
        }
    }

    switchLanguage(language: string) {
        this.translateService.use(language);
        localStorage.setItem("current language", language);
        this.setLanguageButton(this.languageText);
        this.translateService.get('supplier').subscribe({
            next: (res: string) => {
                localStorage.setItem(
                    "multiLanguage", JSON.stringify(res)
                )
                this.initMultiLanguage();
            }
        });
        let title;
        let publicMassage;
        this.translateService.get('supplier.questionnire.title').subscribe({
            next: (res: any) => {
                title = res
                localStorage.setItem(
                    "title", JSON.stringify(res)
                )
            }
        });
        this.translateService.get('supplier.questionnire.publicMassage').subscribe({
            next: (res: any) => {
                publicMassage = res
                localStorage.setItem(
                    "publicMassage", JSON.stringify(res)
                )
            }
        });
        this.translateService.get('supplier.questionnire.Getting Started').subscribe({
            next: (res: string) => {
                localStorage.setItem(
                    "Getting Started", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translateService.get('supplier.questionnire.Environment').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Environment", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translateService.get('supplier.questionnire.Human Rights & Social Impact').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Human Rights & Social Impact", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translateService.get('supplier.questionnire.Supplier Inclusion & Diversity').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Supplier Inclusion & Diversity", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
        this.translateService.get('supplier.questionnire.Ethics & Sustainability Management').subscribe({
            next: (res: any) => {
                localStorage.setItem(
                    "Ethics & Sustainability Management", JSON.stringify(Object.assign(res, publicMassage, title))
                )
            }
        });
    }

    ngAfterContentChecked() {
        if (localStorage.getItem("multiLanguage")) {
            const multiLanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
            if (multiLanguageJson.body != undefined) {
                this.profileDetailsJson = JSON.parse(JSON.stringify(multiLanguageJson.body.main.profileDetails));
                this.setEditButton();
                const message = "There is a technical issue, please try again or contact us.";
                this.VerifyAlertProps = {
                    type: "alert-profileVerifiedFaild",
                    message: multiLanguageJson.body.sharedComponent.alert[message],
                    icon: "assets/images/icon-error.svg",
                    iconFill: "#ffe8e6",
                    iconClose: "assets/images/icon-cross-blue.svg",
                    onDismiss: () => {
                        this.verifyAlertVisiable = false;
                    }
                };
            }
        }
    }

    async disableAndEnableParentDunsControl() {
        const pcRelation = await this.supplierRelationshipService.getParentChildRelation(this.supplier.supplierTsmId);
        const controlParentDuns = this.form.formGroup.controls.globalUltimateParentDUNS;
        if (pcRelation && pcRelation.status && controlParentDuns) {
            if (pcRelation.status === "NoParent") {
                controlParentDuns.enable();
            }
            else if (pcRelation.status !== "Open") {
                if (controlParentDuns) { controlParentDuns.disable(); }
            }
        }
        this.languageList = [
            { value: "english", name: "English" }
        ];
        const languageListTmp = this.languageTextList.map((value, index) => ({ value, name: this.languageButtonList[index] }));
        this.languageList = this.languageList.concat(languageListTmp);
    }
    headerRole(): string {
        return this.authService.getRole();
    }
    setLanguageListByCountryServed(inSaCountryServed) {
        inSaCountryServed.forEach(item => {
            this.setLanguageByCountryServed(item);
            if (this.languageText && this.languageTextList.indexOf(this.languageText) < 0) {
                //Ignoring this push as for latam we are adding two contries in the above logic
                if (this.languageText !== 'latam') {
                    this.languageTextList.push(this.languageText);
                    this.languageButtonList.push(this.languageButton);
                }
            }
        })
    }
}