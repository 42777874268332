import { WorkflowService } from '@lsl16/sustainability-shared-components';
import { PopUpNotificationRedirectErrorComponent } from '../../pop-up-notification-redirect-error/pop-up-notification-redirect-error.component';
import { AuthService } from './../../../services/auth/auth.service';
import { Component, ElementRef, Input, SecurityContext, ViewChild, ViewEncapsulation } from '@angular/core';
import { ButtonPropsType, SAStatus, SupplierRelationshipService } from '@lsl16/sustainability-shared-components';
import { ActionLink, ButtonStyle, ToolNotification } from 'src/app/models/ToolNotification';
import { NotificationService, NotificationMode } from 'src/app/services/notification/notification.service';
import { SupplierService } from "src/app/services/supplier/supplier.service";
import { SupplierGeneralProfileService } from '../../../services/supplier-general-profile.service';
import { GeneralSupplierType } from '@lsl16/sushub-frontend';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tsm-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationItemComponent {
  @Input() notification: ToolNotification;
  @Input() mode: NotificationMode;
  @ViewChild("messageDiv") messageDiv: ElementRef;

  public translations = {
    alertMessage: '',
    message: '',
    actionLabels: {},
  };

  private primaryButtonProps: ButtonPropsType = {
    label: '',
    backgroundColor: 'blue',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    margin: '0 0 0 2px',
    hoverBackgroundColor: 'darkBlue',
    hoverColor: 'white',
    height: 30,
    onClick: () => this.primaryButtonOnClick()
  };

  private secondaryButtonProps: ButtonPropsType = {
    label: '',
    color: "white",
    backgroundColor: "greyDark",
    borderColor: "greyDark",
    hoverColor: "white",
    hoverBackgroundColor: "greyDark",
    hoverBorderColor: "greyDark",
    fontSize: 16,
    fontFamily: 'Graphik-Medium',
    margin: '0 0 0 2px',
    height: 30,
    onClick: () => this.secondaryButtonOnClick()
  };

  private primaryButtonAction: string;
  private primaryButtonEnable: boolean = false;
  private secondaryButtonAction: string;
  private secondaryButtonEnable: boolean = false;
  private isSupplierUser: boolean = false;

  private document = document;

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private supplierService: SupplierService,
    private supplierRelationshipService: SupplierRelationshipService,
    private supplierGeneralProfileService: SupplierGeneralProfileService,
    private ngbModalService: NgbModal,
    private workflowService: WorkflowService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.isSupplierUser = this.authService.getRole() === 'supplier'
  }

  getButtonProps(action: ActionLink): ButtonPropsType {
    if (action.buttonStyle && action.buttonStyle === ButtonStyle.primary) {
      this.primaryButtonProps.label = this.getButtonLabel(action);
      if (action.action) {
        this.primaryButtonEnable = true;
        this.primaryButtonAction = action.action;
      }
      return this.primaryButtonProps;
    }
    else if (action.buttonStyle && action.buttonStyle === ButtonStyle.secondary) {
      this.secondaryButtonProps.label = this.getButtonLabel(action);
      if (action.action) {
        this.secondaryButtonEnable = true;
        this.secondaryButtonAction = action.action;
      }
      return this.secondaryButtonProps;
    }
  }

  private getButtonLabel(action: ActionLink): string {
    const label = this.translations.actionLabels[action.title];
    if (!label) {
      console.error(`No action label found for action ${action.title}`);
      return "";
    }

    return label;
  }

  async deleteNotification() {
    try {
      await this.notificationService.deleteToolNotification(this.mode, this.notification.notificationId);
    } catch (error) {
      console.error('Failed to delete notification', error);
    }
  }

  async primaryButtonOnClick() {
    if (this.primaryButtonEnable) {
      await this.executeAction(this.primaryButtonAction);
    }
  }

  async secondaryButtonOnClick() {
    if (this.secondaryButtonEnable) {
      await this.executeAction(this.secondaryButtonAction);
    }
  }

  async executeAction(actionLink: string) {
    this.closeNotificationPanel();
    if (await this.canPerformAction(actionLink)) {
      await this.handleActionLink(actionLink);
    } else {
      this.displayErrorMessage('errorAlert');
    }
  }

  async handleActionLink(actionLink: string) {
    if (this.isLinkToQuestionnaire(actionLink)) {
      const modifiedActionLink = await this.retrieveMissingActionLinkParams(actionLink);
      this.notificationService.emitNotificationEvent('redirectToQuestionnaire', modifiedActionLink);
    }
    else if (this.isLinkToAppoveSupplier(actionLink)) {
      const splittedUrl = actionLink.split('/');
      const workflowId = splittedUrl[splittedUrl.length - 1];
      if (await this.checkState(workflowId)) {
        this.document.location.href = actionLink;
      }
      else {
        this.displayErrorMessage('requestClosedInternalUser');
      }
    }
    else {
      this.document.location.href = actionLink;
    }
  }

  public async checkState(workflowId) {
    const response = await this.workflowService.getWorkflowById(workflowId);

    // Potentially an error occurred
    const hasError = !response.state && response.msg;
    if (hasError) {
      console.error(`Error reported from API:`, response.msg);
      return false;
    }

    const isInvitationRequestOpen = response.state && response.state === "AwaitingInviteApproval";
    if (isInvitationRequestOpen) {
      return true;
    }

    return false;
  }

  private async retrieveMissingActionLinkParams(actionLink: string): Promise<string> {
    const paramString = actionLink.split('?')[1];
    let queryParams = new URLSearchParams(paramString);

    const supplierId = queryParams.get('supplierTsmId');
    const countryServed = queryParams.get('countryServed')?.split(',')[0];

    if (!supplierId) {
      throw new Error("Url does not contain supplier id. Cannot open SA.");
    }

    if (!countryServed) {
      throw new Error("Url does not contain the country served for the supplier. Cannot open SA.");
    }

    const supplier = await this.supplierGeneralProfileService.getSupplierById(supplierId);

    const isScope3Supplier = this.valueFromParamOrDefault(queryParams, 'isScope3Supplier', this.isSupplierClassScope3(supplier) ? "true" : "false");
    const supplierName = this.valueFromParamOrDefault(queryParams, 'supplierName', supplier.supplierName);

    const parentSupplierTsmId = this.valueFromParamOrDefault(queryParams, 'parentSupplierTsmId', supplier.parentTsmId)
    const assignedToEmail = this.valueFromParamOrDefault(queryParams, 'assignedToEmail', JSON.parse(localStorage.getItem('userInfo')).pocEmail);

    queryParams.set('isScope3Supplier', isScope3Supplier);
    queryParams.set('supplierName', supplierName);
    queryParams.set('assignedToEmail', assignedToEmail);

    if (parentSupplierTsmId) {
      queryParams.set('parentSupplierTsmId', parentSupplierTsmId);
    }

    return actionLink.split('?')[0] + "?" + queryParams.toString();
  }

  private valueFromParamOrDefault(queryParams: URLSearchParams, paramName: string, defaultValue: string): string {
    if (queryParams.has(paramName)) {
      return queryParams.get(paramName);
    }

    return defaultValue;
  }

  private isSupplierClassScope3(supplier: GeneralSupplierType): boolean {
    return supplier?.classification?.includes("SCOPE3_KEY_SUPPLIER");
  }

  private isLinkToQuestionnaire(actionLink: string): boolean {
    return actionLink.includes("/#/esg-questionnaire/");
  }

  private isLinkToAppoveSupplier(actionLink: string): boolean {
    return actionLink.includes("/#/add-supplier/") || actionLink.includes("/#/add-sub-supplier/");
  }

  private async canPerformAction(actionLink: string): Promise<boolean> {
    if (this.isSupplierUser) {
      if (this.isLinkToQuestionnaire(actionLink)) {
        // trying to access SA > validate SA links
        const paramString = actionLink.split('?')[1];
        const queryParams = new URLSearchParams(paramString);
        const saSupplierId = queryParams.get('supplierTsmId');
        const isMyNotification = this.isCurrentSuppliersNotification(saSupplierId);
        if (!isMyNotification) { return false; }

        // validate the pc status
        const pcRelation = await this.supplierRelationshipService.getParentChildRelation(saSupplierId);
        if (pcRelation && ['AwaitingParentConfirmation', 'AwaitingInviteApproval'].indexOf(pcRelation.status) > -1) {
          return false;
        }

        // validate SA current status
        const reqDataAssess = {
          country: queryParams.get("countryServed"),
          supplierTsmId: saSupplierId,
          buyerTsmId: "c8aa5c00-588a-44d6-a08c-071dc9219cbe"
        };
        const assessmentStatus = await this.supplierService.getAssessmentStatus(reqDataAssess);
        return assessmentStatus && assessmentStatus.esgStatus &&
          assessmentStatus.esgStatus !== SAStatus.cancelled && assessmentStatus.esgStatus !== SAStatus.withdrew;

      } else if (actionLink.includes("/#/parental-supplier-response")) {
        // trying to access page to confirm/reject 'request for parent relation confirmation'
        // validate whether current supplier is parent of given subsidiary supplier
        const urlParts = actionLink.split("/");
        const subsidiaryTsmId = urlParts[urlParts.length - 1];
        const pcRelation = await this.supplierRelationshipService.getParentChildRelation(subsidiaryTsmId);
        const currentSupplierId = JSON.parse(localStorage.getItem('userInfo')).tsmId;
        return pcRelation && pcRelation.parentTsmId === currentSupplierId && pcRelation.status === "AwaitingParentConfirmation";
      }
    }

    return true;
  }

  private displayErrorMessage(msgLabel) {
    const confirmType = {
      options: {},
      centered: true,
      msgLabel: msgLabel
    };
    const modalRef = this.ngbModalService.open(PopUpNotificationRedirectErrorComponent, confirmType);
    modalRef.componentInstance.confirmType = confirmType;
  }

  private isCurrentSuppliersNotification(supplierTsmId: string) {
    const currentSupplier = JSON.parse(localStorage.getItem('userInfo')).tsmId;
    return currentSupplier === supplierTsmId;
  }

  private closeNotificationPanel() {
    this.notificationService.emitNotificationEvent('closeNotification');
  }

  private buildNotificationMsg(notificationMessages) {
    let msg: string = notificationMessages.messages[this.notification.type];
    if (msg) {
      const params = msg.match(/\${\w+}/gm);
      if (params) {
        for (let i = 0; i < params.length; i++) {
          if (this.notification.context) {
            msg = msg.replace(params[i], `<span class="supplier-name"></span>`);
            if (this.messageDiv) {
              const span = this.messageDiv.nativeElement.getElementsByClassName('supplier-name')[i];
              if (span) {
                span.innerText = this.notification.context[i];
              }
            }
          }
        }
      }
    }

    return msg;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );

      if (multilanguageJson.body != undefined) {
        this.translations.alertMessage = multilanguageJson.body.main.notifications.alertMessage;
        this.translations.actionLabels = multilanguageJson.body.main.notifications.actionLabels;
        this.translations.message = this.buildNotificationMsg(multilanguageJson.body.main.notifications);

        if (!this.translations.message) {
          console.error(`Notification message for type '${this.notification.type}' not found`);
        }
      }
    }
  }
}
