<tsm-subheader [props]="subheaderProps"></tsm-subheader>
<div   class="container-fluid mb-5">
  <div class="main_bg">
    <div class="container container-fluid first-container d-flex">    
      <div class="form m-5 p-5">
        <div class="advanced-search advanced-search-content">
          <div *ngIf="!reTriggerSArecords.length" class="empty-message m-5 p-5">
            <img id="searchImage" src="assets/images/EmptyImage_Too-many-records.svg" alt="emptySearch" class="image" />
          </div>
          <tsm-sa-retrigger-status-preview *ngIf="reTriggerSArecords.length" [statusRecord]="reTriggerSArecords"></tsm-sa-retrigger-status-preview>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loadEffect" style="position: fixed;left: 44%;top:30%;z-index: 1000;" *ngIf="loading">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>