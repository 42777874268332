<div class="header-bar p-5">
  <span class="labels overflow-auto">
    <span class="thick-text"> {{ validatedSuppliersCount }} </span>
    <span class="normal-text"> {{ labels.validatedSuppliers }} </span>
    |
    <span class="thick-text"> {{ errorCount }} </span>
    <span class="normal-text"> {{ labels.errors }}</span>
  </span>
  <sh-button
    *ngIf="displaySendAssessmentButton"
    class="button overflow-auto"
    [props]="sendAssessmentButton"
  >
  </sh-button>
</div>
<tsm-supplier-validation-list
  class="list-component"
  [validationResults]="currentSupplierListFile?.validations.report"
  [massInviteProcessed]="massInviteProcessed"
></tsm-supplier-validation-list>
