import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SubheaderPropsType } from "../../../models/SubheaderProps";

@Injectable()
export class IndividualUserManagementSubheader {
    constructor(private router: Router) {}

    /**
     * Description: Provides props for subheader component in user management page
     * Params: none
     * Output: Subheader props object of type SubheaderPropsType
     */
    getProps = (): SubheaderPropsType => {
        return {
            isWelcomeMessage: false,
            headingPrefix: "Profile",
            headingPrefixColor: "grey",
            heading: "User Profile",
            subheaderButtonProps: {
                label: "Go Back",
                fontSize: 16,
                fontFamily: "Graphik-Medium",
                backgroundColor: "paleGrey",
                borderColor: "paleGrey",
                icon: "assets/icons/chevron_left-24px.svg",
                clickIcon: "assets/icons/chevron_left-24px.svg",
                hoverBackgroundColor: "state-button-contained-secondary-b-h",
                hoverBorderColor: "state-button-contained-secondary-b-h",
                width: 136,
                height: 48,
                padding: "12px 20px 12px 16px",
                onClick: () => this.router.navigateByUrl("/buyer/user")
            }
        };
    };
}
