import { Component, OnInit } from "@angular/core";
import { FAQ, HelpAndSupportResponse, Link } from "src/app/models/HelpAndSupport";
import { HelpAndSupportService } from "src/app/services/helpAndSupport/help-and-support.service";
import {
    AlertPropsType,
    ButtonPropsType,
    HttpService
} from "@lsl16/sustainability-shared-components"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopUpFaqComponent } from "src/app/components/pop-up-faq/pop-up-faq.component";
import { EventService } from "src/app/services/EventService.service";
import { AuthService } from "../../services/auth/auth.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'tsm-bv-help-support',
    templateUrl: './bv-help-support.component.html',
    styleUrls: ['./bv-help-support.component.sass']
})
export class BvHelpSupportComponent implements OnInit {
    disabled: boolean = true;
    publishButton: ButtonPropsType = {
        label: "Publish",
        backgroundColor: "gray",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        height: 48,
        borderRadius: 25,
        padding: "14px 10px",
        margin: "0 0 0 2px",
        disabled: this.disabled,
        onClick: async () => {
            this.operationFAQ("Publish FAQ")
        }
    }
    addButton: ButtonPropsType = {
        label: " 十  Add New FAQ",
        backgroundColor: "blue",
        color: "white",
        fontSize: 16,
        fontFamily: "Graphik-Medium",
        height: 48,
        borderRadius: 25,
        padding: "14px 10px",
        margin: "0 0 0 2px",
        onClick: async () => {
            this.operationFAQ('Add New FAQ');
        }
    }
    faqData: any;
    faqs: any = [];
    faqsArry: any = [];
    current = null;
    preCurrent = null;
    links: Link[];
    helpSupportJson: any;
    describe: null;
    supplierGuideDownloadPDF: any;
    localLanguage: string;
    sendOnboarding: any;
    isAuthority: boolean = true;
    showAlertSuccess: boolean = false;
    successAlert: AlertPropsType;
    showAlertFail: boolean = false;
    failAlert: AlertPropsType;
    isisPublished:boolean = false;
    buyerfaqData : any[];
    faquniqueCat : any[];
    buyerhelp: any[];
    constructor(
        private httpService: HttpService,
        private authService: AuthService,
        private helpAndSupportService: HelpAndSupportService,
        public ngbModalService: NgbModal,
        public eventService: EventService
    ) { }

    async ngOnInit() {
        this.sendOnboarding = this.eventService.eventEmit.subscribe(async (value: any) => {
            this.publishButton = {
                label: "Publish",
                backgroundColor: "blue",
                color: "white",
                fontSize: 16,
                fontFamily: "Graphik-Medium",
                height: 48,
                borderRadius: 25,
                padding: "14px 10px",
                margin: "0 0 0 2px",
                disabled: false,
                onClick: async () => {
                    this.operationFAQ("Publish FAQ")
                }
            }
            if(value.type === "Publish"){
                this.publishButton = {
                    label: "Publish",
                    backgroundColor: "gray",
                    color: "white",
                    fontSize: 16,
                    fontFamily: "Graphik-Medium",
                    height: 48,
                    borderRadius: 25,
                    padding: "14px 10px",
                    margin: "0 0 0 2px",
                    disabled: true,
                    onClick: async () => {
                        this.operationFAQ("Publish FAQ")
                    }
                }
            }
            if (value.status === "success") {
                this.setSuccessAlertProps(value.type);
                this.getAnswerData();
                this.handleFAQData();
            } else {
                this.setFailAlertProps(value.type);
            }
        })
        this.getAnswerData()
        this.handleFAQData()

    }

    async getAnswerData() {
        this.isAuthority = this.authService.isDelegatedRepresentative();
        let role: string;
        if (this.isAuthority) {
            role = "authorityDr"
        } else {
            role = "buyer"
        }
        const baseUrl = `${environment.tsmBackendServiceURL}/help-and-support/getHelpAndSupportData/` + role;
        this.faqData = await this.httpService.GetPromise(baseUrl)
        this.faqs = this.faqData.data;
        this.faqsArry = [];
        let arr = [];
        let faqDataarr =[];
        faqDataarr = this.sortSelectResource(this.faqs)
        faqDataarr.forEach((item) => {
            if (!arr.includes(item.category)) {
                arr.push(item.category)
            }
            if(!item.isPublished){
                this.publishButton = {
                    label: "Publish",
                    backgroundColor: "blue",
                    color: "white",
                    fontSize: 16,
                    fontFamily: "Graphik-Medium",
                    height: 48,
                    borderRadius: 25,
                    padding: "14px 10px",
                    margin: "0 0 0 2px",
                    disabled: false,
                    onClick: async () => {
                        this.operationFAQ("Publish FAQ")
                    }
                }
            }
        });
        arr.forEach((item, index) => {
            const arra = []
            faqDataarr.forEach((val, ind) => {
                if (item === val.category) {
                    arra.push(val)
                }
            })
            this.faqsArry.push({
                category: item,
                data: arra
            })
        })
    }
    
    sortSelectResource = (resource: any): any => {
        return resource?.sort((obj1: any, obj2: any) => {
            const val1 = obj1.createDate;
            const val2 = obj2.createDate;
            if (new Date(val1).getTime() > new Date(val2).getTime()) {
                return 1;
            } else if (new Date(val1).getTime() < new Date(val2).getTime()) {
                return -1;
            } else {
                return 0;
            }
        });
    }
    handleFAQData = async (): Promise<any> => {
        const faqUrl = await this.helpAndSupportService.getFAQData();
        this.buyerfaqData = faqUrl.data;
        this.buyerhelp = this.buyerfaqData['FAQRecord'].filter(elements => elements.buyerSupplierFaqQuestion === "Buyer FAQ");
        this.buyerhelp = this.sortSelectResourceFaq(this.buyerhelp);
        let faqCat = this.buyerhelp.map(e=>e.category)
        this.faquniqueCat = faqCat.filter((value, index, self) => {     return self.indexOf(value) == index; });
    }


    sortSelectResourceFaq = (resource: any): any => {
        return resource?.sort((obj1: any, obj2: any) => {
            const val1 = obj1.lastModifiedOn;
            const val2 = obj2.lastModifiedOn;
            if (new Date(val1).getTime() > new Date(val2).getTime()) {
                return 1;
            } else if (new Date(val1).getTime() < new Date(val2).getTime()) {
                return -1;
            } else {
                return 0;
            }
        }).sort((a: any, b: any) => {
          if (a.index === null || a.index === undefined) return 1;
          if (b.index === null || b.index === undefined) return -1;
          if (a.index < b.index) return -1;
          if (a.index > b.index) return 1;
          return 0;
        });;
    }

    

    handleClick = (i) => {
        if (this.preCurrent == i) {
            this.current = null;
            this.preCurrent = this.current;
            return;
        }
        this.current = i;
        this.preCurrent = this.current;
    };



    operationFAQ = (title, info?) => {
      let index = info?.index;
      if (title.indexOf('Add New FAQ') > -1) {
        index = this.buyerhelp?.length + 1;
      }
      const confirmType = {
        windowClass: 'modal-faq',
        title: title,
        categoryInfo: info?.category,
        questionInfo: info?.question,
        answerInfo: info?.answer,
        index: index,
        helpAndSupportId: info?.faqId,
        language: info?.language,
        buyerSupplierFaqQuestion: info?.buyerSupplierFaqQuestion,
        type: info?.type,
        centered: true,
        size: 'lg',
      };
      const modalReference = this.ngbModalService.open(
        PopUpFaqComponent,
        confirmType
      );
      modalReference.componentInstance.confirmType = confirmType;
    }

    setSuccessAlertProps = (message): void => {
        this.showAlertSuccess = true
        this.successAlert = {
            type: "alert-profileVerifiedSuccess",
            message: `${message} FAQ Success`,
            icon: "assets/images/icon-tick.svg",
            iconFill: "#d5eed9",
            iconClose: "assets/images/icon-cross-green.svg",
            onDismiss: () => {
                this.showAlertSuccess = false;
            }
        };
        window.scrollTo(0, 0);
    };
    setFailAlertProps = (message): void => {
        this.showAlertFail = true;
        this.failAlert = {
            type: "alert-requestVerifiedFaild",
            message: `${message} FAQ Error`,
            icon: "assets/images/icon-error.svg",
            iconFill: "#cc1e32",
            iconClose: "assets/images/icon-cross-blue.svg",
            onDismiss: () => {
                this.showAlertFail = false;
            }
        };
        window.scrollTo(0, 0);
    }
}
