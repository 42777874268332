import { Component, OnInit, Input } from '@angular/core';
import { ButtonPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EventService } from 'src/app/services/EventService.service';
import { HelpAndSupportService } from 'src/app/services/helpAndSupport/help-and-support.service';
import { environment } from "src/environments/environment";
import { v4 as uuid } from 'uuid';
@Component({
  selector: 'tsm-pop-up-faq',
  templateUrl: './pop-up-faq.component.html',
  styleUrls: ['./pop-up-faq.component.sass']
})
export class PopUpFaqComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    public eventService: EventService,
    public helpAndSupportService: HelpAndSupportService,
    private httpService: HttpService,
  ) { }
  @Input() confirmType: any;
  fqaTitle: string;
  tip: string = "*";
  categoryLabel: string = "Category";
  questionLabel: string = "Question";
  answerLabel: string = "Answer";
  deleteTip: string = "Are you sure to delete the FAQ?"
  publishTip: string = "Are you sure to publish the content? The contents will be visible to buyers after publishing."
  categoryInfo: string = ""
  questionInfo: string = "";
  answerInfo: string = "";
  helpAndSupportId: string
  isCreate: boolean = true;
  isEdit: boolean = true;
  isDelete: boolean = true;
  isPublish: boolean = true;

  saveProps: ButtonPropsType = {
    label: "Save",
    color: "white",
    class: "faqGray",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    padding: "14px 10px",
    margin: "0 0 0 2px",
    iconHeight: 14,
    iconWidth: 14,
    iconPosition: "right",
    disabled: true,
    onClick: async () => {
      await this.save();
    }
  };

  cancelProps: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.cancel();
    }
  };

  async ngOnInit(): Promise<void> {
    await this.config();
    this.accessbility();
  }

  async config() {
    const res = this.confirmType;
    this.helpAndSupportId = res.helpAndSupportId;
    this.fqaTitle = res.title;
    this.isCreate = res.title.indexOf('Add New FAQ') > -1;
    this.isEdit = res.title.indexOf('Edit FAQ') > -1;
    this.isDelete = res.title.indexOf('Delete FAQ') > -1;
    this.isPublish = res.title.indexOf('Publish FAQ') > -1;
    if (this.isEdit) {
      this.categoryInfo = res.categoryInfo; //category
      this.questionInfo = res.questionInfo; //question
      this.answerInfo = res.answerInfo;
    }
    if (this.isEdit || this.isDelete || this.isPublish) {
      this.saveProps.disabled = false;
      this.saveProps.class = "faqBlue";
    }
    if (this.isDelete || this.isPublish) {
      this.saveProps.label = "Yes";
    }
  }

  async textChange() {
    if (this.categoryInfo.trim() && this.questionInfo.trim() && this.answerInfo.trim()) {
      this.saveProps.disabled = false;
      this.saveProps.class = "faqBlue";
    } else {
      this.saveProps.disabled = true;
      this.saveProps.class = "faqGray";
    }
  }

  async save() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const currTime = new Date().toJSON();
    const fqaParameter = {
      category: this.categoryInfo.trim(),
      question: this.questionInfo.trim(),
      answer: this.answerInfo,
      lastModifiedBy: userInfo.pocEmail,
      lastModifiedOn: currTime,
      language:this.confirmType?.language,
      buyerSupplierFaqQuestion: this.confirmType?.buyerSupplierFaqQuestion,
      index: this.confirmType?.index
    }
    const baseUrl = `${environment.tsmBackendServiceURL}`;
    let bulkUpload = {
      FAQRecord: [],
      updateHelpPageContent: [],
    };
    this.cancel();
    if (this.isCreate) {
      fqaParameter['faqId'] = uuid();
      fqaParameter['addUpdateDelete'] = 'Add question';
      fqaParameter['language'] = 'English';
      fqaParameter['buyerSupplierFaqQuestion'] = 'Buyer FAQ';
      bulkUpload.FAQRecord = [fqaParameter];
      const data = await this.helpAndSupportService.addData(bulkUpload);
      this.status(data, 'Create');
    } else if (this.isDelete) {
      fqaParameter['faqId'] = this.helpAndSupportId;
      fqaParameter['addUpdateDelete'] = 'Delete question';
      bulkUpload.FAQRecord = [fqaParameter];
      const data = await this.helpAndSupportService.addData(bulkUpload);
      this.status(data, 'Delete');
    } else if (this.isEdit) {
      fqaParameter['faqId'] = this.helpAndSupportId;
      fqaParameter['addUpdateDelete'] = 'Update question';
      bulkUpload.FAQRecord = [fqaParameter];
      const data = await this.helpAndSupportService.addData(bulkUpload);
      this.status(data, 'Edit');
    } else {
      let data: any = await this.httpService.GetPromise(`${baseUrl}/help-and-support/publishHelpAndSupportData`);
      this.status(data, 'Publish');
    }
  }

  async cancel(): Promise<any> {
    this.activeModal.close();
  }

  status(data, type) {
    data?.status ? this.eventService.eventEmit.emit({
      status: "success",
      type: type
    }) : this.eventService.eventEmit.emit({
      status: "error",
      type: type
    })
  }
  accessbility() {
    const tsmRoot = document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }
}
