import { Component, OnInit, Input } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { SupplierListResponseReportRow } from 'src/app/models/SupplierListFile';

@Component({
  selector: 'tsm-supplier-validation-list',
  templateUrl: './supplier-validation-list.component.html',
  styleUrls: ['./supplier-validation-list.component.sass']
})
export class SupplierValidationListComponent implements OnInit {
  @Input() set validationResults(reportRow: SupplierListResponseReportRow[]) {
    this.handleSupplierValidationsUpdate(reportRow);
  }
  @Input() massInviteProcessed: boolean = false;

  private doHideTooltip: boolean = true;
  sortedValidationResults: SupplierListResponseReportRow[];

  labels = {
    supplierTsmId: "SUPPLIER TSM ID",
    supplierName: "SUPPLIER NAME",
    country: "COUNTRY",
    rowNumber: "ROW NUMBER",
    validationResults: "VALIDATION RESULTS",
    rowNumberHelptext: "The ROW NUMBER corresponds to the row number in the uploaded xml file that contains the supplier/country combination in question."
  }

  constructor(
    private appComponent: AppComponent
  ) { }

  ngOnInit(): void { }

  handleSupplierValidationsUpdate(supplierValidations: SupplierListResponseReportRow[]) {
    this.sortedValidationResults = this.sortSupplierValidationList(supplierValidations);
  }

  private sortSupplierValidationList(supplierValidations: SupplierListResponseReportRow[]): SupplierListResponseReportRow[] {
    let errorList: SupplierListResponseReportRow[] = [];
    let successList: SupplierListResponseReportRow[] = [];

    supplierValidations.forEach(row => {
      if (row.success) {
        successList.push(row);
      } else {
        errorList.push(row);
      }
    });

    errorList.sort(function (a, b) {
      return a.rowNumber - b.rowNumber;
    });
    successList.sort(function (a, b) {
      return a.rowNumber - b.rowNumber;
    });
    return errorList.concat(successList);
  }

  showTooltip(evt: MouseEvent): void {
    this.doHideTooltip = false;
    setTimeout(() => {
      if (!this.doHideTooltip) {
        this.appComponent.showGlobalTooltip(this.labels.rowNumberHelptext, "", evt.pageX - 200, evt.pageY + 20);
      }
    }, 20);
  }

  hideTooltip(): void {
    this.doHideTooltip = true;
    setTimeout(() => {
      if (this.doHideTooltip) {
        this.appComponent.hideGlobalTooltip();
      }
    }, 30);
  }
}
