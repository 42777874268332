<div class="main_container" >
<div class="title">
    Risk Assessment
  </div>
  <form class="ra-form">
    <div class="shortDescDiv" *ngIf="questionsLoaded">
      <label for="shortDesc">Short description of this risk assessment 
        <div class="image_container">
        <img src="./assets/images/alert.svg" alt="alert"
        style="width: 15px;height: 15px;position: relative;margin-left: 7px;" />
        <div class="link-mare-panel">
          <p style="line-height: 1.5;font-weight: normal"> Please provide short description of this risk assessment which would help users to identify the assessment and match it to a particular engagement with this supplier </p>
        </div>
      </div>
      </label>
      <textarea [disabled]="context.mode=='view'" class="form-control" [class.view-text-box]="context.mode=='view'" id="shortDesc" rows="3" (input)="shortDesc = $event.target.value" [value]="shortDesc" maxlength="5000"></textarea>
    </div>
    <!-- <div class="shortDescDiv" style="margin-top:1rem;padding:10px">
      <label style="color:rgb(78, 78, 135);font-weight:600;font-size: 16px;">BI/BDA</label>
      <hr style="position: relative;left: -10px">
<div class="message">
      <p style="line-height: 1.5;font-weight:bold">If you would consider this supplier to be a Business Development Agent ("BDA") or a Business Intermediary ("BI"), please ensure that you start the required vetting by accessing this  <a href="https://ethicscompliancehub-bip.accenture.com/#/main/Delegator">BI Portal</a> here.</p>
    </div>
    </div> -->
    
      <app-dynamic-form *ngIf="context.mode=='create' || context.mode=='edit'" [model]="riskAssessmentInfo"
        [configs]="getConfigsInfo" [context]="context" #dynamicForm>
        </app-dynamic-form>
    <app-dynamic-view *ngIf="context.mode=='view'" [model]="riskAssessmentInfo" [configs]="getConfigsInfo" [context]="context" #dynamicViewForm></app-dynamic-view>
  </form>
  <div class="d-flex justify-content-end button-div-ra" *ngIf="questionsLoaded">
    <sh-button *ngIf="context.mode!='view'" class="fl_right me-3 mt-3" [props]="CancelRaProps"></sh-button>
    <sh-button *ngIf="context.mode!='view'" class="fl_right me-3 mt-3" [props]="saveDraftRaProps"></sh-button>
    <sh-button *ngIf="context.mode!='view'" class="fl_right me-3 mt-3" [props]="submitRaProps"></sh-button>
    <sh-button *ngIf="getBIRole() && context.mode=='view' && getBiBdaStatus()" class="fl_right me-3 mt-3" [props]="reviewbibda"></sh-button>
  </div>
  <div class="loadEffect" style="position: fixed;left: 50%;top:30%;z-index: 1000;" *ngIf="!(questionsLoaded)">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</div>
</div>