<tsm-secondary-banner></tsm-secondary-banner>

<sh-alert [props]="saveErrorAlertProps" [ngStyle]="{'display': saveError ? 'block' : 'none'}"></sh-alert>
<div class="body_container">
    <div class="main_bg page-padding">
        <div class="container">
          <div class="form shadow-sm">
              <div class="d-flex title">
                <div class="me-auto ms-2 align-self-center cursor-pointer">
                  Complete your Sustainability Assessment request
                </div>
              </div>
              <form>
                <qnr-dynamic-form [configs]="configs" [model]="model" [context]="context"  #form></qnr-dynamic-form>
                <div class="tips">
                  <img class="aboutico" src="assets/icons/icon-infomation.svg" alt="icon-information" />
                  <div class="aboutto">After sending this request you will be copied into the email.</div>
                </div>
                <div class="d-flex confirm">
                  <div class="align-self-center" >
                    <sh-button [props]="confirmButton"></sh-button>
                  </div>
                </div>
              </form>
          </div>
        </div>
    </div>
</div>
