import { Component, OnInit } from '@angular/core';
import { SubheaderPropsType } from 'src/app/models/SubheaderProps';

@Component({
  selector: 'tsm-request-multiple-sa-container',
  templateUrl: './request-multiple-sa-container.component.html',
  styleUrls: ['./request-multiple-sa-container.component.sass']
})
export class RequestMultipleSaContainerComponent implements OnInit {
  subheaderProps: SubheaderPropsType;
  tabs = [
    {
      id: "Search",
      title: "Search Suppliers",
      icon: '',
      active: true
    },
    {
      id: "Upload",
      title: "Upload Supplier List",
      icon: '',
      active: false
    }
  ];
  constructor() { }

  ngOnInit(): void {
    this.subheaderProps = {
      isWelcomeMessage: false,
      heading: ""
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.subheaderProps.heading = multilanguageJson.body.main.reportFilters.massSaHeading;
      }
    }
  }

}
