import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { BaseSupplierProfileComponent } from "../base-supplier-profile/base-supplier-profile.component";
import { SupplierGeneralProfileService } from "../../../services/supplier-general-profile.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { SupplierHubService } from "src/app/services/supplierHub/supplier-hub.service";
import { SupplierService } from "src/app/services/supplier/supplier.service";
import { AuthorityService } from "src/app/services/authority/authority.service";
import { Options } from "../../../models/Options";
import { EsgQuestionnaireService } from "@lsl16/sushub-frontend";
import { ReportService } from "src/app/services/authority/report.service";
import { environment } from "../../../../environments/environment";
import { AlertPropsType, HttpService, SAStatus,SupplierInformationBoxComponent, SupplierRelationshipService } from "@lsl16/sustainability-shared-components";
import { isEmpty } from "lodash";
import { ProfileDetailsComponent } from "src/app/components/profile-details/profile-details.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ProfileConfig } from "src/app/components/profile-details/profile";
import { ModalService } from "src/app/services/modal/modal.service";
import { Subscription } from "rxjs";
import { PopUpBuyerTerminateReasonComponent } from "src/app/components/pop-up-buyer-terminate-reason/pop-up-buyer-terminate-reason.component";
@Component({
    selector: "tsm-auth-supplier-profile",
    templateUrl: "./auth-supplier-profile.component.html",
    styleUrls: ["./auth-supplier-profile.component.sass"]
})
export class AuthSupplierProfileComponent extends BaseSupplierProfileComponent implements OnInit {
    @ViewChild('supplierInformationBox') supplierInformationBox: SupplierInformationBoxComponent
    defaultCountryServed: string;
    viewOnlyFlag: boolean;
    esgStatus: string;
    private baseUrl?: string;
    profileData: any;
    inSaCountryServed: boolean = true;
    supplierGeneralProfileEventEmitSubscription: Subscription;
    alertProps: AlertPropsType;
    showAlert: boolean;
    isScope3KeySup: boolean = false;

    viewEsgAssessmentProps: Options = {
        ...this.esgAssessment,
        buttonDisplay: false,
        description: "Share how your business operates from a responsible business perspective.",
        buttonProps: {
            label: "View Assessment",
            disabled: false,
            width: 220,
            color: "white",
            backgroundColor: "greyDark",
            borderColor: "greyDark",
            hoverColor: "white",
            hoverBackgroundColor: "greyDark",
            hoverBorderColor: "greyDark"
        },
        onClick: () => {
            this.redirectToEsgAssessment();
        }
    };

    editProfileDetailData: ProfileConfig = {
        type: "edit-profile-detail",
        options: {},
        supplier: {}
      };
    terminateReasonPopUpModalConfig: ProfileConfig = {
        type: "pop-up-buyer-terminate-reason",
        options: {},
        supplier: {}
    };

    constructor(
        public supplierGeneralProfileService: SupplierGeneralProfileService,
        public authService: AuthService,
        supplierHubService: SupplierHubService,
        private route: ActivatedRoute,
        private httpService: HttpService,
        private supplierService: SupplierService,
        public authorityService: AuthorityService,
        private router: Router,
        private esgQuestionnaireService: EsgQuestionnaireService,
        public reportService: ReportService,
        public ngbModalService: NgbModal,
        private modalService: ModalService,
        supplierRelationshipService: SupplierRelationshipService
    ) {
        super(supplierGeneralProfileService, authService, supplierHubService, reportService, supplierRelationshipService);
    }

    async ngOnInit(): Promise<void> {
        await this.getAll()
        this.baseUrl = `${environment.tsmBackendServiceURL}/userprofile`;
        await this.getprofile();
        let routeParam = this.route.snapshot.paramMap.get("id");
        this.selectedSupplierTsmId = routeParam;
        this.supplierTsmId = routeParam;
        await this.initialiseSupplier();
        await this.fetchParentData();
        if (!isEmpty(this.supplier.parentTsmId) && this.supplier.supplierTsmId !== this.supplier.parentTsmId) {
            this.supplierTsmId = this.supplier.parentTsmId;
        }
        this.userType = this.authService.getRole();
        this.countryServed = this.getCountryServed();
        if (this.supplier != undefined && this.supplier.saCountryServed != undefined && this.supplier.saCountryServed.indexOf(this.countryServed) < 0) {
            this.inSaCountryServed = false;
        }
        await this.refreshData();
        this.editProfileDetailData.options = this.modalService.getOptions(this.editProfileDetailData.type);
        this.isScope3KeySupplier();
        this.supplierGeneralProfileEventEmitSubscription = this.supplierGeneralProfileService.eventEmit.subscribe(
            async (event) => {
                let emitArry = event.split("#");
                if (emitArry[0] === "verifySuccess") {
                    this.showAlert = true;
                    this.countryServed = emitArry[1];
                    await this.initialiseSupplierWithCountryServed(this.countryServed);
                    this.getprofile()
                    this.alertProps = {
                        type: "alert-profileVerifiedSuccess",
                        message: "Profile updated successfully.",
                        icon: "assets/images/icon-tick.svg",
                        iconFill: "#d5eed9",
                        iconClose: "assets/images/icon-cross-green.svg",
                        onDismiss: this.onAlertDismiss
                    };
                }
            }
        );
    }

    private isScope3KeySupplier() {
        if (this.supplierTsmId != this.selectedSupplierTsmId) {
          this.isScope3KeySup = this.isScope3Supplier(this.parentSupplier);
        } else {
          this.isScope3KeySup = this.isScope3Supplier(this.supplier);
        }
    }
    setAlertProps = (props): void => {
        this.showAlert = true;
        this.alertProps = {
          ...props,
          onDismiss: () => {
            this.showAlert = false;
          }
        };
        window.scrollTo(0, 0);
    }

    async getprofile(): Promise<any> {
        const b2cUser = this.authService.decodeToken();
        const queryUserProfileUrl = `${this.baseUrl}/getUserprofile/${b2cUser.email}`;
        this.profileData = await this.httpService.GetPromise(queryUserProfileUrl);
    }

    private getCountryServed(): string {
        let countryServed = "";
        this.route.queryParams.subscribe((params: Params) => {
            this.defaultCountryServed = params["countryServed"];
        });

        if (this.defaultCountryServed) {
            countryServed = this.defaultCountryServed;
        }
        else {
            countryServed = this.supplier.countryServed[0] ? this.supplier.countryServed[0] : "";
        }
        return countryServed;
    }

    

    private async refreshData() {
        this.viewOnlyFlag = false;
        await this.getAssessmentStatus();
        this.prepareTabs();

        if (this.esgStatus !== SAStatus.awaitingAcceptance && this.esgStatus !== SAStatus.awaitingSubmission && this.countryServed) {
            const userInfo = this.authService.getLoginUser();
            const supAndAuthID = {
                supplierTsmId: this.supplierTsmId,
                authorityTsmId: userInfo.tsmId,
                country: this.countryServed
            };
            const response = await this.authorityService.getLatestTaskRecord(supAndAuthID);
            if (response && response.esgQuestionnaireId) {
                this.viewOnlyFlag = true;
                this.questionnaireId = response.esgQuestionnaireId;
                this.updatePropsBasedOnESgStatus();
            }
        }
    }

    private async getAssessmentStatus() {
        const initStatus = "not yet initiated";
        if (this.countryServed === "") {
            this.esgStatus = initStatus;
            return;
        }
        if (this.countryServed) {
        const esgStatusResponse = await this.supplierService.getSAStatus(this.supplierTsmId, this.countryServed);
            if (!esgStatusResponse || Object.keys(esgStatusResponse.assessment).length == 0) {
                 this.esgStatus = initStatus;
            } else {
            this.esgStatus = esgStatusResponse?.assessment;
            }
        }
    }

    redirectToEsgAssessment = async () => {
        let route = "/esg-questionnaire/survey-getting-started";
        if (this.canReviewAssessment(this.esgStatus)) {
            route = "/esg-questionnaire/survey-review-summary";
        }

        let queryParams = {};
        if (this.supplierTsmId != this.selectedSupplierTsmId) {
            queryParams = {
                supplierName: this.supplier.supplierName, countryServed: this.countryServed, supplierTsmId: this.selectedSupplierTsmId, esgStatus: this.esgStatus, parentSupplierTsmId: this.supplierTsmId,
                isScope3Supplier: this.isScope3Supplier(this.parentSupplier)
            };
        }
        else {
            queryParams = {
                supplierName: this.supplier.supplierName, countryServed: this.countryServed, supplierTsmId: this.selectedSupplierTsmId, esgStatus: this.esgStatus,
                isScope3Supplier: this.isScope3Supplier(this.supplier)
            };
        }
        this.router
            .navigate([route, this.questionnaireId], {
                queryParams: queryParams
            });
        window.scroll(0, 0);
    };

    updatePropsBasedOnESgStatus() {
        if (this.canReviewAssessment(this.esgStatus)) {
            this.viewEsgAssessmentProps.buttonProps.label = "Review Assessment";
        }
    }

    private prepareTabs() {
        this.collectReviewData();
    }

    async openProfilePopup(): Promise<void> {
        if (!this.supplier) {
            await this.initialiseSupplier();
        }

        const confirmType = this.editProfileDetailData;
        let confirmTypeSupplier = {
            ...this.supplier,
            currentcountryServed: this.countryServed
        };
        confirmType.supplier = confirmTypeSupplier;
        const modalReference = this.ngbModalService.open(ProfileDetailsComponent, confirmType.options);
        modalReference.componentInstance.confirmType = confirmType;
    }

    onAlertDismiss = (): void => {
        this.showAlert = false;
    };

    async openTerminatePopup(modalChoice: string): Promise<void> {
        if (modalChoice == "terminate-modal") {
          this.openTerminateReasonModal();  
        }
    }
    async refershTerminate(value: boolean): Promise<void> {
        console.log("refershTerminateBtn",value);
        this.supplierInformationBox.terminateButtonDisplay("authority");
    }
    
    public async openTerminateReasonModal(): Promise<void> {
        // avoid opening the modal twice at the sametime
        if (this.ngbModalService.hasOpenModals()) {
          return;
        }
        this.terminateReasonPopUpModalConfig.supplier = this.supplier
        this.terminateReasonPopUpModalConfig.options = {
          scrollable: true,
          animation: true,
          backdrop: 'static',
          windowClass: 'message-pop-up-modal-window',
          backdropClass: 'modal-custom-backdrop',
        };
        const modalReference = this.ngbModalService.open(PopUpBuyerTerminateReasonComponent, this.terminateReasonPopUpModalConfig.options);
        modalReference.componentInstance.confirmType = this.terminateReasonPopUpModalConfig;
        let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
        if (tsmRoot) {
          tsmRoot.setAttribute("aria-hidden", "false");
        }
    }  
}
