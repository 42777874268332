import { Injectable, Inject } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "src/environments/environment";
import { LoginUser, Platform, Role } from "../../models/LoginUser";
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalBroadcastService } from "@azure/msal-angular";
import {
  InteractionType,
  PopupRequest,
  RedirectRequest,
  AuthenticationResult,
} from "@azure/msal-browser";
import { EventEmitter } from "@angular/core";
import { AlertPropsType, HttpService } from "@lsl16/sustainability-shared-components";
import { Roles } from "src/app/models/Roles";
import { B2cService } from "../b2c/b2c.service";
import { SupplierGeneralProfileService } from "../supplier-general-profile.service";
import { PopUpPromisionsupplierComponent } from "src/app/components/pop-up-promisionsupplier/pop-up-promisionsupplier.component";
import { PopUpPromisionbuyerComponent } from "src/app/components/pop-up-promisionbuyer/pop-up-promisionbuyer.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash";
import { DOCUMENT } from '@angular/common';
import CryptoJS from 'crypto-js';
import { lastValueFrom  } from "rxjs";

type BoolString = "True" | "False";

export type ButtonPermission = {
  "tsm": { [permission: string]: number },
  "sup": { [permission: string]: number },
  "sph": { [permission: string]: number },
}

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public static TOKEN_KEY = "sustain_access_token";
  public roleChangeEventEmitter;
  private authUrl?: string;
  private baseUrl?: string;
  private tokenHelper?: JwtHelperService;
  private loginUser: LoginUser;
  private currentRole: Roles;
  saveErrorAlertProps: AlertPropsType = {
    type: "alert-error",
    message: "There is a technical issue, please try again or contact us.",
    icon: "assets/images/icon-error.svg",
    iconFill: "#cc1e32",
    iconClose: "assets/images/icon-cross-blue.svg"
  };
  result: any;
  docScanner:any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private httpService: HttpService,
    private b2cService: B2cService,
    private supplierGeneralProfileService: SupplierGeneralProfileService,
    public ngbModalService: NgbModal,
    @Inject(DOCUMENT) private document: Document

  ) {
    this.roleChangeEventEmitter = new EventEmitter();
    this.authUrl = `${environment.tsmBackendServiceURL}/b2c/auth`;
    this.baseUrl = `${environment.tsmBackendServiceURL}/userprofile`;
    this.tokenHelper = new JwtHelperService();
  }

  ngOnInit() {
    this.checkTokenValidity();
  }
  
  checkTokenValidity() {
    setInterval(() => {
      if (this.isValidToken()) {
        console.log("Token is valid");
      } else {
        console.log("Token is invalid");
      }
    }, 5 * 60 * 1000); // Check every 5 minutes
  }
  
  public isValidToken(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }
  
    const expiryTime = this.tokenHelper.getTokenExpirationDate(token); // Implemented this method to get token expiry time.
    const currentTime = Date.now();
  
    // Check if the token will expire within the next 5 minutes (300000 ms).
    if (Number(expiryTime) - Number(currentTime) < 300000) {
      this.refreshToken();
    }
      
    if (this.tokenHelper.isTokenExpired(token)) {
      return false;
    }
    return true;
  }
  
  public refreshToken(): void {
    lastValueFrom(this.msalService.acquireTokenSilent({
      scopes: environment.auth.apiConfig.scopes,
      account: this.msalService.instance.getAllAccounts()[0]
    })).then((response: AuthenticationResult) => {
      if (response && response[AuthService.TOKEN_KEY]) {
        this.saveToken(response[AuthService.TOKEN_KEY]);
      }
    }).catch((error) => {
      console.error("Token refresh failed", error);
      this.logout(); // Logout if token refresh fails.
    });
  }
  
  public getToken() {
    return localStorage.getItem(AuthService.TOKEN_KEY);
  }


  public saveToken(token: string) {
    if(token)
    {
      sessionStorage.setItem('ddrumsdk.idtoken', token);
    }
    return localStorage.setItem(AuthService.TOKEN_KEY, token);
  }

  public logout(): void {
    const intervalId = setInterval(() => {
      if (this.isValidToken()) {
        console.log("Token is valid");
      } else {
        console.log("Token is invalid");
      }
    }, 5 * 60 * 1000); // Check every 5 minutes
    
    clearInterval(intervalId); // Clear the interval on logout.
  
    if (this.currentRole === "buyer" && this.loginUser && (JSON.parse(this.loginUser.TSM)['3'].length === 0 || (JSON.parse(this.loginUser.TSM)['1'].includes(5) && JSON.parse(this.loginUser.TSM)['3'].includes(3)))){
      this.logOutBuyerFromAccentureTenant(environment.auth.accentureTenantId);  
    } 
    else if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    }
    else {
      localStorage.clear();
      this.msalService.logoutRedirect();
    }
    // //remove auth refresh required as if the user needs it (unlikely), it will be checked upon login
    this.removeAuthRefresh();
    this.loginUser = new LoginUser();
  }
  logOutBuyerFromAccentureTenant(tenantId){
    localStorage.clear();
    let redirectUri = environment.auth.redirectUri
    this.document.location.href = "https://login.microsoftonline.com/" + tenantId + "/oauth2/v2.0/logout?post_logout_redirect_uri=" + redirectUri    
  }


  public decodeToken = (): any => {
    const token = this.getToken();
    if (!token) {
      return {};
    }
  
    let b2cUser = this.tokenHelper.decodeToken(token);
    // Handle the email mismatch between B2B user login and token resolution
    const domainHint = b2cUser.extension_tsm_domainHint;
    const b2cUserEmail = b2cUser.email;
    const indexLen = b2cUserEmail ? b2cUserEmail.indexOf("#EXT#") : -1;
    const domainType = "B2C";
    if (domainHint && domainHint !== domainType && indexLen > 0) {
      const b2bEmail = b2cUserEmail.substring(0, indexLen);
      const indexEnd = b2bEmail.lastIndexOf("_");
      b2cUser.email = b2bEmail.substring(0, indexEnd) + "@" + b2bEmail.substring(indexEnd + 1);
      b2cUser.b2bflag = true;
    }
    if(localStorage.getItem('MyAccessToggle')==="true"){
      this.getRoleFromMyAcess(b2cUser);
    }
    return {
      userName: b2cUser.userName,
      userId: b2cUser.userId,
      firstName: b2cUser.firstName,
      lastName: b2cUser.lastName,
      extension_tsm_tsmId: b2cUser.extension_tsm_tsmId,
      extension_tsm_TSM: b2cUser.extension_tsm_TSM,
      extension_tsm_SUP: b2cUser.extension_tsm_SUP,
      extension_tsm_SPH: b2cUser.extension_tsm_SPH,
      extension_tsm_domainHint: b2cUser.extension_tsm_domainHint,
      extension_tsm_privacyStatement: b2cUser.extension_tsm_privacyStatement,
      extension_tsm_defaultProfile: b2cUser.extension_tsm_defaultProfile,
      extension_tsm_systemAdmin: b2cUser.extension_tsm_systemAdmin,
      exp: b2cUser.exp,
      email: b2cUser.email,
      b2bflag: b2cUser.b2bflag ? b2cUser.b2bflag : false,
      roles: b2cUser.roles
    };


  };



  getRoleFromMyAcess (b2cUser) {
    if (b2cUser.roles?.length > 0) {
      const extensionTsmTsm = JSON.parse(b2cUser.extension_tsm_TSM);
      const extensionTsmSph = JSON.parse(b2cUser.extension_tsm_SPH);
      const mappingRole = {
        BR_01: 3,
        BR_02: 1,
        BR_04: 4,
        BR_05: 5
      };
      const mappingAuth = {
        BR_01: 3,
        BR_02: 3,
        BR_04: 1,
        BR_05: 1
      };
      const myaccessArray = ["BR_01", "BR_02", "BR_04", "BR_05"];

      if (b2cUser.roles.includes("BR_03")) {
        b2cUser.extension_tsm_systemAdmin = true;
      } else {
        b2cUser.extension_tsm_systemAdmin = false;
      }

      _.forEach(myaccessArray, (key) => {
        const roleValue = _.get(mappingRole, key);
        const authValue = _.get(mappingAuth, key);
        if (_.lt(_.indexOf(b2cUser.roles, key), 0)) {
          _.remove(extensionTsmTsm[authValue], (val) => {
            return _.eq(roleValue, val);
          });
          _.remove(extensionTsmSph[authValue], (val) => {
            return _.eq(roleValue, val);
          });
        }
      });

      _.forEach(b2cUser.roles, (key) => {
        if (myaccessArray.includes(key)) {
          const authValue = _.get(mappingAuth, key);
          const roleValue = _.get(mappingRole, key);
          extensionTsmTsm[authValue] = _.sortBy(_.uniq(_.concat(extensionTsmTsm[authValue], roleValue)));
          extensionTsmSph[authValue] = _.sortBy(_.uniq(_.concat(extensionTsmSph[authValue], roleValue)));
        }
      });
      b2cUser.extension_tsm_TSM = JSON.stringify(extensionTsmTsm);
      b2cUser.extension_tsm_SPH = JSON.stringify(extensionTsmSph);
    }


  }


  public getLoginUser(): LoginUser {
    let b2cUser = this.decodeToken();
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      return {};
    }
    this.loginUser = {
      userId: b2cUser.userId,
      userName: b2cUser.userName,
      firstName: b2cUser.fileName,
      lastName: b2cUser.lastName,
      tsmId: userInfo.tsmId,
      TSM: userInfo.extension_tsm_TSM,
      SUP: userInfo.extension_tsm_SUP,
      SPH: userInfo.extension_tsm_SPH,
      domainHint: b2cUser.domainHint,
      privacyStatement: userInfo.extension_tsm_privacyStatement,
      defaultProfile: (userInfo.extension_tsm_defaultRole === "buyer") ? "buyer" : (userInfo.extension_tsm_defaultRole === "supplier") ? "supplier" : "authority",
      systemAdmin: userInfo.extension_tsm_systemAdmin,
      expires: b2cUser.exp,
      email: b2cUser.email
    };
    if (!this.currentRole) {
      localStorage.setItem("currentRole", this.loginUser.defaultProfile);
    }
    return this.loginUser;
  }

  public getAllowedRoles = (platform: "tsm" | "sph" | "sup"): Role[] => {
    let allowedRoles = [];
    let roleAuthorities;
    if (!this.loginUser) {
      return [];
    }
    switch (platform) {
      case "tsm":
        roleAuthorities = JSON.parse(this.loginUser.TSM);
        break;
      case "sph":
        roleAuthorities = JSON.parse(this.loginUser.SPH);
        break;
      case "sup":
        roleAuthorities = JSON.parse(this.loginUser.SUP);
        break;
      default:
        roleAuthorities = JSON.parse(this.loginUser.TSM);
    }

    for (let roleKey in roleAuthorities) {
      if (roleAuthorities[roleKey].length > 0) {
        let key = parseInt(roleKey) as 1 | 2 | 3;
        allowedRoles.push(this.convertRoleKeyToRole(key));
      }
    }

    return allowedRoles;
  };

  public convertRoleKeyToRole = (roleKey: 1 | 2 | 3): Role => {
    switch (roleKey) {
      case 1:
        return "buyer";
      case 2:
        return "supplier";
      case 3:
        return "authority";
    }
  };

  public setCurrentRole = (role: Role): void => {
    localStorage.removeItem("buttonPermission");
    this.roleChangeEventEmitter.emit({ role });
    this.currentRole = role;
    localStorage.setItem("currentRole", role);
  };

  public getRole(): Role {
    this.currentRole = <Role>localStorage.getItem("currentRole");
    if (!this.currentRole) {
      this.currentRole = this.decodeToken()?.extension_tsm_defaultProfile;
    }
    return this.currentRole;
  }

  public getCurrentPlatform = () => {
    return <Platform>localStorage.getItem("platform");
  };

  public setCurrentPlatform = (platform: Platform) => {
    localStorage.setItem("platform", platform);
  };

  public isUserDeactivated = () => {
    return this.getUserDeactivated() === "True";
  };

  public getUserDeactivated = () => {
    return <BoolString>localStorage.getItem("UserDeactivated") || "False";
  };

  public setUserDeactivated = (deactivated: BoolString) => {
    localStorage.setItem("UserDeactivated", deactivated);
  }

  public async authentication(): Promise<any> {
    let reqData = {};
    let inValidData = {
      page: {},
      button: {}
    };
    let userInfo = this.getLoginUser();
    this.docScanner = userInfo ? userInfo.userId : null;
    //if auth refresh is required (for automatic onboarding request)
    if (Object.keys(userInfo).length <= 0) {
      await this.getTSMUserInfo();
      userInfo = this.getLoginUser();
    }
    if (this.getAuthRefresh() === "required") {
      this.loginUser = userInfo;
    }
    const defaultProfile = this.getRole();
    if (!defaultProfile || !userInfo) {
      return inValidData;
    }
    if (!userInfo.TSM && !userInfo.SUP && !userInfo.SPH) {
      return inValidData;
    }
    const defaultKey = this.convertSchemaKey(defaultProfile);
    reqData["schemaId"] = defaultKey;
    reqData["roleId"] = this.getIdentity(defaultKey);
    let authList = await this.getAuthorisationList(reqData);
    return authList;
  }

  public convertSchemaKey(schemaKey) {
    let defaultKey = 0;
    switch (schemaKey) {
      case "Buyer":
      case "buyer":
        defaultKey = 1;
        break;
      case "Supplier":
      case "supplier":
        defaultKey = 2;
        break;
      case "Authority":
      case "authority":
        defaultKey = 3;
        break;
      default:
        defaultKey = 1;
    }
    return defaultKey;
  }

  public getIdentity(defaultKey) {
    const identityMap = {};
    if (defaultKey === 0) {
      return "";
    }
    this.getVal(defaultKey, this.loginUser.TSM, "tsm", identityMap);
    this.getVal(defaultKey, this.loginUser.SUP, "sup", identityMap);
    this.getVal(defaultKey, this.loginUser.SPH, "sph", identityMap);
    return identityMap;
  }

  public getVal(key, data, str, map) {
    let dataObj = data;
    if (typeof data === "string") {
      dataObj = JSON.parse(data.replace(/'/g, "\""));
    }
    let keys = Object.keys(dataObj);
    keys.forEach((item) => {
      if (Number(item) === key) {
        const tempKey = str + "RoleId";
        map[tempKey] = dataObj[item];
      }
    });
  }

  iv = CryptoJS.lib.WordArray.random(16);
  async encryptData(data) {
    const stringData = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(stringData, this.docScanner, { iv: this.iv }).toString();
    return encrypted;
  }

  async getAuthorisationList(reqData) {
    const encryptedReqData = await this.encryptData(reqData);
    const url = `${this.authUrl}`;
    const response = await this.httpService.PostPromise(url, {
      data: encryptedReqData,
      iv: this.iv.toString(CryptoJS.enc.Base64) 
    });

    if (response && !response['Forbidden']) {
      const combinateData = {
        page: {},
        button: {}
      };
      const respData = response;
      Object.assign(combinateData.page, respData["sup"].page, respData["sph"].page, respData["tsm"].page);
      combinateData.button["tsm"] = respData["tsm"].button;
      combinateData.button["sph"] = respData["sph"].button;
      combinateData.button["sup"] = respData["sup"].button;
      if (Object.keys(combinateData.button["tsm"]).length === 0
        && Object.keys(combinateData.button["sph"]).length === 0
        && Object.keys(combinateData.button["sup"]).length === 0) {
        if (!this.isUserDeactivated()) {
          this.setUserDeactivated("True");
        };
      } else {
        if (this.isUserDeactivated()) {
          this.setUserDeactivated("False");
        };
      }
      return combinateData;
    } else {
      if (!this.isUserDeactivated()) {
        this.setUserDeactivated("True");
      };
      return {
        page: {},
        button: {
          tsm: {},
          sup: {},
          sph: {}
        }
      };
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService.loginPopup(userFlowRequest).subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  async handleButtonPermission(): Promise<void> {
    // clear button permission local storage
    localStorage.removeItem("buttonPermission");
    const authResponse = await this.authentication();
    const buttonPermission = authResponse.button;
    // no profile
    if (Object.keys(buttonPermission).length === 0) {
      return;
    }
    // no result from API
    if (Object.keys(buttonPermission.tsm).length === 0
    && Object.keys(buttonPermission.sup).length === 0
    && Object.keys(buttonPermission.sph).length === 0) {
      return;
    }
    localStorage.setItem("buttonPermission", JSON.stringify(buttonPermission));
  }

  /**
   * Sets the local storage key "authRefresh" as the value passed in the parameters.
   * This functionality is needed for the automatic sustainability assessment trigger,
   * for when the user comes to TSM after doing an onboarding request.
   * @param authRefresh Value to set the localStorage item as
   */
  setAuthRefresh = async (authRefresh: string): Promise<void> => {
    localStorage.setItem("authRefresh", authRefresh);
    //Get the updated auth permissions for the user
    await this.handleButtonPermission();
  };

  /**
   * Gets the local storage key "authRefresh". This functionality is needed for the automatic
   * sustainability assessment trigger, for when the user comes to TSM after doing an onboarding request.
   */
  getAuthRefresh = (): string => {
    return localStorage.getItem("authRefresh");
  };

  /**
   * Removes the local storage key "authRefresh". This functionality is needed for the automatic
   * sustainability assessment trigger, for when the user comes to TSM after doing an onboarding request.
   */
  removeAuthRefresh = (): void => {
    localStorage.removeItem("authRefresh");
  };

  /**
   * refresh poc profile permission info
   */
  refreshPocProfileInfoInLocalStorage = async (profileTsmId: string): Promise<void> => {

    const userProfile = {
      extension_tsm_defaultRole: "",
      extension_tsm_privacyStatement: "",
      pocEmail: "",
      profileData: {},
      extension_tsm_systemAdmin: false,
      extension_tsm_defaultProfile: ""
    };

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const queryUserProfileUrl = `${this.baseUrl}/getUserprofile/${this.loginUser.email}`;

    const profileMapping = await this.httpService.GetPromise(queryUserProfileUrl);

    Object.assign(userProfile, profileMapping);

    const user = userProfile.profileData[profileTsmId];

    userInfo.tsmId = profileTsmId;

    userInfo.extension_tsm_SPH = user.extension_tsm_SPH;

    userInfo.extension_tsm_TSM = user.extension_tsm_TSM;

    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    console.log("set new userinfo after verify" + userInfo);

    this.supplierGeneralProfileService.eventEmit.emit("refreshPocProfilePermission");

  };

  async getTSMUserInfo(): Promise<void> {
    await this.getTokenMyAccess();
    let b2cUser = this.decodeToken();
    if (!b2cUser || !b2cUser.email) {
      return;
    }
    const domainEmail = ["@accenture.com", "@avenade.com"];
    const array = domainEmail.filter((item) => b2cUser.email.toLowerCase().indexOf(item) >= 0);
    if (b2cUser.b2bflag && b2cUser.extension_tsm_defaultProfile === "buyer" && array.length <= 0) {
      const service = this.ngbModalService;
      if (!service.hasOpenModals()) {
        service.open(PopUpPromisionbuyerComponent, {
          centered: true,
          size: "lg",
          scrollable: true,
          windowClass: "countdown-modal",
          backdropClass: "modal-custom-backdrop",
          backdrop: "static",
          keyboard: false
        });
        return;
      }
    }
    const userInfo = {
      tsmId: "",
      pocEmail: "",
      extension_tsm_privacyStatement: "",
      extension_tsm_defaultRole: "",
      extension_tsm_systemAdmin: false,
      extension_tsm_TSM: "",
      extension_tsm_SUP: "",
      extension_tsm_SPH: "",
      userId:"",
      extension_tsm_Roles:""
    };
    // Check whether the information is obtained for the first time
    const queryUserProfileUrl = `${this.baseUrl}/getUserprofile/${b2cUser.email}`;
    const userProfile = {
      extension_tsm_defaultRole: "",
      extension_tsm_privacyStatement: "",
      pocEmail: "",
      profileData: {},
      extension_tsm_systemAdmin: false,
      extension_tsm_defaultProfile: ""
    };
    const profileMapping = await this.httpService.GetPromise<any>(queryUserProfileUrl);
    if (profileMapping != null && Object.keys(profileMapping).length > 0 && !profileMapping.Forbidden) {
      Object.assign(userProfile, profileMapping);
      const user = userProfile.profileData[userProfile.extension_tsm_defaultProfile];
      userInfo.tsmId = userProfile.extension_tsm_defaultProfile;
      userInfo.pocEmail = b2cUser.email;
      userInfo.extension_tsm_TSM = user.extension_tsm_TSM;
      userInfo.extension_tsm_SUP = user.extension_tsm_SUP;
      userInfo.extension_tsm_SPH = user.extension_tsm_SPH;
      userInfo.extension_tsm_privacyStatement = userProfile.extension_tsm_privacyStatement;
      userInfo.extension_tsm_defaultRole = userProfile.extension_tsm_defaultRole;
      userInfo.extension_tsm_systemAdmin = userProfile.extension_tsm_systemAdmin;
      userInfo.userId = b2cUser.userId;
      userInfo.extension_tsm_Roles = JSON.stringify(b2cUser.roles);
    } else {
      if (b2cUser.extension_tsm_defaultProfile === "supplier") {
        const service = this.ngbModalService;
        if (!service.hasOpenModals()) {
          const modalReference = service.open(PopUpPromisionsupplierComponent, {
            centered: true,
            size: "lg",
            scrollable: true,
            windowClass: "countdown-modal",
            backdropClass: "modal-custom-backdrop",
            backdrop: "static",
            keyboard: false
          });
          return;
        }
      }
      userInfo.tsmId = b2cUser.extension_tsm_tsmId;
      userInfo.pocEmail = b2cUser.email;
      userInfo.extension_tsm_TSM = b2cUser.extension_tsm_TSM;
      userInfo.extension_tsm_SPH = b2cUser.extension_tsm_SPH;
      userInfo.extension_tsm_SUP = b2cUser.extension_tsm_SUP;
      userInfo.extension_tsm_privacyStatement = b2cUser.extension_tsm_privacyStatement;
      userInfo.extension_tsm_defaultRole = b2cUser.extension_tsm_defaultProfile;
      userInfo.extension_tsm_systemAdmin = b2cUser.extension_tsm_systemAdmin;
      userInfo.userId = b2cUser.userId;
      userInfo.extension_tsm_Roles = JSON.stringify(b2cUser.roles);
    }
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }

  // system admin
  public isSystemAdmin = () => this.loginUser?.systemAdmin ?? false;

  // buyer roles
  public isGeneralBuyerUser(): boolean {
    return this.currentRole === 'buyer' && JSON.parse(this.loginUser.TSM)['1'].includes(1);
  }

  public isOnlyGeneralBuyerUser(): boolean {
    const tsm = JSON.parse(this.loginUser.TSM);
    return this.currentRole === 'buyer' && tsm['1'].length === 1 && tsm['1'].includes(1);
  }

  public isRequestorAdmin(): boolean {
    return this.currentRole === 'buyer' && JSON.parse(this.loginUser.TSM)['1'].includes(3);
  }

  public isSystemSupplierAdmin(): boolean {
    return this.currentRole === 'buyer' && JSON.parse(this.loginUser.TSM)['1'].includes(4);
  }

  public isBuyerExpert(): boolean {
    return this.currentRole === 'buyer' && JSON.parse(this.loginUser.TSM)['1'].includes(5);
  }

  // supplier roles
  public isGeneralSupplier(): boolean {
    return JSON.parse(this.loginUser.TSM)['2'].includes(1);
  }

  public isSupplierAdmin(): boolean {
    return JSON.parse(this.loginUser.TSM)['2'].includes(2);
  }

  // authority roles
  public isSustainabilityExpert(): boolean {
    return this.currentRole === 'authority' && JSON.parse(this.loginUser.TSM)['3'].includes(1);
  }

  public isSE(): boolean{
    return this.currentRole == "authority" || "buyer" && JSON.parse(this.loginUser.TSM)['3'].includes(1);
  }

  public isAA(): boolean {
    return this.currentRole === 'authority' || "buyer" && JSON.parse(this.loginUser.TSM)['3'].includes(2);
  }

  public isDR(): boolean {
    return this.currentRole === 'authority' || "buyer"  && JSON.parse(this.loginUser.TSM)['3'].includes(3);
  }

  public isAuthorityAdmin(): boolean {
    return this.currentRole === 'authority' && JSON.parse(this.loginUser.TSM)['3'].includes(2);
  }

  public isDelegatedRepresentative(): boolean {
    return this.currentRole === 'authority' && JSON.parse(this.loginUser.TSM)['3'].includes(3);
  }
  
  //To check whether the logged in user has BI role
  public isBI(): boolean {
    let userExtention =JSON.parse(localStorage.getItem("userInfo"))
    if(userExtention.extension_tsm_Roles) {
      let userRoles = JSON.parse(userExtention.extension_tsm_Roles);
      return this.currentRole === 'buyer' && userRoles.includes("BR_12");
    }else {
      return false
    } 
  }
  // Risk Assessment special roles
  public isScm(): boolean {
    let userExtention =JSON.parse(localStorage.getItem("userInfo"))
    if(userExtention.extension_tsm_Roles) {
      let userRoles = JSON.parse(userExtention.extension_tsm_Roles);
      return this.currentRole === 'buyer' && userRoles.includes("BR_07");
    }else {
      return false
    } 
  }
 
  public isSrdp(): boolean {
    let userExtention =JSON.parse(localStorage.getItem("userInfo"))
    if(userExtention.extension_tsm_Roles) {
      let userRoles = JSON.parse(userExtention.extension_tsm_Roles);
      return this.currentRole === 'buyer' && userRoles.includes("BR_08");
    }else {
      return false
    }
  }
 
  public isPrisk(): boolean {
    let userExtention =JSON.parse(localStorage.getItem("userInfo"))
    if(userExtention.extension_tsm_Roles) {
      let userRoles = JSON.parse(userExtention.extension_tsm_Roles);
      return this.currentRole === 'buyer' && userRoles.includes("BR_09");
    }else {
      return false
    }
  }
 
  public isSrsa(): boolean {
    let userExtention =JSON.parse(localStorage.getItem("userInfo"))
    if(userExtention.extension_tsm_Roles) {
      let userRoles = JSON.parse(userExtention.extension_tsm_Roles);
      return this.currentRole === 'buyer' && userRoles.includes("BR_10");
    }else {
      return false
    }
  }
 
  public isSrra(): boolean {
    let userExtention =JSON.parse(localStorage.getItem("userInfo"))
    if(userExtention.extension_tsm_Roles) {
      let userRoles = JSON.parse(userExtention.extension_tsm_Roles);
    return this.currentRole === 'buyer' && userRoles.includes("BR_11");
    }else {
      return false
    }
  }

  public async JudgeShowWelcomePage(isPutWelcomeUser: boolean, isGetWelcomeStatus: boolean, isDonotShowPage?): Promise<boolean> {
    try {
      const userInfo = this.getLoginUser();

      if (isPutWelcomeUser) {
        const url = `${environment.tsmBackendServiceURL}/welcomepage/status`
        const userdata = {
          userId: userInfo.userId,
          userEmail: userInfo.email,
          welcomeStatus: isDonotShowPage
        };
        await this.httpService.PostPromise(url, userdata);
      }

      if (isGetWelcomeStatus) {
        if (userInfo.defaultProfile !== 'supplier') {
          const url = `${environment.tsmBackendServiceURL}/welcomepage/status/${userInfo.email}`
          const response: any = await this.httpService.GetPromise(url);
          if (response.status) {
            return true;
          }
          else return false;
        }
        else return false;
      }
    }
    catch (err) {
      console.log(`JudgeShowWelcomePage: ${err}`);
      return false;
    }
  }
  async getTokenMyAccess(){
    localStorage.setItem("MyAccessToggle","true" );
  }

  public isAuthorityDRUser(): boolean {
    let currentRole = <Role>localStorage.getItem("currentRole");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!currentRole) {
      currentRole = this.decodeToken()?.extension_tsm_defaultProfile;
    }
     return currentRole === 'authority' && JSON.parse(userInfo.extension_tsm_TSM)['3'].includes(3);
  }
}