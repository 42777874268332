import { Component, OnInit } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
    selector: "tsm-callback",
    templateUrl: "./callback.component.html",
    styleUrls: ["./callback.component.sass"]
})
export class CallbackComponent implements OnInit {
    constructor(private router: Router, private authService: AuthService) {}

    async ngOnInit(): Promise<void> {
      const tree: UrlTree = this.router.parseUrl(this.router.url);
      const params = tree.queryParams;
      const accessToken = params["access_token"];
      this.authService.saveToken(accessToken);
      await this.authService.handleButtonPermission();
      await this.router.navigateByUrl("");
    }
}
