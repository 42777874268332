import { RouteReuseStrategy, DefaultUrlSerializer, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { Observable, Subject } from 'rxjs';
export class RouteMsg {
  url: string = '';
  type: string = '';
  constructor(type: string, url: string) {
      this.type = type;
      this.url = url;
  }
}
export class AppRoutingCache implements RouteReuseStrategy {
  fromPath: string = ''
  toPath: string = ''
  public static routeText$ = new Subject<RouteMsg>();
  public static handlers: { [key: string]: DetachedRouteHandle } = {};
  public static getRouteText(): Observable<RouteMsg> {
    return AppRoutingCache.routeText$.asObservable();
}
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (!route.data.keep) {
      return false;
    }
    AppRoutingCache.routeText$.next(new RouteMsg('detach', route['_routerState'].url));
    return true;
  }
  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    AppRoutingCache.handlers[route.routeConfig.path] = handle;
  }
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (!(this.fromPath === 'supplier-profile/:id' && this.toPath === 'dashboard-search') || sessionStorage.getItem('useCache') === 'false') {
      return false
    }
    return !!route.routeConfig && !!AppRoutingCache.handlers[route.routeConfig.path];
  }
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig) {
      return null;
    }
    AppRoutingCache.routeText$.next(new RouteMsg('attach', route['_routerState'].url));
    return AppRoutingCache.handlers[route.routeConfig.path];
  }
  public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    this.toPath = future.routeConfig ? future.routeConfig.path : ''
    this.fromPath = current.routeConfig ? current.routeConfig.path : ''
    return future.routeConfig == current.routeConfig;
  }
}