import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root"
})
export class SystemAdminGuard  {
    constructor(private authService: AuthService) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log(this.authService.getLoginUser())
        let flag = this.authService.getLoginUser()?.systemAdmin;
        console.log(flag)
        if (!flag) {
            window.alert("You don't have permission to view this page");
        }
        return flag
    }
}