<div class="relationship-container">
  <div *ngIf="!isSupplierParent; else SubsidiarySupplierTemplate">
    <div *ngIf="showParent !== 'showParent'" class="noparent-content">
      <img src="assets/images/NoParentsAssigned.svg" alt="no-parent"/>
      <div class="empty-msg-text">
        <div *ngIf="showParent === 'showNoParent' && !isIndirect">
          {{ translatedLabels.noParentTitle }}
        </div>
        <div
          *ngIf="
            showParent === 'showNoRelationExists' && !isIndirect
          "
        >
          {{ translatedLabels.emptyRelationshipTitle }}
        </div>
        <div *ngIf="isIndirect">
          {{ translatedLabels.noRelationshipTitle }}
        </div>
      </div>
    </div>
    <tsm-supplier-parent-detail
      [supplierTsmId]="supplierTsmId"
      [isDuplicate]="isDuplicate"
      *ngIf="showParent === 'showParent'"
      [isDuplicate]="isDuplicate"
    >
    </tsm-supplier-parent-detail>
  </div>
  <ng-template #SubsidiarySupplierTemplate>
    <tsm-parent-subsidiary-suppliers
      *ngIf="isSupplierParent"
      [parentSupplierTsmId]="supplierTsmId"
      [isDuplicate]="isDuplicate"
    >
    </tsm-parent-subsidiary-suppliers>
  </ng-template>
</div>
<div *ngIf="showHistory && !isSupplierParent">
  <div id="parentChildHistoryTitle" class="parent-child">
    {{ translatedLabels.relationshipHistory }}
  </div>
  <div class="parent-child-container">
    <tsm-parent-child-history [parentChildHistory]="parentChildHistory"></tsm-parent-child-history>   
  </div>
</div>
