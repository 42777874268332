import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {
  SupplierListFileEvent,
  SelectedSupplierListFileEvent,
  SupplierListResponseReportRow,
  MassInviteEvents,
  MassInviteEventTypes as MIEventTypes
} from "src/app/models/SupplierListFile";
import { MassInviteService } from 'src/app/services/massInvite/mass-invite.service';
@Component({
  selector: 'tsm-request-multiple-sa-upload',
  templateUrl: './request-multiple-sa-upload.component.html',
  styleUrls: ['./request-multiple-sa-upload.component.sass']
})
export class RequestMultipleSaUploadComponent implements OnInit {
  public displayError: boolean = false;
  currentSupplierListFile: SelectedSupplierListFileEvent | null = null;
  updateSupplierValidationListSubject: Subject<SupplierListResponseReportRow[]> = new Subject<SupplierListResponseReportRow[]>();

  tooltipProps = {
    tooltip: true,
    tooltipMessage: 'Please note the following before uploading a document:<br><ul><li>The file name can only contain letters, numbers, and special characters visible on the keyboard.</li><li>Maximum data rows in the xml file: 100</li><li>Accepted file format: xml</li><li>The file must contain at least the columns ‘Supplier TSM Id’, ‘Country Served’, ‘Request Purpose’.</li></ul>'
  };

  translations: any = {
    text: 'Upload an .xml file with the list of suppliers you’d like to request the SA from here.',
    attachDocumentation: 'Drag and drop/Browse for your supplier list here',
    emptyMessage: 'Upload your supplier list to see the details here'
  };

  constructor(
    private massInviteService: MassInviteService
  ) { }

  /* istanbul ignore next - no auts for getters*/
  get showEmptyMessage() {
    return this.currentSupplierListFile === null || this.displayError;
  }

  ngOnInit(): void {
    this.subscribeToEvents();
  }

  private subscribeToEvents(): void {
    this.massInviteService.eventEmit.subscribe((event) => {
      this.handleMassInviteServiceEvents(event);
    })
  }

  private handleMassInviteServiceEvents(event: MassInviteEvents) {
    switch (event.name) {
      case MIEventTypes.MIProcess_Done:
        const data = {
          filename: this.currentSupplierListFile.filename,
          contents: this.currentSupplierListFile.contents,
          validations: event.value,
          hasErrors: this.currentSupplierListFile.hasErrors
        };
        this.currentSupplierListFile = data;
        this.updateSupplierValidationListSubject.next(event.value.report);
        break;
      case MIEventTypes.MIProcess_Fail:
        this.displayError = true;
        break;
    }
  }

  handleSupplierListFileEvent(data: SupplierListFileEvent) {
    if ("deleted" in data) {
      this.currentSupplierListFile = null;
    } else {
      this.currentSupplierListFile = data;
      if (!data.hasErrors) {
        this.updateSupplierValidationListSubject.next(data.validations.report);
      }
    }
    this.displayError = data.hasErrors;
  }

  handleValidationErrorEvent(displayError: boolean) {
    console.log("event handler called", displayError);
    this.displayError = displayError;
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.translations = multilanguageJson.body.main.requestMultipleSAUpload;
        this.tooltipProps.tooltipMessage = this.translations.tooltipMessage;
      }
    }
  }
}
