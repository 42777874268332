<div class="modal-header" >
    <img class="modal-icon" [src]="privacyIconUrl" alt="icon-privacy" />
    <h4 class="modal-title">{{privacyJson.title}}</h4>
    <div ngbDropdown style="width: 130px;left: -23px;" class="d-inline-block" *ngIf = "isSupplier" >
        <button class="btn btn-outline-secondary language-button" id="dropdownBasicPrivacy" ngbDropdownToggle>{{"currentLanguage" | translate}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasicPrivacy" >
          <button ngbDropdownItem *ngFor="let item of languageList" (click)="switchLanguage(item.value)" class="language-button">{{item.name}}</button>
        </div>
      </div>
      <div *ngIf = "privacyFlag == true" >
        <a tabindex="0" (keydown.enter)="activeModal.close()"><img 
          class="modal-header-close-button"
          src="assets/icons/x.svg"
          alt="icon-x"
          (click)="activeModal.close()"
      /></a>
      </div>
  </div>
  
  <div class="modal-body" >
    <div class="body-content">
      <div class="modal-body-content" [innerHTML]="privacyJson.content"></div>
      <div class="d-flex justify-content-left" (keydown.enter)="buttonProps.onClick()">
          <button class="close-btn" (click)="buttonProps.onClick()">
              <div class="close-label" tabindex="0">{{buttonProps.label}}</div>
          </button>
      </div>
  </div>
</div>
