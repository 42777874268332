import { Component, OnInit, Input } from '@angular/core';
import { UserType } from '@lsl16/sustainability-shared-components';
import { ActivitylogService } from 'src/app/services/activitylog/activitylog.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { B2cService } from 'src/app/services/b2c/b2c.service';



@Component({
  selector: 'tsm-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.sass']
})
export class ActivityLogComponent implements OnInit {
  @Input()
  supplierTsmId: string;
  activityLogs = []
  userType: UserType;
  constructor(private activityLogService: ActivitylogService,
    private b2cService: B2cService,
    public authService: AuthService,
  ) { }

  async getParentChildHistory(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.activityLogService.getAllParentChildHistory(this.supplierTsmId)
        .subscribe((data: any) => {
          this.formatParentChildLogs(data);
          resolve();
        },
          (error) => {
            console.log(error)
          })
    })
  }

  async getSubsupplierHistory(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.activityLogService.getAllSubsupplierHistory(this.supplierTsmId)
        .subscribe((data: any) => {
          this.formatSubsupplierLogs(data.sphSubSupplierData)
          resolve()
        },
          (error) => {
            console.log(error)
          })
    })
  }

  async getUserOperationLogs(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.activityLogService.getUserOperationLogs(this.supplierTsmId)
        .subscribe((data: any) => {
          const formatOperationLogs = this.formatOperationLogs(data.operationLogs)
          formatOperationLogs
            .then(() => {
              resolve()
            })
        },
          (error) => {
            console.log(error)
          })
    })

  }

  async getTasksLogs(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.activityLogService.getAllTask(this.supplierTsmId)
        .subscribe((tasks: any) => {
          if (this.userType == "buyer") {
            this.formatTasksForBuyer(tasks.sphTasksData)
            resolve()
          }
          if (this.userType == "supplier") {
            this.formatTasksForSupplier(tasks.sphTasksData)
            resolve()
          }

        },
          (error) => {
            console.log(error)
          })
    })
  }

  formatParentChildLogs(allParentChild) {
    for (let parChild of allParentChild) {
      if (parChild.timestamp) {
        this.activityLogs.push({
          datetime: parChild.timestamp,
          event: parChild.payload.event,
          by: this.maskEmail(parChild.user)
        })
      }
    }
  }

  formatSubsupplierLogs(allSubsupplier) {
    for (let subsupplier of allSubsupplier) {
      if (subsupplier.invitedAt) {
        this.activityLogs.push({
          datetime: subsupplier.invitedAt,
          event: "Supplier invited new Sub-supplier",
          by: this.maskEmail(subsupplier.invitedby)
        })
      }
      if (subsupplier.connectedAt) {
        this.activityLogs.push({
          datetime: subsupplier.connectedAt,
          event: "Buyer accepted Sub-supplier invite request",
          by: subsupplier.connectedBy
        })
      }
      if (subsupplier.cancelledAt) {
        this.activityLogs.push({
          datetime: subsupplier.cancelledAt,
          event: "Supplier cancelled Sub-supplier relationship",
          by: this.maskEmail(subsupplier.cancelledBy)
        })
      }
      if (subsupplier.rejectedAt) {
        this.activityLogs.push({
          datetime: subsupplier.rejectedAt,
          event: "Buyer rejected Sub-supplier invite request",
          by: subsupplier.rejectedBy
        })
      }
    }
  }

  maskEmail(user) {
    if(user){
      const parts = user.split('@');
      const username = parts[0];
      const domain = parts[1];
  
      if (username.length <= 3) {
        return `${username.charAt(0)}${'*'.repeat(5)}@${domain}`;
      }
  
      const maskedUsername =
        username.charAt(0) + username.charAt(1) + username.charAt(2) +
        '*'.repeat(5);
      return `${maskedUsername}@${domain}`;
    }
  }

  async formatOperationLogs(allOperations): Promise<void> {
    return new Promise((resolve, reject) => {
      for (let operation of allOperations) {
        if (operation.operType == "1016") {
          this.activityLogs.push({
            datetime: operation.createTime,
            event: operation.operDesc,
            by: operation.userEmail
          })
        }
        else if (operation.operType == "1018") {
          this.activityLogs.push({
            datetime: operation.createTime,
            event: operation.operDesc,
            by: this.maskEmail(operation.operDetail.operationDesc.afterUpdate.pocEmail)
          })
        } else {
          this.activityLogs.push({
            datetime: operation.createTime,
            event: operation.operDesc + " for " + operation.operDetail.pocEmail,
            by: this.maskEmail(operation.operDetail.operator || operation.operDetail.operationDesc.afterUpdate.pocEmail)
          })
        }
      }
      resolve();
    })
  }

  formatTasksForBuyer(alltasks) {
    for (let task of alltasks) {
      if (task.creationDate) {
        if (task.assignedBy == "SYSTEM") {
          this.activityLogs.push({
            datetime: task.creationDate,
            event: "Sustainability Assessment for " + task.countryServed + " sent to supplier",
            by: "System@accenture.com"
          })
        } else {
          this.activityLogs.push({
            datetime: task.creationDate,
            event: "Sustainability Assessment for " + task.countryServed + " sent to supplier",
            by: task.assignedByEmail
          })
        }
      }
      if (task.startFillingSADate) {
        this.activityLogs.push({
          datetime: task.startFillingSADate,
          event: "Supplier Started filling Sustainability Assessment for " + task.countryServed,
          by: this.maskEmail(task.assignedToEmail)
        })
      }
      if (task?.review?.validationCompleteDate) {
        this.activityLogs.push({
          datetime: task.review.validationCompleteDate,
          event: "Sustainability Assessment for " + task.countryServed + " Validation Completed",
          by: task.review.validationCompletedBy
        })
      }
      if (task.esgSubmitted) {
        this.activityLogs.push({
          datetime: task.esgSubmissionDate,
          event: "Sustainability Assessment for " + task.countryServed + " submitted by supplier",
          by: this.maskEmail(task.submittedBy)
        })
      }
      if (task?.review?.updateRequiredDate) {
        this.activityLogs.push({
          datetime: task.review.updateRequiredDate,
          event: "Requested rework for " + task.countryServed + " Sustainability Assessment",
          by: this.maskEmail(task.review.updatedRequiredBy)
        })
      }
    }
  }


  formatTasksForSupplier(alltasks) {
    for (let task of alltasks) {
      if (task.creationDate) {
        if (task.assignedBy == "SYSTEM") {
          this.activityLogs.push({
            datetime: task.creationDate,
            event: "Sustainability Assessment request for " + task.countryServed + " received",
            by: "System@accenture.com"
          })
        } else {
          this.activityLogs.push({
            datetime: task.creationDate,
            event: "Sustainability Assessment request for " + task.countryServed + " received",
            by: task.assignedByEmail
          })
        }
      }
      if (task.esgSubmitted) {
        this.activityLogs.push({
          datetime: task.esgSubmissionDate,
          event: "Sustainability Assessment for " + task.countryServed + " submitted by supplier",
          by: task.submittedBy
        })
      }
      if (task?.review?.updateRequiredDate) {
        this.activityLogs.push({
          datetime: task.review.updateRequiredDate,
          event: "Sustainability Assessment update request for " + task.countryServed + " received",
          by: task.review.updatedRequiredBy
        })
      }
    }
  }

  sortActivityLog() {
    this.activityLogs.sort((a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());
  }

  getBuyerLogs() {
    const getParentChildHistoryPromise = this.getParentChildHistory();
    const getSubsupplierHistoryPromise = this.getSubsupplierHistory();
    const getUserOperationLogsPromise = this.getUserOperationLogs();
    const getTasksLogsPromise = this.getTasksLogs();
    const getDuplicateProfilePromise = this.getDuplicateProfile();
    Promise.all([
      getParentChildHistoryPromise,
      getSubsupplierHistoryPromise,
      getUserOperationLogsPromise,
      getTasksLogsPromise,
      getDuplicateProfilePromise
    ]).then(() => {
      this.sortActivityLog();
    });
  }

  getSupplierLogs() {
    const getTasksLogsPromise = this.getTasksLogs();
    const getDuplicateProfilePromise = this.getDuplicateProfile();
    Promise.all([
      getTasksLogsPromise,
      getDuplicateProfilePromise
    ]).then(() => {
      this.sortActivityLog();
    });
  }

  async getDuplicateProfile(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.activityLogService.getDuplicateProfiles(this.supplierTsmId)
        .subscribe((profile: any) => {
          this.formatDuplicateSupplier(profile.duplicateProfileResult)
          resolve()

        },
          (error) => {
            console.log(error)
          })
    })
  }

  formatDuplicateSupplier(supplierDetails) {
    for (let supplier of supplierDetails) {
      if (supplier.mergeDate) {
        this.activityLogs.push({
          datetime: supplier.mergeDate,
          primaryTsmId:supplier.primaryTsmId,
          secondaryTsmId:supplier.secondaryTsmId,
          primarySupplierName: supplier.primarySupplierName,
          secondrySupplierName: supplier.secondrySupplierName,
          by: 'Merge Profiles Process',
          isProfileMerge: true
        })
      }
    }
  }

  openSupplier(id: string) {
    let url = "/#/supplier-profile/" + id;
    window.open(url, "_blank");
  }



  ngOnInit(): void {
    this.userType = this.authService.getRole();
    if (this.userType == "buyer") {
      this.getBuyerLogs();
    }
    if (this.userType == "supplier") {
      this.getSupplierLogs();
    }
  }
}
