<div class="modal-header">
    <img class="modal-icon" [src]="modalData.iconUrl" alt="icon-modalData" />
    <h4 class="modal-title">{{ modalData.title }}</h4>
</div>
<div class="modal-body">
    <div style="margin:0px 30px;">
        <div style="font-size: 18px;font-weight: normal;color: #333;">Please specify the reason to withdraw the selected
            invite.</div>
        <div *ngFor="let item of reasonList;" style="margin:24px 0px">
            <label class="reasonText" style="display: flex;">
                <input style=" width: 20px;height: 20px;margin-right: 10px;" type="radio" name="reason" value="item"
                    (click)="check(item)">
                <div style="font-size: 14px;line-height:20px;">{{item}}</div>
            </label>
        </div>
        <textarea *ngIf="showReason" (keyup)="onKeyUp()" [(ngModel)]="content" maxlength="400" name=""
            style="outline: none;border:none;padding-left: 20px;color:#6964a7;border-radius: 16px;width: 536px;height: 36px; background-color: #f6f6f9;line-height: 30px;"
            placeholder="Please specific the withdraw reason within 400 characters." id="" cols="30"
            rows="10"></textarea>
        <div style="display:flex;margin-top: 36px;">
            <img style="margin-top: -24px;margin-right: 10px;transform: rotateX(180deg);"
                src="assets/icons/info-blue.svg" alt="">
            <div style="line-height: 1.64;font-family: Graphik;font-size: 14px;">Notification emails will be sent to
                supplier point of contacts after withdrawal completed.</div>
        </div>
    </div>
</div>
<div class="modal-body" style="text-align: center;margin-top: -60px;">

    <div class="d-flex " style="margin-top:30px;justify-content: space-between;">
        <sh-button ngbAutofocus [props]="buttonProps1" style="width: 120px;"> </sh-button>
        <sh-button ngbAutofocus [props]="buttonProps2" id="button_termination" *ngIf="loading == false;"> </sh-button>
        <sh-button ngbAutofocus [props]="loadingButtonProps" id="button_termination" *ngIf="loading == true;">
        </sh-button>
    </div>
</div><!--  -->