import { Component, Input, OnInit } from "@angular/core";
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { emailTemplateManagerModel } from "src/app/models/emailTemplateManager";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpTemplateEditComponent } from "../../pop-up-template-edit/pop-up-template-edit.component";
import { PopUpTemplateDeleteComponent } from "../../pop-up-template-delete/pop-up-template-delete.component";

@Component({
  selector: 'email-template-manager-log-item',
  templateUrl: './email-template-manager-log-item.component.html',
  styleUrls: ['./email-template-manager-log-item.component.sass']
})

export class EmailTemplateManagerLogItemComponent implements OnInit {
  @Input() model: emailTemplateManagerModel;
  @Input() itemindex: number;
  @Input() id: string;
  editButton: ButtonPropsType;
  deleteButton: ButtonPropsType;

  constructor(private ngbModalService: NgbModal) { }
  ngOnInit(): void {
    this.editButton = {
      label: "Edit",
      color: "purple",
      fontSize: 14,
      backgroundColor: "white",
      borderColor: "palePurple",
      hoverColor: "purple",
      hoverBackgroundColor: "white",
      hoverBorderColor: "palePurple",
      height: 40,
      padding: "5px",
      margin: "0px 5px",
      iconPosition: "left",
      onClick: () => this.openEditPopUp()
    };
    this.deleteButton = {
      label: "Delete",
      color: "red",
      fontSize: 14,
      backgroundColor: "white",
      borderColor: "palePurple",
      hoverColor: "purple",
      hoverBackgroundColor: "white",
      hoverBorderColor: "palePurple",
      height: 40,
      padding: "5px",
      margin: "0px 5px",
      iconPosition: "left",
      onClick: () => this.openDeletePrompt()
    };
  }

  openEditPopUp() {
    const confirmType = {
      windowClass: 'modal-faq',
      title: 'Email Template Editor',
      emailTemplateId: this.model.id,
      emailType: this.model.email_type,
      emailSubject: this.model.subject,
      emailTemplate: this.model.template,
      categoryInfo: "type from categoryinfo",
      emailLanguage: this.model.language_type || "English",
      centered: true,
      size: 'lg',
    };
    const modalReference = this.ngbModalService.open(
      PopUpTemplateEditComponent,
      confirmType
    );
    modalReference.componentInstance.confirmType = confirmType;
  }
  openDeletePrompt() {
    const confirmType = {
      windowClass: 'modal-faq',
      title: 'Delete Template',
      emailTemplateId: this.model.id,
      emailType: this.model.email_type,
      emailSubject: this.model.subject,
      emailTemplate: this.model.template,
      emailLanguage: this.model.language_type || "English",
      categoryInfo: "type from categoryinfo",
      centered: true,
      size: 'lg',
    };
    const modalReference = this.ngbModalService.open(
      PopUpTemplateDeleteComponent,
      confirmType
    );
    modalReference.componentInstance.confirmType = confirmType;
  }
}
