import { Inject, Injectable, EventEmitter } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BlacklistService {

  private esgUrl = '';

  constructor( private httpService: HttpService) {
      this.esgUrl = `${environment.susHubESGServiceURL}`;
     }

     /**
      * Get balcklist email from DB by buyertsmid
      * @param buyerTsmId 
      */
     returnBlacklist = async (buyerTsmId): Promise<any[]> => {
      const url = `${this.esgUrl}/Blacklisted/` + buyerTsmId;
      let blacklist = [];
      blacklist = await this.httpService.GetPromise(url);
      return blacklist;
     }
}
