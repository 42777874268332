export const responseEnum = {
  b2c_email_used: {
    code: 105,
    msg: "email address has been used by others"
  },  
  b2c_invalid_domain: {
    code: "InvalidParameterValue",
    msg: "Domain contains illegal character"
  },
  // tsm-backend
  search_supplier_no_result: {
    code: 3016,
    msg: "there is no supplier for this search"
  },
  // b2c-user-management
  create_user_domain_error: {
    code: 6007,
    msg: "the user being added must have the same email address domain as the B2C token's email"
  },
  create_user_same_error: {
    code: 6016,
    msg: "the user being added must have the different email address as the B2C token's email"
  },
  create_user_duplicate_poc: {
    code: 6019,
    msg: "The user being added is already invited"
  }
};
