import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ButtonPropsType, SubSupplier, SubSupplierEvents, SubSupplierRelationshipService, SupplierRelationshipService } from '@lsl16/sustainability-shared-components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HeaderEvents, HeaderService } from 'src/app/services/header/header-service';
import { AddSubSupplierComponent } from '../../add-sub-supplier/add-sub-supplier.component';
import { orderBy } from "lodash";

type subSupplierLiterals = {
  name: string;
  location: string;
  pointOfContact: string;
  countriesServed: string;
  score: string,
  relationStatus: string,
  addSupplierButtonLabel: string;
  emptyMessage: string;
};

@Component({
  selector: 'tsm-sub-suppliers',
  templateUrl: './sub-suppliers.component.html',
  styleUrls: ['./sub-suppliers.component.sass']
})
export class SubSuppliersComponent implements OnInit {
  @Input() supplierTsmId: string;
  @Input() displayButtons: boolean = false;
  @Input() isDuplicate = false;

  labelTranslations: subSupplierLiterals;
  addSupplierButtonProps: ButtonPropsType;
  subSupplieritems: SubSupplier[] = [];
  noRecordsFound: boolean = false;
  isSupplier: boolean = false;

  constructor(
    public authService: AuthService,
    private ngbModalService: NgbModal,
    private subSupplierRelationshipService: SubSupplierRelationshipService,
    private headerService: HeaderService,
    private supplierRelationshipService: SupplierRelationshipService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isSupplier = this.authService.getRole() === 'supplier';
    this.setButtonProps();
    this.loadTranslations();
    await this.fetchSubSuppliers();
    this.subscribeEvents();
  }

  async fetchSubSuppliers(): Promise<void> {
    try {
      const response =
        await this.subSupplierRelationshipService.getAwaitingAndInvitedSubSuppliers(
          this.supplierTsmId
        );

      if (response && response.length > 0) {
        this.sortSuppliers(response);
        this.noRecordsFound = false;
      }
      else {
        this.noRecordsFound = true;
      }
    } catch (error) {
      console.error(`Failed to get sub suppliers for supplier ${this.supplierTsmId}.`, error);
    }
  }

  setButtonProps(): void {
    this.addSupplierButtonProps = {
      label: '',
      color: 'white',
      fontSize: 16,
      backgroundColor: "blue",
      borderColor: "blue",
      height: 48,
      width: 171,
      disabled: this.isDuplicate,
      padding: '0px',
      margin: '0px',
      icon: 'assets/icons/Add-Icon.svg',
      clickIcon: 'assets/icons/Add-Icon.svg',
      iconPosition: 'left',
      onClick: () => this.openModal()
    };
  }

  openModal() {
    const options = {
      scrollable: true,
      animation: true,
      windowClass: 'add-parent-modal-window',
      backdropClass: 'modal-custom-backdrop',
    };
    const modalReference = this.ngbModalService.open(AddSubSupplierComponent, options);
    modalReference.componentInstance.supplierTsmId = this.supplierTsmId;
    let tsmRoot = <HTMLElement>document.getElementsByTagName("tsm-root")[0];
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  loadTranslations() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.subSupplier;
        this.addSupplierButtonProps.label = this.labelTranslations.addSupplierButtonLabel;
      }
    }
  }

  subscribeEvents() {
    this.headerService.eventEmit.subscribe((event) => {
      if (event === HeaderEvents.multiLanguageChanged) {
        this.loadTranslations();
      }
    });
    this.subSupplierRelationshipService.eventEmit.subscribe((event) => {
      this.handleSubSupplierServiceEvents(event);
    });

     this.supplierRelationshipService.duplicateEmitter.subscribe((duplicate: boolean) => {
       this.addSupplierButtonProps.disabled = duplicate;
    });
  }

  async handleSubSupplierServiceEvents(eventType: SubSupplierEvents) {
    const eventArray = ['ConfirmedNewSupplier', 'NewSupplierInvited', 'RelationshipCancelled'];
    if (eventArray.includes(eventType)) {
      await this.fetchSubSuppliers();
    }
  }

  sortSuppliers(response: SubSupplier[]) {
    response = orderBy(response, ["status", "name"], ['desc', "asc"]);
    console.log(response,"inside sub supplier")
    this.subSupplieritems = response;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      changes.supplierTsmId['previousValue'] != undefined &&
      changes.supplierTsmId['currentValue'] !== changes.supplierTsmId['previousValue']
    ) {
      await this.fetchSubSuppliers();
    }
  }
}
