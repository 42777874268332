<div class="body_container py-3 px-5">
    <div class="article-container my-5 py-5 ps-5 pe-5">
        <div class="article-title" *ngFor = "let val of additionalSupportHeader">
            <img src="assets/images/icon-fa-qpurple.png" alt="pic" />
            <span *ngIf="val.mainHeader !== 'null'">{{val.mainHeader}}</span>
        </div>
        <div class="article-content">
            <div class="content-left">
                <div class="article-description article-text" *ngFor = "let val of additionalSupportHeader">
                    <span *ngIf="val.mainContent !== 'null'" [innerHTML]="val.mainContent | html"></span>
                </div> 
                <div *ngIf="supplierhelp" class="article-question">
                    <div class="article-sub-title" *ngFor = "let val of additionalSupportHeader">
                        <p *ngIf="val.faqHeader !== 'null'">{{val.faqHeader}}</p>
                    </div>
                        <ul>
                            <li *ngFor = "let que  of supplierhelp; let i = index">
                                <div class="article-list">
                                    <span class="article-dropdown" (click)="handleClick(i)">
                                        <span class="right-arrow" [ngClass]="{ 'bottom-arrow': current == i }"></span>
                                        <span class="article-question-text" [innerHTML]="que.question | html"></span>
                                    </span>
                                </div>    
                                <div *ngIf="current === i">
                                    <div
                                        class="article-text"
                                        [ngClass]="{ 'list-style': listStyle == 'unorder','list-style-1': listStyle == 'child_1','list-style-2': listStyle == 'child_2' }"                        
                                    >
                                        <p
                                            class="none-liststyle"   
                                            [innerHTML]="que.answer | html"       
                                        ></p>
                                     </div>
                                </div>
                            </li>
                        </ul>
                </div>
                <div class="article-videos">
                    <div class="article-sub-title" *ngFor = "let val of additionalSupportHeader">
                        <p *ngIf="val.quickTutorialHeader !== 'null'">{{val.quickTutorialHeader}}</p>
                    </div>
                    <div class="article-video">
                        <video width="100%" height="100%" controls preload="auto">
                            <source
                                src="assets/videos/Accenture Supplier Inclusion & Sustainability_ Be the Champion.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
                 <div *ngIf="additionalSupportHeader" class="article-addtional">
                    <div class="article-sub-title" *ngFor = "let val of additionalSupportHeader">
                        <p *ngIf="val.additionalSupportHeader !== 'null'">{{val.additionalSupportHeader}}</p>
                    </div>
                    <div class="article-text"  *ngFor = "let val of additionalSupportHeader">
                        <span *ngIf="val.additionalSupportContent !== 'null'" [innerHTML]="val.additionalSupportContent | html"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="links" class="content-right">
                <tsm-help-and-support-download [role]="'supplier'"></tsm-help-and-support-download>
                <div class="article-sub-title article-sub-title-pdt3rem"  *ngFor = "let val of additionalSupportHeader">
                   <p *ngIf="val.quickLinkHeader !== 'null'">{{val.quickLinkHeader}}</p>
                </div>
                <div *ngFor="let item of links" class="sub-link">
                    <a *ngIf="item.index !== 0" class="link" target="_blank" [href]="item.url">{{ item.name }}</a>
                </div>
            </div>
        </div>
    </div>
</div>
