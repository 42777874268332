<tsm-subheader [props]="subheaderProps"></tsm-subheader>
<tsm-sa-retriggering-process-flow></tsm-sa-retriggering-process-flow>

<div class="tab-container">
    <tsm-tab-bar [tabs]="tabs"></tsm-tab-bar>
  </div>
  
  <div *ngFor="let tab of tabs">
    <div *ngIf="tab.active && tab.id === 'Search'">
      <!-- <tsm-sa-retriggering-serach></tsm-sa-retriggering-serach> -->
      <tsm-sa-retriggering-search></tsm-sa-retriggering-search>
    </div>
  </div>