import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: "tsm-help-and-support-download",
    templateUrl: "./help-and-support-download.component.html",
    styleUrls: ["./help-and-support-download.component.sass"]
})
export class HelpAndSupportDownloadComponent implements OnInit {
    @Input() role: string;
    supplierGuideDownloadPDF: any;
    faqsDownloads: any;

    constructor(
        private httpService: HttpService,
        private http: HttpClient
    ) { }

    async ngOnInit() {
        const baseUrl = `${environment.tsmBackendServiceURL}/document/query/${this.role}`;
        const downloadData: any = await this.httpService.GetPromise(baseUrl);
        const supplierUserGuideData = downloadData.data.filter(item => item.status === "2" && item.category === "User Guide");
        let supplierUserGuideArray = [];
        supplierUserGuideData.forEach(element => {
            supplierUserGuideArray.push({
                displayText: element.displayText,
                documentId: element.documentId,
                fileName: element.fileName
            })
        });
        this.supplierGuideDownloadPDF = supplierUserGuideArray;
        if (this.role === "supplier") {
            const faqsData = downloadData.data.filter(item => item.status === "2" && item.category === "FAQ");
            let faqsArray = [];
            faqsData.forEach(element => {
                faqsArray.push({
                    displayText: element.displayText,
                    documentId: element.documentId,
                    fileName: element.fileName
                })
            });
            this.faqsDownloads = faqsArray;
        }
    }

    onClick = async (item: any) => {
        const baseUrl = `${environment.tsmBackendServiceURL}/document/download/${item.documentId}`;
        const file = await this.http.get(baseUrl, { responseType: "blob" }).toPromise();
        const link = document.createElement('a');
        let blob = new Blob([file], { type: 'application/pdf' });
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.setAttribute('download', item.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
