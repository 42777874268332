<div class="item">
    <div class="row hover_pointer align-items-center">
        <div class="col-3">
            <span *ngIf="model.eid == ''">
                <img src="assets/icons/icon-profile-grey.svg" class="nohead" />
            </span>
            <span *ngIf="model.eid !== ''">
                <img [src]='url+model.eid' class="head" alt="icon-head" />
            </span>
            <span class="userName">{{model.displayName}}</span>
        </div>
        <div class="col-4" style="padding-left: 0rem; padding-right: 0rem;">
            <span class="email" style="white-space: normal;
            word-break: break-all;">{{model.email}}</span>
        </div>
        <div class="col-3" style="padding-left: 0rem;">
            <span class="role">{{model.displayRole}}</span>
        </div>
        <div class="col-md-2">
            <sh-button *ngIf="showEditUser()" [props]="props"></sh-button>
        </div>
    </div>
</div>
