import { Inject, Injectable } from "@angular/core";

import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class LoginGuard  {

  constructor(
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) { }
  async canActivate(): Promise<any> {
    // query access token
    const storageList = Object.entries(this.msalService.instance["browserStorage"].browserStorage.windowStorage);

    if (!storageList || storageList.length === 0) {
      return true;
    }
    for (const storage of storageList) {
      if (storage[0].indexOf(`${environment.auth.authorityDomain}-idtoken`) > -1) {
        const storageStr = storage[1];
        if (!!storageStr && typeof storageStr === "string") {
          const storageObj = JSON.parse(storageStr);
          if (localStorage.getItem("sustain_access_token") !== storageObj.secret) {
            localStorage.setItem("sustain_access_token", storageObj.secret);
            // handle button permission
            await this.handleButtonPermission();
          }
        }
      }
    }
    return true;
  }
  async handleButtonPermission(): Promise<void> {
    // clear button permission local storage
    localStorage.removeItem("buttonPermission");
    const authResponse = await this.authService.authentication();
    const buttonPermission = authResponse.button;
    // no profile
    if (Object.keys(buttonPermission).length === 0) {
      return;
    }
    // no result from API
    if (Object.keys(buttonPermission.tsm).length === 0
    && Object.keys(buttonPermission.sup).length === 0
    && Object.keys(buttonPermission.sph).length === 0) {
      return;
    }
    localStorage.setItem("buttonPermission", JSON.stringify(buttonPermission));
  }
}
