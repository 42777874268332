<div *ngIf="showAlert || showAlert_fail">
  <sh-alert *ngIf="showAlert" [props]="alertProps"></sh-alert>
  <sh-alert *ngIf="showAlert_fail" [props]="alertProps_fail"></sh-alert>
</div>
<div *ngIf="(!logList || logList.length == 0) && showProgressAlert" class="progressStatus">
  <span>
    <img class="status-icon"
    src="assets/icons/info-light-blue.svg"
    alt="Info Icon">
    {{currentStatusMsg}}
  </span>
  <span *ngIf="refreshed" class="normal-text">Last refreshed at {{refresheddate}}</span>
</div>
<div class="header-bar p-3">
  <div class="mb-3">
    <span class="labels overflow-auto">
      <span class="thick-text"> {{validatedSupplierCount}} </span>
      <span class="normal-text"> {{ labels.validatedSuppliers }} </span>
      |
      <span class="thick-text"> {{errorsCount}} </span>
      <span class="normal-text"> {{ labels.errors }}</span>
    </span>
  </div>
  <sh-button class="overflow-auto" [props]="downloadLogsButton" *ngIf="logList && logList.length > 0">
  </sh-button>
  <sh-button class="button overflow-auto" [props]="createSupplierButton" *ngIf="logList && logList.length > 0">
  </sh-button>
</div>
<tsm-bulk-profiles-validation-item class="list-component" [rowDatas]="logList" *ngIf="logList && logList.length > 0">
</tsm-bulk-profiles-validation-item>