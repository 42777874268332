<div class="relationship-container">
  <div *ngIf="!isSupplierParent; else SubsidiarySupplierTemplate">
    <div *ngIf="showParent === 'noParent'" class="noparent-content">
      <img src="assets/images/NoParentsAssigned.svg" alt="no-parent" />
      <div class="empty-msg-text">
        <div
          *ngIf="
            isParentSupplier === 'no' &&
            (showSecifyParent || showParent === 'noParent')
          "
        >
          <div *ngIf="!isIndirect">
            {{ translatedLabels.emptyRelationshipTitle }}
          </div>
          <div *ngIf="isIndirect">
            {{ translatedLabels.noRelationshipTitle }}
          </div>
        </div>
        <div *ngIf="isParentSupplier === 'no' && showSecifyParent">
          {{ translatedLabels.emptyRelationshipSubtitle }}
        </div>
        <div *ngIf="isParentSupplier === 'awaitingConfirmation'">
          {{ translatedLabels.awaitingParentConfirmationTitle }}
        </div>
      </div>
    </div>
    <tsm-supplier-parent-detail
      *ngIf="showParent === 'showParent'"
      [supplierTsmId]="supplierTsmId"
    >
    </tsm-supplier-parent-detail>
    <tsm-parent-relationship-action
      *ngIf="showAction"
      [supplierTsmId]="supplierTsmId"
      [supplierName]="supplierName"
    >
    </tsm-parent-relationship-action>
    <div
      *ngIf="isParentSupplier === 'no' && showSecifyParent"
      [ngClass]="
        showParent !== 'showParent' && !confirmParentMode
          ? 'noparent-content specifyParent'
          : 'noparent-content specifyParentLeft'
      "
    >
      <sh-button [props]="specifyParent"></sh-button>
    </div>
  </div>
  <ng-template #SubsidiarySupplierTemplate>
    <tsm-parent-subsidiary-suppliers
      *ngIf="isSupplierParent"
      [parentSupplierTsmId]="supplierTsmId"
      [isSupplier]="isSupplier"
      [subSupplierMode]="subSupplierMode"
      [displayButtons]="displayButtons"
    ></tsm-parent-subsidiary-suppliers>
  </ng-template>
</div>
<div *ngIf="showHistory && !isSupplierParent">
  <div id="parentChildHistoryTitle" class="parent-child">
    {{ translatedLabels.relationshipHistory }}
  </div>
  <div class="parent-child-container">
    <tsm-parent-child-history
      [parentChildHistory]="parentChildHistory"
    ></tsm-parent-child-history>
  </div>
</div>
