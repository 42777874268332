<div *ngIf="opened" class="modal-header">
    <h4 class="modal-title">{{ "supplier.popUp.promisionsupplier.title" | translate }}</h4>
</div>
<div *ngIf="opened" class="modal-body">
    <div class="modal-body-content">
        <span style="margin-left: 10px;" class="modal-body-content-text">{{ "supplier.popUp.promisionsupplier.content" | translate }}</span>
    </div>
    <div class="d-flex flex-row-reverse">
        <sh-button ngbAutofocus [props]="buttonProps2"> </sh-button>
    </div>
</div>