import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertPropsType, ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { InternalUser } from 'src/app/models/InternalUser';
import { RoleResolutionService } from 'src/app/services/roleResolution/role-resolution.service';
import { SystemAdminService } from 'src/app/services/systemAdmin/system-admin.service';
import { groupBy, orderBy, isEmpty } from "lodash";

@Component({
  selector: 'tsm-system-admin-user-profile',
  templateUrl: './system-admin-user-profile.component.html',
  styleUrls: ['./system-admin-user-profile.component.sass']
})

export class SystemAdminUserProfileComponent implements OnInit {
  user: InternalUser;
  roles: any = [];
  saveError: boolean;
  saveErrorAlertProps: AlertPropsType;

  systemAdminBoard = "/system-admin-board";

  constructor(private systemAdminService: SystemAdminService,
    private route: ActivatedRoute,
    private router: Router,
    private roleResolutionService: RoleResolutionService) { }

  async ngOnInit() {
    const userId = this.route.snapshot.paramMap.get("id");
    // role list  
    this.roles = await this.loadRoles();
    // get user information
    this.user = await this.getUsersById(userId);
  }

  /**
   * Gets a specific user's details from their userID
   * @returns: User: InternalUser
   * @param userId user id
   */
  async getUsersById(userId: string): Promise<any> {
    const user = await this.systemAdminService.getUsersById(userId);
    if (!(user && user.userId)) {
      await this.router.navigateByUrl("/system-admin-board");
    }
    return new InternalUser(this.roleResolutionService, user);
  }

  private async loadRoles(): Promise<any> {
    const profileMap = this.roleResolutionService.getProfileTypeMap();
    const roleList = await this.roleResolutionService.getAllUserRoleDetails()

    const rolesByProfileId = groupBy(roleList, role => role.profileId);

    const result = [];
    for (var profileId in rolesByProfileId) {
      // ignore suppliers
      if (profileId === "2") {
        continue;
      }
      result.push(
        {
          profileId: profileId,
          profileName: profileMap[profileId],
          permissions: orderBy(rolesByProfileId[profileId], 'roleName', 'asc')
        })
    }

    return orderBy(result, "profileName", 'asc');
  }

  /**
   * component outer event
   * include update user role and delete user
   * @param value {opt: opt, roles: roles , displayRoles: displayRoles}
   */
  async outerEvent(value: any) {
    const outerData = JSON.parse(value);
    if (outerData.opt === "updateUserRole") {
      await this.updateUserRole(outerData.roles, outerData.displayRoles);
    }
  }

  /**
   * update user role
   * @param roles roles
   * @param displayRoles displayRoles
   */
  async updateUserRole(roles: any, displayRoles: string) {
    let updatedData = {
      roles,
      isSystemAdmin: this.user.isSystemAdmin
    };

    const response = await this.systemAdminService.updateUserRole(this.user.userId, updatedData);

    // redirects to system admin board after successful API call
    if (response.msg === "success") {
      this.router.navigateByUrl("/system-admin-board");
      let successMessage = `Role for user ${this.user.displayName} was changed to ${displayRoles}`;
      if (isEmpty(displayRoles)) {
        successMessage = `User ${this.user.displayName} was deactivated`;
      }

      // Emit Submission Success event
      setTimeout(() => {
        this.systemAdminService.eventEmit.emit({
          type: "addSuccess",
          message: successMessage
        });
      }, 1000);
    } else {
      // alert error if reponse API call fails
      let message = "There was a problem saving your changes. Please try again or contact your system administrator.";
      this.saveError = true;
      this.saveErrorAlertProps = {
        type: "alert-requestVerifiedFaild",
        message,
        icon: "assets/images/icon-error.svg",
        iconFill: "#cc1e32",
        iconClose: "assets/images/icon-cross-blue.svg",
        onDismiss: this.onAlertDismiss
      };
      window.scrollTo(0, 0);
    }
  }

  onAlertDismiss = (): void => {
    this.saveError = false;
  };

}
