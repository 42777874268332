<div class="modal-header">
    <h4 class="modal-title">Start Risk Assessment</h4>
  </div>
  <div class="modal-body">
    <div class="body-content" *ngIf="showComponent">
      <div class="modal-body-content">
        <qnr-dynamic-form [configs]="configs" [model]="model" [context]="context" [callingComponent]="callingComponent" #form></qnr-dynamic-form>
      </div>
      <div *ngIf="independentContractorFlag">
        <p class="errorMsg">Please select a value for Independent Contractor</p>
      </div>
      <div class="button-content">
        <sh-button ngbAutofocus [props]="cancelProps"></sh-button>
        <sh-button class="continueButton" ngbAutofocus [props]="continueProps" [userType]="userType"></sh-button>
      </div>
    </div>
  </div>