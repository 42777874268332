import { Component, Input, OnInit } from '@angular/core';
import { ParentChildHistoryItem } from '@lsl16/sustainability-shared-components/lib/models/ParentChildHistory';

type parentChildHistoryLiterals = {
  date: string;
  action: string;
  parentCompany: string;
  dunsNumber: string;
}

@Component({
  selector: 'tsm-parent-child-history',
  templateUrl: './parent-child-history.component.html',
  styleUrls: ['./parent-child-history.component.sass']
})
export class ParentChildHistoryComponent implements OnInit {
  @Input() parentChildHistory: ParentChildHistoryItem[];
  labelTranslation: parentChildHistoryLiterals;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        this.labelTranslation = multilanguageJson.body.main.parentChildHistory;
      }
    }
  }
}
