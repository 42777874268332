<div class="main-container">
    <div *ngIf="previewState!=='RecordsFound';else recordPreview" class="search-message-container">
        <img id="searchImage" *ngIf="previewState == 'initial' || previewState == 'NoRecordsFound'"
            src="assets/images/EmptyImage_Users.svg" alt="emptyUsers" class="image" />
        <img id="timeoutImage" *ngIf="previewState == 'TimeoutError'"
            src="assets/images/EmptyImage_Too-many-records.svg" alt="search error" class="image" />

        <div id="initialMessage" *ngIf="previewState == 'initial'" class="highlight-text">
            {{translatedLabels.noSearchMessage}}
        </div>
        <div id="emptySearchMessage" *ngIf="previewState == 'NoRecordsFound'" class="highlight-text">
            {{translatedLabels.emptySearchMessage}}</div>
        <div id="timeoutMessage" *ngIf="previewState == 'TimeoutError'" class="highlight-text-error">
            {{translatedLabels.timeoutErrorMessage}}</div>
    </div>

    <ng-template #recordPreview>
        <div class="download-button-container">
            <tsm-sa-and-scoring-report-download-button [filters]="filters" [totalReportsCount]="reportPreview?.rowcount"
                [reportType]="reportType"
                [filterRequestCompletedEvent]="requestStatusEventEmitter" class="download-button">
            </tsm-sa-and-scoring-report-download-button>
        </div>
        <div *ngIf="reportPreview?.rowcount > 100" class="info-message-container">
            <img src="assets/icons/icon-info-blue.svg" alt="infoIcon" />
            <div>{{countInfoLabel}}</div>
        </div>
        <div class="row bottom-line">
            <div *ngFor="let column of reportPreview?.columns" class="col column-header"
                [ngStyle]="{'max-width': column.width+'%'}">
                {{column.label}}
            </div>
        </div>
        <div *ngFor="let row of reportPreview?.rows" class="row bottom-line">
            <div *ngFor="let column of reportPreview?.columns; index as i" class="col column-data"
                [ngStyle]="{'max-width': column.width+'%'}">
                {{row[i]}}
            </div>
        </div>
    </ng-template>
</div>