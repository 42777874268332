<div class="body_container py-3 px-5"> 
  <div class="my-3 ps-5 pe-5">
    <div class="pb-5" *ngIf="showAlert || showAlert_fail">
      <sh-alert *ngIf="showAlert" [props]="alertProps"></sh-alert>
      <sh-alert *ngIf="showAlert_fail" [props]="alertProps_fail"></sh-alert>
    </div>

    <div class="pt-3 pb-3">
      <div class="bulk-upload-title">
        <span>Buyer/Supplier Help & Support Bulk Upload</span>
      </div>
      <div class="">
        <textarea
          class="content-textarea"
          [(ngModel)]="contentText"
          placeholder="Content to be provided"
          cols="90"
          rows="9"
          readonly
        >
        </textarea>
      </div>
      <div class="d-flex">
        <sh-button
          class="fl_right me-3 mt-3"
          [props]="downloadTemplateAssButton"
        ></sh-button>

        <div class="uploadContainer fl_right me-3 mt-3">
          <label
            class="uploadFile dropTarget"
            ng2FileDrop
            [uploader]="uploader"
            (onFileDrop)="handleFileInput($event)"
          >
            <input
              #uploadFileInput
              type="file"
              (click)="uploadFileInput.value = null"
              accept=".xlsx, .xls"
              (change)="handleFileInput($event.target.files)"
            />
            <div class="labelWithIcon">
              <span class="label"> Bulk Upload Template </span>
            </div>
          </label>
        </div>
        <sh-button
          class="fl_right me-3 mt-3"
          [props]="extractFAQButton"
        ></sh-button>
      </div>
    </div>
  </div>
</div>
