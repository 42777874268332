import { Component, OnInit } from '@angular/core';
import { SubheaderPropsType } from 'src/app/models/SubheaderProps';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'tsm-create-bulk-tsm-profiles',
  templateUrl: './create-bulk-tsm-profiles.component.html',
  styleUrls: ['./create-bulk-tsm-profiles.component.sass']
})
export class CreateBulkTsmProfilesComponent implements OnInit {
  subheaderProps: SubheaderPropsType;
  tabs = [
    {
      id: "Upload",
      title: "Upload Supplier List",
      icon: '',
      active: true
    }
  ];
  constructor(
    private activatedRoute: ActivatedRoute
  ){}

  ngOnInit(): void {
    this.subheaderProps = {
      isWelcomeMessage: false,
      heading: ""
    }
    
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.subheaderProps.heading = multilanguageJson.body.main.reportFilters.createBulkTsmProfileHeading;
      }
    }
  }
}
