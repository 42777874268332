export enum InfoSecScoreCode {
    notRequired = "Not Required - Exempted",
    aboveNormal = "Above Normal",
    high = "High",
    normal = "Normal"
}
export enum InfoSecScoreText{
    notRequired = "Not Required - Exempted",
    aboveNormal = "Above Normal",
    high = "High",
    normal = "Normal"
}