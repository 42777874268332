import { Component, OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ButtonPropsType} from "@lsl16/sustainability-shared-components";
import {Popup} from "../../models/Popup";
import {ModalService} from "src/app/services/modal/modal.service";
import { HttpService } from "@lsl16/sustainability-shared-components";

@Component({
  selector: 'tsm-bv-ra-request-message-pop-up',
  templateUrl: './bv-ra-request-message-pop-up.component.html',
  styleUrls: ['./bv-ra-request-message-pop-up.component.sass']
})
export class BvRaRequestMessagePopUpComponent implements OnInit {

  buttonProps1: ButtonPropsType = {
    label: "Ok",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderColor:"purple",
  };
  
  modalData: Popup;
  translations: any;
  constructor(public activeModal: NgbActiveModal
   ) { }

  ngOnInit(): void {
    this.buttonProps1.onClick = this.activeModal.close;
  }

  ngAfterContentChecked(): void {
    if (localStorage.getItem('multiLanguage')) {
      const multiLanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      this.translations =
        multiLanguageJson.popUp.riskAssessmentAvailibility;
      this.buttonProps1.label = this.translations.confirmLabel;
    }
  }
}
