<div class="container-fluid">
    <div class="main_bg">
        <div class="container container-fluid first-container d-flex">
            <div class="form me-5 ms-5">
                <div class="row row-cols-auto">
                    <div>
                        <div class="initial-step">{{labelTranslations.stepByStep}}</div>
                        <div class="step-text text-start">{{labelTranslations.guidelines}} </div>
                    </div>
                    <ng-container *ngFor="let step of steps; index as i; let last = last">
                        <div>
                            <div class="number-circle">{{i+1}}</div>
                            <div class="step-text">{{labelTranslations[step.id]}}</div>
                        </div>
                        <div class="col ps-0 pe-0 line-grow" *ngIf="!last">
                            <p class="line"></p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>