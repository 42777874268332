<div class="container-fluid mb-5">
  <div class="main_bg">
    <div class="container container-fluid first-container d-flex">
      <div class="form me-5 mb-5 ms-5 p-5">
        <div class="advanced-search advanced-search-content">
          <div class="search-panel bg-white mb-3 mx-0">
            <div class="filters-area row g-3">
              <div class="col-4 px-16" id="mkunit">
                <tsm-market-unit-filter [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'marketUnit')">
                </tsm-market-unit-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-country-served-filter [clearFilter]="clearFilter" (valueChanged)="
                    filterCriteriaChanged($event, 'countryServed')
                  ">
                </tsm-country-served-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-company-size-filter [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'companySize')">
                </tsm-company-size-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-company-category-filter [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'companyCategory')">
                </tsm-company-category-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-company-subcategory-filter [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'companySubCategory')">
                </tsm-company-subcategory-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-supplier-score-filter [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'supplierScore')">
                </tsm-supplier-score-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-esg-status-filter (valueChanged)="filterCriteriaChanged($event, 'esgStatus')"
                  filterMode="saRetrigger"></tsm-esg-status-filter>
                <div class="error">SA can be retriggered for validated assessments only</div>
              </div>
              <div class="col-4 px-16">
                <tsm-supplier-category-filter [clearFilter]="clearFilter" (valueChanged)="
                    filterCriteriaChanged($event, 'supplierCategory')
                  ">
                </tsm-supplier-category-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-sa-request-date-filter [clearFilter]="clearFilter" [invalidValue]="invalidValue" (dateFromChanged)="
                    filterCriteriaChanged($event, 'saRequestDate')
                "></tsm-sa-request-date-filter>
              </div>
              <div class="inline-block"></div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="align-self-end me-3">
                <sh-button [props]="clearFilterButton"></sh-button>
              </div>
              <div class="align-self-end">
                <sh-button [props]="applyFilterButton"></sh-button>
              </div>
            </div>
            <div id="waitingMessage" class="d-flex justify-content-end waitingMessage"
              *ngIf="showWaitingMessage && !filterRequestCompleted">
              {{ waitingMessageLabel }}
            </div>

            <div id="waitingMessage" class="d-flex justify-content-end waitingMessage set-position">
              {{saRetriggerFilterScenario}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-5">
      <tsm-sa-retriggering-preview [applyFilterEvent]="applyFilterEventEmitter"
        (requestStatusEventEmitter)="handleRequestStatusEvent($event)"></tsm-sa-retriggering-preview>

    </div>
  </div>
</div>