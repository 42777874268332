import { Component, Input, OnInit } from '@angular/core';
import { CustomStyles } from 'src/app/models/Styles';

@Component({
  selector: 'tsm-relation-status',
  templateUrl: './relation-status.component.html',
  styleUrls: ['./relation-status.component.sass']
})
export class RelationStatusComponent implements OnInit {
  @Input() customStyles: CustomStyles;
  constructor() { }

  ngOnInit(): void {
  }

}
