<!-- <div class="supplier-validation-list"> -->
    <div class="mt-2 p-3 mx-2">
        <ngx-datatable class="bootstrap" [rows]="rowDatas" [headerHeight]="50" [scrollbarH]="true" [rowHeight]="'auto'"
        [columnMode]="'force'">
        <ngx-datatable-column *ngFor="let col of columns" [prop]="col.prop" [width]="col.width"
            [frozenLeft]="col.isLeftPinnedColumn" [frozenRight]="col.isRightPinnedColumn">
            <ng-template ngx-datatable-header-template>
                {{col.name | uppercase}}
                <ng-contianer [ngSwitch]="col.prop">
                    <ng-contianer *ngSwitchCase="'Row No'">
                        <img
                        class="help-icon"
                        src="..\..\..\..\assets\icons\icon-help-enabled.svg"
                        width="20"
                        alt="help icon"
                        (mousemove)="showTooltip($event)"
                        (mouseleave)="hideTooltip()"
                        />
                    </ng-contianer>
                </ng-contianer>
            </ng-template>
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-rowIndex="rowIndex">
                <ng-contianer [ngSwitch]="col.prop">
                    <ng-contianer *ngSwitchCase="'Validation Results'" (mousemove)="showTooltip_2($event, labelType.validationResults, rowIndex)"
                    (mouseleave)="hideTooltip_2()">
                        <div *ngIf="row['isFailed'] === true; else successSymbol">
                            <img
                              class="status-icon"
                              src="assets/images/Warning_Icon.svg"
                              alt="Error Icon"
                            />
                            {{value}}
                        </div>
                        <ng-template #successSymbol>
                            <div *ngIf="row['Validation Results'] === ''; else successSymbolAndMsg">
                                <img
                                    class="status-icon"
                                    src="assets/images/Validation_Icon.svg"
                                    alt="Success Icon"
                                />
                                {{validationLabels.validationSuccess}}
                            </div>
                            <ng-template #successSymbolAndMsg>
                                <img
                                    class="status-icon"
                                    src="assets/images/Validation_Icon.svg"
                                    alt="Success Icon"
                                />
                                {{value}}
                            </ng-template>
                        </ng-template>
                        
                    </ng-contianer>
                    <span *ngSwitchDefault>{{value}}</span>
                </ng-contianer>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
    </div>
  