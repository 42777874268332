import { Injectable } from '@angular/core';
import { CustomValidator, ComponentConfig, DynamicContext } from "@lsl16/sustainability-shared-components";
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Context, Validators } from './constants';

@Injectable()
export class CustomValidators {
    @CustomValidator({name: Validators.EMAIL_ALREADY_PRESENT, async: false})
    public emailAlreadyPresent(config: ComponentConfig, context: DynamicContext): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            if(!control.value || control.value.trim() === "") {
                return null;
            }

            const adjustedEmail = control.value.trim().toLowerCase();

            let organisationUsers = context.getValue(Context.ORGANISATION_USERS);
            
            if(organisationUsers && Array.isArray(organisationUsers) && organisationUsers.some(
                u => adjustedEmail === u.Email?.toLowerCase()
            )) {
                return { [Validators.EMAIL_ALREADY_PRESENT]: true };
            }

            return null;
        }
    }
}