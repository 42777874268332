<div class="row row-cols-auto itemlist">
  <div class="id-width">
    <h6 class="subtitle">ID</h6>
  </div>
  <div class="col-1-5">
    <div class="subtitle">SUPPLIER PROFILE</div>
  </div>
  <div class="col-1-5">
    <h6 class="subtitle">COUNTRY SERVED</h6>
  </div>
  <div class="col-1-5">
    <div class="subtitle-requestor">UPDATE REQUESTOR</div>
  </div>
  <div class="col-comment">
    <h6 class="subtitle">COMMENTS</h6>
  </div>
  <div class="date-width">
    <div class="subtitle">REQUEST DATE</div>
  </div>
  <div class="col-status">
    <h6 class="subtitle">STATUS</h6>
  </div>
  <div class="col-action">
    <h6 class="subtitle-action">ACTION</h6>
  </div>
</div>
<div
  *ngFor="let item of items; let i = index"
  style="border-bottom: solid 1px rgba(78, 78, 135, 0.2)"
>
  <tsm-manage-SA-request-approve-log-item
    [model]="item"
    (reQuery)="reQuery()"
  ></tsm-manage-SA-request-approve-log-item>
</div>
