import { EventEmitter } from '@angular/core';
import { ButtonPropsType } from '@lsl16/sustainability-shared-components';
import { AdminBoardService } from './../../services/adminboard/admin-board.service';
import { AfterContentChecked, Component, Input, OnInit, Output } from '@angular/core';

type UserProfileData = {
  firstName: string,
  lastName: string,
  email: string
}

@Component({
  selector: 'tsm-user-profile-container',
  templateUrl: './user-profile-container.component.html',
  styleUrls: ['./user-profile-container.component.sass']
})
export class UserProfileContainerComponent implements OnInit, AfterContentChecked {
  @Input() userId: string;
  @Output() saveClickedEvent = new EventEmitter<void>();
 
  currentUser: UserProfileData;
  currentButtonProps: ButtonPropsType;
  showSaveButton: boolean;
  showSaveSuccess: boolean;

  translatedLabels = {
    saveButtonLabel: "",
    saveSuccessButtonLabel: ""
  };

  constructor(
    private adminBoardService: AdminBoardService
  ) { }

  public ngAfterContentChecked(): void {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(localStorage.getItem('multiLanguage'));
    
      if (multilanguageJson.body) {
        const translations = multilanguageJson.body.sharedComponent.userProfile;

        this.translatedLabels.saveButtonLabel = translations.saveButtonLabel;
        this.translatedLabels.saveSuccessButtonLabel = translations.changeSaved;
      }
    }
  }

  public async ngOnInit(): Promise<void> {
    const user = await this.adminBoardService.getAdminBoardLogItem(this.userId);
    this.currentUser = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.userName
    }
  }

  public onDataChanged(canSave: boolean) {
    if (canSave) {
      this.currentButtonProps = this.getEnabledSaveButtonProps();
    } else {
      this.currentButtonProps = this.getDisabledSaveButtonProps();
    }

    this.showSaveSuccess = false;
    this.showSaveButton = true;
  }

  public onSaveSuccess() {
    this.showSaveButton = false;
    this.showSaveSuccess = true;
  }

  private getEnabledSaveButtonProps(): ButtonPropsType {
    return {
      label: this.translatedLabels.saveButtonLabel,
      color: "purple",
      fontSize: 16,
      fontFamily: "Graphik-Medium",
      backgroundColor: "paleGrey",
      borderColor: "paleGrey",
      hoverColor: "purple",
      hoverBackgroundColor: "state-button-contained-secondary-b-h",
      hoverBorderColor: "state-button-contained-secondary-b-h",
      height: 48,
      width: 170,
      padding: "16px 8px",
      iconPosition: "left",
      disabled: false,
      onClick: () => this.emitSaveClickedEvent()
    };
  }

  private getDisabledSaveButtonProps(): ButtonPropsType {
    return {
      label: this.translatedLabels.saveButtonLabel,
      color: "grey",
      fontSize: 16,
      fontFamily: "Graphik-Medium",
      backgroundColor: "paleGrey",
      borderColor: "paleGrey",
      height: 48,
      width: 170,
      padding: "16px 8px",
      iconPosition: "left",
      disabled: true
    };
  }

  private emitSaveClickedEvent(): void {
    this.saveClickedEvent.emit();
  }

}
