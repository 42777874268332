import { ParentChildRelation } from '@lsl16/sustainability-shared-components';
import { Component, Input, OnInit } from '@angular/core';
import { SupplierRelationshipService} from '@lsl16/sustainability-shared-components';
import { ParentChildHistory } from '@lsl16/sustainability-shared-components/lib/models/ParentChildHistory';
import { isEmpty } from "lodash";

type ShowParentStatus = "showNoRelationExists" | "showNoParent" | "showParent" ;

@Component({
  selector: 'tsm-buyer-relationship',
  templateUrl: './buyer-relationship.component.html',
  styleUrls: ['./buyer-relationship.component.sass']
})
export class BuyerRelationshipComponent implements OnInit {
  @Input() supplierTsmId: string;
  @Input() isSupplierParent: boolean;
  @Input() isIndirect: boolean = false;  
  @Input() isDuplicate = false;
  public translatedLabels = {}
  public showParent: ShowParentStatus = "showNoRelationExists";
  private parentChildRelation?: ParentChildRelation;
  showHistory: boolean = false;
  parentChildHistory: ParentChildHistory;

  constructor(
    private relationshipService: SupplierRelationshipService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.updateSupplierRelation();    
    await this.getParentChildHistory();
    this.subscribeEvent();
  }

  private subscribeEvent() {
    this.relationshipService.getSupplierRelationChangedEvent()
      .subscribe(next => this.handleSupplierRelationChanged(next));
  }

  private async updateUiFlags() {
    if (this.isWithoutParentRelation()) {
      this.showParent = "showNoRelationExists";
      return;
    }

    if (this.parentChildRelation.status == "NoParent") {
      this.showParent = "showNoParent";
      return;
    }

    this.showParent = "showParent";
  } 

  private isWithoutParentRelation() {
    // the supplier has no parent relation when the id property is empty
    return !this.parentChildRelation?.childTsmId;
  }

  private async handleSupplierRelationChanged(supplierTsmId: string) {
    if (supplierTsmId === this.supplierTsmId) {
      await this.updateSupplierRelation();
    }
  }

  private async updateSupplierRelation() {
    this.parentChildRelation = await this.relationshipService.getParentChildRelation(this.supplierTsmId);
    await this.updateUiFlags();
  }

  private async getParentChildHistory() {
    this.showHistory = false;
    const parentChildHistory = await this.relationshipService.getParentChildHistory(this.supplierTsmId);
    if (!isEmpty(parentChildHistory)) {
      this.parentChildHistory = parentChildHistory;
      this.showHistory = true;
    }
  } 

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.translatedLabels = multilanguageJson.body.main.supplierRelationshipAsBuyer;
      }
    }
  }
}
