<div class="download-button-container">
    <div class="d-flex " style="margin-top:30px;justify-content: space-between;">
      <div class="page-size-selection">
        <label for="pageSize">RETRIGGERED ON:</label>
         {{firstStatusRecord.triggeredOn | date: 'MM/dd/yyyy'}}
      </div>
      <div class="page-size-selection">
        <label for="pageSize">POC NAME:</label>
        {{firstStatusRecord.pocEmail}}
      </div>
    </div>
  </div>
  
  <div >
      <ngx-datatable class="bootstrap" [reorderable]="true" [rows]="statusRecord" [headerHeight]="50" [scrollbarH]="true" [footerHeight]="100" [rowHeight]="52" [columnMode]="'force'" [limit]="100" >
        <ngx-datatable-column *ngFor="let col of columns; let columnIndex = index" [prop]="col.prop" [width]="col.width">
          <ng-template ngx-datatable-header-template let-colIndex="colIndex">
            <ng-contianer >
              <div class="column-title">{{ col.name }}</div>
            </ng-contianer>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
            <div class="cell-data">
              <span >{{ value }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  