import { ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { ButtonPropsType, AdminBoardLogItem } from "@lsl16/sustainability-shared-components";
import { UserProfileContainerComponent } from "src/app/components/user-profile-container/user-profile-container.component";

@Component({
  selector: 'tsm-user-profile-edit-roles',
  templateUrl: './user-profile-edit-roles.component.html',
  styleUrls: ['./user-profile-edit-roles.component.sass']
})
export class UserProfileEditRolesComponent implements OnInit {
    currentRole: any[];
    checkedRoles: any[];
    displayRoleList: any[];
    open = false;
    props: ButtonPropsType;
    propsDisabled: ButtonPropsType;
    saved = false;
    selectedItems: any[] = [];
    @Input() loggedInUser: any;
    @Input() user: AdminBoardLogItem;
    @Input() roles: any[];
    @Output() outerEvent = new EventEmitter<string>();
    @ViewChild("inputPanel") inputPanel: ElementRef;
    @ViewChild("dropPpanel") dropPpanel: ElementRef;
    @ViewChild(UserProfileContainerComponent) userProfileContainer: UserProfileContainerComponent;
    userProfileJson: any;
    deleteUser: any;
    restrictAccess: any;
    saveChange: any;

    constructor() {}
    ngOnInit(): void {
        this.currentRole = this.user.role;
        this.checkedRoles = this.user.role;
        for (const r in this.roles) {
            if (this.checkedRoles.includes(parseInt(this.roles[r].Code))) {
                this.roles[r].checked = true;
            }
        }
        this.props = this.getButtonProps();
        this.propsDisabled = this.disabledProps();
    }
    /**
     * Saves the user's role
     */
    saveRole(): void {
        this.setRoleState();
        if (!this.user.role) {
            return;
        }
        const outEventData = {
            opt: "updateUserRole",
            value: this.user.role
        };
        this.outerEvent.emit(JSON.stringify(outEventData));
        this.saved = true;
        this.currentRole = this.user.role;
        this.userProfileContainer.onSaveSuccess();
    }

    getButtonProps(): ButtonPropsType {
        return {
            label: "Save Changes",
            color: "purple",
            fontSize: 16,
            fontFamily: "Graphik-Medium",
            backgroundColor: "paleGrey",
            borderColor: "paleGrey",
            hoverColor: "purple",
            hoverBackgroundColor: "state-button-contained-secondary-b-h",
            hoverBorderColor: "state-button-contained-secondary-b-h",
            height: 48,
            width: 170,
            padding: "16px 8px",
            iconPosition: "left",
            disabled: false,
            onClick: () => this.saveRole()
        };
    }

    disabledProps(): ButtonPropsType {
        return {
            label: "Save Changes",
            color: "grey",
            fontSize: 16,
            fontFamily: "Graphik-Medium",
            backgroundColor: "paleGrey",
            borderColor: "paleGrey",
            height: 48,
            width: 170,
            padding: "16px 8px",
            iconPosition: "left",
            disabled: true
        };
    }

    delete(): void {
        const outEventData = {
            opt: "deleteUser"
        };
        this.outerEvent.emit(JSON.stringify(outEventData));
    }

    restrict(): void {
    }

    setRoleState(): void {
        this.saved = false;
        this.user.role = this.checkedRoles;
    }

    /**
     * Toggles the dropdown box
     * @returns: void
     */
    toggleDropdown(): void {
        this.open = !this.open;
        this.setRoleState();
    }

    @HostListener("document:click", ["$event"])
    documentClick(event) {
        if (this.inputPanel && this.dropPpanel) {
            if (
                this.inputPanel.nativeElement.contains(event.target) ||
                this.dropPpanel.nativeElement.contains(event.target)
            ) {
                return;
            }
        }
        this.open = false;
        this.setRoleState();
    }

    setButtonDisabledProp = (disabled: boolean): void => {
        const canSave = this.checkedRoles.length > 0;
        this.userProfileContainer.onDataChanged(canSave);
        if (this.checkedRoles.length < 1) {
            this.props.disabled = true;
            this.disabledProps;
        } else {
            this.props.disabled = false;
        }
    };

    checkedChanged(event, role: any) {
        const target = this.roles.find((d) => d.Code === role.Code);
        target.checked = !target.checked;
        this.selectedItems = this.roles.filter((d) => d.checked);
        this.checkedRoles = this.selectedItems.map((i) => parseInt(i["Code"]));
        this.setButtonDisabledProp(true);
        this.user.displayRole = this.selectedItems.map((i) => i["Text"]).toString();
    }

    ngAfterContentChecked() {
        if(localStorage.getItem("multiLanguage")) {
            const multiLanguageJson =  JSON.parse(localStorage.getItem("multiLanguage"));
            if (multiLanguageJson.body != undefined) {
                this.userProfileJson = multiLanguageJson.body.sharedComponent.userProfile;
                this.deleteUser = this.userProfileJson.deleteUser;
                this.restrictAccess = this.userProfileJson.restrictAccess;
                this.saveChange = this.userProfileJson.changeSaved;
                for (let index = 0; index < this.roles.length; index++) {
                    this.roles[index].Text = this.userProfileJson.roles[index]
                }
                let showRoles = '';
                this.roles.forEach(element => {
                    if (element['checked'] == true) {
                        if (showRoles == '') {
                            showRoles = showRoles + element['Text']
                        } else {
                            showRoles = showRoles + ';' + element['Text']
                        }
                    }
                });
                document.getElementById('displayRole').innerHTML = showRoles;
            }
        }
    }

}
