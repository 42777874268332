export const countryNodifySendSaData = {
    title: "Initiating Sustainability Assessment Request",
    iconUrl: "",
    content: ``,
    headerCloseButton: false,
    centered: true,
    size: "lg",
};

export const countryNodifyWithdrawSaData = {
    title: "Withdrawing Sustainability Assessments",
    iconUrl: "",
    content: `You are about to withdraw a Sustainability Assessment request for`,
    headerCloseButton: false,
    centered: true,
    size: "lg",
};

export const countryNodifyPCmapping = {
    title: "Initiating Sustainability Assessment Request",
    iconUrl: "",
    content: ``,
    headerCloseButton: false,
    centered: true,
    size: "lg",
};