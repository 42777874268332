import _ from 'lodash';

import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
    ButtonPropsType,
    ComponentConfig,
    DynamicContext,
    DynamicFormComponent,
    AlertPropsType,
    SupplierRelationshipService,
    SingleChoiceDropdownComponent
} from "@lsl16/sustainability-shared-components";
import { SupplierService } from "../../services/supplier/supplier.service";
import { SupplierGeneralProfileService } from "src/app/services/supplier-general-profile.service";
import { Subscription } from "rxjs";
import { BlacklistService } from "../../services/blacklist/blacklist.service";
import { EsgQuestionnaireService, MetadataService } from "@lsl16/sushub-frontend";
import { AuthService } from "src/app/services/auth/auth.service";
import { responseEnum } from "../../../utilities/response-enum";

import { AdminBoardService } from 'src/app/services/adminboard/admin-board.service';
import { Context } from 'src/app/questionnaire/constants';
import { countryList } from 'src/utilities/const/country-list';

const SUPPLIER_POC_COMPONENT = "supplierPoC";

@Component({
    selector: "tsm-assess-request-form",
    templateUrl: "./sustainability-assessment.component.html",
    styleUrls: ["./sustainability-assessment.component.sass"]
})
export class SustainabilityAssessmentComponent implements OnInit {
    configs: Array<ComponentConfig> = [];
    model: any = {};
    context: DynamicContext;
    @ViewChild("form", { static: false }) form: DynamicFormComponent;
    confirmButton: ButtonPropsType;
    supplierTsmId: string;
    countryServed: string;
    marketUnit: string;
    countryGroupSa: string;
    supplierName: string;
    saveError: boolean;
    taskId: string;
    saveErrorAlertProps: AlertPropsType = {
        type: "alert-error",
        message: "There is a technical issue, please try again or contact us.",
        icon: "assets/images/icon-error.svg",
        iconFill: "#cc1e32",
        iconClose: "assets/images/icon-cross-blue.svg"
    };
    supplierTsmIdEmitSubscription: Subscription;
    buyerId: string;
    buyerUserTsmId: string;
    backId: string;
    hasParent: boolean = false;
    updatedCountryServedArray: string[];
    usersFromBackend: any[] = [];
    successCountries = [];
    failCountries = [];
    bothValues = {};

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private metadataService: MetadataService,
        private supplierService: SupplierService,
        private supplierGeneralProfileService: SupplierGeneralProfileService,
        private blacklistService: BlacklistService,
        private esgQuestionnaireService: EsgQuestionnaireService,
        public authService: AuthService,
        private supplierRelationshipService: SupplierRelationshipService,
        private adminBoardService: AdminBoardService
    ) {
        this.handleAddNewPoCEvent = this.handleAddNewPoCEvent.bind(this);
        this.handleSupplierPoCChangeEvent = this.handleSupplierPoCChangeEvent.bind(this);
    }

    hasAnySupplierPermission(user) {
        try {
            const extensionData = JSON.parse(user.extension_tsm_TSM);
            return extensionData["2"].includes(1) || extensionData["2"].includes(2);
        }
        catch {
            console.log(`Error parsing extension data for ${user.userName}.`);
            return false;
        }
    }

    getSupplierPermissionByExtension(user) {
        const extensionData = JSON.parse(user.extension_tsm_TSM);
        if (extensionData && extensionData["2"] && Array.isArray(extensionData["2"])) {
            if (extensionData["2"].includes(2)) {
                return "supplierAdmin";
            }
            else if (extensionData["2"].includes(1)) {
                return "generalSupplier";
            }
        }
    }

    getFirstUserAlphabeticallyByUserName(users) {
        const result = _.orderBy(users, ["userName"]);
        return result[0];
    }

    getSelectedOrganisationUserId(users) {
        const parsedUsers = _.groupBy(
            users.map(u => ({ ...u, role: this.getSupplierPermissionByExtension(u) })),
            u => u.role
        );

        if (parsedUsers.supplierAdmin) {
            return this.getFirstUserAlphabeticallyByUserName(parsedUsers.supplierAdmin).userId;
        }

        if (parsedUsers.generalSupplier) {
            return this.getFirstUserAlphabeticallyByUserName(parsedUsers.generalSupplier).userId;
        }
    }

    async setOrganisationUsers() {
        const tmpUserResult = await this.adminBoardService.getAdminBoardLog(this.supplierTsmId);

        if (Array.isArray(tmpUserResult) && tmpUserResult.length > 0) {
            this.usersFromBackend = tmpUserResult.filter(u => this.hasAnySupplierPermission(u));
            //remove unregistered users
            this.usersFromBackend = this.usersFromBackend.filter(u => u.userId !== undefined);
            
            const selectedUserId = this.getSelectedOrganisationUserId(this.usersFromBackend)

            const organisationUsers = this.usersFromBackend.map((u) => ({
                Code: u.userId,
                Text: u.firstName && u.lastName ? `${u.firstName} ${u.lastName}` : u.userName,
                Selected: u.userId === selectedUserId,
                Email: u.userName
            }))

            this.context.set(Context.ORGANISATION_USERS, organisationUsers);
        }
    }

    handleAddNewPoCEvent() {
        const model = this.context.getModel();
        model.primaryContactFirstName = "";
        model.primaryContactLastName = "";
        model.primaryContactEmail = "";

        const pocComponent = this.context.getComponent(SUPPLIER_POC_COMPONENT);
        // this cast is fine since it can only be broken by changing the form-metadata in the backend
        (pocComponent as SingleChoiceDropdownComponent).choose(null, null);

        this.form.updateFormValues();
    }

    handleSupplierPoCChangeEvent(newPoC) {
        const model = this.context.getModel();

        const user = _.find(this.usersFromBackend, u => u.userId === newPoC);

        if (user) {
            model.primaryContactFirstName = user.firstName;
            model.primaryContactLastName = user.lastName;
            model.primaryContactEmail = user.userName;
        }

        this.form.updateFormValues();
        this.enableNameControls();
    }

    createDynamicContext() {
        this.context = new DynamicContext();
    }

    async ngOnInit() {
        const routeParam = this.route.snapshot.paramMap.get("id");
        const routeParamCountry = this.route.snapshot.paramMap.get("country");
        this.supplierTsmId = routeParam;
        this.countryServed = routeParamCountry;

        this.createDynamicContext();

        const userInfo = this.authService.getLoginUser();
        this.buyerId = userInfo.tsmId;
        this.buyerUserTsmId = userInfo.userId;

        this.backId = this.supplierTsmId;

        const parent = await this.supplierRelationshipService.getParentChildRelation(this.supplierTsmId);
        if (parent && parent.parentTsmId && parent.status === 'MutuallyConfirmed') {
            this.hasParent = true;
            this.supplierTsmId = parent.parentTsmId;
        }

        const promises = await Promise.all([
            this.blacklistService.returnBlacklist(this.buyerId),
            this.metadataService.getAssessmentFormConfig(),
            this.setOrganisationUsers()     
        ]);

        const blacklist = promises[0];
        const formMetadata = promises[1];

        this.context.set("blacklist", blacklist);

        const supplier = await this.supplierGeneralProfileService.getSupplierById(this.supplierTsmId);

        this.context.on("BUTTON:EVENT::add-new-poc", this.handleAddNewPoCEvent);
        this.form.subscribeToModelChange(SUPPLIER_POC_COMPONENT, this.handleSupplierPoCChangeEvent);

        if (this.hasParent) {
            this.countryServed.split(',').forEach(i=>{          
                if(!supplier.countryServed.includes(i)){
                    supplier.countryServed.push(i)
                    this.updatedCountryServedArray = supplier.countryServed;
                }
            })
        }

        this.configs = formMetadata;

        this.confirmButton = {
            label: "Confirm & Send To Supplier",
            color: "white",
            fontSize: 16,
            backgroundColor: "blue",
            borderColor: "blue",
            height: 48,
            width: 280,
            padding: "10px",
            onClick: () => {
                this.submitForm();
            }
        };
        this.route.queryParams.subscribe((params) => {
            if (this.hasParent) {
                this.supplierName = supplier.supplierName;
            } else {
                this.supplierName = params.supplierName;
            }
            if (params.taskId) {
                this.taskId = params.taskId;
            }
        });
    }

    async submitForm() {
        if (this.form.validate()) {
            this.confirmButton.isProcessing = true;
            const values = this.form.values();
            Object.assign(values, {
                supplierTsmId: this.supplierTsmId,
                host: window.location.origin,
                buyerTsmId: this.buyerId,
                buyerUserTsmId: this.buyerUserTsmId,
                supplierName: this.supplierName,
                defaultTaskType: "assessment"
            });
            this.route.queryParams.subscribe((params) => {
                if (params.taskId) {
                    Object.assign(values, {
                        taskId: this.taskId,
                        primaryContactEmail: params.primaryContactEmail,
                    });
                }
            });
            // addNew
            if (values.taskId) {
                values.countryServed = this.countryServed;
                const response = await this.supplierService.addNew(values);
                // redirects user after successful API call
                if (response.text === "OK") {
                    this.successSupplierService('');
                } else {
                    this.failSupplierService(response);
                }
            } else {
                // get countries for create task and indentify its type
                let createTaskCountries = this.countryServed.split(',');
                if (countryList.Data.countryGroupSa[createTaskCountries[0]] == '') {
                    // create task need a countryServed param
                    values.countryServed = createTaskCountries[0];
                    const response = await this.supplierService.add(values);
                    this.processNoTypeCountry(response, createTaskCountries);
                } else {
                    let typeList = [];
                    // create task for type need a countryServed param
                    let typeRepresentCountries = [];
                    for (let a = 0; a < createTaskCountries.length; a++) {
                        let groupSa = countryList.Data.countryGroupSa[createTaskCountries[a]];
                        if (typeList.indexOf(groupSa) < 0) {
                            typeList.push(groupSa);
                            typeRepresentCountries.push(createTaskCountries[a]);
                        }
                    }
                    // traverse multi type will get multi responses
                    let responseList = [];
                    for (let b = 0; b < typeList.length; b++) {
                        values.countryServed = typeRepresentCountries[b];
                        const response = await this.supplierService.add(values);
                        responseList.push(response);
                    }
                    this.processTypeCountry(responseList, typeList, typeRepresentCountries, createTaskCountries);
                }
            }
        }
    }

    private processNoTypeCountry(response, createTaskCountries) {
        // redirects user after successful API call
        if (response && response.$metadata && response.$metadata.httpStatusCode === 200) {
            // reset counry for jump page to buyer
            this.countryServed = createTaskCountries[0];
            this.successSupplierService('');
        } else {
            this.failSupplierService(response);
        }
    }

    private processTypeCountry(responseList, typeList, typeRepresentCountries, createTaskCountries) {
        let successResp = [];
        let failResp = [];
        let successRespCountries = [];
        let failTypes = [];
        for (let a = 0; a < responseList.length; a++) {
            let resp = responseList[a];
            if (resp && resp.$metadata && resp.$metadata.httpStatusCode === 200) {
                successResp.push(resp);
                successRespCountries.push(typeRepresentCountries[a])
            } else {
                failResp.push(resp);
                failTypes.push(typeList[a])
            }
        }
        switch (successResp.length) {
            // all success
            case responseList.length:
                this.countryServed = createTaskCountries[0];
                this.successSupplierService('');
                break;
            // all fail
            case 0:
                this.failSupplierService(failResp[0]);
                break;
            // both
            default:
                // jump to buyer page should show success response country
                this.countryServed = successRespCountries[0];
                this.successCountries = [];
                this.failCountries = [];
                // get countryList for success and fail in separate
                for (let a = 0; a < createTaskCountries.length; a++) {
                    if (countryList.Data.countryGroupSa[createTaskCountries[a]] != failTypes[0]) {
                        this.successCountries.push(countryList.Data.mapping[createTaskCountries[a]]);
                    } else {
                        this.failCountries.push(countryList.Data.mapping[createTaskCountries[a]]);
                    }
                }
                let message_success = 'You have successfully sent the Sustainability Assessment request of ' + this.successCountries.join(',') + ' to the Supplier.';
                let message_fail = 'The Sustainability Assessment request of ' + this.failCountries.join(',') + ' haven\'t sent successfully.';
                this.bothValues = {
                    text: "bothCase",
                    message_success: message_success,
                    message_fail: message_fail
                }
                
                this.successSupplierService('multi-alert');
                break;
        }
    }

    private successSupplierService(alert) {
        // udate countyServed of parent
        if (this.hasParent && this.updatedCountryServedArray) {
            const updateSupplierValues = {
                countryServed: this.updatedCountryServedArray,
                pocEmail: this.authService.getLoginUser().email,
            };
            this.supplierGeneralProfileService.updateSupplier(
                this.supplierTsmId,
                updateSupplierValues
            );
        }
        this.router.navigate(['/supplier-profile', this.backId], {
            queryParams: { countryServed: this.countryServed },
        });
        // Emit Submission Success event
        if (alert && alert == 'multi-alert') {
            setTimeout(() => {
                this.esgQuestionnaireService.eventEmit.emit(this.bothValues);
            }, 3000);
        } else {
            setTimeout(() => {
                this.esgQuestionnaireService.eventEmit.emit('addSuccess');
            }, 3000);
        }
    }

    private failSupplierService(response) {
        this.confirmButton.isProcessing = false;
        let message = "There is a technical issue, please try again or contact us.";
        if (response.code === responseEnum.b2c_email_used.code) {
            message =
                "This email address is already in use. Please provide a different email address for the supplier.";
        } else if (response.code === responseEnum.b2c_invalid_domain.code) {
            message = "Please ensure that the email address doesn't contain any special characters.";
        }
        // alert error if reponse API call fails
        this.saveError = true;
        this.saveErrorAlertProps = {
            type: "alert-requestVerifiedFaild",
            message,
            icon: "assets/images/icon-error.svg",
            iconFill: "#cc1e32",
            iconClose: "assets/images/icon-cross-blue.svg",
            onDismiss: this.onAlertDismiss
        };
        window.scrollTo(0, 0);
    }

    onAlertDismiss = (): void => {
        this.saveError = false;
    };

    private enableNameControls() {
        const firstNameFormControl = this.form?.formGroup?.controls.primaryContactFirstName;
        const lastNameFormControl = this.form?.formGroup?.controls.primaryContactLastName;
        if (firstNameFormControl?.value === '') {
            firstNameFormControl?.enable();
        }
        if (lastNameFormControl?.value === '') {
            lastNameFormControl?.enable();
        }
    }
}
