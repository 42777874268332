<tsm-user-secondary-banner [jumpUrl]="supplierAdminBoard"></tsm-user-secondary-banner>
<sh-alert [props]="saveErrorAlertProps" [ngStyle]="{'display': saveError ? 'block' : 'none'}"></sh-alert>
<div class="body_container">
  <div class="main_bg page-padding">
    <div class="container">
      <div class="form shadow-sm">
        <div class="d-flex title">
          <div class="me-auto ms-2 align-self-center cursor-pointer">
            {{'supplier.body.main.createNewUser.title' | translate}}
          </div>
        </div>
        <form class="detailform">
          <qnr-dynamic-form [configs]="configs" [model]="model" [context]="context" #form></qnr-dynamic-form>
          <div class="inline-block">
          </div>
          <div class="d-flex justify-content-between">
            <div class="align-self-center">
              &nbsp;
            </div>
            <div class="align-self-center">
              <sh-button [props]="nextButton"></sh-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>