import { Component, OnInit, Input } from '@angular/core';
import { SupplierListResponseReportRow } from 'src/app/models/SupplierListFile';
import { AppComponent } from 'src/app/app.component';

type RowDataLabels = {
  supplierTsmId: string;
  supplierName: string;
  countryServed: string;
  fullCountryServed?: string;
  rowNumber: string;
  validationResults: string;
  fullValidationResults?: string;
  successMessage: string;
};

enum LabelType {
  countryServed,
  validationResults,
}

@Component({
  selector: 'tsm-supplier-validation-item',
  templateUrl: './supplier-validation-item.component.html',
  styleUrls: ['./supplier-validation-item.component.sass'],
})
export class SupplierValidationItemComponent implements OnInit {
  @Input() rowData: SupplierListResponseReportRow;
  @Input() massInviteProcessed: boolean = false;

  isCountryServedTruncated: boolean = false;
  isValidationResultsTruncated: boolean = false;
  rowHasError: boolean = false;
  doHideTooltip: boolean = true;
  maxLabelLengthCountryServed: number = 60;
  maxLabelLengthValidationResults: number = 80;

  labels: RowDataLabels = {
    supplierTsmId: '',
    supplierName: '',
    countryServed: '',
    rowNumber: '',
    validationResults: '',
    successMessage: '',
  };

  saProcessedFailed = 'Sustainability Assessment sending failed';
  saProcessedSuccessful = 'Sustainability Assessment sent successfully';
  internalServerError =
    'An unexpected error occurred, please contact your administrator';
  validationSuccessful = 'Validated Successfully';

  constructor(
    private appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    this.transformRowData();
  }

  private transformRowData() {
    if (this.rowData) {
      // Supplier Tsm Id
      this.labels.supplierTsmId = this.rowData.supplierTsmId;

      // Supplier Name
      this.labels.supplierName = this.rowData.name;

      // Countries Served
      this.mergeAndTruncateStrings(
        LabelType.countryServed,
        this.rowData.countryServed
      );

      // Row Number
      this.labels.rowNumber = this.rowData.rowNumber.toString();

      // Validation Result
      this.transformValidationResultsColumnData();
    }
  }

  private transformValidationResultsColumnData() {
    const hasRowValidationError =
      this.rowData.validations && this.rowData.validations.length > 0;
    const hasInternalServerError = this.rowData.errors.length > 0;

    this.rowHasError = !this.rowData.success;

    if (this.rowHasError) {
      if (hasRowValidationError) {
        this.mergeAndTruncateStrings(
          LabelType.validationResults,
          this.rowData.validations
        );
      } else if (hasInternalServerError) {
        this.labels.validationResults = this.massInviteProcessed
          ? this.saProcessedFailed + '; ' + this.internalServerError
          : this.internalServerError;
      }
    } else {
      this.labels.successMessage = this.massInviteProcessed
        ? this.saProcessedSuccessful
        : this.validationSuccessful;
    }
  }

  //make LabelType available to HTTP
  /* istanbul ignore next - no auts for getters*/
  public get labelType(): typeof LabelType {
    return LabelType;
  }

  private mergeAndTruncateStrings(labelType: LabelType, ar: string[]) {
    if (labelType === LabelType.countryServed) {
      if (ar.length === 1) {
        this.labels.countryServed = ar[0];
      } else {
        this.labels.countryServed = this.mergeStrings(ar, ", ");
      }
      this.createCountryServedTooltipText();
    }
    else if (labelType === LabelType.validationResults) {
      if (ar.length === 1) {
        this.labels.validationResults = this.massInviteProcessed
          ? this.saProcessedFailed + '; ' + ar[0]
          : ar[0];
      }
      else if (ar.length > 1) {
        const mergedString = this.mergeStrings(ar, "; ");
        this.labels.validationResults = this.massInviteProcessed
          ? this.saProcessedFailed + '; ' + mergedString
          : mergedString;
      }
      this.createValidationResultTooltipText();
    }
  }

  private createValidationResultTooltipText() {
    if (this.labels.validationResults.length > this.maxLabelLengthValidationResults) {
      this.labels.fullValidationResults = this.labels.validationResults;
      this.labels.validationResults = `${this.labels.validationResults
        .substring(0, this.maxLabelLengthValidationResults)
        .trim()}...`;
      this.isValidationResultsTruncated = true;
    }
  }

  private createCountryServedTooltipText() {
    if (this.labels.countryServed.length > this.maxLabelLengthCountryServed) {
      this.labels.fullCountryServed = this.labels.countryServed;
      this.labels.countryServed = `${this.labels.countryServed
        .substring(0, this.maxLabelLengthCountryServed)
        .trim()}...`;
      this.isCountryServedTruncated = true;
    }
  }

  private mergeStrings(ar: string[], separator: string): string {
    let mergedString: string = "";

    if (!ar || ar.length === 0) {
      return mergedString;
    }

    mergedString = ar[0];

    if (ar.length > 1) {
      for (let i: number = 1; i < ar.length; i++) {
        mergedString = mergedString + separator + ar[i];
      }
    }
    return mergedString;
  }

  showTooltip(evt: MouseEvent, labelType: LabelType): void {
    //prevents the tooltip from showing on non truncated labels
    if (
      (labelType === LabelType.countryServed &&
        !this.isCountryServedTruncated) ||
      (labelType === LabelType.validationResults &&
        !this.isValidationResultsTruncated)
    ) {
      return;
    }

    this.doHideTooltip = false;
    setTimeout(() => {
      if (!this.doHideTooltip) {
        let fullLabel: string;

        if (labelType === LabelType.countryServed) {
          fullLabel = this.labels.fullCountryServed;
        } else if (labelType === LabelType.validationResults) {
          fullLabel = this.labels.fullValidationResults;
        }

        this.appComponent.showGlobalTooltip(
          fullLabel,
          '',
          evt.pageX - 200,
          evt.pageY + 20
        );
      }
    }, 20);
  }

  hideTooltip(): void {
    this.doHideTooltip = true;
    setTimeout(() => {
      if (this.doHideTooltip) {
        this.appComponent.hideGlobalTooltip();
      }
    }, 30);
  }
}
