<div class="activitylogcontainer">
    <div class="row ms-3 mb-3">
        <div class="col-2">
            <div class="collabel">Date and Time </div>
        </div>
        <div class="col-10">
            <div class="collabel">Description</div>
        </div>
    </div>
    <div class="row ms-3 mb-2 header-border"></div>
    <div class="table-overflow">
        <div class="row ms-3 mb-2" *ngFor="let log of activityLogs">
            <ng-container *ngIf="log">
                <div class="col-2">
                    <div class="content">{{log.datetime | date:'dd MMM yyyy'}} </div>
                    <div class="subcontent">{{log.datetime | date:'H:mm:ss'}}</div>
                </div>
                <div class="col-10">
                    <div class="content" *ngIf="log.isProfileMerge;else isProfileMerge">
                        <a tabindex="0" (click)="openSupplier(log.secondaryTsmId)" class="btn btn-link p-0 text-start">
                            {{log.secondrySupplierName}}</a>
                        Profile is merged into <a tabindex="0" (click)="openSupplier(log.primaryTsmId)"
                            class="btn btn-link p-0 text-start"> {{log.primarySupplierName}}</a> Profile
                    </div>
                    <ng-template #isProfileMerge>
                        <div class="content">{{log.event}}</div>
                    </ng-template>
                    <div class="subcontent" *ngIf="log.by;else by">By {{log.by}}</div>
                    <ng-template #by>
                        <div class="subcontent">Buyer/Requestor not part of proc team anymore</div>
                    </ng-template>
                </div>
                <div class="row ms-3 header-border"></div>
            </ng-container>
        </div>
    </div>
</div>