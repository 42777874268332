<div class="modal-header">
    <h4 class="modal-title">{{fqaTitle}}</h4>
</div>
<div class="modal-body">
    <div class="body-content">
        <div *ngIf="isCreate || isEdit" class="modal-body-content">
            <div class="modal-body-content">
                <label class="select-label" for="category">{{categoryLabel}} <span class="red">{{tip}}</span> </label>
                <textarea class="category-input" [title]="categoryInfo" [(ngModel)]="categoryInfo" rows="5" cols="90"
                    maxlength="500" [placeholder]="'Insert a category'" (ngModelChange)="textChange()"></textarea>
            </div>
            <div class="modal-body-content">
                <label class="select-label" for="question">{{questionLabel}} <span class="red">{{tip}} </span> </label>
                <textarea class="question-input" [title]="questionInfo" [(ngModel)]="questionInfo" rows="5" cols="90"
                    maxlength="500" [placeholder]="'Insert a question'" (ngModelChange)="textChange()"></textarea>
            </div>
            <div class="modal-body-content">
                <label class="select-label" for="answer">{{answerLabel}} <span class="red">{{tip}}</span></label>
                <textarea class="textarea-text" [title]="answerInfo" [(ngModel)]="answerInfo" rows="5" cols="90"
                    [placeholder]="'Insert an answer to the question'" (ngModelChange)="textChange()"></textarea>
            </div>
        </div>
        <div *ngIf="isDelete" class="modal-body-content">
            <p class="deleteFaq">{{deleteTip}}</p>
        </div>
        <div *ngIf="isPublish" class="modal-body-content">
            <p class="deleteFaq">{{publishTip}}</p>
        </div>
        <div class="button-content">
            <sh-button ngbAutofocus [props]="cancelProps"></sh-button>
            <sh-button class="saveButton" ngbAutofocus [props]="saveProps"></sh-button>
        </div>
    </div>
</div>