import { Injectable } from "@angular/core";
import { CustomFilter } from "@lsl16/sustainability-shared-components";
import { ComponentConfig } from "@lsl16/sustainability-shared-components";
import { DynamicContext } from "@lsl16/sustainability-shared-components";
import { UntypedFormGroup } from "@angular/forms";
import { Context } from "./constants";

@Injectable()
export class CustomFilters {
    @CustomFilter("hasSupplierUsers")
    hasSupplierUsers(config: ComponentConfig, context: DynamicContext, formGroup: UntypedFormGroup) {
        return (): boolean => {
            const orgUsers = context.getValue(Context.ORGANISATION_USERS);
            return (orgUsers && orgUsers.length > 0) || false;
        };
    }
}