import { Component, OnInit } from "@angular/core";
import { BaseContainerComponent } from "src/app/components/base-container/base-container.component";

@Component({
    selector: "tsm-supplier-profile-container",
    templateUrl: "./supplier-profile-container.component.html",
    styleUrls: ["./supplier-profile-container.component.sass"]
})
export class SupplierProfileContainerComponent extends BaseContainerComponent implements OnInit {
    ngOnInit(): void {
        super.ngOnInit();
    }
}
