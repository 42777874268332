import { Component, OnInit, Input } from '@angular/core';
import { SubsidiarySupplier, SupplierRelationUpdateSuccessEventType } from '@lsl16/sustainability-shared-components';
import { SupplierRelationshipService } from '@lsl16/sustainability-shared-components';

type parentSubsidiarySupplierLiterals = {
  companyName: string;
  location: string;
  pointOfContact: string;
}

@Component({
  selector: 'tsm-parent-subsidiary-suppliers',
  templateUrl: './parent-subsidiary-suppliers.component.html',
  styleUrls: ['./parent-subsidiary-suppliers.component.sass']
})
export class ParentSubsidiarySuppliersComponent implements OnInit {
  @Input() parentSupplierTsmId: string;
  @Input() isSupplier: boolean;
  @Input() subSupplierMode: boolean = false;
  @Input() displayButtons: boolean = false;
  @Input() isDuplicate = false;

  subsidiarySuppliers: Array<SubsidiarySupplier>;
  labelTranslation: parentSubsidiarySupplierLiterals;
  isSubsidiaryUser: boolean = false;
  currentParentSupplierTsmId: string;

  constructor(
    private supplierRelationshipService: SupplierRelationshipService
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentParentSupplierTsmId = this.parentSupplierTsmId;
    this.isSubsidiaryUser = await this.isSubsidiaryUserAcessParent();
    await this.fetchSubsidiarySuppliers();
    this.subscribeEvent();
  }

  async ngDoCheck(): Promise<void> {
    if (this.currentParentSupplierTsmId !== this.parentSupplierTsmId) {
      this.currentParentSupplierTsmId = this.parentSupplierTsmId;
      this.isSubsidiaryUser = await this.isSubsidiaryUserAcessParent();
      await this.fetchSubsidiarySuppliers();
    }
  }

  private subscribeEvent() {
    this.supplierRelationshipService.getSupplierRelationUpdateSuccessEvent()
      .subscribe(next => this.handleSupplierRelationUpdateSuccessEvent(next));
  }

  async handleSupplierRelationUpdateSuccessEvent(eventType: SupplierRelationUpdateSuccessEventType) {
    if (eventType === "SubsidiaryRelationshipRequestCancelled") {
      await this.fetchSubsidiarySuppliers();
    }
  }

  async isSubsidiaryUserAcessParent(): Promise<boolean> {
    if (this.isSupplier) {
      const parentAccess = await this.supplierRelationshipService.hasAccessToParentSupplier(this.parentSupplierTsmId);
      if (parentAccess.subsidiary) {
        return true;
      }
    }
    return false;
  }

  async fetchSubsidiarySuppliers(): Promise<void> {
    try {
      const response =
        await this.supplierRelationshipService.getSubsidiarySuppliers(
          this.parentSupplierTsmId,
        );
      this.subsidiarySuppliers = response;
    } catch (error) {
      console.error(`Failed to get subsidiary supplier users for parent ${this.parentSupplierTsmId}.`, error);
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem("multiLanguage")) {
      const multilanguageJson = JSON.parse(localStorage.getItem("multiLanguage"));
      if (multilanguageJson.body != undefined) {
        this.labelTranslation = multilanguageJson.body.main.parentSubsidiarySupplier;
      }
    }
  }
}
