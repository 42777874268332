import { FilterData, FilterType, fromToDateFilter } from './../../services/esgReport/esg-report.service';
import { Component, OnInit } from '@angular/core';
import { BaseSupplierFilterComponent } from 'src/app/components/base-filter/base-supplier-filter.component';

@Component({
  selector: 'tsm-sascoring-report',
  templateUrl: './sascoring-report.component.html',
  styleUrls: ['./sascoring-report.component.sass']
})
export class SAScoringReportComponent extends BaseSupplierFilterComponent implements OnInit {
  private filtersToApply: FilterType[] = [
    'supplierName',
    'countryServed',
    'marketUnit',
    'requestDateFrom',
    'requestDateTo',
    'requestPurpose',
    'internalPoCEmail',
    'diligenceEndDateFrom',
    'diligenceEndDateTo',
    'requestorEmail',
    'esgSubmissionDateFrom',
    'esgSubmissionDateTo',
    'esgStatus',
    'globalUltimateParentDUNS',
    'includeIndirect',
    'supplierCategory',
    'includeCompliant',
    'supplierTsmId'
  ];
  private emailTypes: FilterType[] = ['requestorEmail', 'internalPoCEmail'];
  private dateTypes: fromToDateFilter[] = [
    { from: 'esgSubmissionDateFrom', to: 'esgSubmissionDateTo' },
    { from: 'requestDateFrom', to: 'requestDateTo' },
    { from: 'diligenceEndDateFrom', to: 'diligenceEndDateTo' },
  ];

  constructor() {
    super()
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFilterCriteria();
    this.initButtons();
  }

  initFilterCriteria() {
    this.filterCriteria = {
      countryServed: null,
      diligenceEndDateFrom: null,
      diligenceEndDateTo: null,
      esgStatus: null,
      esgSubmissionDateFrom: null,
      esgSubmissionDateTo: null,
      internalPoCEmail: "",
      marketUnit: null,
      requestDateFrom: null,
      requestDateTo: null,
      requestPurpose: null,
      requestorEmail: "",
      supplierName: "",
      globalUltimateParentDUNS: "",
      includeIndirect: false,
      supplierCategory: null,
      supplierTsmId:""
    };
    this.filterCriteriaPayload = {
      countryServed: null,
      diligenceEndDateFrom: null,
      diligenceEndDateTo: null,
      esgStatus: null,
      esgSubmissionDateFrom: null,
      esgSubmissionDateTo: null,
      internalPoCEmail: "",
      marketUnit: null,
      requestDateFrom: null,
      requestDateTo: null,
      requestPurpose: null,
      requestorEmail: "",
      supplierName: "",
      globalUltimateParentDUNS: "",
      includeIndirect: false,
      supplierCategory: null,
      supplierTsmId:""
    }
  }

  initButtons() {
    this.clearFilterButton = {
      label: "",
      color: "purple",
      clickBorderColor: "purple",
      backgroundColor: "lightPurple",
      hoverBorderColor: "purple",
      fontSize: 16,
      height: 48,
      width: 180,
      onClick: async () => {
        this.clearFilters();
      }
    };
    this.applyFilterButton = {
      label: "",
      color: "white",
      fontSize: 16,
      backgroundColor: "blue",
      borderColor: "blue",
      height: 48,
      width: 180,
      onClick: async () => {
        this.applyFilters();
      }
    };
  }

  applyFilters() {
    this.invalidFields = [];
    this.filterCriteriaPayload = { ...this.filterCriteria };
    this.checkDuns('globalUltimateParentDUNS');
    this.emailTypes.forEach((filter) => {
      this.checkEmail(filter);
    });

    this.dateTypes.forEach((filter) => {
      this.validateDates(filter);
      this.formatPayloadDate(filter);
    });

    if (Object.keys(this.invalidFields).length > 0) {
      return
    }
    const filterData: FilterData[] = this.filtersToApply.filter((filterType) => this.includeFilterInPayload(filterType))
      .map(filter => ({
        filterName: filter,
        filterValue: this.filterCriteriaPayload[filter]
      }));
    this.setFilterButtonsDisabledProps(false);
    this.applyFilterEventEmitter.emit({ reportType: 'sa_scoring', filters: filterData });
    this.scheduleWaitingMessage();
  }

  clearFilters() {
    this.initFilterCriteria();
    this.clearFilter.next(true);
  }

  includeFilterInPayload(saFiltertype: FilterType): boolean {
    // compliant filter value needs to be explicilty included
    if (saFiltertype === "includeCompliant") {
      return true;
    }

    return !!this.filterCriteriaPayload[saFiltertype];
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.reportProps.heading = multilanguageJson.body.main.reportFilters.heading;
        this.applyFilterButton.label = multilanguageJson.body.main.reportFilters.applyFilter;
        this.clearFilterButton.label = multilanguageJson.body.main.reportFilters.clearFilter;
        this.waitingMessageLabel = multilanguageJson.body.main.reportFilters.waitingMessage;
      }
    }
  }
}
