import { Injectable } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class EmailTemplateService {
    private baseUrl = `${environment.SUSHUBEMAILServiceURL}`;
    constructor(private httpService: HttpService) { }

    getData = async (): Promise<any> => {
        const getEmailTypes = `${this.baseUrl}/email-types/`;
        return await this.httpService.GetPromise(getEmailTypes);
    }

    updateData = async (payload): Promise<any> => {
        const getRecordsUrl = `${this.baseUrl}/email-templates/update/${payload.emailTemplateId}`;
        return await this.httpService.PostPromise(getRecordsUrl, payload.templateData);
    }
    deleteData = async (payload): Promise<any> => {
        const deleteTemplateUrl = `${this.baseUrl}/email-templates/delete/${payload.templateId}`;
        return await this.httpService.PostPromise(deleteTemplateUrl, payload.templateData);
    }
}
